import React from "react";

export const FileSize = (props: { size?: number }) => {
  let label = "bytes";
  let size = props.size;

  if (size === null || size === undefined) {
    return <></>;
  }

  if (size / 2 ** 20 > 1) {
    label = "MB";
    size = Math.round(size / 2 ** 20);
  } else if (size / 1024 > 1) {
    label = "KB";
    size = Math.round(size / 1024);
  }

  return (
    <span>
      {size} {label}
    </span>
  );
};
