import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import SplunkRum from "@splunk/otel-web";
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import {
  NavLink,
  Outlet,
  Route,
  Routes,
  // See https://github.com/remix-run/react-router/issues/8264#issuecomment-991271554
  unstable_HistoryRouter as HistoryRouter,
} from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { BrowserWarning } from "./components/BrowserWarning";
import * as Modal from "./components/Modal";
import { NavBar } from "./components/NavBar";
import { ProfileLink } from "./components/ProfileLink";
import { LoadingMessage } from "./components/tasks/LoadingMessage";
import ErrorHandler from "./components/utils/ErrorHandler";
import { history } from "./index";
import { AUDIENCE, triggerPasswordChange, userHasPermission } from "./lib/Auth";
import { loadIconLibrary } from "./lib/Icons";
import * as api from "./lib/MAPApi";
import * as Keys from "./pages/APIKeyManagementPage";
import * as AdminEmail from "./pages/AdminEmailSubmissionManagementPage";
import * as Users from "./pages/UserManagementPage";
import { DevOpsPage } from "./pages/DevOpsPage";
import { ErrorTestPage } from "./pages/ErrorTestPage";
import { Home } from "./pages/Home";
import * as IC from "./pages/InteractiveCuckooPage";
import * as IWA from "./pages/InteractiveWebAnalyzerPage";
import { JobDetail } from "./pages/JobDetail";
import { KnowledgeCenterPage } from "./pages/KnowledgeCenterPage";
import { LogsPage } from "./pages/LogsPage";
import {
  MaintenancePage,
  UpdateAndMaintenanceChecker,
} from "./pages/MaintenancePage";
import { AdminRecentJobs, RecentJobs } from "./pages/RecentJobs";
import * as RepProxy from "./pages/RepProxy";
import { SearchPage } from "./pages/SearchPage";
import { SharedJobDetail } from "./pages/SharedJobDetail";

const DocsPage = React.lazy(() => import("./pages/DocsPage"));

const IOCSearchPage = React.lazy(() => import("./pages/IOCSearchPage"));

const App = () => {
  useEffect(() => loadIconLibrary(), []);

  const AuthApp = withAuthenticationRequired(AuthenticatedApp);

  return (
    <>
      <BrowserWarning />
      <api.APIContext.Provider value={{ api: api }}>
        <HistoryRouter history={history}>
          {window.location.pathname !== "/maintenance" ? (
            <UpdateAndMaintenanceChecker />
          ) : null}
          <div className="App">
            <ToastContainer
              autoClose={3000}
              pauseOnFocusLoss={false}
              theme="colored"
            />
            <Modal.Container />
            <ErrorHandler>
              <Routes>
                <Route
                  path="/maintenance"
                  exact={true}
                  element={<MaintenancePage />}
                />
                <Route
                  path="/shared/:jobid/:shareToken"
                  element={<SharedJobDetail />}
                />
                {/* default routes below */}
                <Route path="/*" element={<AuthApp />} />
              </Routes>
            </ErrorHandler>
          </div>
        </HistoryRouter>
      </api.APIContext.Provider>
    </>
  );
};
export default App;

function AuthenticatedApp() {
  useEffect(() => {
    loadIconLibrary();
  }, []);

  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
  } = useAuth0();

  const [token, setToken] = useState(null);

  const onChangePassword = () => {
    triggerPasswordChange(user.email)
      .then(() => {
        toast.success("Check your inbox to complete changing your password");
      })
      .catch((err) => {
        SplunkRum.error(err, { action: "password reset" });
        toast.error("Failed to reset your password. Please try again", {
          autoClose: false,
        });
      });
  };

  useEffect(() => {
    let recordSession = false;
    if (user && token) {
      let tenantID;

      try {
        const d = jwt_decode(token);
        tenantID = d["https://map.twinwave.io/TenantID"];
        recordSession = (d["permissions"] || []).includes("analytics:record");
      } catch (_) {}

      const { email, sub: id } = user;

      SplunkRum.setGlobalAttributes({
        "enduser.id": id,
        "enduser.email": email,
        "enduser.tenantID": tenantID,
      });
      if (recordSession) {
        // TODO Is this possible?
      }
    }

    return () => {
      SplunkRum.setGlobalAttributes({
        "enduser.id": undefined,
        "enduser.email": undefined,
        "enduser.tenantID": undefined,
      });
      // TODO Stop session recording if needed
      if (recordSession) {
      }
    };
  }, [user, token]);

  useEffect(() => {
    let ignore = false;

    (async () => {
      try {
        const token = await getAccessTokenSilently({ audience: AUDIENCE });
        if (!ignore) {
          setToken(token);
        }
      } catch (err) {
        console.log("error getting access token:", err);
        if (err.error === "consent_required") {
          try {
            SplunkRum.error("Auth0 Consent Required");
            const token = await getAccessTokenWithPopup({ audience: AUDIENCE });
            if (!ignore) {
              setToken(token);
            }
          } catch (err) {
            console.log("error getting access token:", err);
          }
        }
      }
    })();

    return () => {
      ignore = true;
    };
  }, [getAccessTokenSilently, getAccessTokenWithPopup]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    SplunkRum.error(error);
    throw new Error(error);
  }

  if (!isAuthenticated) {
    // this SHOULD be impossible, but we might as well return here so we don't fall through
    return (
      <div className="App">
        <NavBar />
        <button onClick={loginWithRedirect}>Log in</button>
      </div>
    );
  }

  return (
    <>
      <NavBar
        leftItems={
          <>
            <NavLink
              className={(isActive) =>
                "navbar-item" + (isActive ? " is-active" : "")
              }
              to="/recentjobs"
            >
              <span className="is-size-5">Recent</span>
            </NavLink>
            <NavLink
              className={(isActive) =>
                "navbar-item" + (isActive ? " is-active" : "")
              }
              to="/search"
            >
              <span className="is-size-5">Search</span>
            </NavLink>
            <IWA.MenuItem />
            <IC.MenuItem />
            {userHasPermission(token, "app:admin") && (
              <>
                <NavLink
                  className={(isActive) =>
                    "navbar-item" + (isActive ? " is-active" : "")
                  }
                  to="/allrecents"
                >
                  <span className="is-size-5">All Jobs</span>
                </NavLink>
                <NavLink
                  className={(isActive) =>
                    "navbar-item" + (isActive ? " is-active" : "")
                  }
                  to="/forensicssearch"
                >
                  <span className="is-size-5">Search Forensics</span>
                </NavLink>
              </>
            )}
          </>
        }
        rightItems={
          <>
            {user ? (
              <ProfileLink
                onChangePassword={onChangePassword}
                onLogout={(evt) => {
                  logout({ returnTo: window.location.origin });
                }}
                profile={user}
              />
            ) : null}
          </>
        }
      />
      <Outlet />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/job/:jobid" element={<JobDetail />} />
        <Route
          path="/shared/:jobid/:shareToken"
          element={<SharedJobDetail />}
        />
        <Route path="/recentjobs" element={<RecentJobs />} />
        <Route path={IWA.PATH} element={<IWA.NewSession />} />
        <Route path={IC.PATH} element={<IC.NewSession />} />
        <Route path="/etest" element={<ErrorTestPage />} />
        <Route path="/search" element={<SearchPage />} />
        <Route
          path="/docs"
          element={
            <React.Suspense fallback={<LoadingMessage />}>
              <DocsPage />
            </React.Suspense>
          }
        />
        <Route
          path="/knowledgecenter"
          element={
            <React.Suspense fallback={<LoadingMessage />}>
              <KnowledgeCenterPage />
            </React.Suspense>
          }
          exact={true}
        />
        <Route
          path="/knowledgecenter/:video"
          element={
            <React.Suspense fallback={<LoadingMessage />}>
              <KnowledgeCenterPage />
            </React.Suspense>
          }
        />
        {userHasPermission(token, "app:admin") && (
          // TODO: Also route this block for tenant:manage when ready
          <>
            <Route exact path={Users.PATH} element={<Users.ListUsers />} />
            <Route path={Users.PATH_CREATE} element={<Users.CreateForm />} />
            <Route
              path={Users.PATH_CREATE_WITH_TENANT}
              element={<Users.CreateForm />}
            />
            <Route exact path={Users.PATH_EDIT} element={<Users.EditForm />} />
          </>
        )}
        {userHasPermission(token, "app:admin") && (
          <>
            <Route exact path={Keys.PATH} element={<Keys.ListKeys />} />
            <Route
              exact
              path={Keys.PATH_CREATE}
              element={<Keys.CreateForm />}
            />
            <Route exact path={Keys.PATH_EDIT} element={<Keys.EditForm />} />

            <Route
              exact
              path={AdminEmail.PATH}
              element={<AdminEmail.ListConfigs />}
            />
            <Route
              exact
              path={AdminEmail.PATH_CREATE}
              element={<AdminEmail.CreateForm />}
            />
            <Route
              exact
              path={AdminEmail.PATH_EDIT}
              element={<AdminEmail.EditForm />}
            />

            <Route path="/allrecents" element={<AdminRecentJobs />} />

            <Route
              path="/forensicssearch"
              element={
                <React.Suspense fallback={<LoadingMessage />}>
                  <IOCSearchPage />
                </React.Suspense>
              }
            />
            <Route path="/devops" element={<DevOpsPage />} />
            <Route path={RepProxy.PATH} element={<RepProxy.RepProxy />} />
            <Route path="/logs/*" element={<LogsPage />} />
          </>
        )}
      </Routes>
    </>
  );
}
