import { withAuth0 } from "@auth0/auth0-react";
import Tooltip from "@cypress/react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SplunkRum from "@splunk/otel-web";
import React from "react";
import { toast } from "react-toastify";
import { withRouter } from "src/components/utils/RouterUtils";
import { UserHasPermission } from "src/lib/Auth";
import { FileSize } from "./FileSize";
import { APIContext } from "../lib/MAPApi";
import { decodeURL, URLIsEncoded } from "../lib/URLDecode";
import { PlainSourceString } from "./forensics/SourceCodeString";
import * as Modal from "./Modal";
import { VPN_REGIONS } from "./SubmissionParameters";
import { UADeviceSelector } from "./UADeviceSelector";
import { taskDisplayName } from "./utils/DisplayNames";
import { ThreatName } from "./utils/ThreatName";
import { ToggleSwitch } from "./utils/ToggleSwitch";

const MAX_FILE_SIZE = 500 * 1024 * 1024;
const PASSWORD_HELP =
  "In addition to any supplied password, Attack Analyzer also uses a list of well known passwords to attempt to brute force decrypt the document/archive.";
const URL_BATCH_SUBMIT_PRIORITY = "15"; // Low priority
const URL_BATCH_LIMIT = 25;

class SubmitFormInternal extends React.Component {
  state = {
    selectedFile: null,
    urlBatch: "",
    validURLBatch: true,
    invalidURLBatchReason: null,
    urlBatchUniqueList: new Set(),
    urlBatchMessages: [],
    urlBatchSubmissionProgress: 0,
    uploading: false,
    selectedTab: "basic",
    engines: new Map(),
    archivePassword: "",
    decodeURLs: true,
    allowCachedResults: false,
    allowInternetAccess: true,
    exitRegion: "",
    userAgent: "alias:Default",
    osVersion: "win10",
    sandboxTimeout: "3",
    sandboxBrowser: "chrome",
  };

  static contextType = APIContext;

  fileRef = React.createRef();

  handleURLBatchField = (evt) => {
    let urlBatch = evt.target.value;

    // Error out if more than max allowed entries
    // Disregard the empty lines
    let urls = urlBatch.split("\n");
    if (urls.length > URL_BATCH_LIMIT) {
      let uniqueUrls = new Set(urls);
      uniqueUrls.delete(""); // Remove the empty line, if exists
      if (uniqueUrls.size > URL_BATCH_LIMIT) {
        this.setState({
          urlBatch: urlBatch,
          validURLBatch: false,
          invalidURLBatchReason: `URL batch size is limited to ${URL_BATCH_LIMIT} (${uniqueUrls.size} unique urls currently entered)`,
        });
        return;
      }
    }

    // URL decoding happens at submission time

    for (let i = 0; i < urls.length; i++) {
      // Now make sure the url is valid
      if (i === urls.length - 1 && urls[i].length < 10) {
        // If last URL is less than 10 characters long,
        // lets not worry about validating it
        continue;
      }
      let isValid = this.URLIsValid(urls[i]);
      if (!isValid) {
        this.setState({
          urlBatch: urlBatch,
          validURLBatch: false,
          invalidURLBatchReason:
            "On line " + (i + 1) + " : " + this.invalidURLReason(urls[i]),
        });
        // As soon as one URL fails, we don't do further validation
        return;
      }
    }

    // everything valid...
    this.setState({
      urlBatch: urlBatch,
      validURLBatch: true,
      invalidURLBatchReason: null,
    });
  };

  handlePasswordField = (evt) => {
    this.setState({
      archivePassword: evt.target.value,
    });
  };

  URLIsValid = (url) => {
    if (url === null || url === undefined || url.length === 0) {
      return true;
    }

    const validURL = /^\s*h(tt|xx)ps?:\/\/[^\s]+\s*$/i;

    return validURL.test(url);
  };

  invalidURLReason = (url) => {
    if (!/^\s*h(tt|xx)ps?:\/\//i.test(url)) {
      return "URL must begin with http:// or https://";
    }
    if (/\s/.test(url.trim())) {
      return "URL cannot contain whitespace characters";
    }

    return "";
  };

  defangURL = (url) => {
    return url.replace(/^\s*hxxp/i, "http").replace(/\[\.]/g, ".");
  };

  FileSizeIsValid = (file) => {
    return !(file.size === 0 || file.size > MAX_FILE_SIZE);
  };

  parameters = (context) => {
    let parameters = [];
    if (this.state.archivePassword) {
      parameters.push({
        Name: "archive_document_password",
        Value: this.state.archivePassword,
      });
    }

    if (typeof this.state.decodeURLs === "boolean") {
      parameters.push({
        Name: "decode_rewritten_urls",
        Value: `${this.state.decodeURLs}`,
      });
    }

    if (typeof this.state.allowCachedResults === "boolean") {
      parameters.push({
        Name: "allow_cached_results",
        Value: `${this.state.allowCachedResults}`,
      });
    }

    if (typeof this.state.allowInternetAccess === "boolean") {
      parameters.push({
        Name: "allow_internet_access",
        Value: `${this.state.allowInternetAccess}`,
      });
    }

    if (typeof this.state.exitRegion === "string" && this.state.exitRegion) {
      parameters.push({
        Name: "wa_exit_region",
        Value: this.state.exitRegion,
      });
    }

    if (
      typeof this.state.userAgent === "string" &&
      this.state.userAgent &&
      this.state.userAgent !== "alias:Default" &&
      this.state.userAgent !== "custom"
    ) {
      parameters.push({
        Name: "user_agent",
        Value: this.state.userAgent,
      });
    }

    if (this.isMode("interactive_sandbox")) {
      parameters.push(
        { Name: "cuckoo_interactive_mode", Value: "true" },
        { Name: "sandbox_timeout", Value: this.state.sandboxTimeout }
      );
      if (context === "URL") {
        // only push package for URL jobs as we are just controlling the browser
        parameters.push({
          Name: "sandbox_package",
          Value: this.state.sandboxBrowser,
        });
      }
    }

    return parameters;
  };

  handleJobSubmit = (evt) => {
    if (this.state.selectedFile) {
      this.handleFileSubmit(evt);
    } else if (this.state.urlBatch.length > 0) {
      this.handleURLBatchSubmit(evt);
    } else {
      toast.warn("No valid input to analyze", {});
    }
  };

  handleURLBatchSubmit = async (evt) => {
    evt.preventDefault();

    // Make sure, the input is not empty
    if (this.state.urlBatch === "") {
      toast.warn("No valid input to analyze", {});
      return;
    }

    // Split the urls into individual urls
    let urls = this.state.urlBatch.split("\n");

    // Get unique urls to make sure we are in the limits
    // One caveat here is - if an encoded url and corresponding decoded url are submitted,
    // they will be counted as two for this validation.
    let uniqueUrls = new Set(urls);
    uniqueUrls.delete(""); // Remove the entry representing the empty line if any

    // If the number of rows (excluding the duplicates) exceed the limit...
    if (uniqueUrls.size > URL_BATCH_LIMIT) {
      toast.error(
        `URL batch size is limited to ${URL_BATCH_LIMIT} (${uniqueUrls.size} currently entered)`,
        {
          autoClose: false,
        }
      );
      return;
    }

    // Track if at least one URL is decoded
    let decodedUrlCount = 0;
    // We still want to go through all the entries to preserve the line number.
    // Otherwise, using uniqueURLs would have been better
    let alreadySeenUrls = new Set();
    alreadySeenUrls.add("");
    for (let i = 0; i < urls.length; i++) {
      if (alreadySeenUrls.has(urls[i])) {
        // disregard already seen URLs as duplicates
        continue;
      }
      alreadySeenUrls.add(urls[i]);

      // First verify if URL decoding is needed
      if (URLIsEncoded(urls[i]) && this.state.decodeURLs) {
        try {
          let decodedUrl = decodeURL(urls[i]);
          uniqueUrls.delete(urls[i]); // delete encoded url
          uniqueUrls.add(decodedUrl); // add decoded url
          decodedUrlCount++;
        } catch (err) {
          console.log("Error decoding URL on line " + (i + 1) + " : " + err);
          toast.error("Failed to decode URL on line " + (i + 1), {
            autoClose: false,
          });
          // If an error encountered, stop further processing
          return;
        }
      }

      // Check that all the submitted URLs are valid...
      let isValid = this.URLIsValid(urls[i]);
      if (!isValid) {
        let reason = this.invalidURLReason(urls[i]);
        let msg = `Invalid URL submitted on line ${i + 1}`;
        if (reason) {
          msg += ` (${reason})`;
        }
        toast.error(msg, {
          autoClose: false,
        });
        return;
      }
    }

    // Check if valid engines are selected...
    if (!this.atLeastOneValidEngineSelected("URL")) {
      toast.error("Invalid Engine Selection: Enable at least 1 URL Engine.", {
        autoClose: false,
      });
      return;
    }

    // Track if duplicates are removed
    let duplicatesRemoved = 0;
    if (urls.length > uniqueUrls.size) {
      // get the count of duplicates removed
      duplicatesRemoved =
        urls.filter((entry) => entry.length > 0).length - uniqueUrls.size;
    }

    let msgs = [];
    if (decodedUrlCount > 0) {
      if (decodedUrlCount > 1) {
        msgs.push(`${decodedUrlCount} URLs were decoded/rewritten`);
      } else {
        msgs.push(`${decodedUrlCount} URL is decoded/rewritten`);
      }
    }
    if (duplicatesRemoved > 0) {
      if (duplicatesRemoved > 1) {
        msgs.push(`${duplicatesRemoved} duplicate URLs were deleted`);
      } else {
        msgs.push(`${duplicatesRemoved} duplicate URL is deleted`);
      }
    }

    if (msgs.length === 0 && uniqueUrls.size === 1) {
      // Only in the scenario where input is not modified and only 1 unique url is submitted
      // no need of a confirmation model. Call submitBatch directly
      await this.submitBatch(uniqueUrls);
    } else {
      // Otherwise, set the state variables for Confirmation popup
      this.setState({ urlBatchUniqueList: uniqueUrls, urlBatchMessages: msgs });
    }
  };

  submitBatch = async (uniqueUrls) => {
    // When a single URL is submitted. This could be in any mode.
    // However, in interactive_web and interactive_sandbox, this is always true
    // Also when a single URL is present, we don't want to change the submission priority
    if (uniqueUrls.size === 1) {
      await this.submitSingleUrl(uniqueUrls.values().next().value);
    } else {
      // Set default priority for batch submit
      let params = this.parameters("URL");
      params.push({ Name: "priority", Value: URL_BATCH_SUBMIT_PRIORITY });

      const jobPromiseArr = []; // Keep track of all the async calls
      let i = 0; // keeping the count of the submissions to advance the progress bar
      for (let url of uniqueUrls) {
        i++;
        // Update the progress bar...
        this.setState({
          urlBatchSubmissionProgress: (100 / uniqueUrls.size) * i,
        });

        // Make the API call
        const jobPromise = this.context.api
          .callAPI(
            this.props.auth0.getAccessTokenSilently,
            this.context.api.submitURL,
            this.defangURL(url),
            await this.enginesForSubmission(),
            params
          )
          .then(({ JobID }) => {
            this.props.submitHandler(JobID);
            return 1; // We will use this counter when processing the values
          })
          .catch((err) => {
            SplunkRum.error(err, { action: "URL submit" });
            toast.error("Error submitting URL : " + url + " : " + err, {
              autoClose: false,
            });
            return 0;
          });
        jobPromiseArr.push(jobPromise);

        // sleep for 250 ms before next submission to avoid rate limit issues
        await new Promise((resolve) => setTimeout(resolve, 250));
      }

      // Wait for all promises to figure out how many succeeded/failed
      Promise.all(jobPromiseArr).then((values) => {
        let success = 0;
        values.forEach((value) => (success += value));

        let failed = uniqueUrls.size - success;
        if (failed > 0) {
          toast.warn(
            "URL Batch submitted for scanning : " +
              success +
              " success and " +
              failed +
              " failed"
          );
        } else {
          toast.success(
            "URL Batch of " + uniqueUrls.size + " submitted for scanning"
          );
        }
      });
    }

    // reset everything after success
    this.setState({
      urlBatch: "",
      urlBatchSubmissionProgress: 0,
      urlBatchUniqueList: new Set(),
      urlBatchMessages: [],
    });
  };

  submitSingleUrl = async (url) => {
    this.context.api
      .callAPI(
        this.props.auth0.getAccessTokenSilently,
        this.context.api.submitURL,
        this.defangURL(url),
        await this.enginesForSubmission(),
        this.parameters("URL")
      )
      .then(({ JobID }) => {
        if (this.isMode("interactive_web")) {
          this.props.router.navigate("/interactivewebanalyzer", {
            state: { JobID },
          });
        } else if (this.isMode("interactive_sandbox")) {
          this.props.router.navigate("/interactivesandbox", {
            state: {
              JobID,
              sandboxTimeout: this.state.sandboxTimeout,
            },
          });
        } else {
          toast.success("URL submitted for scanning");
          this.props.submitHandler(JobID);
        }
      })
      .catch((err) => {
        SplunkRum.error(err, { action: "URL submit" });
        toast.error("Error submitting URL: " + err, {
          autoClose: false,
        });
      });
  };

  handleFileSelect = (evt) => {
    evt.preventDefault();

    if (this.fileRef.current.files.length < 1) {
      return;
    }

    this.setState({ selectedFile: this.fileRef.current.files[0] });
  };

  handleFileSubmit = async (evt) => {
    evt.preventDefault();

    if (!this.state.selectedFile) {
      return;
    }

    if (!this.FileSizeIsValid(this.state.selectedFile)) {
      toast.error("File has invalid size", {
        autoClose: false,
      });
      return;
    }

    if (!this.atLeastOneValidEngineSelected("file")) {
      toast.error("Invalid Engine Selection: Enable at least 1 File Engine.", {
        autoClose: false,
      });
      return;
    }

    this.setState({ uploading: true });

    this.context.api
      .callAPI(
        this.props.auth0.getAccessTokenSilently,
        this.context.api.submitFile,
        this.state.selectedFile,
        await this.enginesForSubmission(),
        this.parameters("file")
      )
      .then(({ JobID }) => {
        if (this.isMode("interactive_web")) {
          this.props.router.navigate("/interactivewebanalyzer", {
            state: { JobID },
          });
        } else if (this.isMode("interactive_sandbox")) {
          this.props.router.navigate("/interactivesandbox", {
            state: {
              JobID,
              sandboxTimeout: this.state.sandboxTimeout,
            },
          });
        } else {
          toast.success("File submitted for scanning");
          this.props.submitHandler(JobID);
          this.setState({ uploading: false, selectedFile: null });
        }
      })
      .catch((err) => {
        SplunkRum.error(err, { action: "file submit" });
        if (err.message === "Failed to fetch") {
          toast.error(
            "Error submitting File: Network Error. This error typically indicates that a web proxy or other security device blocked the file from being uploaded to Attack Analyzer",
            {
              autoClose: false,
            }
          );
        } else {
          toast.error("Error submitting File: " + err, {
            autoClose: false,
          });
        }
        this.setState({ uploading: false, selectedFile: null });
      });
  };

  /**
   *
   * @param {"basic"|"advanced"|"interactive_web"|"interactive_sandbox"} tab
   * @returns
   */
  selectTab = (tab) => (evt) => {
    evt.preventDefault();
    if (tab === "advanced") this.loadEngines();
    let stateMap = { selectedTab: tab };
    // When we switch from basic/advanced to interactive_*,
    // input will be set to blank
    if (
      (this.state.selectedTab === "basic" ||
        this.state.selectedTab === "advanced") &&
      (tab === "interactive_web" || tab === "interactive_sandbox")
    ) {
      stateMap = {
        ...stateMap,
        urlBatch: "",
        validURLBatch: true,
        invalidURLBatchReason: "",
      };
    }
    this.setState(stateMap);
  };

  loadEngines = () => {
    if (this.state.engines.size === 0) {
      this.context.api
        .callAPI(
          this.props.auth0.getAccessTokenSilently,
          this.context.api.getEngines
        )
        .then((resp) => {
          let engines = new Map();
          resp.forEach((e) => {
            engines.set(e.Name, { config: e, checked: e.DefaultEnabled });
          });
          this.setState({ engines: engines });
        });
    }
  };

  toggleEngine = (engine) => {
    let engines = this.state.engines;
    let e = engines.get(engine);
    e.checked = !e.checked;

    engines.set(engine, e);

    this.setState({ engines: engines });
  };

  /**
   * Helper method to customize labels on special conditions
   * Ex. SAA-299 : Adding "(public)" for Hybrid Analysis
   */
  getLabel = (configName) => {
    let label = taskDisplayName(configName);
    if (configName === "hybridanalysis") {
      label = label + " (public)";
    }
    return label;
  };

  /**
   *
   * @param {"basic"|"advanced"|"interactive_web"|"interactive_sandbox"} mode
   * @returns {boolean}
   */
  isMode = (mode) => this.state.selectedTab === mode;

  enginesForSubmission = async () => {
    let engines = [];

    if (this.isMode("advanced")) {
      for (let [engineName, engineState] of this.state.engines) {
        if (engineState.checked) {
          engines.push(engineName);
        }
      }
    } else if (this.isMode("interactive_web")) {
      // If we're not in advanced and IWA is implicitly selected, we must manually
      // grab the default engines as there is no way to say defaults+iwa without
      // being explicit.
      const options = await this.context.api.callAPI(
        this.props.auth0.getAccessTokenSilently,
        this.context.api.getEngines
      );

      engines = options.filter((o) => o.DefaultEnabled).map(({ Name }) => Name);

      engines.push("interactive_web_analyzer");
    } else if (this.isMode("interactive_sandbox")) {
      const options = await this.context.api.callAPI(
        this.props.auth0.getAccessTokenSilently,
        this.context.api.getEngines
      );
      engines = options
        .filter(
          (o) =>
            !(
              o.Name.startsWith("twinwave_cuckoo") ||
              o.Name.startsWith("sandbox_win") ||
              !o.DefaultEnabled
            )
        )
        .map(({ Name }) => Name);

      if (this.state.osVersion === "win7") {
        engines.push("interactive_win7");
      } else {
        engines.push("interactive_win10");
      }
    }

    return engines;
  };

  atLeastOneValidEngineSelected = (type) => {
    if (!this.isMode("advanced")) return true;
    for (const state of this.state.engines.values()) {
      if (
        state?.checked &&
        (state?.config?.SupportedTypes || []).includes(type)
      ) {
        return true;
      }
    }
    return false;
  };

  toggleDecodeURLs = () => {
    let shouldDecode = !this.state.decodeURLs;

    this.setState({
      decodeURLs: shouldDecode,
    });

    if (shouldDecode) {
      // check to see if the current URL needs to be decoded
      if (URLIsEncoded(this.state.url)) {
        let url = decodeURL(this.state.url);
        this.setState({
          url: url,
          validURL: this.URLIsValid(url),
        });
      }
    }
  };

  render() {
    return (
      <div className="container">
        <div className="box">
          <div className="tabs">
            <ul>
              <li className={this.isMode("basic") ? "is-active" : ""}>
                <a onClick={this.selectTab("basic")} href="/">
                  Basic
                </a>
              </li>
              <li className={this.isMode("advanced") ? "is-active" : ""}>
                <a onClick={this.selectTab("advanced")} href="/">
                  Advanced
                </a>
              </li>
              <li className={this.isMode("interactive_web") ? "is-active" : ""}>
                <a onClick={this.selectTab("interactive_web")} href="/">
                  Interactive Web
                </a>
              </li>
              <li
                className={
                  this.isMode("interactive_sandbox") ? "is-active" : ""
                }
              >
                <a onClick={this.selectTab("interactive_sandbox")} href="/">
                  Interactive Sandbox
                </a>
              </li>
            </ul>
          </div>
          {!this.state.selectedFile ? (
            <>
              <Modal.Modal isActive={this.state.urlBatchUniqueList.size > 0}>
                <Modal.Card title="Confirm Submission" delete={false}>
                  <Modal.CardBody>
                    <>
                      {this.state.urlBatchMessages.length > 0 && (
                        <>
                          <p>
                            The following modifications were made to your input:
                          </p>
                          <p className="has-text-primary">
                            <ul>
                              {this.state.urlBatchMessages.map((msg) => {
                                return <li key={msg}>{msg}</li>;
                              })}
                            </ul>
                          </p>
                        </>
                      )}
                      <p className="pt-1 pb-1">
                        The following URLs will be submitted to the system with
                        one job being created for each. Do you want to continue?
                      </p>
                      <PlainSourceString
                        value={[...this.state.urlBatchUniqueList].join("\n")}
                        wrapLongLines={true}
                        highlight={true}
                      ></PlainSourceString>
                    </>
                  </Modal.CardBody>
                  <Modal.CardFooter>
                    <button
                      className={`button is-primary`}
                      onClick={() => {
                        this.submitBatch(this.state.urlBatchUniqueList);
                      }}
                    >
                      Submit
                    </button>
                    <button
                      className={`button`}
                      onClick={() => {
                        this.setState({ urlBatchUniqueList: new Set() });
                      }}
                    >
                      Cancel
                    </button>
                  </Modal.CardFooter>
                </Modal.Card>
              </Modal.Modal>
              <Modal.Modal isActive={this.state.urlBatchSubmissionProgress > 0}>
                <Modal.Card title="Job Submission Progress">
                  <Modal.CardBody>
                    <progress
                      className="progress is-primary"
                      value={this.state.urlBatchSubmissionProgress}
                      max="100"
                    >
                      100%
                    </progress>
                  </Modal.CardBody>
                </Modal.Card>
              </Modal.Modal>
              <div className="columns is-vcentered">
                <div className="column">
                  <div>
                    <form onSubmit={this.handleURLBatchSubmit}>
                      <div className="field">
                        <div className="control is-expanded">
                          {this.isMode("interactive_sandbox") ||
                          this.isMode("interactive_web") ? (
                            <input
                              className="input"
                              type="text"
                              value={this.state.urlBatch}
                              onChange={this.handleURLBatchField}
                              placeholder="enter URL including protocol (http:// or https://)"
                            />
                          ) : (
                            <textarea
                              className="textarea"
                              value={this.state.urlBatch}
                              onChange={this.handleURLBatchField}
                              placeholder="Enter multiple URLs one per line including protocol (http:// or https://)"
                              rows="4"
                            />
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                  {!this.state.validURLBatch &&
                    this.state.urlBatch.length >= 10 && (
                      <div className="has-text-danger">
                        <FontAwesomeIcon icon="exclamation-triangle" /> Invalid
                        URL{" "}
                        {this.state.invalidURLBatchReason
                          ? ` (${this.state.invalidURLBatchReason})`
                          : null}
                      </div>
                    )}
                </div>
                <div
                  className="column is-divider-vertical"
                  data-content="OR"
                ></div>
                <div className="column is-2" style={{ minWidth: "14rem" }}>
                  <div>
                    <form>
                      <div className="field">
                        <div className="file is-primary">
                          <label className="file-label">
                            <input
                              className="file-input"
                              type="file"
                              name="resume"
                              ref={this.fileRef}
                              onChange={this.handleFileSelect}
                              disabled={this.state.uploading}
                            />
                            <span className="file-cta is-primary">
                              <span className="file-icon">
                                <FontAwesomeIcon icon="upload" />
                              </span>
                              <span className="file-label">Choose a file…</span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="columns is-centered is-vcentered is-gapless">
              <div className="column is-narrow">
                <div>
                  <FontAwesomeIcon icon={["far", "file"]} />{" "}
                  <span
                    className="has-text-weight-bold"
                    style={{ borderBottom: "dashed 1px" }}
                  >
                    <ThreatName name={this.state.selectedFile.name} />
                  </span>{" "}
                  <span className="has-text-grey">
                    (<FileSize size={this.state.selectedFile.size} />)
                  </span>{" "}
                  {this.state.selectedFile.size === 0 && (
                    <span className="has-text-danger">
                      <FontAwesomeIcon icon="exclamation-triangle" /> file is
                      empty{" "}
                    </span>
                  )}
                  {this.state.selectedFile.size > MAX_FILE_SIZE && (
                    <span className="has-text-danger">
                      <FontAwesomeIcon icon="exclamation-triangle" /> file is
                      too large{" "}
                    </span>
                  )}
                </div>
              </div>
              <div className="column is-narrow">
                <button
                  className="button is-text"
                  onClick={() => this.setState({ selectedFile: null })}
                  title="clear selection"
                >
                  <FontAwesomeIcon icon={["far", "window-close"]} />
                </button>
              </div>
            </div>
          )}
          {this.isMode("interactive_web") && (
            <div className="has-padding-bottom-20 is-clearfix">
              <UADeviceSelector
                value={this.state.userAgent}
                onChange={(d) => this.setState({ userAgent: d })}
              />
            </div>
          )}
          {this.isMode("interactive_sandbox") && (
            <div className="columns">
              <div className="column is-narrow">
                <div className="field">
                  <label className="label">OS Version</label>
                  <div className="control">
                    <div className="select">
                      <select
                        value={this.state.osVersion}
                        onChange={(e) =>
                          this.setState({ osVersion: e.target.value })
                        }
                      >
                        <option value="win10">Windows 10</option>
                        <option value="win7">Windows 7</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-narrow">
                <div className="field">
                  <label className="label">Web Browser</label>
                  <div className="control">
                    <div className="select">
                      <select
                        value={this.state.sandboxBrowser}
                        onChange={(e) =>
                          this.setState({ sandboxBrowser: e.target.value })
                        }
                      >
                        <option value="chrome">Chrome</option>
                        <option value="firefox">Firefox</option>
                        <option value="ie">Internet Explorer</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <label className="label">Session Duration</label>
                  <div className="control">
                    <div className="select">
                      <select
                        value={this.state.sandboxTimeout}
                        onChange={(e) =>
                          this.setState({ sandboxTimeout: e.target.value })
                        }
                      >
                        <option value="1">1 Minute</option>
                        <option value="3">3 Minutes</option>
                        <option value="5">5 Minutes</option>
                        <option value="10">10 Minutes</option>
                        <option value="15">15 Minutes</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.isMode("advanced") && (
            <>
              <div className="columns">
                <div className="column is-4">
                  <div className="card is-full-height ">
                    <div className="card-header">
                      <div className="card-header-title is-centered">
                        Optional Parameters
                      </div>
                    </div>
                    <div className="card-content">
                      <div className="field">
                        <ToggleSwitch
                          name="decodeURLs"
                          checked={this.state.decodeURLs}
                          onChange={this.toggleDecodeURLs}
                          label="Decode rewritten URLs"
                        />
                      </div>
                      <div className="field">
                        <ToggleSwitch
                          name="allowInternetAccess"
                          checked={this.state.allowInternetAccess}
                          onChange={() => {
                            this.setState({
                              allowInternetAccess:
                                !this.state.allowInternetAccess,
                            });
                          }}
                          label="Allow Internet access"
                        />
                      </div>
                      <UserHasPermission permission="app:admin">
                        <div className="field">
                          <ToggleSwitch
                            name="allowCachedResults"
                            checked={this.state.allowCachedResults}
                            onChange={() =>
                              this.setState({
                                allowCachedResults:
                                  !this.state.allowCachedResults,
                              })
                            }
                            label="Allow cached results"
                          />
                        </div>
                      </UserHasPermission>
                      <div className="field">
                        <label className="label">
                          Archive/Document Password
                          <Tooltip
                            title={
                              <>
                                <p>
                                  An optional password for extracting files from
                                  password-protected archive files or for
                                  decrypting documents.
                                </p>
                                <p className="has-padding-top-10">
                                  {PASSWORD_HELP}
                                </p>
                              </>
                            }
                          >
                            <span className="icon">
                              <FontAwesomeIcon
                                icon={["fa", "question-circle"]}
                              />
                            </span>
                          </Tooltip>
                        </label>
                        <div className="control has-icons-left">
                          <input
                            className="input"
                            type="text"
                            value={this.state.archivePassword}
                            onChange={this.handlePasswordField}
                            placeholder="password"
                          />
                          <span className="icon is-left is-small">
                            <FontAwesomeIcon icon="lock" />
                          </span>
                        </div>
                        {/(^\s+)|(\s+$)/.exec(this.state.archivePassword) && (
                          <div>
                            <span className="has-text-warning">
                              <FontAwesomeIcon icon="exclamation-triangle" />
                              &nbsp;Password has leading or trailing whitespace
                            </span>
                          </div>
                        )}
                        <div className="is-size-7 has-text-grey has-padding-top-10">
                          {PASSWORD_HELP}
                        </div>
                      </div>
                    </div>
                    <hr style={{ margin: "0" }} />
                    <div className="card-header">
                      <div className="card-header-title is-centered">
                        Web Analyzer Parameters
                      </div>
                    </div>
                    <div className="card-content">
                      <div className="field">
                        <label className="label">
                          Internet Region
                          <Tooltip
                            title={
                              <>
                                <p>
                                  Use this to select a specific Internet exit
                                  region for Web Analyzer.
                                </p>
                              </>
                            }
                          >
                            <span className="icon">
                              <FontAwesomeIcon
                                icon={["fa", "question-circle"]}
                              />
                            </span>
                          </Tooltip>
                        </label>
                        <div className="control">
                          <div className="select is-fullwidth">
                            <select
                              value={this.state.exitRegion}
                              onChange={(evt) => {
                                evt.preventDefault();
                                this.setState({
                                  exitRegion: evt.target.value,
                                });
                              }}
                            >
                              <option value="">Default</option>
                              {[
                                "us_residential",
                                "us",
                                "asia",
                                "europe",
                                "direct",
                              ].map((v) => (
                                <option key={v} value={v}>
                                  {VPN_REGIONS[v] || v}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <UADeviceSelector
                        value={this.state.userAgent}
                        onChange={(d) => this.setState({ userAgent: d })}
                      />
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="card">
                    <div className="card-header">
                      <div className="card-header-title is-centered">
                        Engine Selection
                      </div>
                    </div>
                    <div className="card-content">
                      <div className="help">
                        Only selected engines will be included as part of this
                        analysis
                      </div>
                      <div className="columns">
                        <div className="column">
                          <div className="field">
                            <label className="label">URL Only Engines</label>
                          </div>
                          {Array.from(this.state.engines.values())
                            .filter(
                              (e) =>
                                e.config.SupportedTypes.length === 1 &&
                                e.config.SupportedTypes.includes("URL")
                            )
                            .map((e) => (
                              <div className="field" key={e.config.Name}>
                                <ToggleSwitch
                                  name={e.config.Name}
                                  checked={e.checked}
                                  onChange={() => {
                                    this.toggleEngine(e.config.Name);
                                  }}
                                  label={taskDisplayName(e.config.Name)}
                                />
                              </div>
                            ))}
                        </div>
                        <div className="column">
                          <div className="field">
                            <label className="label">URL/File Engines</label>
                          </div>
                          {Array.from(this.state.engines.values())
                            .filter(
                              (e) =>
                                e.config.SupportedTypes.includes("URL") &&
                                e.config.SupportedTypes.includes("file")
                            )
                            .map((e) => (
                              <div className="field" key={e.config.Name}>
                                <ToggleSwitch
                                  name={e.config.Name}
                                  checked={e.checked}
                                  onChange={() => {
                                    this.toggleEngine(e.config.Name);
                                  }}
                                  label={taskDisplayName(e.config.Name)}
                                />
                              </div>
                            ))}
                        </div>
                        <div className="column">
                          <div className="field">
                            <label className="label">File Only Engines</label>
                          </div>
                          {Array.from(this.state.engines.values())
                            .filter(
                              (e) =>
                                e.config.SupportedTypes.length === 1 &&
                                e.config.SupportedTypes.includes("file")
                            )
                            .map((e) => (
                              <div className="field" key={e.config.Name}>
                                <ToggleSwitch
                                  name={e.config.Name}
                                  checked={e.checked}
                                  onChange={() => {
                                    this.toggleEngine(e.config.Name);
                                  }}
                                  label={this.getLabel(e.config.Name)}
                                />
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="columns is-centered">
            <div className="column is-narrow">
              <button
                className={`button is-primary ${
                  this.state.uploading && "is-loading"
                }`}
                onClick={this.handleJobSubmit}
              >
                {this.isMode("interactive_web") ||
                this.isMode("interactive_sandbox")
                  ? "Launch Session"
                  : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const SubmitForm = withRouter(withAuth0(SubmitFormInternal));
