import { taskDisplayName } from "./utils/DisplayNames";
import deviceDescriptorsJSON from "./deviceDescriptors.json";

const boolFormatter = (s: string) => ({ true: "Yes", false: "No" }[s] || s);

export const VPN_REGIONS: { [key: string]: string } = {
  us_residential: "US Residential",
  us: "US",
  asia: "Asia",
  europe: "Europe",
  direct: "Direct (No VPN)",
};

const CUSTOMER_VISIBLE_PARAMETER_TRANSLATIONS: {
  [key: string]: {
    name: string;
    formatter?: (s: string) => string;
    default?: string;
  };
} = {
  archive_document_password: {
    name: "Archive/Document Password",
  },
  allow_internet_access: {
    name: "Allow Internet Access",
    formatter: boolFormatter,
    default: "true",
  },
  decode_rewritten_urls: {
    name: "Decode Rewritten URLs",
    formatter: boolFormatter,
    default: "true",
  },
  user_agent: {
    name: `${taskDisplayName("web_analyzer")} - User Agent`,
    formatter: (s) =>
      deviceDescriptorsJSON[
        s.substring("alias:".length) as keyof typeof deviceDescriptorsJSON
      ]?.userAgent || s,
  },
  wa_exit_region: {
    name: `${taskDisplayName("web_analyzer")} - Internet Region`,
    formatter: (v) => VPN_REGIONS[v] || v,
  },
};

export const SubmissionParameters = ({
  parameters,
}: {
  parameters?: { Name: string; Value: string }[];
}) => {
  if (!parameters || parameters.length === 0) return null;

  const filtered = parameters
    .map((v) => ({ ...v, Name: v.Name.toLowerCase().trim() })) // make casing and white-space discrepencies irrelevant
    .filter(({ Name }) => CUSTOMER_VISIBLE_PARAMETER_TRANSLATIONS[Name])
    .filter(
      ({ Name, Value }) =>
        Value && Value !== CUSTOMER_VISIBLE_PARAMETER_TRANSLATIONS[Name].default // the UI sometimes sets default values, but we don't want to call those out so drop them
    );

  if (filtered.length === 0) return null;

  // backend does not guarantee a sort order, but we want it stable for the UI
  filtered.sort((a, b) => a.Name.localeCompare(b.Name));

  return (
    <tr>
      <th className="nowrap">Custom Parameters</th>
      <td style={{ maxWidth: "0" }}>
        {/* width=100% is very important otherwise the minWidth=0 will NOT work in Safari and you will be missing values. Without minWidth=0, elliding will not work. */}
        <table width="100%" className="table">
          <tbody>
            {filtered.map(({ Name, Value }) => (
              <tr key={Value}>
                <td
                  className="nowrap"
                  style={{
                    padding: 0,
                    paddingRight: "1em",
                  }}
                >
                  {CUSTOMER_VISIBLE_PARAMETER_TRANSLATIONS[Name].name}:
                </td>
                <td
                  style={{
                    maxWidth: "0",
                    width: "100%",
                    padding: 0,
                  }}
                >
                  <div className="nowrap" style={{ display: "flex" }}>
                    <div
                      className="nowrap"
                      style={{
                        display: "flex",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        flex: 1,
                      }}
                    >
                      {CUSTOMER_VISIBLE_PARAMETER_TRANSLATIONS[Name].formatter
                        ? CUSTOMER_VISIBLE_PARAMETER_TRANSLATIONS[Name]
                            .formatter!(Value)
                        : Value}
                      {/* reserve a little extra space so italics don't get clipped */}
                      &nbsp;
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </td>
    </tr>
  );
};
