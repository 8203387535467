import { Auth0Context } from "@auth0/auth0-react";
import React from "react";
import { Labels } from "src/components/utils/Labels";
import { withParams } from "src/components/utils/WithParams";
import { LabelType } from "src/lib/APITypes";
import { APIContext } from "src/lib/MAPApi";
import { FileSize } from "../components/FileSize";
import { NavBar } from "../components/NavBar";
import { NormalizedForensicsComponent } from "../components/NormalizedForensics";
import { ResourceTree } from "../components/ResourceTree";
import { Score } from "../components/Score";
import { SortedTasks, TaskList } from "../components/TaskList";
import { TaskResults } from "../components/TaskResults";
import { Copyable } from "../components/utils/Copyable";
import { taskDisplayName } from "../components/utils/DisplayNames";
import { Tab, Tabs } from "../components/utils/Tabs";
import * as api from "../lib/ShareAPI";
import {
  getFileMeta,
  getMaxScore,
  getSortedResources,
} from "../pages/JobDetail";

const getFileStatic = (job, name) => {
  let staticTask = null;
  let parent = getSortedResources(job)[0];

  for (let i = 0; i < job.Tasks.length; i++) {
    let task = job.Tasks[i];

    if (task.Engine === "static_file" && task.ResourceID === parent.ID) {
      staticTask = task;
      break;
    }
  }

  try {
    return staticTask.Results.Details[name];
  } catch (err) {
    return null;
  }
};

class TokenAuth {
  constructor(token) {
    this.token = token;
  }

  getAccessTokenSilently = () => {
    return new Promise((resolve) => {
      resolve(this.token);
    });
  };

  userHasPermission = (perm) => false;
}

export class SharedJobDetailInternal extends React.Component {
  state = {
    job: null,
    selectedTask: null,
    selectedResource: null,
    forensics: null,
    forensicsEngine: null,
    error: null,
  };

  loadJob = () => {
    api
      .getJob(this.props.params.shareToken, this.props.params.jobid)
      .then((job) => {
        this.setState({ job });

        if (job.Submission.SHA256) {
          document.title = `Attack Analyzer - File Analysis ${job.Submission.SHA256}`;
        } else {
          document.title = `Attack Analyzer - URL Analysis ${job.ID}`;
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: "Job not found" });
      });
  };

  componentDidMount() {
    this.loadJob();
  }

  componentWillUnmount() {
    clearTimeout(this.refresher);
  }

  selectTask = (resource, task) => {
    api.abortDownload();

    this.setState({
      selectedTask: task,
      selectedResource: resource,
      forensicsEngine: task.Engine,
      forensics: null,
    });

    if (task.Results != null && task.Results.Forensics != null) {
      if (task.Results.Forensics.Normalized !== "") {
        this.setState({ forensics: "loading" });
        api
          .getForensics(
            this.props.params.shareToken,
            this.state.job.ID,
            task.ID
          )
          .then((forensics) => {
            forensics &&
              this.setState({
                forensics: forensics,
              });
          });
      }
    }
    if (task.Engine === "consolidated" && this.state.job.State === "done") {
      this.setState({
        forensics: "loading",
      });
      api
        .getConsolidatedForensics(
          this.props.params.shareToken,
          this.state.job.ID
        )
        .then((forensics) => {
          forensics &&
            this.setState({
              forensics: forensics,
            });
        });
    }
  };

  selectResource = (resource) => {
    let st = SortedTasks(this.state.job, resource);

    if (st && st.length > 0) {
      this.selectTask(resource, st[0]);
    }
  };

  render() {
    let job = this.state.job;
    let jobScore = 0;

    try {
      jobScore = getMaxScore(job);
    } catch (err) {}

    if (this.state.error != null) {
      return (
        <div className="section columns">
          <div className="column is-half is-offset-one-quarter">
            <div className="message is-danger">
              <div className="message-header">Error</div>
              <div className="message-body">
                <p>Invalid shared job link</p>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (this.state.job == null) {
      return <div />;
    }

    const initialResource = getSortedResources(job)[0];

    const condHeader = (header, value, enableCopy = false) =>
      value ? (
        <tr>
          <th>{header}</th>
          {enableCopy ? (
            <td>
              <Copyable data={value}>{value}</Copyable>
            </td>
          ) : (
            <td>{value}</td>
          )}
        </tr>
      ) : null;

    const fileSize = getFileMeta(job, "Size");

    const fakeAuth = new TokenAuth(this.props.params.shareToken);

    return (
      <APIContext.Provider value={{ api: api }}>
        <Auth0Context.Provider
          value={{
            getAccessTokenSilently: fakeAuth.getAccessTokenSilently,
          }}
        >
          <NavBar logoTarget="#" />
          <div>
            <div className="container is-fluid has-margin-top-20">
              <Copyable data={initialResource.Name}>
                <h1 className="title is-4 resource-name has-margin-bottom-10">
                  <div title={initialResource.Name} className="resource-name">
                    {initialResource.Name}
                  </div>
                </h1>
              </Copyable>
              <div>
                <table className="table is-narrow is-completely-borderless">
                  <tbody>
                    <tr>
                      <th>Score</th>
                      <td>
                        <Score score={jobScore} />
                      </td>
                    </tr>
                    <Labels
                      tableMode
                      labels={[
                        ...(job.Labels || []),
                        { Type: LabelType.Verdict, Value: job?.Verdict },
                        {
                          Type: LabelType.Generic,
                          Value: job?.RequestedEngines?.some((e) =>
                            e.startsWith("interactive")
                          )
                            ? "Interactive"
                            : "",
                        },
                      ]}
                    />
                    <tr>
                      <th>Submitted</th>
                      <td>
                        {new Date(Date.parse(job.CreatedAt)).toLocaleString()}
                      </td>
                    </tr>
                    {condHeader("SHA256:", getFileMeta(job, "SHA256"), true)}
                    {condHeader("MD5:", getFileMeta(job, "MD5"))}
                    {condHeader("File Type:", getFileStatic(job, "magic"))}
                    {fileSize ? (
                      <tr>
                        <th>File Size</th>
                        <td>
                          <FileSize size={fileSize} />
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>

                <ResourceTree
                  job={job}
                  onSelect={this.selectResource}
                  selectedResource={this.state.selectedResource}
                />
              </div>
            </div>

            <div className="container is-fluid has-margin-top-20">
              <div className="columns print-block">
                <div className="column is-one-third-tablet is-one-quarter-desktop is-one-fifth-fullhd print-fullwidth">
                  <TaskList
                    job={job}
                    onSelect={this.selectTask}
                    selectedTask={this.state.selectedTask}
                    score={jobScore}
                  />
                </div>

                <div className="column is-two-thirds-tablet is-three-quarters-desktop is-four-fifths-fullhd">
                  <Tabs
                    for={
                      this.state.selectedTask != null
                        ? this.state.selectedTask.ID
                        : null
                    }
                  >
                    <Tab label="Task Results">
                      <TaskResults
                        task={this.state.selectedTask}
                        engine={taskDisplayName(this.state.forensicsEngine)}
                        forensics={this.state.forensics}
                        job={job}
                        resource={this.state.selectedResource}
                      />
                    </Tab>
                    <Tab label="Normalized Forensics">
                      <NormalizedForensicsComponent
                        job={job}
                        task={this.state.selectedTask}
                        resource={
                          this.state.selectedResource ?? job.Resources[0]
                        }
                        forensics={this.state.forensics}
                        engine={taskDisplayName(this.state.forensicsEngine)}
                        consolidated={
                          this.state.forensicsEngine === "consolidated"
                        }
                      />
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </Auth0Context.Provider>
      </APIContext.Provider>
    );
  }
}

export const SharedJobDetail = withParams(SharedJobDetailInternal);
