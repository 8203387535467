import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext, useState } from "react";
import {
  Job,
  LabelType,
  NormalizedForensics,
  Resource,
  StateType,
  Task,
} from "src/lib/APITypes";
import { APIContext } from "src/lib/MAPApi";
import { FileSize } from "./FileSize";
import { Score } from "./Score";
import { Copyable } from "./utils/Copyable";

import "./ResourceSummary.scss";
import { JobDuration } from "./JobDuration";
import { LoadingMessage } from "./tasks/LoadingMessage";
import { FileViewer } from "./HexViewer";
import { useUserHasPermissionHook } from "../lib/Auth";
import * as Menu from "../components/Menu";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { DebugLogLink } from "src/pages/LogsPage";
import { Labels } from "./utils/Labels";
import { ThreatName } from "./utils/ThreatName";
import { toast } from "react-toastify";
import * as Modal from "./Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface ActionConfig {
  title: string;
  onClick: (event: React.MouseEvent) => void;
  icon: IconProp;
}

function collectTasks(job: Job, resource: Resource): Task[] {
  let tasks = [];

  for (let t of job.Tasks) {
    if (t.ResourceID === resource.ID) {
      tasks.push(t);
    }
  }

  return tasks;
}

export const ResourceSummary = ({
  resource,
  job,
  task,
  forensics,
  menuActions = [],
}: {
  resource: Resource;
  job: Job;
  task?: Task;
  forensics?: NormalizedForensics | "loading" | null;
  menuActions?: ActionConfig[];
}) => {
  const isAdmin = useUserHasPermissionHook("app:admin");
  if (!task || task.Engine === "consolidated") {
    if (forensics === "loading") {
      return <LoadingMessage />;
    }
    return (
      <JobSummary job={job} forensics={forensics} menuActions={menuActions} />
    );
  } else {
    if (isAdmin && task?.Results?.Details?.Cache) {
      menuActions.push({
        title: "View Cache Details",
        onClick: () =>
          window.alert(JSON.stringify(task.Results.Details.Cache, null, "  ")),
        icon: ["far", "info-circle"],
      });
    }

    if (isAdmin) {
      if (task?.Results?.Details?.NetworkEgress)
        menuActions.push({
          title: "View Network Egress Details",
          onClick: () =>
            window.alert(
              JSON.stringify(task.Results.Details.NetworkEgress, null, "  ")
            ),
          icon: ["far", "info-circle"],
        });

      const l = DebugLogLink(job, task);
      if (l)
        menuActions.push({
          title: "Debug Logs",
          onClick: () => {
            window.open(l, "_blank", "noreferrer,noopener");
          },
          icon: ["far", "scroll"],
        });
    }

    if (resource.Type === "URL") {
      return (
        <URLResourceSummary
          resource={resource}
          job={job}
          task={task}
          menuActions={menuActions}
        />
      );
    } else {
      return (
        <FileResourceSummary
          resource={resource}
          job={job}
          task={task}
          menuActions={menuActions}
        />
      );
    }
  }
};

const JobSummary = ({
  job,
  forensics,
  menuActions,
}: {
  job: Job;
  forensics: NormalizedForensics | null | undefined;
  menuActions: ActionConfig[];
}) => {
  const { api } = useContext(APIContext);
  const { getAccessTokenSilently } = useAuth0();
  const resource = job.Resources[0];
  var [hexviewerVisible, setHexviewerVisible] = useState(false);
  var [isDownloading, setIsDownloading] = useState(false);

  const urlsAnalyzed = job.Resources.filter((r) => r.Type === "URL").length;
  const filesAnalyzed = job.Resources.filter((r) => r.Type === "file").length;

  const downloadSample = () => {
    setIsDownloading(true);
    api
      .callAPI(
        getAccessTokenSilently,
        api.getSample,
        resource.JobID,
        resource.FileMetadata.SHA256
      )
      .catch(() => toast.error("download failed", { autoClose: false }))
      .finally(() => setIsDownloading(false));
  };

  let defaultActions: ActionConfig[] = [];
  if (resource.Type === "file") {
    defaultActions = [
      {
        title: "Download File",
        onClick: () => {
          downloadSample();
        },
        icon: "download",
      },
    ];
    if (api.MODE === "standard") {
      defaultActions.push({
        title: "View File Contents",
        onClick: (e: React.MouseEvent) => {
          setHexviewerVisible(true);
        },
        icon: ["far", "file-alt"],
      });
    }
  }

  let actions = defaultActions.concat(menuActions);

  return (
    <section className="mb-5">
      <TaskMenu actions={actions} />
      <table className="table is-completely-borderless is-narrow">
        <tbody>
          <tr>
            <th className="fit-to-content">Initial&nbsp;Submission</th>
            <td>
              <Copyable data={job.Submission.Name}>
                <ThreatName name={job.Submission.Name} />
              </Copyable>
            </td>
          </tr>
          <tr>
            <th>Job Duration</th>
            <td>
              <JobDuration job={job} />
            </td>
          </tr>
          <tr>
            <th>Resources&nbsp;Analyzed</th>
            <td>
              {urlsAnalyzed} URLs, {filesAnalyzed} files
            </td>
          </tr>
          <Labels
            tableMode
            labels={[
              ...job.Labels,
              { Type: LabelType.Verdict, Value: job?.Verdict! },
            ]}
          />
        </tbody>
      </table>
      <FileViewer
        resource={{ jobid: job.ID, sha256: resource.FileMetadata.SHA256 }}
        isVisible={hexviewerVisible}
        filename={resource.Name}
        mimetype={resource.FileMetadata.MimeType}
        onClose={() => {
          setHexviewerVisible(false);
        }}
      />
      <Modal.Modal isActive={isDownloading} close={() => {}}>
        <Modal.Card title="Downloading file">
          <Modal.CardBody>
            <p>
              <FontAwesomeIcon icon="spinner" spin /> File downloading...
            </p>
          </Modal.CardBody>
        </Modal.Card>
      </Modal.Modal>
    </section>
  );
};

const FileResourceSummary = ({
  resource,
  job,
  task,
  menuActions,
}: {
  resource: Resource;
  job: Job;
  task: Task;
  menuActions: ActionConfig[];
}) => {
  const { api } = useContext(APIContext);
  const { getAccessTokenSilently } = useAuth0();
  const [hexviewerVisible, setHexviewerVisible] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadSample = () => {
    setIsDownloading(true);
    api
      .callAPI(
        getAccessTokenSilently,
        api.getSample,
        resource.JobID,
        resource.FileMetadata.SHA256
      )
      .catch(() => toast.error("download failed", { autoClose: false }))
      .finally(() => setIsDownloading(false));
  };

  let defaultActions: ActionConfig[] = [
    {
      title: "Download File",
      onClick: () => {
        downloadSample();
      },
      icon: "download",
    },
  ];

  if (api.MODE === "standard") {
    defaultActions.push({
      title: "View File",
      onClick: () => {
        setHexviewerVisible(true);
      },
      icon: ["far", "file-alt"],
    });
  }

  let actions = defaultActions.concat(menuActions);

  return (
    <section className="mb-5">
      <TaskMenu actions={actions} />
      <table className="table resource-summary">
        <tbody>
          <tr>
            <td className="fit-to-content">
              <Score
                score={
                  task.State === StateType.Done ? task.Results.Score : "TBD"
                }
              />
            </td>
            <td colSpan={2}>
              <Copyable data={resource.Name}>
                <span className="is-size-5">
                  <ThreatName name={resource.Name} />
                </span>{" "}
                (
                <FileSize size={resource.FileMetadata.Size} />)
              </Copyable>
            </td>
          </tr>
          <tr>
            <td></td>
            <th>SHA256</th>
            <td>
              <Copyable data={resource.FileMetadata.SHA256}>
                {resource.FileMetadata.SHA256}
              </Copyable>
            </td>
          </tr>
          <tr>
            <td></td>
            <th>Mime Type</th>
            <td>{resource.FileMetadata.MimeType}</td>
          </tr>
        </tbody>
      </table>
      <FileViewer
        resource={{ jobid: job.ID, sha256: resource.FileMetadata.SHA256 }}
        isVisible={hexviewerVisible}
        filename={resource.Name}
        mimetype={resource.FileMetadata.MimeType}
        onClose={() => {
          setHexviewerVisible(false);
        }}
      />
      <Modal.Modal isActive={isDownloading} close={() => {}}>
        <Modal.Card title="Downloading file">
          <Modal.CardBody>
            <p>
              <FontAwesomeIcon icon="spinner" spin /> File downloading...
            </p>
          </Modal.CardBody>
        </Modal.Card>
      </Modal.Modal>
    </section>
  );
};

const URLResourceSummary = ({
  resource,
  job,
  task,
  menuActions,
}: {
  resource: Resource;
  job: Job;
  task: Task;
  menuActions: ActionConfig[];
}) => {
  let tasks = collectTasks(job, resource);

  let title = null;
  let whois = null;

  for (let t of tasks) {
    if (!title && t.Results.Details?.Title) {
      title = t.Results.Details.Title;
    }
    if (!whois && t.Results.Details?.Whois) {
      whois = t.Results.Details.Whois;
    }
  }

  return (
    <section className="mb-5">
      <TaskMenu actions={menuActions} />
      <table className="table resource-summary">
        <tbody>
          <tr>
            <td className="fit-to-content">
              <Score
                score={
                  task.State === StateType.Done ? task.Results.Score : "TBD"
                }
              />
            </td>
            <td colSpan={2}>
              <Copyable data={resource.Name}>
                <ThreatName name={resource.Name} />
              </Copyable>
            </td>
          </tr>
          <tr>
            <td></td>
            <th>Title</th>
            <td>{title ? `"${title}"` : "<no title>"}</td>
          </tr>
          {whois ? <WhoisDisplay whois={whois} /> : <></>}
        </tbody>
      </table>
    </section>
  );
};

const WhoisDisplay = ({ whois }: { whois: any }) => {
  let domainAge = undefined;
  if (whois.CreatedAt != null) {
    try {
      let age = new Date().getTime() - new Date(whois.CreatedAt).getTime();
      domainAge = Math.floor(age / (60 * 60 * 24 * 1000));
    } catch {}
  }

  var [showDetails, setShowDetails] = useState(false);

  return (
    <tr>
      <td></td>
      <th>Domain</th>
      <td>
        {whois.DomainName}{" "}
        {domainAge ? (
          <>
            <span
              className={
                domainAge < 7
                  ? "has-text-danger"
                  : domainAge < 60
                  ? "has-text-warning"
                  : ""
              }
            >
              {" "}
              (registered{" "}
              {domainAge <= 365 ? (
                <>{domainAge} days ago</>
              ) : (
                <>{Math.round(domainAge / 365)} years ago</>
              )}
              )
            </span>
          </>
        ) : (
          ""
        )}
        {showDetails ? (
          <>
            <button
              className="button is-small is-text has-text-link"
              onClick={() => {
                setShowDetails(false);
              }}
            >
              hide details
            </button>
            <section className="">
              <table className="table is-narrow is-completely-borderless">
                <tbody>
                  <tr>
                    <th>Owner</th>
                    <td>{whois.Org}</td>
                  </tr>
                  <tr>
                    <th>Registrar</th>
                    <td>{whois.Registrar}</td>
                  </tr>
                  <tr>
                    <th>Registration Date</th>
                    <td>
                      {new Date(Date.parse(whois.CreatedAt)).toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <th>Last Update</th>
                    <td>
                      {new Date(Date.parse(whois.UpdatedAt)).toLocaleString()}
                    </td>
                  </tr>
                  <tr>
                    <th>State</th>
                    <td>{whois.State || "unknown"}</td>
                  </tr>
                  <tr>
                    <th>Country</th>
                    <td>{whois.Country || "unknown"}</td>
                  </tr>
                </tbody>
              </table>
            </section>
          </>
        ) : (
          <button
            className="button is-small is-text has-text-link"
            onClick={() => {
              setShowDetails(true);
            }}
          >
            show details
          </button>
        )}
      </td>
    </tr>
  );
};

export const TaskMenu = ({ actions }: { actions: ActionConfig[] }) => {
  const withReporting = (action: ActionConfig) => {
    return (evt: React.MouseEvent) => {
      action.onClick(evt);
    };
  };

  const [viewFileContents]: ActionConfig[] = actions.filter(
    ({ title }) => title.toLowerCase() === "view file"
  );
  const more = actions.filter(
    ({ title }) => title.toLowerCase() !== "view file"
  );

  return (
    <div className="is-pulled-right" title="menu" style={{ minWidth: "3rem" }}>
      {viewFileContents && (
        <Menu.Item
          text={viewFileContents.title}
          icon={viewFileContents.icon}
          onClick={withReporting(viewFileContents)}
          standalone={true}
        />
      )}
      {more.length > 0 ? (
        <Menu.More text={viewFileContents ? "More" : undefined}>
          {more.map((a) => (
            <Menu.Item
              icon={a.icon}
              key={a.title}
              text={a.title}
              onClick={withReporting(a)}
            />
          ))}
        </Menu.More>
      ) : (
        <span>&nbsp;</span> // render enough content to get the DOM to allocate it space
      )}
    </div>
  );
};
