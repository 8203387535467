import React from "react";
import { MalwareConfig, TypedString, Credential } from "src/lib/APITypes";
import { taskDisplayName } from "../utils/DisplayNames";
import { Expandable } from "../utils/Expandable";
import { GenericDataDisplay } from "../utils/GenericDataDisplay";

interface MalwareConfigProps {
  value: MalwareConfig;
}

export const MalwareConfigDisplay = ({ value }: MalwareConfigProps) => {
  let title = "Extracted Malware Config";
  if (value.Engines) {
    title += " from " + taskDisplayName(value.Engines[0]);
  }

  return (
    <Expandable expanded={true} title={title}>
      <div>
        <b>Malware Name:</b> {value.MalwareName || "unspecified"}
      </div>
      {value.Addresses && (
        <div>
          <b>Addresses:</b>
          <table className="table is-striped is-narrow">
            <tbody>
              {value.Addresses.map((a, i) => (
                <TypedStringDisplay value={a} key={i} />
              ))}
            </tbody>
          </table>
        </div>
      )}
      {value.Keys && (
        <div>
          <b>Keys:</b>
          <table className="table is-striped is-narrow">
            <tbody>
              {value.Keys.map((a, i) => (
                <TypedStringDisplay value={a} key={i} />
              ))}
            </tbody>
          </table>
        </div>
      )}
      {value.Credentials && (
        <div>
          <b>Credentials:</b>
          <table className="table is-striped is-narrow">
            <tbody>
              {value.Credentials.map((a, i) => (
                <CredentialDisplay value={a} key={i} />
              ))}
            </tbody>
          </table>
        </div>
      )}
      {value.Filenames && (
        <div>
          <b>Filenames:</b>
          <table className="table is-striped is-narrow">
            <tbody>
              {value.Filenames.map((a, i) => (
                <tr>
                  <td>{a}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {value.Other && Object.keys(value.Other).length ? (
        <div>
          <b>Other Config:</b>
          <div className="pl-3">
            <GenericDataDisplay data={value.Other} />
          </div>
        </div>
      ) : null}
    </Expandable>
  );
};

interface TypedStringProps {
  value: TypedString;
}

export const TypedStringDisplay = ({ value }: TypedStringProps) => {
  return (
    <tr>
      <td>{value.Type}</td>
      <td>{value.Value}</td>
    </tr>
  );
};

interface CredentialProps {
  value: Credential;
}

export const CredentialDisplay = ({ value }: CredentialProps) => {
  return (
    <tr>
      <td>{value.Username}</td>
      <td>{value.Password}</td>
    </tr>
  );
};
