import { logError } from "./MAPApi";
import { saveWithMimeType } from "./Utils";

const pako = require("pako");
const apiURL = process.env.REACT_APP_API_URL;

export const MODE = "shared";

export var weirdBrowser = false;
var controller;
try {
  controller = new AbortController();
} catch (err) {
  controller = {};
  console.log(
    "This browser doesn't support the AbortController feature, which may cause strange UI behavior"
  );
  weirdBrowser = true;
}

export const abortDownload = () => {
  try {
    controller.abort();
    controller = new AbortController();
  } catch (err) {}
};

function handleErrors(resp) {
  if (resp.ok) {
    return resp;
  }

  return resp.json().then((jsonResponse) => {
    if (jsonResponse.status) {
      throw Error(jsonResponse.status);
    } else {
      throw resp.statusText;
    }
  });
}

export const getJob = (shareToken, jobid) => {
  return fetch(`${apiURL}/shared/${jobid}/${shareToken}`)
    .then(handleErrors)
    .then((resp) => resp.json());
};

export const getForensics = (shareToken, jobid, taskid) => {
  return fetch(
    `${apiURL}/shared/${jobid}/${shareToken}/tasks/${taskid}/forensics`,
    {
      signal: controller.signal,
    }
  )
    .then(handleErrors)
    .then((resp) => resp.json())
    .catch((err) => logError(err, true))
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const getConsolidatedForensics = (shareToken, jobid) => {
  return fetch(`${apiURL}/shared/${jobid}/${shareToken}/forensics`, {
    signal: controller.signal,
  })
    .then(handleErrors)
    .then((resp) => resp.json())
    .catch((err) => logError(err, true))
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const getArtifactURL = (shareToken, artifactPath) => {
  let url = new URL(`${apiURL}/shared/artifact/url/${shareToken}`);
  url.searchParams.append("path", artifactPath);

  return fetch(url)
    .then(handleErrors)
    .then((resp) => resp.json())
    .then((resp) => {
      return resp.URL;
    })
    .catch((err) => logError(err, true))
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const getArtifactByPath = (shareToken, artifactPath) => {
  let url = new URL(`${apiURL}/shared/artifact/url/${shareToken}`);
  url.searchParams.append("path", artifactPath);

  return fetch(url)
    .then(handleErrors)
    .then((resp) => resp.json())
    .then((resp) => {
      // weird hack from https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
      const link = document.createElement("a");
      link.href = resp.URL;
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => logError(err, true))
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const getArtifactByPathToMemory = (shareToken, artifactPath) => {
  let url = new URL(`${apiURL}/shared/artifact/url/${shareToken}`);
  url.searchParams.append("path", artifactPath);

  return fetch(url)
    .then(handleErrors)
    .then((resp) => resp.json())
    .then((resp) => {
      return fetch(resp.URL)
        .then(handleErrors)
        .then((resp) => resp.arrayBuffer())
        .then((buf) => {
          let a = new Uint8Array(buf);
          if (a[0] === 0x1f && a[1] === 0x8b) {
            // gzip header detected
            a = pako.inflate(a);
          }
          let s = "";
          for (let i = 0; i < a.length; i++) {
            s += String.fromCharCode(a[i]);
          }
          return s;
        });
    })
    .catch((err) => logError(err, true))
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const getArtifact = (shareToken, jobid, taskid, sha256) => {
  return fetch(
    `${apiURL}/shared/${jobid}/${shareToken}/tasks/${taskid}/artifacts/${sha256}`
  )
    .then(handleErrors)
    .then((resp) => resp.blob())
    .then((blob) => {
      saveWithMimeType(blob, `${sha256}.zip`, "application/zip");
    })
    .catch((err) => logError(err, true))
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const getSample = (shareToken, jobid, sha256) => {
  return fetch(`${apiURL}/shared/${jobid}/${shareToken}/resources/${sha256}`)
    .then(handleErrors)
    .then((resp) => resp.blob())
    .then((blob) => {
      saveWithMimeType(blob, `${sha256}.zip`, "application/zip");
    })
    .catch((err) => logError(err, true))
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const callAPI = (tokenGetter, apifunc, ...args) => {
  return tokenGetter().then((token) => {
    return apifunc(token, ...args);
  });
};
