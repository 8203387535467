import { withAuth0 } from "@auth0/auth0-react";
import Tooltip from "@cypress/react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { flushSync } from "react-dom";
import { Link } from "react-router-dom";
import { SubmissionParameters } from "src/components/SubmissionParameters";
import { Labels } from "src/components/utils/Labels";
import { withParams } from "src/components/utils/WithParams";
import { LabelType } from "src/lib/APITypes";
import { FileSize } from "../components/FileSize";
import { JobMenu } from "../components/JobMenu";
import { NormalizedForensicsComponent } from "../components/NormalizedForensics";
import { RawForensics } from "../components/RawForensics";
import { ResourceTree } from "../components/ResourceTree";
import { Score } from "../components/Score";
import { SortedTasks, TaskList } from "../components/TaskList";
import { TaskResults } from "../components/TaskResults";
import { Copyable } from "../components/utils/Copyable";
import { taskDisplayName } from "../components/utils/DisplayNames";
import { Tab, Tabs } from "../components/utils/Tabs";
import { APIContext } from "../lib/MAPApi";

const getFileStatic = (job, name) => {
  let staticTask = null;
  let parent = getSortedResources(job)[0];

  for (let i = 0; i < job.Tasks.length; i++) {
    let task = job.Tasks[i];

    if (task.Engine === "static_file" && task.ResourceID === parent.ID) {
      staticTask = task;
      break;
    }
  }

  try {
    return staticTask.Results.Details[name];
  } catch (err) {
    return null;
  }
};

export const getJobDuration = (job) => {
  let end = Date.now();

  if (job.CompletedAt != null && !job.CompletedAt.startsWith("0")) {
    // it's not done yet
    end = Date.parse(job.CompletedAt);
  }

  let start = Date.parse(job.StartedAt || job.CreatedAt);

  return Math.floor((end - start) / 60000);
};

const submittedBy = (job) => {
  return (
    <>
      {job.Username || (job.APIKey && `API - ${job.APIKey.Label}`) || "API"}{" "}
      {job.Username && ` (${job.SubmissionSource})`}
    </>
  );
};

export const getSortedResources = (job) => {
  let resources = job.Resources;
  resources.sort((a, b) => new Date(a.CreatedAt) - new Date(b.CreatedAt)); // put into timestamp order for deterministic ordering
  let sorted = [];

  // build up a tree out of the resources
  let tree = resources.map((r) => {
    return { resource: r, children: [] };
  });
  for (let i = 0; i < tree.length; i++) {
    const r = tree[i];
    if (r.resource.ParentID) {
      const parent = tree.filter(
        (t) => t.resource.ID === r.resource.ParentID
      )[0];
      parent.children.push(r);
    }
  }

  const roots = tree.filter((r) => !r.resource.ParentID);

  function appendResource(r) {
    sorted.push(r.resource);
    r.children.forEach(appendResource);
  }

  roots.forEach(appendResource);

  return sorted;
};

export const getFileMeta = (job, name) => {
  try {
    return job.Resources[0].FileMetadata[name];
  } catch (err) {
    return null;
  }
};

export const getMaxScore = (job) => {
  let maxScore = 0.0;

  for (let k in job.Tasks) {
    let score = 0.0;
    try {
      let results = job.Tasks[k].Results;
      score = results.score || results.Score;
    } catch (err) {}

    if (score > maxScore) {
      maxScore = score;
    }
  }

  return maxScore;
};

export class JobDetailInternal extends React.Component {
  state = {
    job: null,
    selectedTask: null,
    selectedResource: null,
    forensics: null,
    rawForensics: null,
    forensicsEngine: null,
    error: null,
    otherScans: {
      jobs: [],
      hasNext: false,
    },
    modalMessage: null,
  };

  refresher = null;

  static contextType = APIContext;

  loadJob = () => {
    this.context.api
      .callAPI(
        this.props.auth0.getAccessTokenSilently,
        this.context.api.getJob,
        this.props.params.jobid
      )
      .then((job) => {
        // Must be sync as some components trigger re-render based on the state job field
        // Else it stops updating a job that is in-progress when it completes.
        flushSync(() => this.setState({ job: job }));

        if (
          job.State === "inprogress" ||
          (job.State === "done" && !job.ForensicsPath)
        ) {
          this.refresher = setTimeout(this.reloadJob, 5000);
        } else if (job.State === "pending") {
          let interval = 30000;
          if (getJobDuration(job) === 0) {
            interval = 2000; // be more aggressive with refresh for the first minute of a job's life
          }
          this.refresher = setTimeout(this.reloadJob, interval);
        }

        if (job.Submission.SHA256) {
          document.title = `Attack Analyzer - File Analysis ${job.Submission.SHA256}`;
        } else {
          document.title = `Attack Analyzer - URL Analysis ${job.ID}`;
        }

        if (["done", "inprogress"].includes(job.State)) {
          this.findOtherScans(job);
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: "Job not found" });
      });
  };

  reloadJob = () => {
    this.context.api
      .callAPI(
        this.props.auth0.getAccessTokenSilently,
        this.context.api.getJob,
        this.props.params.jobid
      )
      .then((job) => {
        // Must be sync as some components trigger re-render based on the state job field
        // Else it stops updating a job that is in-progress when it completes.
        flushSync(() => this.setState({ job: job }));
        if (
          job.State === "inprogress" ||
          (job.State === "done" && !job.ForensicsPath)
        ) {
          this.refresher = setTimeout(this.reloadJob, 5000);
        } else if (job.State === "pending") {
          let interval = 30000;
          if (getJobDuration(job) === 0) {
            interval = 2000; // be more aggressive with refresh for the first minute of a job's life
          }
          this.refresher = setTimeout(this.reloadJob, interval);
        }

        if (
          this.state.selectedTask &&
          this.state.selectedTask.State !== "done"
        ) {
          let newerversionoftask = null;

          const newversionoftasks = job.Tasks.filter(
            (t) => t.ID === this.state.selectedTask.ID
          );

          if (newversionoftasks.length > 0) {
            const n = newversionoftasks[0];
            if (n.State === "done") {
              newerversionoftask = n;
            }
          }

          if (
            this.state.selectedTask.ID === "consolidated" &&
            job.State === "done" &&
            job.ForensicsPath
          ) {
            newerversionoftask = JSON.parse(
              JSON.stringify(this.state.selectedTask)
            );
            newerversionoftask.State = "done";
          }

          if (newerversionoftask) {
            this.setState({ selectedTask: newerversionoftask });
            this.selectTask(this.state.selectedResource, newerversionoftask);
          }
        }
      })
      .catch((err) => {
        console.log("Error reloading job: " + err);
        this.refresher = setTimeout(this.reloadJob, 30000);
      });
  };

  searchParams = (job) => {
    if (!job) {
      return null;
    }

    return {
      mode: "resources",
      term: job.Submission.SHA256 || job.Submission.Name,
      field: job.Submission.SHA256 ? "sha256" : "url",
      type: "exact",
      timeframe: "0",
      count: 50,
      page: 1,
    };
  };

  findOtherScans = (job) => {
    const searchParams = this.searchParams(job);

    if (!searchParams) {
      return;
    }

    this.context.api
      .callAPI(
        this.props.auth0.getAccessTokenSilently,
        this.context.api.searchRelated,
        searchParams.field,
        searchParams.term
      )
      .then(({ Jobs, HasNext }) => {
        const others = Jobs.filter((s) => s.ID !== job.ID);
        this.setState({ otherScans: { jobs: others, hasNext: HasNext } });
      });
  };

  getOtherScanSummary = () => {
    return (
      <ul>
        {this.state.otherScans.jobs.map((s) => {
          return (
            <li key={s.ID}>
              {new Date(Date.parse(s["@timestamp"])).toLocaleString()} <br />
              <span className="is-size-7 nowrap">
                ({s.Username || `API - ${s.APIKeyLabel}`})
              </span>
            </li>
          );
        })}
        {this.state.otherScans.hasNext && <li>and more…</li>}
      </ul>
    );
  };

  componentDidMount() {
    this.loadJob();
  }

  componentWillUnmount() {
    clearTimeout(this.refresher);
  }

  selectTask = (resource, task) => {
    this.context.api.abortDownload();

    this.setState({
      selectedTask: task,
      selectedResource: resource,
      forensicsEngine: task.Engine,
      forensics: null,
      rawForensics: null,
    });

    if (task.Results && task.Results.Forensics) {
      if (task.Results.Forensics.Normalized) {
        this.setState({ forensics: "loading" });
        this.context.api
          .callAPI(
            this.props.auth0.getAccessTokenSilently,
            this.context.api.getForensics,
            this.state.job.ID,
            task.ID
          )
          .then((forensics) => {
            forensics &&
              this.setState({
                forensics: forensics,
              });
          });
      }
    }
    if (task.Engine === "consolidated" && this.state.job.State === "done") {
      this.setState({
        forensics: "loading",
        rawForensics: "loading",
      });
      this.context.api
        .callAPI(
          this.props.auth0.getAccessTokenSilently,
          this.context.api.getConsolidatedForensics,
          this.state.job.ID
        )
        .then((forensics) => {
          forensics &&
            this.setState({
              forensics: forensics,
              rawForensics: forensics,
            });
        });
    }
  };

  selectResource = (resource) => {
    let st = SortedTasks(this.state.job, resource);

    if (st && st.length > 0) {
      this.selectTask(resource, st[0]);
    }
  };

  render() {
    let job = this.state.job;
    let jobScore = 0;

    try {
      jobScore = getMaxScore(job);
    } catch (err) {}

    if (this.state.error != null) {
      return (
        <div className="section columns">
          <div className="column is-half is-offset-one-quarter">
            <div className="message is-danger">
              <div className="message-header">Error</div>
              <div className="message-body">
                <p>Unable to load job from the server: {this.state.error}</p>
                <p>
                  <b>Reloading the application window may resolve this issue</b>
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (this.state.job == null) {
      return <div />;
    }

    if (["error", "pending"].includes(job.State)) {
      return <IncompleteJob job={job} />;
    }

    // const duration = getJobDuration(job);
    const initialResource = getSortedResources(job)[0];

    const condHeader = (header, value, enableCopy = false) =>
      value ? (
        <tr>
          <th>{header}</th>
          {enableCopy ? (
            <td>
              <Copyable data={value}>{value}</Copyable>
            </td>
          ) : (
            <td>{value}</td>
          )}
        </tr>
      ) : null;

    const fileSize = getFileMeta(job, "Size");

    const selectedTask = this.state.selectedTask;
    const hasRawForensics =
      this.state.rawForensics != null ||
      (selectedTask != null &&
        selectedTask.Results != null &&
        selectedTask.Results.Forensics != null &&
        selectedTask.Results.Forensics.Raw !== "");

    return (
      <div>
        <div className="container is-fluid mt-2">
          <div style={{ display: "flex" }}>
            <div
              style={{
                minWidth: 0,
                flexGrow: 1,
                paddingRight: 20,
                alignSelf: "center",
              }}
            >
              <Copyable data={initialResource.Name}>
                <h1 className="title is-4 resource-name">
                  {job.State === "inprogress" && (
                    <FontAwesomeIcon icon="spinner" spin />
                  )}
                  {job.Sharing.ShareToken && (
                    <Tooltip
                      title={`Shared by ${job.Sharing.SharedBy} on ${new Date(
                        Date.parse(job.Sharing.SharedAt)
                      ).toLocaleString()}`}
                      placement="bottom"
                    >
                      <span className="tag is-warning is-medium">Shared</span>
                    </Tooltip>
                  )}{" "}
                  <span title={initialResource.Name}>
                    {initialResource.Name}
                  </span>
                </h1>
              </Copyable>
            </div>
            <div style={{ display: "contents" }}>
              <JobMenu job={job} reloadJob={this.reloadJob} />
            </div>
          </div>
          <div>
            <table className="table is-narrow is-completely-borderless">
              <tbody>
                <tr>
                  <th>Score</th>
                  <td>
                    <Score score={jobScore} />
                  </td>
                </tr>
                <Labels
                  tableMode
                  labels={[
                    ...(job?.Labels || []),
                    { Type: LabelType.Verdict, Value: job?.Verdict },
                    {
                      Type: LabelType.Generic,
                      Value: job?.RequestedEngines?.some((e) =>
                        e.startsWith("interactive")
                      )
                        ? "Interactive"
                        : "",
                    },
                  ]}
                />
                <tr>
                  <th>Submitted</th>
                  <td>
                    {new Date(Date.parse(job.CreatedAt)).toLocaleString()} by{" "}
                    {submittedBy(job)}
                  </td>
                </tr>
                {job.RequestedEngines && (
                  <tr>
                    <th>Requested&nbsp;Engines</th>
                    <td>
                      {job.RequestedEngines.map((e) => taskDisplayName(e)).join(
                        ", "
                      )}
                    </td>
                  </tr>
                )}
                <SubmissionParameters parameters={job.Parameters} />
                {/* <tr>
                  <th className="nowrap">Job Duration</th>
                  <td>{duration} minutes</td>
                </tr> */}
                {condHeader("SHA256", job.Submission?.SHA256, true)}
                {/* {condHeader("MD5", job.Submission?.MD5)} */}
                {condHeader("File Type", getFileStatic(job, "magic"))}
                {fileSize ? (
                  <tr>
                    <th>File Size</th>
                    <td>
                      <FileSize size={fileSize} />
                    </td>
                  </tr>
                ) : null}
                {this.state.otherScans.jobs.length > 1 && (
                  <tr>
                    <th className="nowrap">History</th>
                    <td>
                      <Link
                        to={`/search?${new URLSearchParams(
                          this.searchParams(this.state.job)
                        ).toString()}`}
                      >
                        <Tooltip
                          title={this.getOtherScanSummary()}
                          placement="right"
                        >
                          <span className="tag is-primary">
                            {this.state.otherScans.jobs.length}
                            {this.state.otherScans.hasNext ? "+" : null} other
                            scans
                          </span>
                        </Tooltip>
                      </Link>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <ResourceTree
              job={job}
              onSelect={this.selectResource}
              selectedResource={this.state.selectedResource}
            />
          </div>
        </div>

        <div className="container is-fluid has-margin-top-20">
          <div className="columns print-block">
            <div className="column is-one-third-tablet is-one-quarter-desktop is-one-fifth-fullhd print-fullwidth">
              <TaskList
                job={job}
                onSelect={this.selectTask}
                score={jobScore}
                selectedTask={this.state.selectedTask}
              />
            </div>

            <div className="column is-two-thirds-tablet is-three-quarters-desktop is-four-fifths-fullhd">
              <Tabs
                for={
                  this.state.selectedTask != null
                    ? this.state.selectedTask.ID
                    : null
                }
              >
                <Tab label="Task Results">
                  <TaskResults
                    task={this.state.selectedTask}
                    engine={taskDisplayName(this.state.forensicsEngine)}
                    forensics={this.state.forensics}
                    job={job}
                    resource={this.state.selectedResource}
                  />
                </Tab>
                <Tab label="Normalized Forensics">
                  <NormalizedForensicsComponent
                    job={job}
                    task={this.state.selectedTask}
                    resource={this.state.selectedResource ?? job.Resources[0]}
                    forensics={this.state.forensics}
                    engine={taskDisplayName(this.state.forensicsEngine)}
                    consolidated={this.state.forensicsEngine === "consolidated"}
                  />
                </Tab>
                {hasRawForensics ? (
                  <Tab label="Raw Forensics">
                    <RawForensics
                      jobid={this.props.params.jobid}
                      taskid={this.state.selectedTask.ID}
                      engine={taskDisplayName(this.state.forensicsEngine)}
                      forensics={this.state.rawForensics} // pass in forensics if they're available (only for consolidated task)
                    />
                  </Tab>
                ) : null}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const IncompleteJob = ({ job }) => {
  return (
    <div>
      <div className="container is-fluid has-margin-top-20">
        <div className="columns is-vcentered">
          <div className="column is-11">
            <Copyable data={job.Submission.Name}>
              <h1 className="title is-4 resource-name">
                <span title={job.Submission.Name}>{job.Submission.Name}</span>
              </h1>
            </Copyable>
          </div>
        </div>
        <div>
          <table className="table is-narrow is-completely-borderless">
            <tbody>
              <tr>
                <th>Submitted</th>
                <td>
                  {new Date(Date.parse(job.CreatedAt)).toLocaleString()}{" "}
                  {job.State === "pending" && (
                    <>({getJobDuration(job)} minutes ago)</>
                  )}
                </td>
              </tr>
              <tr>
                <th className="nowrap">Submitted By:</th>
                <td>{submittedBy(job)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="section has-text-centered mt-6">
        {job.State === "pending" && (
          <div>
            <p>
              The analysis for this submission has not started yet due to the
              system being at your maximum allowed analysis quota.
            </p>
            <p className="mt-4">
              As soon as resources are available, the analysis will
              automatically start for this submission.
            </p>
          </div>
        )}
        {job.State === "error" && (
          <div>
            <p>
              The system encountered an error processing the submission and was
              unable to recover.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export const JobDetail = withAuth0(withParams(JobDetailInternal));
