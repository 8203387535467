import React from "react";
import "../css/Toggle.scss";

export const ToggleSwitch = (props) => {
  return (
    <>
      <input
        id={props.name}
        type="checkbox"
        name={props.name}
        className="sw"
        checked={props.checked}
        onChange={props.onChange}
      />
      <label htmlFor={props.name}>
        <span>{props.label}</span>
      </label>
    </>
  );
};
