import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";
import { ArtifactCommon, Detection, LabelType } from "src/lib/APITypes";
import { Score } from "../Score";
import { LegacyJSXCopyable } from "../utils/Copyable";
import { taskDisplayName } from "../utils/DisplayNames";
import { Expandable } from "../utils/Expandable";
import { ExpandableBox } from "../utils/ExpandableBox";
import { GenericDataDisplay } from "../utils/GenericDataDisplay";
import { Labels } from "../utils/Labels";

// export interface Detection {
//   Name: string;
//   Description: string;
//   Severity: number;
//   MalwareFamilies: string[] | Set<string>;
//   PhishedBrands: string[] | Set<string>;
//   Engines: string[];
//   Details: Map<string, any> | Map<string, any>[];
// }

interface DetectionGroup {
  Name: string;
  Verdict?: string;
  Description: string;
  Severity: number;
  MalwareFamilies: Set<string>;
  PhishKitFamilies: Set<string>;
  PhishedBrands: Set<string>;
  Engines: string[];
  Details?: any;
}

function detectionGroupToDetection(g: DetectionGroup): Detection {
  return {
    Name: g.Name,
    Verdict: g.Verdict ?? "",
    Description: g.Description,
    Severity: g.Severity,
    MalwareFamilies: [...g.MalwareFamilies],
    PhishKitFamilies: [...g.PhishKitFamilies],
    PhishedBrands: [...g.PhishedBrands],
    Engines: g.Engines,
    Details: g.Details,
  };
}

const getEngines = (v: Detection) => {
  try {
    return v.Engines?.map((e) => <p key={e}>{taskDisplayName(e)}</p>);
  } catch (err) {
    return null;
  }
};

const isMetaDetection = (d: Detection) => {
  return (
    (d?.Name || "").startsWith("twinwave_meta_") ||
    d.Details?.IsMetaRule === true
  );
};

export const DetectionTable = ({
  detections,
  isConsolidated = false,
  expanded = false,
  isNested = false,
}: {
  detections: Detection[] | null;
  isConsolidated?: boolean;
  expanded?: boolean;
  isNested?: boolean;
}) => {
  if (!detections || detections.length === 0) {
    return <></>;
  }

  detections.sort((a, b) => {
    const aName = a.Description || a.Name;
    const bName = b.Description || b.Name;
    if (b.Severity !== a.Severity) {
      return (b.Severity || 0) - (a.Severity || 0);
    } else if (aName !== bName) {
      if (isMetaDetection(a) && !isMetaDetection(b)) {
        return -1;
      }

      if (!isMetaDetection(a) && isMetaDetection(b)) {
        return 1;
      }

      return aName > bName ? 1 : -1;
    } else {
      return 0;
    }
  });

  let idx = 0;

  const reduceByDescriptionOrName = (
    existingGroups: Map<string, DetectionGroup>,
    v: Detection
  ) => {
    // [RAW] NOTE: Descriptions tend to be more specific than names, and we
    //   don't want to lose info. We add in severity to prevent grouping of
    //   of uniquley interesting detections:
    //
    //   > [Bryan] thinks when we get the same detection but with different
    //   > scores (which presumably means different details) that we probably
    //   > shouldn't merge those. So merge with same score, but keep separate
    //   > w/ different score
    //
    let groupKey = `${v.Description || v.Name}-${v.Severity}`;
    // For Intezer, allow differing severities to group together
    if (v.Name && v.Name.toLowerCase().includes("intezer")) {
      groupKey = `${v.Description || v.Name}`;
    }

    let group = existingGroups.get(groupKey) || {
      Engines: v.Engines,
      Severity: v.Severity,
      Description: v.Description,
      MalwareFamilies: new Set(v.MalwareFamilies || []),
      PhishKitFamilies: new Set(v.PhishKitFamilies || []),
      PhishedBrands: new Set(v.PhishedBrands || []),
      Details: [],
      Name: v.Name,
    };

    if (Object.keys(v.Details || {}).length) {
      group.Details?.push(v.Details);
    }
    (v.MalwareFamilies || []).forEach((v: string) =>
      group.MalwareFamilies.add(v)
    );
    (v.PhishKitFamilies || []).forEach((v: string) =>
      group.PhishKitFamilies.add(v)
    );
    (v.PhishedBrands || []).forEach((v: string) => group.PhishedBrands.add(v));
    existingGroups.set(groupKey, group);

    // Use the MAX severity from all groups
    group.Severity = Math.max(group.Severity || 0, v.Severity || 0);

    // [TP-2085] Merge Engines
    group.Engines = group.Engines || [];
    group.Engines.push(...(v.Engines || []));
    group.Engines = [...new Set(group.Engines)];
    group.Engines.sort();

    return existingGroups;
  };

  const groupDetections = (ds: Detection[]) => {
    const grouped = Array.from(
      ds.reduce(reduceByDescriptionOrName, new Map()).values()
    );

    // // Unnest singleton details.
    grouped.forEach((v) => {
      // if (v.Details?.length === 1) {
      //   v.Details = v.Details[0];
      //   return;
      // }

      if (v.Details?.length === 0) {
        delete v.Details;
        return;
      }

      if (v.Details?.length > 1) {
        v.Description = `${v.Description} (${v.Details?.length} times)`;
      }
    });

    return grouped;
  };

  return (
    <table className="table is-fullwidth">
      {!isNested && (
        <thead>
          <tr>
            {isConsolidated ? <th>Engine</th> : null}
            <th>Signature / Alert</th>
            <th>Score</th>
          </tr>
        </thead>
      )}
      <tbody>
        {groupDetections(detections).map((d) => (
          <DetectionRow
            isConsolidated={isConsolidated}
            value={detectionGroupToDetection(d)}
            key={idx++}
            expanded={expanded}
            isNested={isNested}
          />
        ))}
      </tbody>
    </table>
  );
};

const suppressSuriFields = (d: Detection) => {
  try {
    if (d.Name.startsWith("suri")) {
      if (
        Array.isArray(d.Details) &&
        d.Details.length > 0 &&
        d.Details[0]._requestref
      ) {
        d.Details = d.Details.map(({ url }) =>
          url
            ? {
                url,
              }
            : undefined
        );
      } else if (d.Details && d.Details._requestref) {
        d.Details = d.Details.url ? { url: d.Details.url } : undefined;
      }
    }
  } catch (_) {}
};

const MetaRuleDetails = ({
  details,
  expanded,
}: {
  details: { [key: string]: any };
  expanded: boolean;
}) => {
  if (!details) {
    return null;
  }

  const nestedDetections = details["Nested Detections"] || [];

  return (
    <div className="nestedDetections">
      <DetectionTable
        detections={nestedDetections}
        expanded={expanded}
        isNested={true}
      />
    </div>
  );
};

export const DetectionRow = ({
  isConsolidated,
  value,
  expanded,
  isNested = false,
}: {
  isConsolidated: boolean;
  value: Detection;
  expanded: boolean;
  isNested?: boolean;
}) => {
  const engines = getEngines(value);
  const labels = [
    ...(value.MalwareFamilies || []).map((v) => ({
      Type: LabelType.MalwareFamily,
      Value: v,
    })),
    ...(value.PhishKitFamilies || []).map((v) => ({
      Type: LabelType.PhishKitFamily,
      Value: v,
    })),
    ...(value.PhishedBrands || []).map((v) => ({
      Type: LabelType.PhishedBrand,
      Value: v,
    })),
  ];

  suppressSuriFields(value);

  if (value.Details && Object.keys(value.Details).length > 0) {
    let detailComponent = undefined;
    if (value.Details.length) {
      detailComponent = value.Details.map(
        (d: ArtifactCommon["Details"], i: number) =>
          d?.IsMetaRule ? ( // we use the nested IsMetaRule detail key rather than the isMetaDetection helper on purpose here, as this treatment is only valid for meta rules that have this key set
            <MetaRuleDetails details={d} key={i} expanded={expanded} />
          ) : (
            <GenericDataDisplay data={d} key={i} />
          )
      );
    }

    if (detailComponent.length > 1) {
      detailComponent = detailComponent.map((c: ReactNode, i: number) => (
        <div key={i} className="detailGroup">
          {c}
        </div>
      ));
    }

    let title = <>{value.Description || value.Name}</>;
    if (isMetaDetection(value)) {
      title = (
        <>
          <FontAwesomeIcon
            icon={["far", "list-tree"]}
            className="has-text-grey"
            title="This detection contains other nested detections"
          />
          &nbsp;
          {title}
        </>
      );
    }

    // Format for copy-and-paste data
    const copyData = (
      <>
        <h1>{title}</h1>
        <br />
        <div>
          <h2>Details:</h2>
          {detailComponent}
          <br />
        </div>
        <div>
          <h2>Score:</h2>
          <Score score={value.Severity ?? "NA"} />
        </div>
        <br />
        {labels.length > 0 ? (
          <div>
            <h2>Tags:</h2>
            <table>
              <tbody>
                <Labels tableMode labels={labels} />
              </tbody>
            </table>
            <br />
          </div>
        ) : null}
      </>
    );

    return (
      <tr>
        {engines != null && isConsolidated ? (
          <td className="nowrap fit-to-content">{engines}</td>
        ) : null}
        <td>
          <Expandable
            title={
              <LegacyJSXCopyable data={copyData}>
                {title} <Labels inlineMode labels={labels} />
              </LegacyJSXCopyable>
            }
            expanded={expanded}
            reportAs={value.Description}
            fullWidth={true}
          >
            {detailComponent}
          </Expandable>
        </td>
        {!isNested && (
          <td className="fit-to-content has-text-centered">
            <Score score={value.Severity ?? "NA"} />
          </td>
        )}
      </tr>
    );
  } else {
    const title = <>{value.Description || value.Name}</>;
    // Format for copy-and-paste data
    const copyData = (
      <>
        <h1>{title}</h1>
        <br />
        <div>
          <h2>Score:</h2>
          <Score score={value.Severity ?? "NA"} />
          <br />
        </div>
        {labels.length > 0 ? (
          <div>
            <h2>Tags:</h2>
            <table>
              <tbody>
                <Labels tableMode labels={labels} />
              </tbody>
            </table>
            <br />
          </div>
        ) : null}
      </>
    );

    return (
      <tr>
        {engines != null && isConsolidated ? (
          <td className="nowrap fit-to-content">{engines}</td>
        ) : null}
        <td>
          <LegacyJSXCopyable data={copyData}>
            {title} <Labels inlineMode labels={labels} />
          </LegacyJSXCopyable>
        </td>
        {!isNested && (
          <td className="fit-to-content has-text-centered">
            <Score score={value.Severity ?? "NA"} />
          </td>
        )}
      </tr>
    );
  }
};

export const DetectionBox = ({
  detections,
  collapsed = false,
  expandAll = false,
  isConsolidated = false,
  maxHeight = "none",
}: {
  detections: Detection[] | undefined | null;
  collapsed?: boolean;
  expandAll?: boolean;
  isConsolidated?: boolean;
  maxHeight?: string;
}) => {
  if (!detections || detections.length === 0) {
    return (
      <ExpandableBox title={`Detections (0)`} contentVisible={!collapsed}>
        <div>No malicious indicators detected</div>
      </ExpandableBox>
    );
  }
  return (
    <ExpandableBox
      title={`Detections (${detections.length})`}
      contentVisible={!collapsed}
      maxHeight={maxHeight}
    >
      <DetectionTable
        detections={detections}
        expanded={expandAll}
        isConsolidated={isConsolidated}
      />
    </ExpandableBox>
  );
};
