import { useAuth0 } from "@auth0/auth0-react";
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";

export const AUTHDOMAIN = process.env.REACT_APP_AUTHDOMAIN;
export const AUTHCLIENTID = process.env.REACT_APP_AUTHCLIENTID;
export const AUDIENCE = process.env.REACT_APP_AUTHAUDIENCE;

export interface Auth0Token {
  permissions: string[];
}

export const useUserHasPermissionHook = (
  permission?: string,
  def: boolean = false
) => {
  const [hasPermission, setHasPermission] = useState(def);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    let ignore = false;

    getAccessTokenSilently({ audience: AUDIENCE })
      .then((token) => {
        if (ignore) return;
        setHasPermission(
          permission ? !!userHasPermission(token, permission) : true
        );
      })
      .catch(() => {});

    return () => {
      ignore = true;
    };
  }, [getAccessTokenSilently, permission]);

  return hasPermission;
};

export const UserHasPermission = ({
  permission,
  children,
}: {
  permission?: string;
  children: React.ReactNode;
}) => {
  const hasPermission = useUserHasPermissionHook(permission);
  return hasPermission ? <>{children}</> : null;
};

export const userHasPermission = (token: string | null, permission: string) => {
  if (token == null) {
    return false;
  }
  const parsedJWT: Auth0Token = jwt_decode(token);

  try {
    return parsedJWT.permissions.includes(permission);
  } catch (err) {
    console.log(err);
  }
};

export const triggerPasswordChange = (email: string) => {
  let reqBody = {
    client_id: AUTHCLIENTID,
    email: email,
    connection: "Username-Password-Authentication",
  };

  return fetch(`https://${AUTHDOMAIN}/dbconnections/change_password`, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(reqBody),
  });
};
