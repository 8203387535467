import React from "react";
import { Detection } from "src/lib/APITypes";
import { DetectionBox } from "../forensics/Detections";
import { ResourceSummary } from "../ResourceSummary";
import { LoadingMessage } from "./LoadingMessage";
import { TaskProperties } from "./TaskTypes";

export const ClamAVResults = ({
  job,
  resource,
  task,
  forensics,
}: TaskProperties) => {
  if (forensics === "loading") {
    return (
      <section>
        <ResourceSummary job={job} task={task} resource={resource} />
        <LoadingMessage />
      </section>
    );
  }

  let detections: Detection[] = [];

  if (forensics && forensics.Detections) {
    detections = forensics.Detections;
  }

  return (
    <section>
      <ResourceSummary job={job} task={task} resource={resource} />
      {detections.length > 0 ? (
        <DetectionBox detections={detections} />
      ) : (
        "Sample was not detected by ClamAV as malicious"
      )}
    </section>
  );
};
