import React from "react";
import { TaskRow } from "./TaskRow";
import { getSortedResources } from "../pages/JobDetail";
import { EncryptedResourceIcon } from "./EncryptedResourceIcon";
import { Job, Resource, Task } from "src/lib/APITypes";
import { TaskSortOrder } from "src/lib/TaskSort";
import { ThreatName } from "./utils/ThreatName";

export function SortedTasks(job: Job, resource: Resource) {
  let tasks = job.Tasks.filter((t) => t.ResourceID === resource.ID);

  tasks.sort((a, b) => (TaskSortOrder(a) < TaskSortOrder(b) ? -1 : 1));

  return tasks;
}

interface TaskListProps {
  score: number;
  job: Job;
  onSelect: any;
  selectedTask: Task | null;
}

export class TaskList extends React.Component<TaskListProps> {
  makeConsolidatedTask = () => {
    return {
      ID: "consolidated",
      Engine: "consolidated",
      Results: {
        Details: {},
        Score: this.props.score,
        DisplayScore: this.props.score * 100,
        Forensics: {
          Raw: "",
          Normalized: "",
        },
      },
      State: this.props.job.State,
      CreatedAt: this.props.job.CreatedAt,
      UpdatedAt: this.props.job.UpdatedAt,
      ResourceID: "",
      StateText: "",
      Priority: this.props.job.Priority,
    };
  };

  state = {
    consolidatedTask: this.makeConsolidatedTask(),
  };

  selectTask = (resource: Resource | null, task: Task) => {
    this.props.onSelect(resource, task);
  };

  componentDidUpdate(lastProps: TaskListProps) {
    if (
      this.props.score !== lastProps.score ||
      this.props.job.State !== lastProps.job.State
    ) {
      this.setState({ consolidatedTask: this.makeConsolidatedTask() });
    }
  }

  componentDidMount() {
    this.selectTask(null, this.state.consolidatedTask);
  }

  render() {
    const job = this.props.job;

    return (
      <div>
        <nav className="panel">
          <p className="panel-heading resource-name">Summary</p>

          <TaskRow
            key="consolidated"
            task={this.state.consolidatedTask}
            isActive={
              this.props.selectedTask?.ID === this.state.consolidatedTask.ID
            }
            onSelect={this.selectTask}
          />

          {getSortedResources(job).map((r) => (
            <div key={r.Location}>
              <p className="panel-heading resource-name" title={r.Name}>
                <EncryptedResourceIcon
                  style={{ position: "relative", top: -3, left: 0 }}
                  resource={r}
                />{" "}
                <ThreatName name={r.Name} />
              </p>

              {SortedTasks(job, r).map((task) => {
                return (
                  <TaskRow
                    key={task.ID}
                    task={task}
                    resource={r}
                    isActive={task.ID === this.props.selectedTask?.ID}
                    onSelect={this.selectTask}
                  />
                );
              })}
            </div>
          ))}
        </nav>
      </div>
    );
  }
}
