import React from "react";
import { Link } from "react-router-dom";

export class NavBar extends React.Component {
  state = {
    hamburgerActive: false,
  };

  toggleBurger = (evt) => {
    this.setState({
      hamburgerActive: !this.state.hamburgerActive,
    });
  };

  render() {
    const logoTarget = this.props.logoTarget || "/";

    let logo_path = "/saa_logo.svg";
    if (process.env.REACT_APP_UI_ENVIRONMENT === "dev") {
      logo_path = "/saa_logo_dev.png";
    } else if (process.env.REACT_APP_UI_ENVIRONMENT === "staging") {
      logo_path = "/saa_logo_staging.png";
    }

    return (
      <nav
        className="navbar is-fixed-top"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <Link
            className="navbar-item"
            to={logoTarget}
            style={{ paddingTop: 0, paddingBottom: 0 }}
          >
            <img
              style={{ maxHeight: "4rem" }}
              src={logo_path}
              alt="Splunk Attack Analyzer"
            />
            <span className="logo-text">Attack&nbsp;Analyzer</span>
          </Link>
          {/* eslint-disable-next-line */}
          <a
            role="button"
            className={
              "navbar-burger burger" +
              (this.state.hamburgerActive ? " is-active" : "")
            }
            data-target="NavMenu"
            onClick={this.toggleBurger}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div
          className={
            "navbar-menu" + (this.state.hamburgerActive ? " is-active" : "")
          }
          id="NavMenu"
        >
          <div className="navbar-start">{this.props.leftItems}</div>
          <div className="navbar-end">{this.props.rightItems}</div>
        </div>
      </nav>
    );
  }
}
