import { useEffect, useState } from "react";
import { useRepProxyHook } from "src/lib/API";
import { RepProxyReturnTypes } from "src/lib/APITypes";

export const PATH = "/repproxy";

export const RepProxy = () => {
  const [mode, setMode] = useState<keyof RepProxyReturnTypes>("/v1/rateurls");
  const [submittedAt, setSubmittedAt] = useState<string>("");

  return (
    <div className="section">
      <div className="container">
        <h1 className="title is-4 has-text-centered">Rep Proxy</h1>
      </div>
      <div className="has-padding-bottom-20">
        <div className="field has-addons">
          <div className="control is-expanded">
            <div className="select is-fullwidth">
              <select
                value={mode}
                name="mode"
                onChange={(e) =>
                  setMode(e.target.value as keyof RepProxyReturnTypes)
                }
              >
                <option value="/v1/cachenormalizeurls">
                  Cache Key Normalize URLs
                </option>
                <option value="/v1/rateurls">Rate URLs</option>
                <option value="/v1/ratelinktexts">Rate Link Texts</option>
              </select>
            </div>
          </div>
          <div className="control">
            <button
              type="submit"
              className="button is-primary"
              onClick={(e) => {
                e.preventDefault();
                setSubmittedAt(`${mode}:${Date.now()}`);
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <Impl submittedAt={submittedAt} path={mode} />
    </div>
  );
};

function Impl<T extends keyof RepProxyReturnTypes>(props: {
  path: T;
  submittedAt: string;
}) {
  const { submit, isLoading, data } = useRepProxyHook(props.path);
  const [urls, setURLs] = useState<string[]>([]);

  useEffect(
    () => {
      if (props.submittedAt && props.submittedAt.startsWith(props.path))
        submit(urls);
    },
    /* eslint-disable-line react-hooks/exhaustive-deps*/ [
      props.submittedAt,
      submit,
      props.path,
    ]
  );
  return (
    <div className="section">
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <div style={{ width: "100%" }}>
          <textarea
            onChange={(e) => {
              e.preventDefault();
              setURLs(e.target.value.split("\n"));
            }}
            value={urls.length ? urls.join("\n") : undefined}
            placeholder="One URL per line…"
            style={{
              width: "50vw",
              height: "100vh",
              fontFamily: "monospace",
              padding: "1em",
            }}
          ></textarea>
        </div>
        <div style={{ width: "100%" }}>
          <pre>{isLoading ? "loading…" : JSON.stringify(data, null, "  ")}</pre>
        </div>
      </div>
    </div>
  );
}
