import React from "react";
import { useEffect } from "react";
import { DetectedObject, LabeledImage } from "src/components/ImageLabeling";
import { usePresignedURLHook } from "src/lib/API";
//@ts-ignore

interface S3ImageProps {
  path: string;
  shotIdx: number;
  caption: string;
  onLoad: any;
  labels?: DetectedObject[];
}

export const S3Image = ({
  path,
  shotIdx,
  caption,
  onLoad,
  labels = [],
}: S3ImageProps) => {
  const { presignedURL } = usePresignedURLHook(path);

  useEffect(() => {
    if (presignedURL && onLoad) {
      onLoad(shotIdx, presignedURL, caption);
    }
  }, [presignedURL, shotIdx, caption, onLoad]);

  if (presignedURL) {
    return (
      <LabeledImage
        src={presignedURL}
        labels={labels}
        showBoundingBoxesByDefault={false}
      />
    );
  }

  return <div>loading...</div>;
};
