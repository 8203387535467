import React from "react";
import {
  Image,
  NormalizedForensics,
  Resource,
  Screenshot,
} from "../../lib/APITypes";
import { ScreenshotBlock } from "./Screenshots";

export const ForensicsScreenshots = ({
  forensics,
  resources = [],
  isConsolidated = false,
  noExpansion = false,
  engine,
}: {
  forensics: NormalizedForensics | null;
  resources?: Resource[];
  isConsolidated?: boolean;
  noExpansion?: boolean;
  engine?: string;
}) => {
  if (!forensics) {
    return <></>;
  }

  let images = forensics.Images || [];
  let screenshots =
    (forensics.Images
      ? images.filter((i) => i.Type === "screenshot")
      : forensics.Screenshots) || [];
  let icons = images.filter((i) => i.Type === "icon");
  let extracted = images.filter((i) => i.Type === "extracted");
  //   let labeled = images.filter((i) => i.Type === "pre_labeled_image");

  return (
    <section>
      {screenshots && (
        <ScreenshotBlock
          screenshots={addEngine(engine, screenshots)}
          resources={resources}
          title="Screenshots"
          isConsolidated={isConsolidated}
          noExpansion={noExpansion}
        />
      )}
      {icons && (
        <ScreenshotBlock
          screenshots={addEngine(engine, icons)}
          resources={resources}
          title="Icons"
          isConsolidated={isConsolidated}
          noExpansion={noExpansion}
        />
      )}
      {extracted && (
        <ScreenshotBlock
          screenshots={addEngine(engine, extracted)}
          resources={resources}
          title="Extracted Images"
          isConsolidated={isConsolidated}
          noExpansion={noExpansion}
        />
      )}

      {/* <UserHasPermission permission={"app:admin"}>
        {labeled && <ScreenshotBlock screenshots={labeled} />}
      </UserHasPermission> */}
    </section>
  );
};

const addEngine = (
  engine: string | undefined,
  screenshots: Screenshot[] | Image[]
) => {
  if (engine !== "interactive_web_analyzer") return screenshots;

  return (screenshots || []).map((s: Screenshot | Image) => {
    if (!s.Engines) s.Engines = [engine];
    return s;
  });
};
