import { SplunkRum } from "@splunk/otel-web";
import { fromUrl, parseDomain } from "parse-domain";
import { FileSize } from "../FileSize";
import { EngineCountCell } from "../NormalizedForensics";
import { Expandable } from "../utils/Expandable";

export function HTTPForensics(props) {
  const http = props.data;

  let domains = {};
  for (let i = 0; i < http.length; i++) {
    let domain = "no domain name";
    try {
      const parsed = parseDomain(fromUrl(http[i].URL));
      if (parsed.icann && parsed.icann.domain) {
        // Prefer icann version otherwise things like "https://fonts.googleapis.com/foo"
        // report fonts as the domain.
        domain = `${parsed.icann.domain}.${parsed.icann.topLevelDomains.join(
          "."
        )}`;
      } else if (parsed.domain) {
        domain = `${parsed.domain}.${parsed.topLevelDomains.join(".")}`;
      }
    } catch (e) {
      SplunkRum.error(e);
    }

    let urls = domains[domain];
    if (urls === undefined) {
      urls = [];
      domains[domain] = urls;
    }
    urls.push(http[i]);
  }

  let sortedDomains = Object.keys(domains);
  sortedDomains.sort((a, b) => (a > b ? 1 : -1));

  return (
    <>
      {sortedDomains.map((d) => (
        <Expandable
          title={`${d} (${domains[d].length})`}
          expanded={props.expanded}
          key={d}
        >
          <table className="table">
            <thead>
              <tr>
                {props.consolidated ? <th>Engines</th> : null}
                <th>Method</th>
                <th>URL</th>
              </tr>
            </thead>
            <tbody>
              {domains[d].map((h, idx) => (
                <HTTP value={h} key={idx} expanded={props.expanded} />
              ))}
            </tbody>
          </table>
        </Expandable>
      ))}
    </>
  );
}

export const HTTP = (props) => {
  const http = props.value;
  const respHdrs = http.ResponseHeaders || {};
  let redirectTarget;

  Object.keys(respHdrs).forEach((k) => {
    if (k.toLowerCase() === "location") {
      redirectTarget = respHdrs[k];
    }
  });

  return (
    <tr>
      <EngineCountCell value={http} />
      <td>{http.Method || "-"}</td>
      <td className="forensic-value">
        {http.URL || http.Hostname + http.Path}
        {(http.Destination.IP !== "" || http.TotalSize !== 0) && (
          <table className="table is-bordered is-striped is-narrow">
            <tbody>
              <tr>
                <th>IP Addr</th>
                <th>Port</th>
                <th>Req Len</th>
                <th>Resp Len</th>
                <th>Status Code</th>
              </tr>
              <tr>
                <td>{http.Destination.IP}</td>
                <td>{http.Destination.Port}</td>
                <td>
                  <FileSize size={http.RequestSize} />
                </td>
                <td>
                  <FileSize size={http.ResponseSize} />
                </td>
                <td>{http.StatusCode}</td>
              </tr>
            </tbody>
          </table>
        )}
        {redirectTarget && (
          <div>
            <b>Redirected To</b>: {redirectTarget}
          </div>
        )}

        {Object.keys(respHdrs).length > 0 && (
          <HTTPHeaders value={respHdrs} expanded={props.expanded} />
        )}
      </td>
    </tr>
  );
};

export const HTTPHeaders = (props) => {
  const headers = props.value;

  return (
    <Expandable title="Response Headers" expanded={props.expanded}>
      <ul>
        {Object.keys(headers).map((h, idx) => (
          <li key={idx}>
            <b>{h}:</b> {headers[h]}
          </li>
        ))}
      </ul>
    </Expandable>
  );
};
