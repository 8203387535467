import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import * as Modal from "src/components/Modal";
import { Tabs, Tab } from "src/components/utils/Tabs";

class Video {
  constructor(
    public ID: number,
    public title: string,
    public category: "training" | "update" | "research",
    public url: string,
    public description: string,
    public date: Date
  ) {}
}

const allVideos = [
  new Video(
    1,
    "Submitting URLs and Files to Attack Analyzer",
    "training",
    "https://player.vimeo.com/video/850663674?h=c15cf95262&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479",
    "Learn best practices for creating jobs within Splunk Attack Analyzer.",
    new Date("2023-08-01")
  ),
  new Video(
    2,
    "Searching in Attack Analyzer",
    "training",
    "https://player.vimeo.com/video/850663879?h=4f20f6310e&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479",
    "Learn how to effectively search in the Splunk Attack Analyzer UI.",
    new Date("2023-08-01")
  ),
  new Video(
    3,
    "Attack Analyzer Engines",
    "training",
    "https://player.vimeo.com/video/850664010?h=df6fc594d2&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479",
    "Learn about the different engines that make up Splunk Attack Analyzer.",
    new Date("2023-08-01")
  ),
  new Video(
    4,
    "Reviewing Completed Jobs in Attack Analyzer",
    "training",
    "https://player.vimeo.com/video/850664238?h=3d51ff916c&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479",
    "Learn how to understand the data generated by Splunk Attack Analyzer.",
    new Date("2023-08-01")
  ),
  new Video(
    5,
    "Telegram Usage in Credential Phishing",
    "research",
    "https://player.vimeo.com/video/750172037?h=469bc266aa&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479",
    "The current ways that phishkits are utilizing telegram from the client and server sides.",
    new Date("2022-06-20")
  ),
  new Video(
    6,
    "Javascript Obfuscator Usage in Credential Phishing",
    "research",
    "https://player.vimeo.com/video/750174948?h=3e16105277&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&player_id=0&app_id=58479",
    "Phishkit users are moving away from Server Side PHP and towards Client Side Javascript for moving stolen credentials. This talk describes a way they are protecting their client side code.",
    new Date("2022-06-21")
  ),
];

export const KnowledgeCenterPage = () => {
  const sortedVideos = allVideos.sort(
    (a, b) => b.date.getTime() - a.date.getTime()
  );
  const trainingVideos = sortedVideos.filter((v) => v.category === "training");
  const featureVideos = sortedVideos.filter((v) => v.category === "update");
  const researchVideos = sortedVideos.filter((v) => v.category === "research");

  let initialVideo = null;
  let videoID: number | null = null;

  const { video }: { video?: string } = useParams();
  videoID = Number.parseInt(video || "");

  if (videoID && !Number.isNaN(videoID)) {
    for (let v of sortedVideos) {
      if (v.ID === videoID) {
        initialVideo = v;
        break;
      }
    }
  }

  const [activeVideo, setActiveVideo] = useState<Video | null>(initialVideo);

  return (
    <>
      <section className="hero is-primary is-small">
        <div className="hero-body">
          <p className="title">Knowledge Center</p>
        </div>
      </section>
      <div className="section">
        <h1 className="title">Product Documentation</h1>
        <p>
          Documentation for Attack Analyzer is located on the{" "}
          <a href="https://docs.splunk.com/Documentation/AttackAnalyzer/Current/Detect/Intro">
            Splunk Docs
          </a>{" "}
          website.
        </p>
        <p className={"mt-2"}>
          New features and functionality are communicated in the{" "}
          <a
            href={
              "https://docs.splunk.com/Documentation/AttackAnalyzer/Current/RN/WhatsNew"
            }
          >
            Release Notes
          </a>{" "}
          section.
        </p>
        <p className={"mt-2"}>
          Additional tutorial videos are available below in the Videos section
          of this page.
        </p>
        <h1 className="title mt-6">API Documentation</h1>
        <p>
          Attack Analyzer's API is designed to be easy to use, and allows you to
          perform any action, and access all the data available via the user
          interface. To use the API, you need an API key which you send in the
          headers of every API request. If you do not have an API key already,
          you can get one by emailing{" "}
          <strong>attackanalyzer-support@splunk.com</strong>.
        </p>
        <p className="mt-2">
          <a className="button" href="/docs">
            View API Documentation
          </a>
        </p>
        <h1 className="title mt-6">Videos</h1>
        <Tabs>
          <Tab label={`All (${sortedVideos.length})`}>
            <VideoList videos={sortedVideos} setActiveVideo={setActiveVideo} />
          </Tab>
          <Tab label={`Training (${trainingVideos.length})`}>
            <VideoList
              videos={trainingVideos}
              setActiveVideo={setActiveVideo}
            />
          </Tab>
          <Tab label={`Updates (${featureVideos.length})`}>
            <VideoList videos={featureVideos} setActiveVideo={setActiveVideo} />
          </Tab>
          <Tab label={`Research (${researchVideos.length})`}>
            <VideoList
              videos={researchVideos}
              setActiveVideo={setActiveVideo}
            />
          </Tab>
        </Tabs>
        <Modal.Modal
          isActive={activeVideo != null}
          wider
          close={() => {
            setActiveVideo(null);
          }}
        >
          <div
            className="container"
            style={{ width: "90vw", height: "55vw", maxHeight: "90vh" }}
          >
            {activeVideo !== null ? (
              <iframe
                width="100%"
                height="100%"
                src={activeVideo?.url}
                title={activeVideo.title}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              ></iframe>
            ) : (
              <div>no video</div>
            )}
          </div>
          <Modal.CardCloseButton text="Close Video" />
        </Modal.Modal>
      </div>
    </>
  );
};

const VideoList = ({
  videos,
  setActiveVideo,
}: {
  videos: Video[];
  setActiveVideo: CallableFunction;
}) => {
  return (
    <div className="columns">
      <div className="column is-half">
        {videos.map((v) => (
          <VideoComponent
            video={v}
            key={v.title}
            setActiveVideo={setActiveVideo}
          />
        ))}
      </div>
    </div>
  );
};

const VideoComponent = ({
  video,
  setActiveVideo,
}: {
  video: Video;
  setActiveVideo: CallableFunction;
}) => {
  return (
    <div className="mb-4">
      <article className="media">
        <div className="media-content">
          <div className="content">
            <p>
              <strong>
                {/* eslint-disable-next-line */}
                <a onClick={() => setActiveVideo(video)}>
                  <FontAwesomeIcon icon={["far", "video"]} /> {video.title}
                </a>
              </strong>
              <br />
              <small className="has-text-grey">
                {video.date.toDateString()}
              </small>
              <br />
              {video.description}
              <br />
            </p>
          </div>
        </div>
      </article>
    </div>
  );
};
