import React, { createRef, useEffect, useState } from "react";
// @ts-ignore
import Tooltip from "@cypress/react-tooltip";

export interface Point {
  X: number;
  Y: number;
}

export interface BoundingBox {
  UpperLeft: Point;
  LowerRight: Point;
}

export interface DetectedObject {
  Label: string;
  Confidence: number;
  BoundingBox: BoundingBox;
}

export interface LabeledImageProps {
  src: string;
  labels?: DetectedObject[];
  showBoundingBoxesByDefault?: boolean;
}

const confidence = (c: number) => {
  if (c >= 0.8) {
    return "high";
  } else if (c >= 0.5) {
    return "medium";
  }

  return "low";
};

export const LabeledImage = ({
  src,
  labels = [],
  showBoundingBoxesByDefault = false,
}: LabeledImageProps) => {
  const imgRef = createRef<HTMLImageElement>();
  const [scaleX, setScaleX] = useState(1.0);
  const [scaleY, setScaleY] = useState(1.0);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [offsetTop, setOffsetTop] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [lastResize, setLastResize] = useState(new Date());
  const [showBoundingBoxes, setShowBoundingBoxes] = useState(
    showBoundingBoxesByDefault
  );

  useEffect(() => {
    const resize = () => {
      setLastResize(new Date());
    };

    window.addEventListener("resize", resize);

    if (imgRef.current && loaded) {
      const im = imgRef.current;
      im.addEventListener("resize", () => {
        console.log("resized");
      });
      setScaleX(im.clientWidth / im.naturalWidth);
      setScaleY(im.clientHeight / im.naturalHeight);

      setOffsetLeft(im.offsetLeft);
      setOffsetTop(im.offsetTop);
    }

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [imgRef, loaded, lastResize]);

  return (
    <div
      style={{ position: "relative", zIndex: 1 }}
      onMouseEnter={() => {
        setShowBoundingBoxes(true);
      }}
      onMouseLeave={() =>
        !showBoundingBoxesByDefault && setShowBoundingBoxes(false)
      }
    >
      <img
        ref={imgRef}
        src={src}
        style={{
          display: "block",
          maxWidth: "100%",
          height: "auto",
          margin: "0 auto",
        }}
        onLoad={() => setLoaded(true)}
        alt="screenshot"
      />
      {loaded &&
        showBoundingBoxes &&
        labels.map((d, i) => (
          <Tooltip
            key={`${i}::${d.Label}`}
            title={
              <>
                Label: {d.Label}
                <br />
                Match Confidence: {confidence(d.Confidence)}
              </>
            }
          >
            <div
              style={{
                position: "absolute",
                left: offsetLeft + d.BoundingBox.UpperLeft.X * scaleX,
                top: offsetTop + d.BoundingBox.UpperLeft.Y * scaleY,
                width:
                  (d.BoundingBox.LowerRight.X - d.BoundingBox.UpperLeft.X) *
                  scaleX,
                height:
                  (d.BoundingBox.LowerRight.Y - d.BoundingBox.UpperLeft.Y) *
                  scaleY,
                backgroundColor: "rgba(0, 255, 255, .5)",
              }}
            ></div>
          </Tooltip>
        ))}
    </div>
  );
};
