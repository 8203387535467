import React, { useEffect, useState } from "react";

function _toInt(v: number | string, fallback: number) {
  switch (typeof v) {
    case "number":
      return Math.floor(v);
    case "string":
      const parsed = parseInt(v);
      if (isNaN(parsed)) {
        return fallback;
      }
      return parsed;
  }
}

export const ScoreRangeInput = ({
  onChange,
  initialMin = 0,
  initialMax = 100,
}: {
  onChange: (min: number, max: number) => void;
  initialMin?: number | string;
  initialMax?: number | string;
}) => {
  const [min, setMin] = useState(initialMin);
  const [max, setMax] = useState(initialMax);

  useEffect(() => {
    if (min !== initialMin) setMin(initialMin);
    if (max !== initialMax) setMax(initialMax);
    // eslint-disable-next-line
  }, [initialMin, initialMax]);

  const scoreIsValid = (min: number | string, max: number | string) => {
    return min >= 0 && min <= 100 && max >= 0 && max <= 100 && max >= min;
  };

  const setScoreFilter = (which: "min" | "max", strVal: string) => {
    let newMin = min;
    let newMax = max;

    if (which === "min") {
      newMin = strVal;
    } else {
      newMax = strVal;
    }

    if (scoreIsValid(newMin, newMax) && (newMin !== min || newMax !== max)) {
      onChange(_toInt(newMin, 0), _toInt(newMax, 100));
    }

    setMin(newMin);
    setMax(newMax);
  };

  return (
    <>
      <div className="columns">
        {/* <div className="column"></div> */}
        <div className="column is-narrow">
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Score&nbsp;From</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    value={min}
                    className="input"
                    type="text"
                    size={3}
                    maxLength={3}
                    onChange={(evt) => {
                      evt.preventDefault();
                      setScoreFilter("min", evt.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="field-label is-normal ml-3">
              <label className="label">To</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    value={max}
                    className="input"
                    type="text"
                    size={3}
                    maxLength={3}
                    onChange={(evt) => {
                      evt.preventDefault();
                      setScoreFilter("max", evt.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!scoreIsValid(min, max) && (
        <div className="has-text-danger">
          Invalid Score (must be between 0 and 100)
        </div>
      )}
    </>
  );
};

export const CompactScoreRangeInput = ({
  onChange,
  initialMin = 0,
  initialMax = 100,
}: {
  onChange: (min: number, max: number) => void;
  initialMin?: number | string;
  initialMax?: number | string;
}) => {
  const [min, setMin] = useState(initialMin);
  const [max, setMax] = useState(initialMax);

  useEffect(() => {
    if (min !== initialMin) setMin(initialMin);
    if (max !== initialMax) setMax(initialMax);
    // eslint-disable-next-line
  }, [initialMin, initialMax]);

  const scoreIsValid = (min: number | string, max: number | string) => {
    return min >= 0 && min <= 100 && max >= 0 && max <= 100 && max >= min;
  };

  const setScoreFilter = (which: "min" | "max", strVal: string) => {
    let newMin = min;
    let newMax = max;

    if (which === "min") {
      newMin = strVal;
    } else {
      newMax = strVal;
    }

    if (scoreIsValid(newMin, newMax) && (newMin !== min || newMax !== max)) {
      onChange(_toInt(newMin, 0), _toInt(newMax, 100));
    }

    setMin(newMin);
    setMax(newMax);
  };

  return (
    <>
      <label className="label">Score</label>
      <div className="field is-horizontal">
        <label className="label p-2" htmlFor="minscore">
          From
        </label>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <input
                id="minscore"
                value={min}
                className="input"
                type="text"
                size={3}
                maxLength={3}
                onChange={(evt) => {
                  evt.preventDefault();
                  setScoreFilter("min", evt.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <label className="label p-2" htmlFor="maxscore">
          To
        </label>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <input
                id="maxscore"
                value={max}
                className="input"
                type="text"
                size={3}
                maxLength={3}
                onChange={(evt) => {
                  evt.preventDefault();
                  setScoreFilter("max", evt.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {!scoreIsValid(min, max) && (
        <div className="has-text-danger">
          Invalid Score (must be between 0 and 100)
        </div>
      )}
    </>
  );
};
