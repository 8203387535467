import { useEffect, useState } from "react";
import { sameContents } from "src/lib/Utils";
import { ToggleSwitch } from "./utils/ToggleSwitch";

export interface Props {
  buttonText: string;
  options:
    | { display: string; value: string }[]
    | (() => Promise<{ display: string; value: string }[]>);
  selection: string[];
  onChange?: (selected: string[]) => void;
}

export const MultiSelect = (props: Props) => {
  const [isActive, setIsActive] = useState(false);
  const [selection, setSelection] = useState<string[]>(props.selection);
  const [options, setOptions] = useState<{ display: string; value: string }[]>(
    []
  );

  const { options: propsOptions, onChange: propsOnChange } = props;

  useEffect(() => {
    if (propsOnChange && !sameContents(selection, props.selection))
      propsOnChange(selection);
  }, [selection, propsOnChange, props.selection]);

  useEffect(() => {
    let cancelled = false;
    (async () => {
      if (typeof propsOptions === "function") {
        const opts = await propsOptions();
        if (cancelled) return;
        setOptions(opts);
      } else {
        if (cancelled) return;
        setOptions(propsOptions);
      }
    })();

    return () => {
      cancelled = true;
    };
  }, [propsOptions]);

  const unselectedOptions = options.filter((o) => !selection.includes(o.value));
  const isAllSelected = unselectedOptions.length === 0;

  return (
    <div
      className={`is-twinwave-multiselect dropdown ${
        isActive ? "is-active" : ""
      }`}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      <div className="dropdown-trigger">
        <button
          type="button"
          className="button"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={(evt) => evt.preventDefault()}
        >
          <span>{`${props.buttonText} (${
            selection.length || "default"
          })`}</span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content">
          <div style={{ paddingLeft: "10px" }}>
            <ToggleSwitch
              name={"All"}
              checked={isAllSelected}
              onChange={() => {
                if (isAllSelected) setSelection([]);
                else setSelection(options.map((v) => v.value));
              }}
              label={"All"}
            />
          </div>
          <hr className="dropdown-divider" />
          {options.map((s) => (
            <div style={{ paddingLeft: "10px" }} key={s.value}>
              <ToggleSwitch
                name={s.display}
                checked={selection.some((v) => s.value === v)}
                onChange={() => {
                  const i = selection.indexOf(s.value);
                  const next = [...selection];
                  if (i >= 0) next.splice(i, 1);
                  else next.push(s.value);
                  setSelection(next);
                }}
                label={s.display}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
