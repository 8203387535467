import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

// Wonky nullability due to needing to handle empty components being injected
type OptionalChildren = JSX.Element | null;

interface TabsProps {
  children: OptionalChildren[];
  for?: string | null;
}

interface TabsState {
  selectedLabel: string | null;
}

export class Tabs extends React.Component<TabsProps, TabsState> {
  state: TabsState = {
    selectedLabel: null,
  };

  constructor(props: TabsProps) {
    super(props);

    if (this.validChildren().length > 0) {
      this.state.selectedLabel = this.validChildren()[0].props.label;
    }
  }

  validChildren = (): JSX.Element[] => {
    if (Array.isArray(this.props.children)) {
      let valid: JSX.Element[] = [];
      for (const f of this.props.children) {
        // Skip invalid tabs which lets us render conditional tabs
        if (
          f !== undefined &&
          f !== null &&
          typeof f === "object" &&
          f.props !== undefined &&
          f.props !== null
        ) {
          valid.push(f);
        }
      }
      return valid;
    } else {
      return this.props.children !== null ? [this.props.children] : [];
    }
  };

  onTabClick = (tab: JSX.Element) => {
    this.setState({
      selectedLabel: tab.props.label,
    });
  };

  componentDidUpdate(prevProps: TabsProps) {
    if (prevProps.for !== this.props.for) {
      // our children likely changed so reset selection to zero
      const children = this.validChildren();
      if (children.length > 0) {
        this.setState({ selectedLabel: this.validChildren()[0].props.label });
      } else {
        this.setState({ selectedLabel: null });
      }
    }
  }

  render() {
    const children = this.validChildren();

    return (
      <div>
        <div className={"tabs"}>
          <ul>
            {children.map((tab) => {
              const cName =
                tab.props.label === this.state.selectedLabel ? "is-active" : "";
              return (
                <li className={cName} key={tab.props.label}>
                  {/* eslint-disable-next-line */}
                  <a onClick={() => this.onTabClick(tab)}>
                    {tab.props.icon != null ? (
                      <span className="icon is-small">
                        <FontAwesomeIcon icon={tab.props.icon} />
                      </span>
                    ) : undefined}
                    {tab.props.label}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="has-padding-left-10">
          {children.map((tab) =>
            tab.props.label === this.state.selectedLabel
              ? tab.props.children
              : undefined
          )}
        </div>
      </div>
    );
  }
}

interface TabProps {
  label: string;
  icon?: IconProp;
  children?: React.ReactNode;
}

export class Tab extends React.Component<TabProps> {
  render() {
    return this.props.children;
  }
}
