import React from "react";
import { DetectionBox } from "../forensics/Detections";
import { TaskProperties } from "./TaskTypes";
import { LoadingMessage } from "./LoadingMessage";
import { ResourceSummary } from "../ResourceSummary";
import { ForensicsScreenshots } from "../forensics/ForensicsScreenshots";

export const FalconXResults = ({
  job,
  task,
  resource,
  forensics,
}: TaskProperties) => {
  return (
    <section>
      <ResourceSummary job={job} task={task} resource={resource} />

      <ul>
        {/* <li>
          <b>Verdict:</b> {task.Results.Details.Verdict}
        </li> */}
        <li>
          <b>Environment:</b> {task.Results.Details.Environment}
        </li>
        {task.Results.Details.Tags && (
          <li>
            <b>Tags:</b>{" "}
            {(task.Results.Details.Tags || []).map((t: string) => (
              <>
                <span className="tag" key={t}>
                  {t}
                </span>{" "}
              </>
            ))}
          </li>
        )}
        {task.Results.Details.SampleFlags && (
          <li>
            <b>Sample Flags:</b>{" "}
            {(task.Results.Details.SampleFlags || []).map((f: string) => (
              <>
                <span className="tag" key={f}>
                  {f}
                </span>{" "}
              </>
            ))}
          </li>
        )}
        {/* <li>
          <b>Resource Classification:</b>{" "}
          <div className="has-padding-left-20">
            <ul>
              {(task.Results.Details.Classification || []).map(
                (v: string, idx: number) => (
                  <li key={idx}>{v}</li>
                )
              )}
            </ul>
          </div>
        </li> */}
      </ul>

      {forensics === "loading" ? (
        <LoadingMessage />
      ) : (
        <div>
          {(forensics?.Detections?.length ?? 0) > 0 && (
            <DetectionBox
              detections={forensics?.Detections}
              maxHeight="30rem"
            />
          )}
          <ForensicsScreenshots forensics={forensics} />
        </div>
      )}
    </section>
  );
};
