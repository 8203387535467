import React from "react";
import { Detection, Image, Screenshot } from "src/lib/APITypes";
import { CAPASummary } from "../CAPA";
import { FileSize } from "../FileSize";
import { DetectionBox } from "../forensics/Detections";
import { ForensicsScreenshots } from "../forensics/ForensicsScreenshots";
import { ResourceSummary } from "../ResourceSummary";
import { Copyable } from "../utils/Copyable";
import { Expandable } from "../utils/Expandable";
import { ExpandableBox } from "../utils/ExpandableBox";
import { TaskProperties } from "./TaskTypes";

export const StaticFileResults = ({
  forensics,
  job,
  resource,
  task,
}: TaskProperties) => {
  if (forensics === "loading") {
    return (
      <>
        <ResourceSummary job={job} resource={resource} task={task} />
        <div className="has-text-info has-margin-top-15">Loading...</div>
      </>
    );
  }

  const details = task.Results.Details;
  let detections: Detection[] = [];
  let images: Image[] | Screenshot[] = [];

  let pe = details.pe || {};
  if (Object.keys(pe).length === 0) {
    // test for empty object
    pe = null;
  }

  let capa = details.capa || {};

  if (forensics) {
    detections = forensics.Detections || [];

    if (forensics.Screenshots.length > 0) {
      images = forensics.Screenshots;
    } else if (forensics.Images && forensics.Images.length > 0) {
      images = forensics.Images;
    }
  }

  const showExif = Object.keys(details.exiftool).some(
    (key) => !key.startsWith("File:") && !key.startsWith("ExifTool:Warning")
  );

  return (
    <section>
      <ResourceSummary job={job} resource={resource} task={task} />

      {detections.length > 0 && <DetectionBox detections={detections} />}

      <ExpandableBox title="File Metadata">
        <table className="table is-narrow">
          <tbody>
            <tr>
              <th>Size</th>
              <td>
                <FileSize size={details.size} />
              </td>
            </tr>
            <tr>
              <th>SHA256</th>
              <td>
                <Copyable data={details.sha256}>{details.sha256}</Copyable>
              </td>
            </tr>
            <tr>
              <th>MD5</th>
              <td>
                <Copyable data={details.md5}>{details.md5}</Copyable>
              </td>
            </tr>
            <tr>
              <th>SHA1</th>
              <td>
                <Copyable data={details.sha1}>{details.sha1}</Copyable>
              </td>
            </tr>
            <tr>
              <th>Ssdeep</th>
              <td>
                <Copyable data={details.ssdeep}>{details.ssdeep}</Copyable>
              </td>
            </tr>
            <tr>
              <th>TLSH</th>
              <td>
                <Copyable data={details.tlsh}>{details.tlsh}</Copyable>
              </td>
            </tr>
            <tr>
              <th>File&nbsp;Type</th>
              <td>{details.magic}</td>
            </tr>
            <tr>
              <th>Mimetype</th>
              <td>{details.mime}</td>
            </tr>
            {details.trid && (
              <tr>
                <th>TrID</th>
                <td>
                  <ul>
                    {details.trid.map((id: string) => (
                      <li key={id}>{id}</li>
                    ))}
                  </ul>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </ExpandableBox>

      {showExif && (
        <ExpandableBox title="EXIF Tool" maxHeight="9rem">
          <table className="table is-narrow">
            <tbody>
              {Object.entries(details.exiftool).map((e) => {
                if (e[0].startsWith("File:")) {
                  return null;
                }
                return (
                  <tr key={e[0]}>
                    <th>{e[0].split(":")[1]}</th>
                    <td>{e[1] as any}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </ExpandableBox>
      )}

      {pe && (
        <ExpandableBox title="PE Analysis" maxHeight={"9rem"}>
          <table className="table is-narrow">
            <tbody>
              <tr>
                <th className="nowrap">Ember Malscore 1</th>
                <td>{pe.ember_malscore}</td>
              </tr>
              <tr>
                <th className="nowrap">Ember Malscore 2</th>
                <td>{pe.ember_malscore_2018}</td>
              </tr>
              <tr>
                <th>Imphash</th>
                <td>{pe.imphash}</td>
              </tr>
              {pe.exported_dll_name && (
                <tr>
                  <th className="nowrap">Exported DLL Name</th>
                  <td>{pe.exported_dll_name}</td>
                </tr>
              )}
              <tr>
                <th className="nowrap">Image Base</th>
                <td>{pe.imagebase}</td>
              </tr>
              <tr>
                <th>Entrypoint</th>
                <td>{pe.entrypoint}</td>
              </tr>
              {pe.peid_signatures && (
                <tr>
                  <th className="nowrap">PEID Signatures</th>
                  <td>
                    <ul>
                      {pe.peid_signatures.map((s: string) => (
                        <li key={s}>{s}</li>
                      ))}
                    </ul>
                  </td>
                </tr>
              )}
              <tr>
                <th className="nowrap">OS Version</th>
                <td>{pe.osversion}</td>
              </tr>
              {pe.pdb_path && (
                <tr>
                  <th className="nowrap">PDB Path</th>
                  <td>{pe.pdb_path}</td>
                </tr>
              )}
              {pe.exports && (
                <tr>
                  <th>Exports</th>
                  <td>
                    <ul>
                      {pe.exports.map((e: any) => (
                        <li key={e.address}>{e.name}</li>
                      ))}
                    </ul>
                  </td>
                </tr>
              )}
              {pe.imports && (
                <tr>
                  <th>Imports</th>
                  <td>
                    {pe.imports.map((dll: any) => (
                      <Expandable
                        title={`${dll.dll} (${dll.imports.length})`}
                        key={dll.dll}
                        expanded={pe.imports.length === 1}
                      >
                        <ul>
                          {dll.imports.map((i: any) => (
                            <li key={i.address}>{i.name}</li>
                          ))}
                        </ul>
                      </Expandable>
                    ))}
                  </td>
                </tr>
              )}
              {pe.sections && (
                <tr>
                  <th>Sections</th>
                  <td>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Entropy</th>
                          <th>Raw Addr</th>
                          <th>Virtual Addr</th>
                          <th>Size</th>
                          <th>Characteristics</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pe.sections.map((section: any, i: number) => (
                          <tr key={i}>
                            <td>{section.name}</td>
                            <td>{section.entropy}</td>
                            <td>{section.raw_address}</td>
                            <td>{section.virtual_address}</td>
                            <td>{section.size_of_data}</td>
                            <td>
                              {section.characteristics.split("|").join(", ")}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </ExpandableBox>
      )}

      {capa && (capa.Capabilities || capa.Attacks) && <CAPASummary {...capa} />}

      {images.length > 0 && <ForensicsScreenshots forensics={forensics} />}
    </section>
  );
};
