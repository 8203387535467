import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface ExpandableProps {
  expanded: boolean;
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  reportAs?: string;
  fullWidth?: boolean;
  children?: React.ReactNode;
}

export class Expandable extends React.Component<ExpandableProps> {
  state = {
    expanded: this.props.expanded,
  };

  static defaultProps = {
    expanded: false,
  };

  expand = () => {
    this.setState({ expanded: true });
  };

  contract = () => {
    this.setState({ expanded: false });
  };

  componentDidUpdate(prevProps: ExpandableProps) {
    if (prevProps.expanded !== this.props.expanded) {
      this.setState({ expanded: this.props.expanded });
    }
  }

  render() {
    if (this.state.expanded) {
      return (
        <div>
          <div className="expandable-control" onClick={this.contract}>
            <span className="icon has-text-link is-small">
              <FontAwesomeIcon icon="minus-square" />
            </span>
            &nbsp;
            <span>{this.props.title}</span>
          </div>
          {this.props.subtitle && <span>{this.props.subtitle}</span>}
          <div
            className={`expandable-content${
              this.props.fullWidth ? " fullwidth" : ""
            }`}
          >
            {this.props.children}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="expandable-control" onClick={this.expand}>
            <span className="icon has-text-link is-small">
              <FontAwesomeIcon icon="plus-square" />
            </span>
            &nbsp;
            <span>{this.props.title}</span>
          </div>
          {this.props.subtitle && <span>{this.props.subtitle}</span>}
        </div>
      );
    }
  }
}
