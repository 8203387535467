import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ResourceTree.css";
import { EncryptedResourceIcon } from "./EncryptedResourceIcon";
import { Copyable } from "./utils/Copyable";
import { withAuth0 } from "@auth0/auth0-react";
import { ThreatName } from "./utils/ThreatName";

class ResourceInternal extends React.Component {
  render() {
    const resource = this.props.node.resource;
    const children = this.props.node.children;

    let resourceClass = "threat-name";
    let resourcePrefix = "";

    if (resource.Score >= 0.7) {
      resourcePrefix = (
        <FontAwesomeIcon
          icon={"exclamation-circle"}
          className="has-text-danger"
        />
      );
    } else if (resource.Score >= 0.3) {
      resourcePrefix = (
        <FontAwesomeIcon
          icon={"exclamation-circle"}
          className="has-text-warning"
        />
      );
    }

    let resourceIcon = <></>;

    if (resource.Type === "file") {
      let iconName =
        {
          pdfdoc: "file-pdf",
          officedoc: "file-alt",
          archive: "file-archive",
          html: "file-code",
          image: "file-image",
          email: "envelope",
        }[resource.FileMetadata.FileType] || "file";
      resourceIcon = (
        <FontAwesomeIcon
          icon={["far", iconName]}
          className="has-text-default"
        />
      );
    }

    return (
      <li className={this.props.root ? "root" : undefined}>
        <div
          className={
            "resource" +
            (this.props.selectedResource?.ID === resource.ID ? " selected" : "")
          }
        >
          <div
            className="resource-title"
            onClick={() => this.props.onSelect(this.props.node.resource)}
          >
            <Copyable data={resource.Name}>
              {resourcePrefix}
              <InjectionMetadata resource={resource} />
              <span className={resourceClass}>
                {resourceIcon} <ThreatName name={resource.Name} />
              </span>
            </Copyable>
          </div>
          <div className="nowrap"></div>
        </div>
        {children.length > 0 && (
          <ul>
            {children.map((r, idx) => (
              <Resource
                jobID={this.props.jobID}
                node={r}
                key={idx}
                selectedResource={this.props.selectedResource}
                onSelect={this.props.onSelect}
              />
            ))}
          </ul>
        )}
      </li>
    );
  }
}

export const Resource = withAuth0(ResourceInternal);

export class ResourceTree extends React.Component {
  render() {
    let resources = this.props.job.Resources;
    resources.sort((a, b) => new Date(a.CreatedAt) - new Date(b.CreatedAt));

    // build up a tree out of the resources
    let tree = resources.map((r) => {
      return { resource: r, children: [] };
    });
    for (let i = 0; i < tree.length; i++) {
      const r = tree[i];
      if (r.resource.ParentID) {
        const parent = tree.filter(
          (t) => t.resource.ID === r.resource.ParentID
        )[0];
        parent.children.push(r);
      }
    }

    const roots = tree.filter((r) => !r.resource.ParentID);

    return (
      <div className="treelist">
        <span className="title is-5">Resources Analyzed</span>
        <ul className="root">
          {roots.map((r, idx) => (
            <Resource
              jobID={this.props.job.ID}
              node={r}
              root={true}
              key={idx}
              selectedResource={this.props.selectedResource}
              onResubmit={idx === 0 ? this.props.onResubmit : null}
              onSelect={this.props.onSelect}
            />
          ))}
        </ul>
      </div>
    );
  }
}

const InjectionMetadata = (props) => {
  const e = (
    <EncryptedResourceIcon
      resource={props.resource}
      EncryptedResourceIcon
      style={{
        position: "relative",
        top: "-1px",
        left: "2px",
        padding: "0 2px",
      }}
    />
  );
  let context = null;
  if (
    props.resource &&
    props.resource.InjectionMetadata &&
    props.resource.InjectionMetadata.AddedBecause
  ) {
    context = (
      <span className="added-because has-text-default">
        {props.resource.InjectionMetadata.AddedBecause}→
      </span>
    );
  }

  return (
    <>
      {e} {context}
    </>
  );
};
