import React from "react";
import ReactJson from "react-json-view";
import { VirusTotalResults } from "./tasks/VirusTotalResults";
import { ConsolidatedResults } from "./tasks/ConsolidatedResults";
import { ClamAVResults } from "./tasks/ClamAVResults";
import { CAPEResults } from "./tasks/CAPEResults";
import { CuckooResults } from "./tasks/CuckooResults";
import { VMRayResults } from "./tasks/VMRayResults";
import { HybridAnalysisResults } from "./tasks/HybridAnalysisResults";
import { URLReputationResults } from "./tasks/URLReputationResult";
import { ArchiveExtractionResults } from "./tasks/ArchiveExtractionResults";
import { StaticURLResults } from "./tasks/StaticURLResults";
import { StaticFileResults } from "./tasks/StaticFileResults";
import { StaticDocResults } from "./tasks/StaticDocResults";
import { WildFireResults } from "./tasks/WildFireResults";
import { EmlAnalyzerResults } from "./tasks/EmlAnalyzerResults";
import { WebAnalyzerResults } from "./tasks/WebAnalyzerResults";
import { FalconXResults } from "./tasks/FalconXResults";
import { FireEyeResults } from "./tasks/FireEyeResults";
import { YaraResults } from "./tasks/YaraResults";
import { IntezerResults } from "./tasks/IntezerResults";
import { HatchingTriageResults } from "./tasks/HatchingTriageResults";
import { ResourceSummary, TaskMenu } from "./ResourceSummary";
import { CaveatCard, truncationCaveat } from "./Caveats";
import { DebugLogLink } from "src/pages/LogsPage";
import { useUserHasPermissionHook } from "src/lib/Auth";

export const TaskResults = (props) => {
  const isAdmin = useUserHasPermissionHook("app:admin");

  if (props.task === null) {
    return <div>Select a task to see results</div>;
  }
  if (props.task.State === "inprogress") {
    if (props.task.Engine === "consolidated") {
      return (
        <div>
          Consolidated results will be available when the job is complete
        </div>
      );
    }
  }
  if (["inprogress", "pending", "dispatched"].includes(props.task.State)) {
    if (props.task.Engine === "consolidated") {
      return (
        <div>
          Consolidated results will be available when the job is complete
        </div>
      );
    }
    return <div>"{props.engine}" task is still in progress</div>;
  }

  let extraAdminMenu = null;
  try {
    if (isAdmin) {
      const l = DebugLogLink(props.job, props.task);
      if (l)
        extraAdminMenu = (
          <TaskMenu
            actions={[
              {
                title: "Debug Logs",
                onClick: () => {
                  window.open(l, "_blank", "noreferrer,noopener");
                },
                icon: ["far", "scroll"],
              },
            ]}
          />
        );
    }
  } catch (_) {}

  if (props.task.State === "error") {
    let errorMsg = null;

    if (props.task.StateText) {
      errorMsg = props.task.StateText;
    } else {
      try {
        errorMsg = props.task.Results.Details.ErrorMessage;
      } catch (err) {}
    }

    return errorMsg ? (
      <>
        {extraAdminMenu}
        <div>
          "{props.engine}" task returned an error: <em>{errorMsg}</em>
        </div>
      </>
    ) : (
      <>
        {extraAdminMenu}
        <div>"{props.engine}" task returned an error.</div>
      </>
    );
  }

  if (props.task.StateText === "truncated") {
    return (
      <>
        {extraAdminMenu}
        <CaveatCard
          caveats={[truncationCaveat(props.resource.Name)]}
          engine={props.engine}
        ></CaveatCard>
      </>
    );
  }

  if (props.task.State === "timedout") {
    return <div>Task timed out before returning results</div>;
  }

  if (props.task.Results === null) {
    return <div>No results available</div>;
  }

  let childComponent = null;

  // We skipped analysis for this task since Internet was not enabled (code === 14),
  // so short circuit and just display caveats.
  const caveats = props?.task?.Results?.Details?.Caveats || [];
  const engine = props.task.Engine;
  const noInternetCaveats = caveats.filter(({ code }) => code === 14);
  if (
    noInternetCaveats.length > 0 &&
    noInternetCaveats.length === caveats.length &&
    engine !== "consolidated"
  ) {
    return (
      <section>
        <ResourceSummary
          job={props.job}
          task={props.task}
          resource={props.resource}
          forensics={props.forensics}
        />
        <CaveatCard caveats={caveats} engine={engine} />
      </section>
    );
  }

  switch (engine) {
    case "virustotal":
      childComponent = (
        <VirusTotalResults
          job={props.job}
          resource={props.resource}
          task={props.task}
          forensics={props.forensics}
        />
      );
      break;
    case "wildfire":
      childComponent = (
        <WildFireResults
          job={props.job}
          resource={props.resource}
          task={props.task}
          forensics={props.forensics}
        />
      );
      break;
    case "fireeye":
      childComponent = (
        <FireEyeResults
          task={props.task}
          job={props.job}
          resource={props.resource}
          forensics={props.forensics}
        />
      );
      break;
    case "consolidated":
      childComponent = (
        <ConsolidatedResults
          consolidated={true}
          forensics={props.forensics}
          job={props.job}
        />
      );
      break;
    case "archive_extraction":
    case "unarchiver":
      childComponent = (
        <ArchiveExtractionResults
          job={props.job}
          resource={props.resource}
          task={props.task}
        />
      );
      break;
    case "clamav":
      childComponent = (
        <ClamAVResults
          job={props.job}
          resource={props.resource}
          task={props.task}
          forensics={props.forensics}
        />
      );
      break;
    case "CAPE":
    case "twcuckoo":
    case "twinwave_cuckoo":
    case "capev2":
    case "capev2win10":
    case "twinwave_cuckoo_win10":
    case "interactive_win7":
    case "interactive_win10":
    case "sandbox_win7":
    case "sandbox_win10":
      childComponent = (
        <CAPEResults
          task={props.task}
          job={props.job}
          resource={props.resource}
          forensics={props.forensics}
        />
      );
      break;
    case "cuckoo_vanilla":
      childComponent = (
        <CuckooResults
          job={props.job}
          resource={props.resource}
          task={props.task}
        />
      );
      break;
    case "vmray":
      childComponent = (
        <VMRayResults
          task={props.task}
          job={props.job}
          resource={props.resource}
          forensics={props.forensics}
        />
      );
      break;
    case "hybridanalysis":
    case "falcon":
      childComponent = (
        <HybridAnalysisResults
          engineName={props.task.Engine}
          job={props.job}
          resource={props.resource}
          task={props.task}
          forensics={props.forensics}
        />
      );
      break;
    case "gsb":
    case "url_reputation":
      childComponent = (
        <URLReputationResults
          job={props.job}
          resource={props.resource}
          task={props.task}
          forensics={props.forensics}
        />
      );
      break;
    case "staticurl":
    case "static_web":
      childComponent = (
        <StaticURLResults
          task={props.task}
          job={props.job}
          resource={props.resource}
          forensics={props.forensics}
        />
      );
      break;
    case "statictools":
    case "static_file":
      childComponent = (
        <StaticFileResults
          job={props.job}
          resource={props.resource}
          task={props.task}
          forensics={props.forensics}
        />
      );
      break;
    case "tika":
    case "static_doc":
      childComponent = (
        <StaticDocResults
          job={props.job}
          resource={props.resource}
          task={props.task}
          forensics={props.forensics}
        />
      );
      break;
    case "eml_analyzer":
      childComponent = (
        <EmlAnalyzerResults
          job={props.job}
          resource={props.resource}
          task={props.task}
          forensics={props.forensics}
        />
      );
      break;
    case "interactive_web_analyzer":
      childComponent = (
        <WebAnalyzerResults
          engine="interactive_web_analyzer"
          task={props.task}
          job={props.job}
          resource={props.resource}
          forensics={props.forensics}
        />
      );
      break;
    case "web_analyzer":
      childComponent = (
        <WebAnalyzerResults
          engine="web_analyzer"
          task={props.task}
          job={props.job}
          resource={props.resource}
          forensics={props.forensics}
        />
      );
      break;
    case "falconx":
      childComponent = (
        <FalconXResults
          job={props.job}
          resource={props.resource}
          task={props.task}
          forensics={props.forensics}
        />
      );
      break;
    case "yara":
      childComponent = (
        <YaraResults
          job={props.job}
          resource={props.resource}
          task={props.task}
          forensics={props.forensics}
        />
      );
      break;
    case "intezer":
      childComponent = (
        <IntezerResults
          job={props.job}
          resource={props.resource}
          task={props.task}
          forensics={props.forensics}
        />
      );
      break;
    case "hatching_triage":
      childComponent = (
        <HatchingTriageResults
          job={props.job}
          resource={props.resource}
          task={props.task}
          forensics={props.forensics}
        />
      );
      break;
    default:
      childComponent = (
        <ReactJson
          src={props.task.Results.Details}
          name={null}
          enableClipboard={false}
          displayDataTypes={false}
        />
      );
  }

  return <>{childComponent}</>;
};
