import React from "react";
import { Job } from "src/lib/APITypes";

export const JobDuration = ({ job }: { job: Job }) => {
  let end = Date.now();
  if (job.CompletedAt != null && !job.CompletedAt.startsWith("0")) {
    // it's not done yet
    end = Date.parse(job.CompletedAt);
  }
  let start = Date.parse(job.StartedAt || job.CreatedAt);
  var jobDuration = Math.floor((end - start) / 1000);

  if (jobDuration < 60) {
    return (
      <>
        {jobDuration} {jobDuration === 1 ? "second" : "seconds"}
      </>
    );
  } else {
    jobDuration = Math.floor(jobDuration / 60);
    return (
      <>
        {jobDuration} {jobDuration === 1 ? "minute" : "minutes"}
      </>
    );
  }
};
