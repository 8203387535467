import React from "react";
import { Detection } from "src/lib/APITypes";
import { DetectionBox } from "../forensics/Detections";
import { ResourceSummary } from "../ResourceSummary";
import { ExpandableBox } from "../utils/ExpandableBox";
import { LoadingMessage } from "./LoadingMessage";
import { TaskProperties } from "./TaskTypes";

export const VirusTotalResults = ({
  job,
  task,
  resource,
  forensics,
}: TaskProperties) => {
  if (!task.Results.Details.Found) {
    return (
      <section>
        <ResourceSummary job={job} resource={resource} task={task} />
        <div className="has-margin-top-20">
          Sample was not found in VirusTotal database
        </div>
      </section>
    );
  }

  if (forensics === "loading") {
    return (
      <section>
        <ResourceSummary job={job} resource={resource} task={task} />
        <LoadingMessage />
      </section>
    );
  }

  let detection: Detection | null = null;
  let avdetails: { [key: string]: string } = {};
  if (forensics && forensics.Detections && forensics.Detections.length > 0) {
    detection = forensics.Detections[0];
    avdetails = detection.Details?.Detections ?? {};
  }

  return (
    <section>
      <ResourceSummary job={job} resource={resource} task={task} />
      <div className="has-margin-top-20">
        <ul>
          <li>
            <b>Scan Date:</b>{" "}
            {new Date(
              Date.parse(task.Results.Details.ScanDate)
            ).toLocaleString()}
          </li>
          <li>
            <b>Permalink:</b>{" "}
            <a
              href={task.Results.Details.UIURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              virustotal
            </a>
          </li>
        </ul>
      </div>

      <DetectionBox detections={detection ? [detection] : []} />

      <ExpandableBox title={`AV Signatures (${Object.keys(avdetails).length})`}>
        <table className="table is-narrow is-full-width">
          <tbody>
            {Object.keys(avdetails).map((e) => (
              <tr key={e}>
                <th>{e}</th>
                <td>{avdetails[e]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </ExpandableBox>
    </section>
  );
};
