import React from "react";
import { SubmitForm } from "../components/SubmitForm";
import { RecentJobs } from "./RecentJobs";

export class Home extends React.Component {
  state = {
    lastSubmittedAt: null,
  };

  componentDidMount() {
    document.title = "Attack Analyzer";
  }

  handleSubmit = () => {
    this.setState({ lastSubmittedAt: new Date() });
  };

  render() {
    return (
      <section className="section">
        <SubmitForm submitHandler={this.handleSubmit} />
        <RecentJobs
          isPaginationEnabled={false}
          refreshInterval={10_000}
          maxResults={10}
          forceRefreshAt={this.state.lastSubmittedAt}
          isHeadless={true}
        />
      </section>
    );
  }
}
