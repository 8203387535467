import { Auth0Provider } from "@auth0/auth0-react";
import SplunkOtelWeb from "@splunk/otel-web";
import { createBrowserHistory } from "history";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.scss";
import { AUTHCLIENTID, AUTHDOMAIN } from "./lib/Auth";
import * as serviceWorker from "./serviceWorker";
import "./themes/Theme.scss";

if (process.env.REACT_APP_OTEL_ENV && process.env.REACT_APP_OTEL_TOKEN) {
  SplunkOtelWeb.init({
    beaconUrl: "https://rum-ingest.us1.signalfx.com/v1/rum",
    rumAuth: process.env.REACT_APP_OTEL_TOKEN,
    app: "twinwave/ui",
    version: process.env.REACT_APP_OTEL_VERSION || "dev",
    environment: process.env.REACT_APP_OTEL_ENV || "dev",
  });
}

export const history = createBrowserHistory();

const onRedirectCallback = (appState) => {
  // Use the router's history module to replace the url
  history.replace(appState?.returnTo || window.location.pathname);
};

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Auth0Provider
    domain={AUTHDOMAIN}
    clientId={AUTHCLIENTID}
    redirectUri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <App />
  </Auth0Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
