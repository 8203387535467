import { useState } from "react";

export function useLocalStorage<T>(key: string, defaultValue: T) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : defaultValue;
    } catch (e) {
      console.warn(`Error retrieving ${key} from localStorage`);
      return defaultValue;
    }
  });

  const setValue = (value: T) => {
    try {
      setStoredValue(value);
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.warn(`Error while setting localStorage for ${key}`, error);
    }
  };

  // NB: Change this to an array return type once CRA updates TS dependencies
  // and can parse array types properly…
  return { storedValue, setValue };
}
