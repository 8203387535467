import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useListUsersHook,
  useCreateUserHook,
  useDeleteUserHook,
  useEditUserHook,
  useGetUserRolesListHook,
  useListTenantsHook,
  useInviteUserHook,
} from "src/lib/API";
import { NewUserRecord, UserList } from "src/lib/APITypes";
import { UserHasPermission } from "../lib/Auth";

export const PATH = "/users";
export const PATH_CREATE = "/users/create";
export const PATH_CREATE_WITH_TENANT = "/users/create/:tenant";
export const PATH_EDIT = "/users/roles/:id/:oldRole";

/// Return the identity source (e.g. "auth0" or "samlp|acme_okta") from an Auth0 username
const idpSource = (userid: string) => {
  let fragments = userid.split("|").slice(0, -1);
  if (fragments.length === 1 && fragments[0] === "auth0") {
    return "Password";
  } else if (fragments.length === 2 && fragments[0] === "samlp") {
    return "SAML (" + fragments[1] + ")";
  } else {
    return "ERROR";
  }
};

const userCountString = (users: UserList) => {
  let start = users.start + 1;
  let end = users.start + users.length;
  let range: string = end > start ? start + " to " + end : end.toString();
  let ret: string = range + " of " + users.total + " users";
  return ret;
};

export const ListUsers = () => {
  const [tenantChoice, setTenantChoice] = useState<string | undefined>();
  const [userListPage, setUserListPage] = useState<number>(() => 0);
  const { tenants } = useListTenantsHook();
  const userlist = useListUsersHook();
  const deletion = useDeleteUserHook();
  const invite = useInviteUserHook();
  const { roles, getRoles, hasError, isLoading } = useGetUserRolesListHook();

  useEffect(() => {
    userlist.refresh(tenantChoice, userListPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userlist.refresh, tenantChoice, userListPage]);

  useEffect(() => {
    if (!deletion.response?.code) return;
    if (deletion.response.code === 200) {
      toast.success("Successfully deleted!");
      return;
    }

    toast.error(
      `Error [${deletion.response?.code}]: ${deletion.response?.statusText}`,
      {
        autoClose: false,
      }
    );
  }, [deletion.response?.code, deletion.response?.statusText]);

  useEffect(() => {
    if (deletion.updatedAt) userlist.refresh(tenantChoice, userListPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletion.updatedAt, userlist.refresh, tenantChoice, userListPage]);

  useEffect(() => {
    if (userlist.users)
      getRoles(userlist.users.users.map((user) => user.user_id));
  }, [getRoles, userlist.users]);

  useEffect(() => {
    if (!invite.response?.code) return;
    if (invite.response.code === 200) {
      toast.success("Invitation email sent!");
      return;
    }
    toast.error(
      `Error [${invite.response?.code}]: ${invite.response?.statusText}`,
      {
        autoClose: false,
      }
    );
  }, [invite.response?.code, invite.response?.statusText]);

  return (
    <div className="section">
      <div className="container">
        <h1 className="title is-4 has-text-centered">User Management</h1>
      </div>
      <div className="container">
        <div className="columns">
          <UserHasPermission permission="app:admin">
            <div className="column is-4">
              <div className="field">
                <label className="label">Tenants</label>
                <div className="field-body">
                  <div className="control">
                    <div className="select">
                      <select
                        id="tenant"
                        name="tenant"
                        value={tenantChoice}
                        onChange={(evt) => {
                          evt.preventDefault();
                          setTenantChoice(evt.target.value);
                          setUserListPage(0);
                        }}
                      >
                        <option key="self" value="self">
                          self
                        </option>
                        {tenants
                          ? tenants.map((t) => (
                              <option key={t.Name} value={t.Name}>
                                {" "}
                                {t.FullName}{" "}
                              </option>
                            ))
                          : ""}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-2 is-offset-6">
              <NavLink
                to={`/users/create/${tenantChoice ?? ""}`}
                className="button is-primary is-pulled-right"
              >
                <span className="icon is-small">
                  <FontAwesomeIcon icon={["far", "plus"]} />
                </span>
                <span>New User</span>
              </NavLink>
            </div>
          </UserHasPermission>
        </div>
        <table className="table is-fullwidth">
          <thead>
            <tr key="headers">
              <th>Email</th>
              <th>Role</th>
              <th>Num logins</th>
              <th>Last Login</th>
              <th>Last IP</th>
              <th>Authentication Method</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {userlist.users?.users.map((k, idx) => (
              <tr key={k.user_id}>
                <td>{k.email}</td>
                <td className={hasError[idx] ? "is-warning" : ""}>
                  {roles && roles.length > idx ? roles[idx] : "Loading..."}
                </td>
                <td>{k.logins_count ? k.logins_count : 0}</td>
                <td>
                  {k.last_login
                    ? new Date(Date.parse(k.last_login)).toLocaleString()
                    : ""}
                </td>
                <td>{k.last_ip}</td>
                <td>{idpSource(k.user_id)}</td>
                <td>
                  <div className="buttons">
                    <span>
                      <button
                        className={
                          k.user_id.startsWith("auth0|")
                            ? "button is-small"
                            : "button is-small is-invisible"
                        }
                      >
                        <span
                          className="icon is-small"
                          title="Resend Invitation Email"
                          onClick={() => invite.submit(k.user_id)}
                        >
                          <FontAwesomeIcon icon={["fas", "envelope-open"]} />
                        </span>
                      </button>

                      {isLoading[idx] === false && hasError[idx] === false ? (
                        <button className="button is-small">
                          <NavLink
                            to={`/users/roles/${k.user_id}/${roles[idx]}`}
                          >
                            <span
                              className="icon is-small"
                              title="Edit User Role"
                            >
                              <FontAwesomeIcon icon={["fas", "edit"]} />
                            </span>
                          </NavLink>
                        </button>
                      ) : (
                        <div className="button is-small is-diabled">
                          <span
                            className="icon is-small is-disabled"
                            title="Edit User Role"
                          >
                            <FontAwesomeIcon icon={["fas", "edit"]} />
                          </span>
                        </div>
                      )}

                      <button
                        className="button is-small is-danger"
                        disabled={deletion.isLoading}
                        onClick={(evt) => {
                          evt.preventDefault();
                          const confirmation = window.prompt(
                            `Are you sure you want to delete the account of ${k.email}? Type DELETE to execute!`
                          );
                          if (confirmation === "DELETE") {
                            deletion.del(k.user_id);
                          } else {
                            window.alert("Operation cancelled!");
                          }
                        }}
                      >
                        <span className="icon is-small" title="Delete User">
                          <FontAwesomeIcon icon={["fas", "trash"]} />
                        </span>
                      </button>
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <hr />
        <div>
          <span>
            Showing{" "}
            {(userlist.users && userCountString(userlist.users)) ?? "(unknown)"}
            {userlist.users && userlist.users.length < userlist.users.total ? (
              <p>
                {Array.from(
                  {
                    length: Math.ceil(
                      userlist.users.total / userlist.users.limit
                    ),
                  },
                  (x, i) => i
                ).map((k) => (
                  <span
                    key={k.toString()}
                    className={
                      k === userListPage ? "button" : "button pagination-next"
                    }
                    onClick={() => setUserListPage(k)}
                  >
                    {" "}
                    {k + 1}{" "}
                  </span>
                ))}
              </p>
            ) : (
              ""
            )}
          </span>
        </div>
        <hr />
      </div>
    </div>
  );
};

const AVAILABLE_ROLES = [
  "Read Only",
  "Legacy Analyst",
  "Analyst",
  "Power User",
  "Administrator",
];

export const NewUserPreview = (args: NewUserRecord) => {
  const { submit, response } = useInviteUserHook();

  useEffect(() => {
    if (!response?.code) return;
    if (response.code === 200) {
      toast.success("Invitation email sent!");
      return;
    }
    toast.error(`Error [${response?.code}]: ${response?.statusText}`, {
      autoClose: false,
    });
  }, [response?.code, response?.statusText]);

  return (
    <div className="section">
      <div className="container is-max-desktop">
        <div className="container">
          <h1 className="title is-4 has-text-centered">New User Created</h1>
        </div>
        <article className="message is-danger">
          <div className="message-header">
            <p>A new user has been created</p>
          </div>
          <div className="message-body has-text-centered">
            <p>Use this link to send an email with an invitation link:</p>
            <span className="button" onClick={() => submit(args.userid)}>
              Send invitation email
            </span>
          </div>
        </article>
      </div>
      <a href={PATH}>Back to user management</a>
    </div>
  );
};

export const EditForm = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { edit, response, isLoading } = useEditUserHook(params.id ?? "");
  const [role, setRole] = useState<string>(params.oldRole ?? "Analyst");

  useEffect(() => {
    if (!response?.code) return;
    if (response.code === 200) {
      toast.success("New role saved!");
      navigate(PATH);
      return;
    }

    toast.error(`Error [${response?.code}]: ${response?.statusText}`, {
      autoClose: false,
    });
  }, [response?.code, response?.statusText, navigate]);

  if (isLoading) return <p>loading…</p>;

  return (
    <div className="section">
      <div className="container is-max-desktop">
        <div className="container">
          <h1 className="title is-4 has-text-centered">Change User Roles</h1>
        </div>
        <h3>{params.id}</h3>
        <form
          onSubmit={(evt) => {
            edit({
              Role: role!,
            });
          }}
        >
          <fieldset disabled={isLoading}>
            <div className="field">
              <label className="label">Role</label>
              <div className="control">
                <span className="select">
                  <select
                    id="role"
                    name="role"
                    value={role}
                    onChange={(evt) => {
                      setRole(evt.target.value);
                    }}
                  >
                    {AVAILABLE_ROLES.map((p) => (
                      <option key={p} value={p}>
                        {p}
                      </option>
                    ))}
                  </select>
                </span>
              </div>
            </div>
            <div className="control">
              <input
                type="submit"
                className="button is-primary"
                disabled={isLoading || !role}
              />
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export const CreateForm = () => {
  const params = useParams();
  const [tenantID] = useState<string>(params.tenant ?? "self");
  const [email, setEmail] = useState<string>("");
  const { submit, response, isLoading } = useCreateUserHook();

  useEffect(() => {
    if (!response?.code || response.code === 200) return;
    toast.error(`Error [${response?.code}]: ${response?.statusText}`, {
      autoClose: false,
    });
  }, [response?.code, response?.statusText]);

  if (response?.result) return <NewUserPreview {...response.result} />;

  return (
    <div className="section">
      <div className="container is-max-desktop">
        <div className="container">
          <h1 className="title is-4 has-text-centered">Create a New User</h1>
          <UserHasPermission permission="app:admin">
            <h3 className="is-4">Within tenant: {tenantID}</h3>
          </UserHasPermission>
        </div>
        <form
          onSubmit={(evt) => {
            evt.preventDefault();
            submit({
              TenantID: tenantID!,
              Email: email!,
              Role: "Analyst",
            });
          }}
        >
          <fieldset disabled={isLoading}>
            <div className="field">
              <label className="label">Email Address</label>
              <div className="control">
                <input
                  required
                  value={email}
                  onChange={(evt) => {
                    evt.preventDefault();
                    setEmail(evt.target.value);
                  }}
                  className="input"
                  type="text"
                  placeholder="user@acme.com"
                />
              </div>
            </div>
            <div className="control">
              <input
                type="submit"
                className="button is-primary"
                disabled={isLoading || !tenantID || !email}
              />
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  );
};
