import React from "react";
import { JobRow } from "./JobRow";
import { JobSummaryRow } from "./JobSummaryRow";

export class JobList extends React.Component {
  render() {
    return (
      <div className="container">
        <h1 className="title is-4 has-text-centered">{this.props.title}</h1>
        <table className="table is-fullwidth is-hoverable">
          <thead>
            <tr>
              <th className="nowrap fit-to-content">Time Submitted</th>
              <th className="nowrap has-text-centered fit-to-content">
                Submitted By
              </th>
              <th>Filename / URL</th>
              <th className="fit-to-content">Resources</th>
              <th className="has-text-centered fit-to-content">Status</th>
              <th className="has-text-centered fit-to-content">
                Max&nbsp;Score
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.jobs.map((job) =>
              this.props.summaryMode ? (
                <JobSummaryRow key={job.JobID} jobData={job} />
              ) : (
                <JobRow
                  key={job.ID}
                  jobData={job}
                  showTenant={this.props.showTenant}
                />
              )
            )}
          </tbody>
        </table>
        {this.props.loading ? (
          <div className="has-text-centered has-text-weight-bold has-text-info">
            Loading...
          </div>
        ) : (
          <>
            {this.props.jobs.length === 0 && (
              <div className="has-text-centered has-text-weight-bold has-text-info">
                {this.props.emptyMessageOverride || "No recent submissions"}
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}
