import React from "react";
import Lightbox from "react-image-lightbox";
import { S3Image } from "../utils/S3Image";
import { taskDisplayName } from "../utils/DisplayNames";
import { Copyable } from "../utils/Copyable";
import "react-image-lightbox/style.css";
import { ExpandableBox } from "../utils/ExpandableBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EngineSortOrder } from "src/lib/TaskSort";

export const Screenshot = (props) => {
  let isManual = false;
  try {
    if (props.engine === "interactive_web_analyzer") {
      const parts = props.url.split("-");
      const i = Number.parseInt(parts[parts.length - 1].split("."));
      if (!Number.isNaN(i)) {
        isManual = i % 2 !== 0;
      }
    }
  } catch (_) {}

  return (
    <div
      className={
        "column" +
        (props.fullSize ? " is-narrow" : " is-half") +
        " has-margin-bottom-20"
      }
      style={{ maxWidth: "100%" }}
    >
      {props.caption && (
        <Copyable data={props.caption}>
          <div
            className="is-size-7 resource-name has-text-centered has-padding-5"
            title={props.caption}
          >
            {isManual && (
              <span className="is-twblue-light has-margin-right-5">
                <FontAwesomeIcon icon="camera-retro" />
              </span>
            )}{" "}
            {props.labels && props.labels.length > 0 && (
              <span className="is-twblue-light has-margin-right-5">
                <FontAwesomeIcon icon="object-ungroup" />
              </span>
            )}{" "}
            {props.resourceScore >= 0.7 ? (
              <>
                <FontAwesomeIcon
                  icon={"exclamation-circle"}
                  className="has-text-danger"
                />{" "}
              </>
            ) : props.resourceScore >= 0.3 ? (
              <>
                <FontAwesomeIcon
                  icon={"exclamation-circle"}
                  className="has-text-warning"
                />{" "}
              </>
            ) : null}
            {props.caption}
          </div>
        </Copyable>
      )}
      <div className="card">
        <div className="card-image">
          {/* eslint-disable-next-line */}
          <a
            onClick={(e) => {
              e.preventDefault();
              props.onClick(props.idx);
            }}
          >
            <S3Image
              labels={props.labels}
              path={props.url}
              shotIdx={props.idx}
              caption={props.caption}
              onLoad={props.onLoad}
            />
          </a>
        </div>
        <footer className="card-footer">
          {(Object.keys(props.hashes).length > 0 ||
            (props.labels && props.labels.length > 0)) && (
            <div
              className="has-text-centered has-padding-10"
              style={{ width: "100%" }}
            >
              {props.labels && props.labels.length > 0 && (
                <div>
                  <span className="is-size-7 has-text-grey has-text-weight-bold">
                    Labels:
                  </span>
                  <span className="is-size-7 has-text-grey has-padding-left-5">
                    {[...new Set(props.labels.map((p) => p.Label))]
                      .sort()
                      .join(", ")}
                  </span>
                </div>
              )}

              {Object.keys(props.hashes).length > 0 && (
                <div className="has-text-centered">
                  <span className="is-size-7 has-text-grey has-text-weight-bold">
                    Hashes:
                  </span>
                  {Object.keys(props.hashes)
                    .sort()
                    .map((ih, idx) => (
                      <span
                        key={ih}
                        className="is-size-7 has-text-grey has-padding-left-5"
                      >
                        {idx > 0 && " | "}
                        {props.hashes[ih]}
                      </span>
                    ))}
                </div>
              )}
            </div>
          )}
        </footer>
      </div>
    </div>
  );
};

function screenshotIsDupe(screenshots, s) {
  for (let os of screenshots) {
    if (
      (s.Engines ? s.Engines[0] : null) !== (os.Engines ? os.Engines[0] : null)
    ) {
      continue;
    }

    try {
      if (
        !Object.keys(s.ImageHashes).length ||
        !Object.keys(os.ImageHashes).length
      ) {
        continue;
      }
    } catch (err) {
      continue;
    }

    if (
      s.Resource === os.Resource &&
      s.ImageHashes["ImageAvgHash"] === os.ImageHashes["ImageAvgHash"] &&
      s.ImageHashes["ImagePhash"] === os.ImageHashes["ImagePhash"] &&
      s.ImageHashes["ImageDhash"] === os.ImageHashes["ImageDhash"]
    ) {
      return true;
    }
  }

  return false;
}

export class ScreenshotBlock extends React.Component {
  state = {
    showModal: false,
    photoIndex: 0,
    expanded: this.props.expanded,
    sortedScreenshots: [],
    loadedScreenshots: [],
  };

  componentDidUpdate(prevProps) {
    if (prevProps.expanded !== this.props.expanded) {
      this.setState({ expanded: this.props.expanded });
    }
  }

  loadScreenshot = (idx, url, caption) => {
    let shots = this.state.loadedScreenshots;
    shots[idx] = { url: url, caption: caption };
    this.setState({ loadedScreenshots: shots });
    // this.state.loadedScreenshots[idx] = { url: url, caption: caption };
  };

  constructor(props) {
    super(props);

    let uniqShots = [];
    for (let ss of props.screenshots) {
      if (!screenshotIsDupe(uniqShots, ss)) {
        uniqShots.push(ss);
      }
    }

    uniqShots.sort((a, b) => {
      const ae = a.Engines ? a.Engines[0] : null;
      const be = b.Engines ? b.Engines[0] : null;

      if (ae !== be) {
        return EngineSortOrder(ae) > EngineSortOrder(be) ? 1 : -1;
      }

      const afn = a.ArtifactPath.split("/").pop();
      const bfn = b.ArtifactPath.split("/").pop();

      return afn > bfn ? 1 : -1;
    });

    this.state.sortedScreenshots = uniqShots;
  }

  render() {
    const screenshots = this.state.sortedScreenshots;

    if (screenshots.length === 0) {
      return <></>;
    }

    let photoKey = 0;
    let screenCount = screenshots.length;

    var shotComponents;
    let shotContent = [];

    var resourceScoreMap = new Map();
    for (let resource of this.props.resources || []) {
      resourceScoreMap.set(resource.Name, resource.Score || 0);
    }

    if (this.props.isConsolidated) {
      let groupedScreenshots = [];
      let lastEngine = { name: undefined, screenshots: [] };
      for (let i = 0; i < screenshots.length; i++) {
        let s = screenshots[i];

        const engine = s.Engines ? s.Engines[0] : "";

        if (engine !== lastEngine.name) {
          lastEngine = { name: engine, screenshots: [s] };
          groupedScreenshots.push(lastEngine);
        } else {
          lastEngine.screenshots.push(s);
        }
      }

      shotContent = groupedScreenshots.map((section) => (
        <ExpandableBox
          title={`${taskDisplayName(section.name)} (${
            section.screenshots.length
          })`}
          contentVisible={true}
          key={section.name}
          height="none"
        >
          <div className="columns is-multiline">
            {section.screenshots.map((s) => (
              <Screenshot
                engine={(s?.Engines || [])[0]}
                url={s.ArtifactPath}
                caption={s.Resource}
                resourceScore={resourceScoreMap.get(s.Resource) || 0}
                hashes={s.ImageHashes || {}}
                labels={s.DetectedObjects || []}
                idx={photoKey}
                key={photoKey++}
                fullSize={screenCount === 1}
                onLoad={this.loadScreenshot}
                onClick={(i) => {
                  this.setState({ showModal: true, photoIndex: i });
                }}
              />
            ))}
          </div>
        </ExpandableBox>
      ));
    } else {
      shotContent = (
        <div className="columns is-multiline">
          {screenshots.map((s) => (
            <Screenshot
              engine={(s?.Engines || [])[0]}
              url={s.ArtifactPath}
              caption={s.Resource}
              hashes={s.ImageHashes || {}}
              labels={s.DetectedObjects || []}
              idx={photoKey}
              key={photoKey++}
              fullSize={screenCount === 1}
              onLoad={this.loadScreenshot}
              onClick={(i) => {
                this.setState({ showModal: true, photoIndex: i });
              }}
            />
          ))}
        </div>
      );
    }

    if (this.props.noExpansion) {
      shotComponents = shotContent;
    } else {
      shotComponents = (
        <ExpandableBox
          title={`${this.props.title || "Screenshots"} (${screenCount})`}
          contentVisible={this.state.expanded}
          height="none"
        >
          {shotContent}
        </ExpandableBox>
      );
    }

    let selectedIdx = this.state.photoIndex;

    return (
      <>
        {shotComponents}
        {this.state.showModal && (
          <Lightbox
            mainSrc={this.state.loadedScreenshots[selectedIdx].url}
            nextSrc={
              this.state.loadedScreenshots[
                (selectedIdx + 1) % this.state.loadedScreenshots.length
              ].url
            }
            prevSrc={
              this.state.loadedScreenshots[
                (selectedIdx + this.state.loadedScreenshots.length - 1) %
                  this.state.loadedScreenshots.length
              ].url
            }
            onCloseRequest={() => this.setState({ showModal: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (selectedIdx + this.state.loadedScreenshots.length - 1) %
                  this.state.loadedScreenshots.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex:
                  (selectedIdx + 1) % this.state.loadedScreenshots.length,
              })
            }
            imageTitle={this.state.loadedScreenshots[selectedIdx].caption}
          />
        )}
      </>
    );
  }
}
