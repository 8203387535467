import { Link } from "react-router-dom";
import { humanize } from "src/lib/Utils";
import { getSearchLink } from "src/pages/SearchPage";
import * as APITypes from "../../lib/APITypes";

export const Label = (props: APITypes.Label) => {
  if (!props.Value) return null;

  let to;
  switch (props.Type) {
    case APITypes.LabelType.MalwareFamily:
    case APITypes.LabelType.PhishedBrand:
    case APITypes.LabelType.PhishKitFamily:
      to = getSearchLink({
        mode: "resources",
        term: props.Value,
        field: "tag",
        type: "exact",
        timeframe: "0",
      });
      break;
    case APITypes.LabelType.Verdict:
      to = getSearchLink({
        mode: "resources",
        field: "filename",
        verdict: props.Value,
        timeframe: "0",
      });
      break;
    case APITypes.LabelType.Generic:
    default:
      break;
  }

  if (to) {
    return (
      <Link className="tag tw-label" to={to}>
        {props.Type === APITypes.LabelType.Verdict
          ? humanize(props.Value)
          : props.Value}
      </Link>
    );
  }

  return <span className="tag tw-label">{props.Value}</span>;
};

const SORT_AND_TRANSLATIONS = [
  {
    Type: APITypes.LabelType.Generic,
    Translate: (c: number) => (c === 1 ? "Generic" : "Generics"),
  },
  {
    Type: APITypes.LabelType.Verdict,
    Translate: (c: number) => (c === 1 ? "Verdict" : "Verdicts"),
  },
  {
    Type: APITypes.LabelType.MalwareFamily,
    Translate: (c: number) => (c === 1 ? "Malware Family" : "Malware Families"),
  },
  {
    Type: APITypes.LabelType.PhishedBrand,
    Translate: (c: number) => (c === 1 ? "Phished Brand" : "Phished Brands"),
  },
  {
    Type: APITypes.LabelType.PhishKitFamily,
    Translate: (c: number) =>
      c === 1 ? "Phish Kit Family" : "Phish Kit Families",
  },
];

const find = (t: APITypes.LabelType) =>
  SORT_AND_TRANSLATIONS.findIndex(({ Type }) => Type === t);

const SORT_PRECEDENCE: {
  [key: string]: ReturnType<typeof find>;
} = Object.freeze({
  [APITypes.LabelType.Generic]: find(APITypes.LabelType.Generic),
  [APITypes.LabelType.Verdict]: find(APITypes.LabelType.Verdict),
  [APITypes.LabelType.PhishKitFamily]: find(APITypes.LabelType.PhishKitFamily),
  [APITypes.LabelType.MalwareFamily]: find(APITypes.LabelType.MalwareFamily),
  [APITypes.LabelType.PhishedBrand]: find(APITypes.LabelType.PhishedBrand),
});

export const Labels = (props: {
  labels?: APITypes.Label[];
  filter?: APITypes.LabelType;
  tableMode?: boolean;
  inlineMode?: boolean;
}) => {
  if (!props.labels || props.labels?.length <= 0) return null;
  const labels = props.labels.filter(
    (v) => v.Type && v.Value && (props.filter ? v.Type === props.filter : true)
  );
  labels.sort((a, b) => {
    if (a.Type !== b.Type)
      return (SORT_PRECEDENCE[a.Type || APITypes.LabelType.Generic] ?? 0) <
        (SORT_PRECEDENCE[b.Type || APITypes.LabelType.Generic] ?? 0)
        ? -1
        : 1;
    return a.Value.toLowerCase().localeCompare(b.Value.toLowerCase());
  });

  if (props.tableMode) {
    const groups: { [key: string]: string[] } = {};
    for (const v of labels) {
      const g = groups[v.Type as string] || [];
      g.push(v.Value);
      groups[v.Type as string] = g;
    }

    const elements = [];
    for (const { Type, Translate } of SORT_AND_TRANSLATIONS) {
      if (groups[Type]?.length) {
        elements.push(
          <tr key={Type}>
            <th className="nowrap">{Translate(groups[Type]?.length)}</th>
            <td>
              <Labels labels={labels} filter={Type} />
            </td>
          </tr>
        );
      }
    }

    return <>{elements}</>;
  }

  const e = (
    <span className="tags">
      {labels?.map((l, i) => {
        return <Label key={`${i}::${l}`} Type={l.Type} Value={l.Value} />;
      })}
    </span>
  );

  if (props.inlineMode) {
    return (
      <div style={{ display: "inline-block", marginLeft: "0.25em" }}>{e}</div>
    );
  }

  return e;
};
