import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useRef, useLayoutEffect, useEffect } from "react";

import "./ExpandableBox.scss";

export const ExpandableBox = ({
  children,
  title,
  maxHeight = "none",
  contentVisible = true,
  className = "",
}: {
  children: any;
  title: string | JSX.Element;
  maxHeight?: string;
  contentVisible?: boolean;
  className?: string;
}) => {
  var [isOverflowed, setIsOverflowed] = useState(false);
  var [currentMaxHeight, setCurrentMaxHeight] = useState(maxHeight);
  var [contentIsVisible, setContentIsVisible] = useState(contentVisible);
  var contentBox = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setCurrentMaxHeight(maxHeight);
  }, [maxHeight]);

  const checkOverflow = () => {
    const elem = contentBox.current;

    const scrollHeight = elem?.scrollHeight || 0;
    const clientHeight = elem?.clientHeight || 0;

    // console.log(`${scrollHeight} > ${clientHeight}?`);

    if (scrollHeight > clientHeight) {
      setIsOverflowed(true);
    } else {
      setIsOverflowed(false);
    }
  };

  useLayoutEffect(() => {
    checkOverflow();
  });

  const makeTaller = () => {
    setCurrentMaxHeight("none");
  };

  const toggleVisible = () => {
    setContentIsVisible(!contentIsVisible);

    if (!contentIsVisible) {
      setCurrentMaxHeight(maxHeight); // reset expansion state when it goes fully collapsed
    }
  };

  let hasMeaningfulChildren = false;
  if (Array.isArray(children)) {
    hasMeaningfulChildren = children.some((e) => e);
  } else {
    hasMeaningfulChildren =
      children !== null && children !== undefined && children !== false;
  }

  // watch child DOM for modifications so we can recalculate overflow
  useEffect(() => {
    if (
      hasMeaningfulChildren &&
      contentIsVisible &&
      currentMaxHeight !== "none"
    ) {
      const observer = new MutationObserver(() => {
        checkOverflow();
      });

      if (contentBox.current) {
        observer.observe(contentBox.current, {
          subtree: true,
          childList: true,
        });
      }

      return () => {
        observer.disconnect();
      };
    }
  }, [hasMeaningfulChildren, contentIsVisible, currentMaxHeight]);

  if (className) {
    className = `expandable-box ${className}`;
  } else {
    className = "expandable-box";
  }

  return (
    <div
      className={className}
      onClick={(evt) => {
        checkOverflow();
      }}
    >
      <div className="box-title" onClick={toggleVisible}>
        <span>{title}</span>
        <span className="visibility-icon">
          {contentIsVisible ? (
            <FontAwesomeIcon icon={["far", "angle-up"]} />
          ) : (
            <FontAwesomeIcon icon={["far", "angle-down"]} />
          )}
        </span>
      </div>
      {hasMeaningfulChildren && contentIsVisible ? (
        <div
          className="box-content"
          style={{ maxHeight: currentMaxHeight }}
          ref={contentBox}
        >
          {children}
        </div>
      ) : null}
      {isOverflowed ? (
        <div className="box-expand" onClick={makeTaller}>
          <span className="has-text-link">
            <FontAwesomeIcon icon={["far", "angle-double-down"]} /> Expand{" "}
            <FontAwesomeIcon icon={["far", "angle-double-down"]} />
          </span>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
