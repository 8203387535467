import React from "react";
import { EngineCountCell } from "../NormalizedForensics";

export const ProcessList = (props) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>Engines</th>
          <th>Process</th>
        </tr>
      </thead>
      <tbody>
        {props.processes.map((p, idx) => (
          <tr key={idx}>
            <EngineCountCell value={p} />
            <td className="forensic-value">
              <div>{p.Name}</div>
              <div className="is-size-7 has-text-grey">
                Path: {p.Path || "-"}
              </div>
              <div className="is-size-7 has-text-grey">
                Arguments: {p.Arguments || "-"}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
