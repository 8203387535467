import React from "react";

export type SCORE_PLACEHOLDER = "-" | "NA" | "TBD";

export interface ScoreProps {
  score: number | SCORE_PLACEHOLDER;
}

export const Score = (props: ScoreProps) => {
  let score;
  let isString = false;
  if (typeof props.score === "string") {
    score = props.score;
    isString = true;
  } else {
    score = Math.round(props.score * 100);
  }

  let textClass = null;

  if (score >= 70) {
    textClass = "is-danger";
  } else if (score >= 30) {
    textClass = "is-warning";
  } else if (isString) {
    textClass = "is-light";
  } else {
    textClass = "is-success";
  }

  return (
    <span className={"tag " + textClass} style={{ minWidth: "2.5rem" }}>
      {score}
    </span>
  );
};
