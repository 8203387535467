import { headerLineDecode } from "emailjs-mime-codec";

/**
 * Single email header split into name/value pairs
 */
export class EmailHeader {
  /** Header name */
  readonly Name: string;
  /** Header value */
  readonly Value: string;
  /**
   * Integer index to record header appearance order
   *
   * This is used in case we sort the array later, we can then recover
   * the original ordering.
   *
   * Uniqueness has to be enforced by the caller.
   */
  readonly Index: number;

  constructor(name: string, value: string, index?: number) {
    this.Name = name;
    this.Value = value;
    this.Index = index ?? 0;
  }

  static fromString(line: string, index: number): EmailHeader {
    const singleHeader = headerLineDecode(line);
    return new EmailHeader(singleHeader.key, singleHeader.value, index);
  }
}

export const parseHeaders = (rawHeaders: string): EmailHeader[] => {
  if (
    rawHeaders === undefined ||
    rawHeaders === null ||
    rawHeaders.length === 0
  ) {
    return [];
  }

  // The headers we get from eml-analyzer are wrapped with non-consistent whitespace
  // due to how python unfolds headers for you.
  let parsedHeaders = []; // TODO What if multiple sets (which is odd)
  if (rawHeaders.length > 0) {
    // We can't use headerLinesDecode as it destroys header ordering
    let current = "";
    let i = 0;
    for (let line of rawHeaders.split(/\r?\n|\r/)) {
      line = line
        .trim()
        .replaceAll(/\t+/g, " ")
        .replaceAll(/ {2,8}/g, " ");

      if (line.match(/^\s/)) {
        // continuation
        current += " " + line;
      } else {
        if (current.length > 0) {
          parsedHeaders.push(EmailHeader.fromString(current, i));
          i++;
        }
        current = line;
      }
    }
    if (current.length > 0) {
      parsedHeaders.push(EmailHeader.fromString(current, i));
    }
  }

  return parsedHeaders;
};
