import * as Menu from "../components/Menu";
import { Job } from "./APITypes";

const TWO_HOURS_IN_MS =
  2 /* hours */ * 60 /* minutes */ * 60 /* seconds */ * 1000; /* milliseconds */

export const toSplunkLogLink = (job: Job) => {
  const t = Date.parse(job.StartedAt || job.CreatedAt);
  const begin = Math.floor((t - TWO_HOURS_IN_MS) / 1000);
  const end = Math.ceil((t + TWO_HOURS_IN_MS) / 1000);

  const params = new URLSearchParams({
    q: `index=twinwave | search "record.extra.job_id"="${job.ID}" OR "job_id"="${job.ID}"`,
    earliest: begin.toString(), // unix ticks
    latest: end.toString(),
  });

  return `https://skynet-search.splunkcloud.com/en-US/app/search/search?${params}`;
};

export const SplunkLogs = ({ job }: { job: Job }) => {
  return (
    <Menu.ItemExternalLink
      href={toSplunkLogLink(job)}
      text={"Logs (VPN Required)"}
      permission="app:admin"
      icon={["far", "scroll"]}
    />
  );
};
