import React from "react";

export const LoadingMessage = () => {
  return <div className="has-text-info has-margin-top-20">Loading…</div>;
};

export const LoadingWrap = (props: {
  children: React.ReactNode;
  isLoading?: boolean;
}) => {
  if (props.isLoading) {
    return <LoadingMessage />;
  }

  return <>{props.children}</>;
};
