import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import * as Modal from "src/components/Modal";
import { useWatchForCuckooSessionURLHook } from "src/lib/API";

export const PATH = "/interactivesandbox";
export const MENU_NAME = "Interactive Sandbox";

export const MenuItem = () => <></>;
export const _MenuItem = () => (
  <NavLink
    className={(isActive) => "navbar-item" + (isActive ? " is-active" : "")}
    to={PATH}
  >
    <span className="icon has-text-link">
      <FontAwesomeIcon icon={["far", "browser"]} />
    </span>
    <span className="is-size-5">{MENU_NAME}</span>
  </NavLink>
);

export const NewSession = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { JobID, sandboxTimeout } = location.state || {};
  const { sessionInfo, isDone } = useWatchForCuckooSessionURLHook(JobID);
  const [isEnded, setIsEnded] = useState(false);

  useEffect(() => {
    if (!JobID) navigate("/");
  }, [JobID, navigate]);

  useEffect(() => {
    if (isDone) navigate(`/job/${JobID}`, { state: { JobID } });
  }, [isDone, navigate, JobID]);

  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    var timeout = 3 * 60;
    if (sandboxTimeout) {
      let timeoutInt = parseInt(sandboxTimeout);
      if (!isNaN(timeoutInt)) {
        timeout = timeoutInt * 60;
      }
    }

    const endsAt = new Date().getTime() / 1000 + timeout;

    const interval = setInterval(() => {
      setTimeLeft(endsAt - new Date().getTime() / 1000);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [sandboxTimeout, sessionInfo]);

  useEffect(() => {
    if (timeLeft < 0) {
      setIsEnded(true);
    }
  }, [timeLeft]);

  if (!JobID)
    return (
      <div className="section">
        <div className="container">
          <h1 className="title is-4 has-text-centered">{MENU_NAME}</h1>
        </div>
      </div>
    );

  if (!sessionInfo) return <Loader />;

  if (!sessionInfo.startsWith("http")) return <></>;

  return (
    <Modal.Modal isActive={true} close={() => {}} fullscreen>
      <div
        style={{
          minHeight: "3.5rem",
          alignItems: "center",
          width: "100%",
          display: "flex",
          paddingLeft: "0.75rem",
          paddingRight: "0.75rem",
          justifyContent: "space-between",
          backgroundColor: "#293639",
        }}
      >
        <img
          style={{ maxHeight: "4rem", verticalAlign: "center" }}
          src="/saa_logo.svg"
          alt="Splunk Attack Analyzer"
        />
        <div className="is-size-5">
          Interactive Sandbox Session{" "}
          {sessionInfo && <span>({formatTimeLeft(timeLeft)} remaining)</span>}
        </div>
        <button
          className="button is-danger"
          onClick={() => {
            setIsEnded(true);
          }}
        >
          End Session
        </button>
      </div>
      {isEnded ? (
        <Modal.Modal
          isActive={true}
          close={() => navigate(`/job/${JobID}`, { state: { JobID } })}
          fullscreen={false}
        >
          <Modal.Card title="Interactive Sandbox Session Ended">
            <Modal.CardBody>
              <p>
                Your interactive session has ended and you will be returned to
                the job page. It may take several minutes for the sandbox
                process to complete processing.
              </p>
            </Modal.CardBody>
            <Modal.CardFooter>
              <Modal.CardCloseButton text="OK" />
            </Modal.CardFooter>
          </Modal.Card>
        </Modal.Modal>
      ) : (
        <>
          <iframe
            sandbox="allow-scripts allow-same-origin"
            title="Interactive Sandbox Session"
            src={sessionInfo}
            style={{ width: "100%", height: "90%" }}
          />
        </>
      )}
    </Modal.Modal>
  );
};

const Loader = () => {
  return (
    <div className="surfer-loader">
      <div className="surfer-spinner">
        <div className="surfer-dot1"></div>
        <div className="surfer-dot2"></div>
      </div>
      <div>Connecting you to an interactive sandbox session...</div>
      <div>
        Please be patient, as it may take several minutes for a sandbox to be
        provisioned and activated
      </div>
    </div>
  );
};

const formatTimeLeft = (t: number) => {
  let m = Math.floor(t / 60);
  let s = Math.floor(t % 60);

  if (m >= 1) {
    return (
      <>
        {m}m{s}s
      </>
    );
  } else if (s < 10) {
    return <span className="has-text-danger">{s}s</span>;
  } else {
    return <>{s}s</>;
  }
};
