import { TaskProperties } from "./TaskTypes";
import { ResourceSummary } from "../ResourceSummary";
import { LoadingMessage } from "./LoadingMessage";
import { DetectionBox } from "../forensics/Detections";
import { taskDisplayName } from "../utils/DisplayNames";
import { CAPASummary } from "../CAPA";
import { humanize } from "src/lib/Utils";

export const IntezerResults = ({
  job,
  resource,
  task,
  forensics,
}: TaskProperties) => {
  if (forensics === "loading") {
    return (
      <section>
        <ResourceSummary job={job} task={task} resource={resource} />
        <LoadingMessage />
      </section>
    );
  }

  const detections = forensics?.Detections || [];
  return (
    <section>
      <ResourceSummary job={job} task={task} resource={resource} />
      <div className="columns">
        <div className="column">
          <table className="table is-narrow is-completely-borderless">
            <tbody>
              <tr>
                <th>Verdict</th>
                <td>
                  {humanize(
                    forensics?.Details.Intezer.Verdict ||
                      forensics?.Verdict ||
                      ""
                  )}
                </td>
              </tr>
              <tr>
                <th>Sub Verdict</th>
                <td>{humanize(forensics?.Details.Intezer.SubVerdict)}</td>
              </tr>
              <tr>
                <th>Family Name</th>
                <td>{forensics?.Details.Intezer.Family || "-"}</td>
              </tr>
              <tr>
                <th>Permalink</th>
                <td>
                  <a
                    href={forensics?.Details.Intezer.URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {taskDisplayName("intezer")} UI
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <DetectionBox detections={detections} />

      <CAPASummary
        Capabilities={forensics?.Details.Intezer.CAPA || []}
        Attacks={forensics?.MitreAttacks || []}
      />
    </section>
  );
};
