import React from "react";
import { Score } from "../Score";

export const CuckooResults = (props) => {
  const task = props.task;

  return (
    <div>
      <ul>
        <li>
          <b>Score:</b> <Score score={task.Results.Score} />
        </li>
        <li>
          <b>Guest Name:</b> {task.Results.Details.GuestName}
        </li>
        <li>
          <b>Completed:</b> {task.Results.Details.Completed}
        </li>
        <li>
          <b>Duration:</b> {task.Results.Details.Duration} seconds
        </li>
      </ul>
    </div>
  );
};
