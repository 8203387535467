import React from "react";
import { Link } from "react-router-dom";
import { UserHasPermission } from "src/lib/Auth";

export const ProfileLink = ({
  profile: { name },
  onLogout,
  onChangePassword,
}) => {
  return (
    <div className="navbar-item has-dropdown is-hoverable is-right">
      <div className="navbar-link">{name}</div>
      <div className="navbar-dropdown">
        <UserHasPermission permission="app:admin">
          <div className="navbar-item">
            <Link to="/devops">DevOps</Link>
          </div>
          <div className="navbar-item">
            <Link to="/apikeys">API Keys</Link>
          </div>
          <div className="navbar-item">
            <Link to="/admin/emlgwy">Email Submission (Admin)</Link>
          </div>
          <div className="navbar-item">
            <Link to="/repproxy">Rep Proxy</Link>
          </div>
          <hr className="navbar-divider"></hr>
        </UserHasPermission>
        <UserHasPermission permission="app:admin">
          <div className="navbar-item">
            <Link to="/users">User Management</Link>
          </div>
          <hr className="navbar-divider"></hr>
        </UserHasPermission>
        <div className="navbar-item">
          {/* eslint-disable-next-line */}
          <a href="/knowledgecenter">Knowledge Center</a>
        </div>
        <div className="navbar-item">
          {/* eslint-disable-next-line */}
          <a href="#" onClick={onChangePassword}>
            Change Password
          </a>
        </div>
        <div className="navbar-item">
          {/* eslint-disable-next-line */}
          <a href="#" onClick={onLogout}>
            Log Out
          </a>
        </div>
      </div>
    </div>
  );
};
