import React, { useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToggleSwitch } from "./utils/ToggleSwitch";
import { toast } from "react-toastify";
import { Job } from "src/lib/APITypes";
import * as Menu from "./Menu";
import * as Modal from "./Modal";
import { useShareHook } from "src/lib/API";

export const ShareJob = ({
  job,
  reloadJob,
}: {
  job: Job;
  reloadJob: VoidFunction;
}) => {
  const { isShared, toggleShare, isLoading, hasError, updatedAt } =
    useShareHook(job.ID, !!job.Sharing.ShareToken);

  // Trigger upstream reload on state changes. Note: You can't bind this to
  // isShared since that gets updated opportunistically before the actual server
  // will have the update.
  useEffect(() => {
    // If updatedAt is null, we haven't toggled yet so don't trigger reload
    if (updatedAt !== null) {
      reloadJob();
    }
  }, [updatedAt, reloadJob]);

  useEffect(() => {
    if (hasError) {
      toast.error("Failed to update share state", {
        autoClose: false,
      });
    }
  }, [hasError]);

  return (
    <Menu.Item
      text={`${isShared ? "Disable" : "Enable"} Sharing`}
      permission="job:share"
      isDisabled={
        job.State === "done"
          ? ""
          : "In progress jobs cannot be shared. Please wait for the job to finish before sharing."
      }
      icon={["far", "share-square"]}
    >
      <Modal.Card title="Analysis Sharing">
        <Modal.CardBody>
          <div className="has-margin-bottom-20">
            <ToggleSwitch
              name="shared"
              checked={isShared}
              label="Sharing"
              disabled={isLoading}
              onChange={() => toggleShare()}
            />
          </div>
          <div>{isShared ? <ShareLink job={job} /> : <ShareExplainer />}</div>
        </Modal.CardBody>
        <Modal.CardFooter>
          <Modal.CardCloseButton text="Close" />
        </Modal.CardFooter>
      </Modal.Card>
    </Menu.Item>
  );
};

const ShareLink = ({ job }: { job: Job }) => {
  const urlField = useRef<HTMLTextAreaElement | null>(null);

  return (
    <>
      <p>
        This analysis was shared by {job.Sharing.SharedBy} at{" "}
        {new Date(Date.parse(job.Sharing.SharedAt)).toLocaleString()}, and can
        be viewed by anyone at this URL:
      </p>
      <textarea
        className="textarea has-fixed-size"
        rows={1}
        ref={urlField}
        value={
          job.Sharing.ShareToken
            ? `${document.location.protocol}//${document.location.host}/shared/${job.ID}/${job.Sharing.ShareToken}`
            : "Loading..."
        }
        readOnly={true}
        wrap="off"
        style={{ height: "4rem" }}
      />
      <button
        className="button is-text has-margin-top-5"
        onClick={(evt) => {
          urlField.current?.select();
          document.execCommand("copy");
          toast.success("URL copied to clipboard");
        }}
      >
        <FontAwesomeIcon icon={["far", "copy"]} />
        &nbsp;Copy URL
      </button>
    </>
  );
};
const ShareExplainer = () => (
  <p>
    Enable sharing to generate a link that can be used to share this analysis
    with anyone
  </p>
);
