import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../css/Copyable.css";

/**
 * Copy given text to the clipboard
 *
 * It will try to use the modern `navigator.clipboard` API if available, else will fallback
 * to the legacy `document.execCommand("copy")` method.  Note that the legacy method is
 * not performant and can freeze the browser or flat out not work on larger (100s of Kbytes)
 * strings.
 *
 * Direct usage in JSX components:
 *
 * ```
 * <button onClick={(e) => { e.preventDefault(); copySource("foo")} title="Copy to clipboard" />
 * ```
 *
 * @param s String to copy to clipboard
 */
export const copySource = async (s: string) => {
  if (navigator.clipboard) {
    await copySourceModernText(s);
  } else {
    await copySourceLegacy(s);
  }
};

const copySourceModernText = async (s: string) => {
  await navigator.clipboard.writeText(s);
};

const copySourceLegacy = async (s: string) => {
  var dummy = document.createElement("textarea");
  dummy.value = s;
  document.body.appendChild(dummy);
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
};

/**
 * Copy a blob using the blobs' mimetype or manually specified type if none is present
 * @param content Blob to copy to clipboard
 * @param mimeType Fallback mimetype when `Blob.type` is undefined.
 */
export const copyBlob = async (content: Blob, mimeType?: string) => {
  const contentType = mimeType ?? content.type;
  const item = new ClipboardItem({ [contentType]: content });
  await navigator.clipboard.write([item]);
};

/**
 * Copy content using the given mimetype or manually specified type if none is present
 * @param content Blob to copy to clipboard
 * @param mimeType Fallback mimetype when `Blob.type` is undefined.
 */
export const copyAsMimeType = async (content: string, mimeType: string) => {
  const item = new ClipboardItem({ [mimeType]: content });
  await navigator.clipboard.write([item]);
};

/**
 * Helper component to add a 'on hover' copy button next to the child elements.
 *
 * @param data string to copy
 * @param children Child components to wrap and place the copy button next to.
 * @returns
 */
export const Copyable = ({
  data,
  children,
}: {
  data: string;
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <>
      <div className="copyable-data">
        <div className="data">{children}</div>
        <div className="copy-button">
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              copySource(data);
            }}
            title="Copy to clipboard"
          >
            <FontAwesomeIcon icon={["far", "copy"]} />
          </button>
        </div>
      </div>
    </>
  );
};

export const InlineCopyable = ({
  data,
  children,
}: {
  data: string;
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <>
      <div className="copyable-data-inline">
        <div className="data">{children}</div>
        <div className="copy-button">
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              copySource(data);
            }}
            title="Copy to clipboard"
          >
            <FontAwesomeIcon icon={["far", "copy"]} />
          </button>
        </div>
      </div>
    </>
  );
};

/**
 * Legacy helper merhod to add a 'copy' button next to the child elements.
 *
 * This version uses a DOM based copyable when you need to copy the browser based HTML formatted flavor of a JSX element
 *
 * This should only be used for smaller segments as it uses the legacy DOM based clipboard which is both
 * deprecated and not performant.
 *
 * @param data string to copy
 * @param children Child components to wrap and place the copy button next to.
 * @returns
 */
export const LegacyJSXCopyable = ({
  data,
  children,
}: {
  data: JSX.Element;
  children: React.ReactNode;
}): JSX.Element => {
  //
  const copyAreaRef = React.createRef<HTMLDivElement>();

  const handleClick = (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    evt.preventDefault();
    evt.stopPropagation();
    window.getSelection()?.removeAllRanges();
    const range = document.createRange();
    range.selectNode(copyAreaRef.current!);
    window.getSelection()?.addRange(range);
    document.execCommand("copy");
    window.getSelection()?.removeAllRanges();
  };

  return (
    <>
      <div className="copyable-data">
        {/* display:none does not work; must be displayed to be copyable, so make it really small */}
        <div
          style={{
            width: "1px",
            height: "1px",
            opacity: 0,
            display: "inline-block",
            overflow: "hidden",
          }}
          ref={copyAreaRef}
        >
          {data}
        </div>
        <div className="data">{children}</div>
        <div className="copy-button">
          <button onClick={handleClick} title="Copy to clipboard">
            <FontAwesomeIcon icon={["far", "copy"]} />
          </button>
        </div>
      </div>
    </>
  );
};
