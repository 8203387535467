import { MitreLink } from "./NormalizedForensics";
import { ExpandableBox } from "./utils/ExpandableBox";

interface CAPACapability {
  Count: number;
  Name: string;
  Namespace: string;
}

const CapaCapabilitiesTable = ({
  capabilities,
}: {
  capabilities: CAPACapability[];
}) => {
  return (
    <table className="table is-narrow">
      <thead>
        <tr>
          <th>Name</th>
          <th>Namespace</th>
          <th className="has-text-centered">Matches</th>
        </tr>
      </thead>
      <tbody>
        {capabilities.map(({ Count, Name, Namespace }) => (
          <tr key={`${Count}::${Name}::${Namespace}`}>
            <td>{Name}</td>
            <td>{Namespace}</td>
            <td className="has-text-centered">{Count}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

interface CAPAAttack {
  ID: string;
  SubTechnique: string;
  Tactic: string;
  Technique: string;
  URL?: string;
}

const CapaAttacksTable = ({ attacks }: { attacks: CAPAAttack[] }) => {
  return (
    <table className="table is-narrow">
      <thead>
        <tr>
          <th>Tactic</th>
          <th>Technique</th>
          <th>Sub Technique</th>
          <th>ID</th>
        </tr>
      </thead>
      <tbody>
        {attacks.map(({ ID, SubTechnique, Tactic, Technique, URL }) => (
          <tr key={`${ID}::${SubTechnique}::${Tactic}::${Technique}::${URL}`}>
            <td>{Tactic}</td>
            <td>{Technique}</td>
            <td>{SubTechnique || "-"}</td>
            <td>
              {URL ? (
                <a href={URL} target="_blank" rel="noopener noreferrer">
                  {ID}
                </a>
              ) : (
                <MitreLink id={ID} />
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export const CAPASummary = ({
  Attacks,
  Capabilities,
}: {
  Attacks: CAPAAttack[];
  Capabilities: CAPACapability[];
}) => {
  return (
    <ExpandableBox title="CAPA Analysis" maxHeight={"9rem"}>
      <div>
        <p className="is-size-6 has-text-weight-bold	">ATT&CK Info</p>
        {Attacks.length < 1 ? (
          <p>No ATT&CK info.</p>
        ) : (
          <CapaAttacksTable attacks={Attacks}></CapaAttacksTable>
        )}
      </div>
      <div className="has-padding-top-20">
        <p className="is-size-6 has-text-weight-bold	">Capabilities</p>
        {Capabilities.length < 1 ? (
          <p>No capabilities.</p>
        ) : (
          <CapaCapabilitiesTable
            capabilities={Capabilities}
          ></CapaCapabilitiesTable>
        )}
      </div>
    </ExpandableBox>
  );
};
