import { Label, LabelType } from "src/lib/APITypes";

export function taskDisplayName(engine?: string, defaultDisplayName?: string) {
  let e = engine ? engine.toLowerCase() : engine;
  if (typeof defaultDisplayName === "undefined") {
    defaultDisplayName = engine;
  }
  switch (e) {
    case "clamav":
      return "ClamAV";
    case "cuckoo_vanilla":
      return "Cuckoo (vanilla)";
    case "hybridanalysis":
      return "Hybrid Analysis";
    case "falcon":
      return "Falcon";
    case "statictools":
    case "static_file":
      return "Static File Analysis";
    case "tika":
    case "static_doc":
      return "Static Doc Analysis";
    case "virustotal":
      return "VirusTotal";
    case "vmray":
      return "VMRay";
    case "wildfire":
      return "WildFire";
    case "cape":
    case "cuckoo_cape":
      return "Cuckoo (CAPE)";
    case "consolidated":
      return "Consolidated";
    case "gsb":
    case "url_reputation":
      return "URL Reputation";
    case "staticurl":
    case "static_web":
      return "Static Web Analysis";
    case "twcuckoo":
    case "twinwave_cuckoo":
    case "sandbox_win7":
      return "Windows 7 Sandbox";
    case "archive_extraction":
    case "unarchiver":
      return "Archive Extraction";
    case "eml_analyzer":
      return "Email Analyzer";
    case "interactive_web_analyzer":
    case "web_analyzer":
      return "Web Analyzer";
    case "falconx":
      return "FalconX";
    case "fireeye":
      return "FireEye";
    case "yara":
      return "YARA";
    case "intezer":
      return "Intezer";
    case "capev2":
      return "Sandbox V2 [BETA]";
    case "capev2win10":
    case "twinwave_cuckoo_win10":
    case "sandbox_win10":
      return "Windows 10 Sandbox";
    case "hatching_triage":
      return "Hatching Triage";
    case "interactive_win7":
      return "Interactive Sandbox (Win7)";
    case "interactive_win10":
      return "Interactive Sandbox (Win10)";
    default:
      return defaultDisplayName;
  }
}

// From https://cuckoo.readthedocs.io/en/latest/usage/packages/
export function packageDisplayName(pkg?: string) {
  let p = pkg ? pkg.toLowerCase() : pkg;
  switch (p) {
    case "applet":
      return "Java Applet";
    case "extraction_applet":
      return "Extraction Java Applet";
    case "bin":
      return "Generic Binary";
    case "extraction_bin":
      return "Extraction Generic Binary";
    case "cpl":
      return "Control Panel Applet";
    case "extraction_cpl":
      return "Extraction Control Panel Applet";
    case "dll":
      return "Dynamically Linked Library";
    case "extraction_dll":
      return "Extraction Dynamically Linked Library";
    case "doc":
      return "Microsoft Word Document";
    case "extraction_doc":
      return "Extraction Microsoft Word Document";
    case "exe":
      return "Generic Windows Executable";
    case "extraction_exe":
      return "Extraction Generic Windows Executable";
    case "resource_extraction":
      return "Resource Extraction";
    case "extraction_resource_extraction":
      return "Extraction Resource Extraction";
    case "generic":
      return "Generic";
    case "extraction_generic":
      return "Extraction Generic";
    case "ie":
      return "Internet Explorer";
    case "extraction_ie":
      return "Extraction Internet Explorer";
    case "jar":
      return "Java JAR";
    case "extraction_jar":
      return "Extraction Java JAR";
    case "js":
      return "JavaScript";
    case "extraction_js":
      return "Extraction JavaScript";
    case "hta":
      return "HTML Application";
    case "extraction_hta":
      return "Extraction HTML Application";
    case "msi":
      return "MSI Windows Installer";
    case "extraction_msi":
      return "Extraction MSI Windows Installer";
    case "pdf":
      return "PDF Document";
    case "extraction_pdf":
      return "Extraction PDF Document";
    case "ppt":
      return "Microsoft PowerPoint";
    case "extraction_ppt":
      return "Extraction Microsoft PowerPoint";
    case "ps1":
      return "PowerShell Script";
    case "extraction_ps1":
      return "Extraction PowerShell Script";
    case "python":
      return "Python Script";
    case "extraction_python":
      return "Extraction Python Script";
    case "vbs":
      return "Visual Basic Script";
    case "extraction_vbs":
      return "Extraction Visual Basic Script";
    case "wsf":
      return "Windows Script Host File";
    case "extraction_wsf":
      return "Extraction Windows Script Host File";
    case "xls":
      return "Microsoft Excel File";
    case "extraction_xls":
      return "Extraction Microsoft Excel File";
    case "zip":
      return "Zip Archive";
    case "extraction_zip":
      return "Extraction Zip Archive";
    case "vmray_static":
    case "static":
      return "Static Analysis";
    case "extraction_vmray_static":
    case "extraction_static":
      return "Extraction Static Analysis";
    case "reputation":
      return "Reputation Check";
    case "wildfire_1":
      return "Windows XP (Pkg 1)";
    case "wildfire_2":
      return "Windows XP (Pkg 2)";
    case "wildfire_3":
      return "Windows XP (Pkg 3)";
    case "wildfire_4":
      return "Windows 7 (Pkg 4)";
    case "wildfire_5":
      return "Windows 7 (Pkg 5)";
    case "wildfire_100":
      return "PDF Static Analyzer";
    case "wildfire_101":
      return "DOC/CDF Static Analyzer";
    case "wildfire_102":
      return "Java/Jar Static Analyzer";
    case "wildfire_103":
      return "Office 2007 Open XML Static Analyzer";
    case "wildfire_104":
      return "Adobe Flash Static Analyzer";
    case "wildfire_204":
      return "PE Static Analyzer";
    case "wildfire_6":
      return "Windows XP (Pkg 6)";
    case "wildfire_20":
      return "Windows XP (Pkg 20)";
    case "wildfire_21":
      return "Windows 7 (Pkg 21)";
    case "wildfire_50":
      return "Mac OSX Mountain Lion";
    case "wildfire_60":
      return "Windows XP (Pkg 60)";
    case "wildfire_61":
      return "Windows 7 (Pkg 61)";
    case "wildfire_66":
      return "Windows 10 (Pkg 66)";
    case "wildfire_105":
      return "RTF Static Analyzer";
    case "wildfire_110":
      return "Max OSX Static Analyzer";
    case "wildfire_200":
      return "APK Static Analyzer";
    case "wildfire_201":
      return "Android 2.3 (Pkg 201)";
    case "wildfire_202":
      return "Android 4.1 (Pkg 202)";
    case "wildfire_203":
      return "Android 4.1 (Pkg 203)";
    case "wildfire_205":
      return "Phishing Static Analyzer";
    case "wildfire_206":
      return "Android 4.3 (Pkg 206)";
    case "wildfire_300":
      return "Windows XP (Pkg 300)";
    case "wildfire_301":
      return "Windows 7 (Pkg 301)";
    case "wildfire_302":
      return "Windows 7 (Pkg 302)";
    case "wildfire_303":
      return "Windows 7 (Pkg 303)";
    case "wildfire_400":
      return "Linux (ELF files)";
    case "wildfire_800":
      return "Archives (RAR and 7-Zip files)";
    default:
      return pkg;
  }
}

/**
 * Generates a list of display labels for selected subset of engines.
 * @param engines list of internal engine names to consider turning into labels
 * @returns List of labels for engines we want to show as labels.
 */
export function enginesToLabels(engines: string[]): Label[] {
  let labels: Label[] = [];

  if (engines.some((e) => e.startsWith("interactive_web"))) {
    labels.push({ Type: LabelType.Generic, Value: "Interactive Web" });
  } else if (engines.some((e) => e.startsWith("interactive_win"))) {
    labels.push({ Type: LabelType.Generic, Value: "Interactive Sandbox" });
  }

  return labels;
}
