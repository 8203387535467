import Tooltip from "@cypress/react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { CaveatCard } from "../Caveats";
import { FileSize } from "../FileSize";
import { ResourceSummary } from "../ResourceSummary";
import { Expandable } from "../utils/Expandable";
import { ExpandableBox } from "../utils/ExpandableBox";
import { ArchiveExtractionResultsV0 } from "./ArchiveExtractionResultsV0";
import { ThreatName } from "../utils/ThreatName";
import { taskDisplayName } from "../utils/DisplayNames";

export const ArchiveExtractionResults = (props) => {
  if (!props || !props.task || !props.task.Results) {
    return (
      <div className="has-text-centered has-text-weight-bold has-text-info">
        Loading…
      </div>
    );
  }

  // Legacy format should get routed to the old UI component
  if (!props.task.Results.Details || !props.task.Results.Details.Version) {
    return <ArchiveExtractionResultsV0 {...props} />;
  }

  const meta = props.task.Results.Details.MetaData;
  const { MetaCaveats, Children } = meta;

  const Caveats = MetaCaveats || props.task.Results.Details.Caveats;

  return (
    <section>
      <ResourceSummary
        job={props.job}
        task={props.task}
        resource={props.resource}
      />
      <ExtractionCaveats caveats={Caveats} />
      {meta.Password && (
        <div>
          <b>Archive Password:</b> {passwordFormatter(meta.Password)}
        </div>
      )}
      {Children.length > 0 ? (
        <ExpandableBox title={`Extracted Files (${Children.length})`}>
          <ul>
            {Children.map((c, idx) => (
              <li key={c.SHA256 + c.Filename}>
                {idx !== 0 && <hr />}
                <FileCard {...c} />
              </li>
            ))}
          </ul>
        </ExpandableBox>
      ) : (
        <p>No files extracted</p>
      )}
    </section>
  );
};

const ExtractionCaveats = ({ caveats }) => {
  if (!caveats || caveats.length < 1) {
    return null;
  }

  const rest = [];
  let customWarnings = null;
  for (let c of caveats) {
    if (c.code !== 3) {
      // 3 = Failed to decrypt code
      rest.push(c);
      continue;
    }

    customWarnings = (
      <>
        <Expandable
          title={
            <>
              Failed to decrypt ({escaped(c.resource_name)}){" "}
              {c.metadata.ListedFiles && c.metadata.ListedFiles.length > 0
                ? `but listed ${c.metadata.ListedFiles.length} ${pluralize(
                    "file",
                    c.metadata.ListedFiles.length
                  )}`
                : null}
            </>
          }
          reportAs={"Failed to decrypt"}
        >
          <p>
            <b>Attempted Passwords:</b>
          </p>
          <ul>
            {c.metadata.AttemptedPasswords.map((p) => (
              <li key={`${p.Source}::${p.Value}`}>{passwordFormatter(p)}</li>
            ))}
          </ul>

          {c.metadata.ListedFiles && c.metadata.ListedFiles.length > 0 ? (
            <>
              <p>
                <b>Files in Archive</b> {listedFileInfo}
              </p>
              <ul>
                {c.metadata.ListedFiles.map(({ full_path, size }, i) => (
                  <li key={`${full_path}-${i}`}>
                    {escaped(full_path)}{" "}
                    {size !== undefined ? (
                      <span>
                        (File Size: <FileSize size={size} />)
                      </span>
                    ) : null}
                  </li>
                ))}
              </ul>
            </>
          ) : null}
        </Expandable>
      </>
    );
  }

  return (
    <CaveatCard
      caveats={rest}
      engine="archive_extraction"
      children={customWarnings}
    />
  );
};

const listedFileInfo = (
  <Tooltip title="Some archive formats support listing metadata about their contents before the archive is decrypted. While the encrypted archive containing the below files could not be decrypted, the following metadata was obtained.">
    <span className="icon has-text-info">
      <FontAwesomeIcon icon={["far", "info-circle"]} />
    </span>
  </Tooltip>
);

const fileMetadata = (
  {
    filename,
    Filename,
    full_path,
    MagicHuman,
    Password,
    SHA256,
    size,
    Size,
    compressed_size,
  },
  includeTarget = true
) => (
  <ul>
    {includeTarget ? escaptedItem("Target", Filename || filename) : null}
    {litem("File Type", MagicHuman)}
    {litem("Full Path", full_path)}
    {litem("SHA256 Hash", SHA256)}
    {sizeItem("File Size", Size || size)}
    {sizeItem("Compressed File Size", compressed_size)}
    {litem("Password", Password, passwordFormatter)}
  </ul>
);

const FileCard = (c) => (
  <div>
    <div className="has-margin-bottom-5 has-text-weight-bold">
      <ThreatName name={c.Filename || c.filename} />
    </div>
    <div className="has-margin-left-20">
      <ul>{fileMetadata(c, false)}</ul>
    </div>
  </div>
);

// Formatting utilities
const identity = (i) => i;
const litem = (name, entry, formatter = identity) =>
  entry ? (
    <li>
      <b>{name}:</b> {formatter(entry)}
    </li>
  ) : null;
const sizeItem = (name, size, formatter = identity) =>
  litem(name, size, (s) => formatter(<FileSize size={s} />));

const escaped = (t, formatter = identity) => formatter(<ThreatName name={t} />);

const escaptedItem = (name, entry, formatter = identity) =>
  litem(name, entry, (e) => formatter(escaped(e)));

const pluralize = (text, c, customPluralization) =>
  c === 1 ? text : customPluralization || `${text}s`;

const passwordFormatter = ({ Value, Source }, formatter = identity) => {
  let source = "-";
  switch (Source) {
    case "brute_force":
      source = "Brute Force List";
      break;
    case "user_supplied":
      source = "User Supplied";
      break;
    default:
      // Might be an engine name or something random
      source = taskDisplayName(Source, "-");
      break;
  }

  return formatter(
    <>
      {escaped(Value)} (Source: {source})
    </>
  );
};
