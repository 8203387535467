import React from "react";

const PADDING = 8;

export const GenericDataDisplay = ({ data }: { data: any }) => {
  const Any = ({
    value,
    depth,
    newLine = false,
  }: {
    value: any;
    depth: number;
    newLine?: boolean;
  }) => {
    const v = value;
    if (["number", "string"].includes(typeof v)) {
      if (newLine) {
        return <span style={{ paddingLeft: depth * PADDING }}>{v}</span>;
      } else {
        return <span>{v}</span>;
      }
    } else if (Array.isArray(v)) {
      return <Arr value={v} depth={depth} />;
    } else if (v && typeof v === "object") {
      return <Obj value={v} depth={depth} />;
    } else {
      return null;
    }
  };

  const isComplex = (e: any) => {
    if (["number", "string"].includes(typeof e)) {
      return false;
    } else if (Array.isArray(e)) {
      return e.length > 1;
    } else if (typeof e === "object") {
      return Object.keys(e).length > 1;
    } else {
      return false;
    }
  };

  const Arr = ({ value, depth }: { value: any[]; depth: number }) => {
    let idx = 0;
    const v = value;

    if (v.length === 1) {
      return <Any value={v[0]} depth={depth + 1} />;
    } else {
      return (
        <ul>
          {v.map((e) => (
            <li
              key={idx++}
              className={
                depth <= 1 && idx < v.length - 1 && isComplex(e)
                  ? "has-padding-bottom-20"
                  : ""
              }
            >
              <Any
                value={e}
                depth={depth === 0 ? 0 : depth + 1}
                newLine={true}
              />
            </li>
          ))}
        </ul>
      );
    }
  };

  const Obj = ({ value, depth }: { value: any; depth: number }) => {
    let idx = 0;
    const v = value;

    const keys = Object.keys(v);
    if (keys.length === 1 && keys[0] === "Data") {
      // special handling for CAPE forensics where everything is under a single "Data" key
      return <Any value={v["Data"]} depth={depth} />;
    }

    return (
      <ul>
        {keys.map((k) => {
          return (
            <li key={idx++}>
              <span
                className={"has-text-weight-medium"}
                style={{ paddingLeft: depth * PADDING }}
              >
                {k}
              </span>
              : <Any value={v[k]} depth={depth + 1} />
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <div className="detection-detail">
      <Any value={data} depth={0} />
    </div>
  );
};
