import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ToggleSwitch } from "../components/utils/ToggleSwitch";
import { toast } from "react-toastify";
import {
  useAdminCreateEmailConfigHook,
  useAdminDeleteEmailConfigHook,
  useAdminEditEmailConfigHook,
  useAdminListEmailConfigsHook,
  useListTenantsHook,
} from "src/lib/API";
import {
  EmailSubmissionConfig,
  AdminCreateEmailSubmissionRequest,
} from "src/lib/APITypes";
import { InlineCopyable } from "src/components/utils/Copyable";
export const PATH = "/admin/emlgwy";
export const PATH_CREATE = "/admin/emlgwy/create";
export const PATH_EDIT = "/admin/emlgwy/:id";

const labelSenderAllowList =
  "Allowed Sender Addresses (optional). Seperate with ;.";

export const ListConfigs = () => {
  const { configs, refresh } = useAdminListEmailConfigsHook();
  const deletion = useAdminDeleteEmailConfigHook();

  useEffect(() => {
    if (!deletion.response?.code) return;
    if (deletion.response.code === 200) {
      toast.success("Successfully deleted!");
      return;
    }

    toast.error(
      `Error [${deletion.response?.code}]: ${deletion.response?.statusText}`,
      {
        autoClose: 10000,
      }
    );
  }, [deletion.response?.code, deletion.response?.statusText]);

  useEffect(() => {
    if (deletion.updatedAt) refresh();
  }, [deletion.updatedAt, refresh]);

  const sortbytenantidandLabel = (
    a: EmailSubmissionConfig,
    b: EmailSubmissionConfig
  ) =>
    a.TenantID === b.TenantID
      ? a.SubmitID > b.SubmitID
        ? 1
        : -1
      : a.TenantID > b.TenantID
      ? 1
      : -1;

  const sortedConfigs = configs.sort(sortbytenantidandLabel);

  return (
    <div className="section">
      <div className="container">
        <h1 className="title is-4 has-text-centered">
          Email Submission Configurations (Admin)
        </h1>
      </div>
      <div className="container">
        <NavLink to={PATH_CREATE} className="button is-primary is-pulled-right">
          <span className="icon is-small">
            <FontAwesomeIcon icon={["far", "plus"]} />
          </span>
          <span>New</span>
        </NavLink>
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>SubmitID</th>
              <th>Tenant</th>
              <th>Profile</th>
              <th>Priority</th>
              <th>Legacy Alias</th>
              <th>CreatedAt</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {sortedConfigs.map((c) => (
              <tr key={c.SubmitID}>
                <td>{c.SubmitID}</td>
                <td>{c.TenantID}</td>
                <td>{c.Profile}</td>
                <td>{c.Priority}</td>
                <td>{c.LegacyAddressAlias}</td>
                <td>{new Date(Date.parse(c.CreatedAt)).toLocaleString()}</td>
                <td>
                  <div className="buttons">
                    <div className="button is-small">
                      <NavLink to={`/admin/emlgwy/${c.SubmitID}`}>
                        <span className="icon is-small">
                          <FontAwesomeIcon icon={["fas", "edit"]} />
                        </span>
                      </NavLink>
                    </div>
                    <button
                      className="button is-small is-danger"
                      disabled={deletion.isLoading}
                      onClick={(evt) => {
                        evt.preventDefault();
                        const confirmation = window.prompt(
                          `Are you sure you want to delete ${c.TenantID}'s config for ${c.SubmitID}? Type DELETE to execute!`
                        );
                        if (confirmation === "DELETE") {
                          deletion.del(c.SubmitID);
                        } else {
                          window.alert("Operation cancelled!");
                        }
                      }}
                    >
                      <span className="icon is-small">
                        <FontAwesomeIcon icon={["fas", "trash"]} />
                      </span>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const EmailSubmissionConfigPreview = (args: EmailSubmissionConfig) => {
  return (
    <div className="section">
      <div className="container is-max-desktop">
        <div className="container">
          <h1 className="title is-4 has-text-centered">
            Your New Submission Configuration
          </h1>
        </div>
        <article className="message">
          <div className="message-header">
            <p>Save Your submitid</p>
          </div>
          <div className="message-body has-text-centered">
            <p>Here is your new email submission address:</p>
            <InlineCopyable data={args.SubmitID}>
              {args.SubmitID}
            </InlineCopyable>
            <p>
              This configuration is for <strong>{args.TenantID}</strong>
            </p>
          </div>
        </article>
      </div>
    </div>
  );
};

function joinEmailList(list: string[]): string {
  if (!list || list.length <= 0) {
    return "";
  }

  return list.map((s) => s.trim()).join(";");
}

function splitEmailList(list: string): string[] {
  if (!list || list.length <= 0) {
    return [];
  }

  return list.split(";");
}

export const EditForm = () => {
  const params = useParams();
  const { edit, response, isLoading, existingConfig } =
    useAdminEditEmailConfigHook(params.id ?? "");

  let initialConfig: EmailSubmissionConfig = {
    SubmitID: "",
    TenantID: "",
    Priority: 15,
    Profile: "default",
    Dkim: { Domain: "" },
    Spf: {
      Enabled: false,
    },

    AllowedSenders: {
      Addresses: [],
    },
    CreatedAt: "",
    UpdatedAt: "",
    LegacyAddressAlias: "",
  };

  const [config, setConfig] = useState<EmailSubmissionConfig>(initialConfig);
  const [senderList, setSenderList] = useState<string>("");

  useEffect(() => {
    if (existingConfig) {
      setConfig(existingConfig);
      setSenderList(joinEmailList(existingConfig.AllowedSenders.Addresses));
    }
  }, [existingConfig]);

  useEffect(() => {
    if (!response?.code) return;
    if (response.code === 200) {
      toast.success("Saved!");
      return;
    }

    toast.error(`Error [${response?.code}]: ${response?.statusText}`, {
      autoClose: 10000,
    });
  }, [response?.code, response?.statusText]);

  if (isLoading) return <p>loading…</p>;

  return (
    <div className="section">
      <div className="container is-max-desktop">
        <div className="container">
          <h1 className="title is-4 has-text-centered">
            Edit Submission Configuration (ADMIN)
          </h1>
        </div>
        <form
          onSubmit={(evt) => {
            evt.preventDefault();
            config.AllowedSenders.Addresses = splitEmailList(senderList);
            edit(config);
          }}
        >
          <fieldset disabled={isLoading}>
            <div className="field">
              <label className="label">Tenant</label>
              <div className="control">
                <input
                  disabled
                  value={existingConfig?.TenantID || ""}
                  className="input"
                  type="text"
                  placeholder="acme"
                />
              </div>
            </div>

            <div className="field">
              <label className="label">Submit ID</label>
              <div className="control">
                <input
                  disabled
                  value={config.SubmitID}
                  className="input"
                  type="text"
                  placeholder="acme"
                />
              </div>
            </div>

            <div className="field">
              <label className="label">Profile</label>
              <div className="control">
                <input
                  required
                  value={config.Profile}
                  className="input"
                  type="text"
                  placeholder="acme"
                  onChange={(evt) => {
                    evt.preventDefault();
                    setConfig({ ...config, Profile: evt.target.value });
                  }}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Priority</label>
              <div className="control">
                <input
                  required
                  value={config.Priority}
                  onChange={(evt) => {
                    evt.preventDefault();
                    setConfig({
                      ...config,
                      Priority: parseInt(evt.target.value),
                    });
                  }}
                  className="input"
                  type="text"
                  placeholder="e.g. 15"
                />
              </div>
            </div>

            <div className="field">
              <ToggleSwitch
                name="Enforce SPF"
                label="Enforce SPF"
                checked={config.Spf.Enabled}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  let newRequest = { ...config };
                  newRequest.Spf.Enabled = evt.target.checked;
                  setConfig({ ...newRequest });
                }}
              />
            </div>

            <div className="field">
              <label className="label">DKIM Domain (optional)</label>
              <input
                value={config.Dkim.Domain}
                className="input"
                placeholder="splunk.com"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  let newRequest = { ...config };
                  newRequest.Dkim.Domain = evt.target.value;
                  setConfig({ ...newRequest });
                }}
              />
            </div>
            <div className="field">
              <label className="label">{labelSenderAllowList}</label>
              <input
                placeholder="me@splunk.com;you@splunk.com"
                value={senderList}
                className="input"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  setSenderList(evt.target.value);
                }}
              />
            </div>

            <div className="control">
              <input
                type="submit"
                className="button is-primary"
                disabled={isLoading || !config}
              />
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export const CreateForm = () => {
  let initialState: AdminCreateEmailSubmissionRequest = {
    TenantID: "",
    Priority: 15,
    Profile: "default",
    Dkim: {
      Domain: "",
    },
    Spf: {
      Enabled: false,
    },

    AllowedSenders: {
      Addresses: [],
    },
    LegacyAddressAlias: "",
  };

  const [request, setRequest] =
    useState<AdminCreateEmailSubmissionRequest>(initialState);
  const { submit, response, isLoading } = useAdminCreateEmailConfigHook();
  const { tenants } = useListTenantsHook();
  const [senderList, setSenderList] = useState<string>("");

  useEffect(() => {
    if (!response?.code || response.code === 200) return;
    toast.error(`Error [${response?.code}]: ${response?.statusText}`, {
      autoClose: 10000,
    });
  }, [response?.code, response?.statusText]);

  if (response?.config)
    return <EmailSubmissionConfigPreview {...response.config} />;

  return (
    <div className="section">
      <div className="container is-max-desktop">
        <div className="container">
          <h1 className="title is-4 has-text-centered">
            Create a New Email Submission Config (ADMIN)
          </h1>
        </div>
        <form
          onSubmit={(evt) => {
            evt.preventDefault();
            request.AllowedSenders.Addresses = splitEmailList(senderList);
            submit(request);
          }}
        >
          <fieldset disabled={isLoading}>
            <div className="field">
              <label className="label">Tenant</label>
              <div className="field-body">
                <div className="control">
                  <div className="select">
                    <select
                      id="tenant"
                      name="tenant"
                      value={request.TenantID}
                      onChange={(evt) => {
                        evt.preventDefault();
                        setRequest({
                          ...request,
                          TenantID: evt.target.value,
                        });
                      }}
                    >
                      <option disabled selected key="__NO_TENANT__" value="">
                        -- select a tenant --
                      </option>
                      {tenants
                        ? tenants.map((t) => (
                            <option key={t.Name} value={t.Name}>
                              {" "}
                              {t.FullName}{" "}
                            </option>
                          ))
                        : ""}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="field">
              <label className="label">Profile</label>
              <div className="control">
                <input
                  required
                  value={request.Profile}
                  onChange={(evt) => {
                    evt.preventDefault();
                    setRequest({
                      ...request,
                      Profile: evt.target.value,
                    });
                  }}
                  className="input"
                  type="text"
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Priority</label>
              <div className="control">
                <input
                  required
                  value={request.Priority}
                  onChange={(evt) => {
                    evt.preventDefault();
                    setRequest({
                      ...request,
                      Priority: evt.target.valueAsNumber,
                    });
                  }}
                  className="input"
                  type="number"
                />
              </div>
            </div>
            <div className="field">
              <ToggleSwitch
                name="Enforce SPF"
                label="Enforce SPF"
                checked={request.Spf.Enabled}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  let newRequest = { ...request };
                  newRequest.Spf.Enabled = evt.target.checked;
                  setRequest({ ...newRequest });
                }}
              />
            </div>
            <div className="field">
              <label className="label">DKIM Domain (optional)</label>
              <input
                value={request.Dkim.Domain}
                className="input"
                placeholder="splunk.com"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  let newRequest = { ...request };
                  newRequest.Dkim.Domain = evt.target.value;
                  setRequest({ ...newRequest });
                }}
              />
            </div>
            <div className="field">
              <label className="label">{labelSenderAllowList}</label>
              <input
                value={senderList}
                placeholder="me@splunk.com;you@splunk.com"
                className="input"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  setSenderList(evt.target.value);
                }}
              />
            </div>

            <div className="control">
              <input
                type="submit"
                className="button is-primary"
                disabled={isLoading || !request.Profile || !request.Priority}
              />
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  );
};
