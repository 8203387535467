import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext } from "react";
import { toast } from "react-toastify";
import { Detection, Resource } from "src/lib/APITypes";
import { APIContext } from "src/lib/MAPApi";
import { FileSize } from "../FileSize";
import { DetectionBox } from "../forensics/Detections";
import { ForensicsScreenshots } from "../forensics/ForensicsScreenshots";
import { ResourceSummary, ActionConfig } from "../ResourceSummary";
import { ThreatName } from "../utils/ThreatName";
import { LoadingMessage } from "./LoadingMessage";
import { TaskProperties } from "./TaskTypes";

interface CAPETask {
  PCAP: string;
}

interface CAPEDetails {
  Package?: string;
  PseudoTask?: boolean;
  Tasks?: CAPETask[];
  ExtractedResources?: Resource[];
}

export const CAPEResults = ({
  job,
  task,
  resource,
  forensics,
}: TaskProperties) => {
  const details: CAPEDetails = task.Results.Details || {};
  const { api } = useContext(APIContext);
  const { getAccessTokenSilently } = useAuth0();

  let pcaps: string[] = [];
  for (let t of details.Tasks ?? []) {
    if (t.PCAP) {
      pcaps.push(t.PCAP);
    }
  }

  // PCAPs have been relocated to forensics
  if (forensics && typeof forensics === "object") {
    const ps = (forensics.SavedArtifacts || []).filter(
      (s) => s.Type === "pcap"
    );
    if (ps.length > 0) {
      pcaps.push(ps[0].ArtifactPath);
    }
  }

  const downloadPCAP = () => {
    if (pcaps.length > 0) {
      api
        .callAPI(getAccessTokenSilently, api.getArtifactByPath, pcaps[0])
        .catch(() => toast.error("Error downloading pcap"));
    }
  };
  let actions: ActionConfig[] = [];
  if (pcaps.length > 0) {
    actions = [
      { title: "Download PCAPs", onClick: downloadPCAP, icon: "download" },
    ];
  }

  if (
    details.PseudoTask &&
    ["resource_extraction"].includes(details.Package!)
  ) {
    const resources = details.ExtractedResources || [];
    return (
      <section>
        <ResourceSummary
          job={job}
          task={task}
          resource={resource}
          menuActions={actions}
        />

        <p className="has-margin-bottom-20">
          TwinWave Cuckoo extracted the following resources from{" "}
          <span className="has-text-weight-bold">
            <ThreatName name={resource.Name} />
          </span>
          :
        </p>
        {resources.map((r) => (
          <div key={r.Name} className="card has-margin-bottom-20">
            <div className="card-header">
              <p className="card-header-title">{r.Name}</p>
            </div>
            <div className="card-content">
              <ul>
                <li>
                  <b>Name:</b> {r.Name}
                </li>
                {r.FileMetadata && (
                  <>
                    <li>
                      <b>SHA256 Hash:</b> {r.FileMetadata.SHA256}
                    </li>
                    <li>
                      <b>MD5 Hash:</b> {r.FileMetadata.MD5}
                    </li>
                    <li>
                      <b>File Size:</b> <FileSize size={r.FileMetadata.Size} />
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        ))}
      </section>
    );
  }

  if (forensics === "loading") {
    return (
      <section>
        <ResourceSummary
          job={job}
          task={task}
          resource={resource}
          menuActions={actions}
        />
        <LoadingMessage />
      </section>
    );
  }

  let detections: Detection[] = [];
  if (forensics && forensics.Detections) {
    detections = forensics.Detections;
  }

  return (
    <section>
      <ResourceSummary
        job={job}
        task={task}
        resource={resource}
        menuActions={actions}
      />
      {detections.length > 0 && (
        <DetectionBox detections={detections} maxHeight="25rem" />
      )}
      <ForensicsScreenshots forensics={forensics} />
    </section>
  );
};
