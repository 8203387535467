import React from "react";

export const WhoisResult = (props) => {
  const whois = props.value;
  return (
    <tr>
      <td>
        <div>
          <b>{whois.DomainName}</b>
        </div>
        <div>
          <span className="has-text-grey">Created:</span>{" "}
          {new Date(Date.parse(whois.CreatedAt)).toLocaleString()}
        </div>
        <div>
          <span className="has-text-grey">Last Updated:</span>{" "}
          {new Date(Date.parse(whois.UpdatedAt)).toLocaleString()}
        </div>
        <div>
          <span className="has-text-grey">Expires:</span>{" "}
          {new Date(Date.parse(whois.ExpiresAt)).toLocaleString()}
        </div>
        <div>
          <span className="has-text-grey">Organization:</span> {whois.Org}
        </div>
        <div>
          <span className="has-text-grey">Registrar:</span> {whois.Registrar}
        </div>
        <div>
          <span className="has-text-grey">Address:</span> {whois.Address}
        </div>
        <div>
          <span className="has-text-grey">City:</span> {whois.City}
        </div>
        <div>
          <span className="has-text-grey">State:</span> {whois.State}
        </div>
        <div>
          <span className="has-text-grey">Country:</span> {whois.Country}
        </div>
      </td>
    </tr>
  );
};
