import { Auth0ContextInterface, withAuth0 } from "@auth0/auth0-react";
import { buildEsQuery } from "@twinwave/kql";
import React, {
  FormEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link, Location, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { MultiSelect } from "src/components/MultiSelect";
import { Score } from "src/components/Score";
import { CompactScoreRangeInput } from "src/components/ScoreRangeInput";
import { enginesToLabels } from "src/components/utils/DisplayNames";
import { Labels as LabelElement } from "src/components/utils/Labels";
import { ThreatName } from "src/components/utils/ThreatName";
import {
  LabelType,
  SearchV2File,
  SearchV2ForensicsResult,
  SearchV2Highlights,
  SearchV2IndexedTaskForensics,
  SearchV2IP,
  SearchV2JobResult,
  SearchV2QueryParams,
  SearchV2Results,
} from "src/lib/APITypes";
import { APIContext } from "src/lib/MAPApi";
import { ipMatcher, sameContents, truncateMidStr } from "src/lib/Utils";
import { Copyable } from "../components/utils/Copyable";
import { UserHasPermission } from "../lib/Auth";

import Flatpickr from "react-flatpickr";
import "../components/css/FlatpickrCustom.css";
import moment from "moment";

const DEFAULT_PAGE_SIZE = 50;

const DEFAULT_FORENSICS_SEARCH_TIMEFRAME = "90";

const DEFAULT_SEARCH_PARAMS: UISearchParams = {
  mode: "resources",
  term: "",
  field: "filename",
  type: "tokenized",
  submitted_by: "",
  start_time: "",
  end_time: "",
  count: DEFAULT_PAGE_SIZE,
  page: 1,
  api_key_id: "",
  tenants: [] as string[],
  verdict: "",
  score_min: 0,
  score_max: 100,
  timeframe: "0",
};

function areSearchParamsValid(p: UISearchParams): boolean {
  return (
    p.mode !== null &&
    p.term !== null &&
    p.field !== null &&
    p.type !== null &&
    p.submitted_by !== null &&
    p.verdict !== null &&
    p.start_time !== null &&
    p.end_time !== null &&
    p.count !== null &&
    p.page !== null
  );
}

/**
 * Apply given search params + defaults to generate a search URL string for use in `<Link to`
 * @param params Search parameters
 * @returns Generated route path with query params
 */
export function getSearchLink(params: Partial<UISearchParams>): string {
  const merged = { ...DEFAULT_SEARCH_PARAMS, ...params } as Object;
  const urlParams = new URLSearchParams(merged as Record<string, string>);
  return `/search?${urlParams.toString()}`;
}

function intParam(v: string | null | undefined): number | undefined {
  if (v === null || v === undefined) {
    return undefined;
  }
  const foo = parseInt(v);
  if (isNaN(foo)) {
    return undefined;
  }
  return foo;
}

// Interface extending SearchV2 params to include additional UI params that are
// not needed to be used in API calls
interface UISearchParams extends SearchV2QueryParams {
  timeframe?: string;
}

interface SubmitFormFunction {
  (uiSearchParams: UISearchParams, replaceHistory?: boolean): boolean;
}

/**
 * Extract search params from URL query params.
 * @returns Search params from query URL (merged with defaults) or undefined if none/invalid
 */
function searchParamsFromUrl(location: Location): UISearchParams | undefined {
  if (!location.search.includes("?")) {
    return undefined;
  }
  const params = new URLSearchParams(location.search);
  // Is there a better way?
  const result: UISearchParams = {
    mode: params.get("mode") ?? DEFAULT_SEARCH_PARAMS.mode,
    term: params.get("term") ?? DEFAULT_SEARCH_PARAMS.term,
    field: params.get("field") ?? DEFAULT_SEARCH_PARAMS.field,
    type: params.get("type") ?? DEFAULT_SEARCH_PARAMS.type,
    submitted_by:
      params.get("submitted_by") ?? DEFAULT_SEARCH_PARAMS.submitted_by,
    start_time: params.get("start_time") ?? DEFAULT_SEARCH_PARAMS.start_time,
    end_time: params.get("end_time") ?? DEFAULT_SEARCH_PARAMS.end_time,
    count: intParam(params.get("count")) ?? DEFAULT_SEARCH_PARAMS.count,
    page: intParam(params.get("page")) ?? DEFAULT_SEARCH_PARAMS.page,
    api_key_id: params.get("api_key_id") ?? DEFAULT_SEARCH_PARAMS.api_key_id,
    tenants: params.get("tenants")?.split(",") ?? DEFAULT_SEARCH_PARAMS.tenants, // ACL check will happen at API
    verdict: params.get("verdict") ?? DEFAULT_SEARCH_PARAMS.verdict,
    score_min:
      intParam(params.get("score_min")) ?? DEFAULT_SEARCH_PARAMS.score_min,
    score_max:
      intParam(params.get("score_max")) ?? DEFAULT_SEARCH_PARAMS.score_max,
  };

  const defaultTimeframe =
    result.mode === "forensics"
      ? DEFAULT_FORENSICS_SEARCH_TIMEFRAME
      : DEFAULT_SEARCH_PARAMS.timeframe;

  result.timeframe = params.get("timeframe") ?? defaultTimeframe;

  if (areSearchParamsValid(result)) {
    return result;
  }
  return undefined;
}

// Extend search results with paging links (as needed)
interface InternalSearchV2Results extends SearchV2Results {
  NextLink?: () => void;
  PrevLink?: () => void;
}

// Used to bundle search results + executed search so we have 1 state update upon search completion
class ExecutedSearchResults {
  results: InternalSearchV2Results;
  query: UISearchParams;

  constructor(results: InternalSearchV2Results, executedQuery: UISearchParams) {
    this.results = results;
    this.query = executedQuery;
  }
}

export const SearchPageInternal = ({
  auth0, // Injected via auth0 wrapper
}: {
  auth0: Auth0ContextInterface;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [results, setResults] = useState<ExecutedSearchResults | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useState(DEFAULT_SEARCH_PARAMS);
  const { api } = useContext(APIContext); // context injected via the withAuth0 wrapper
  const { getAccessTokenSilently } = auth0;

  /**
   * Form submission handler
   * @param uiSearchParams Search parameters
   * @param replaceHistory If true, replace location in history vs adding a new one
   *   This is primarily used for in-page clicks on labels which use navigation methods
   *   vs parameter updates.
   * @returns
   */
  const submitFormHandler: SubmitFormFunction = (
    uiSearchParams: UISearchParams,
    replaceHistory?: boolean
  ): boolean => {
    if (isLoading) {
      return false;
    }
    // Ensure required parameters are all set
    if (!areSearchParamsValid(uiSearchParams)) {
      return false;
    }

    replaceHistory = replaceHistory ?? false;

    // Build API call
    let params = { ...uiSearchParams } as Record<string, any>;
    // Forcibly cast as URLSearchParams() in JS takes objects and stringifies them
    // TS requires Record<string,string> (so we lie) -- https://github.com/microsoft/TypeScript/issues/32951
    let urlParams = new URLSearchParams(params as Record<string, string>);

    if ("?" + urlParams.toString() !== location.search) {
      // record in history
      // Hack to workaround log noise: https://github.com/remix-run/react-router/issues/7460
      setTimeout(
        () =>
          navigate(`${location.pathname}?${urlParams.toString()}`, {
            replace: replaceHistory,
          }),
        0
      );
    }

    if (uiSearchParams.timeframe !== "" && uiSearchParams.timeframe !== "0") {
      // Calculate ranges now (we defer this so we don't do extra navs on minute time changes)
      const now = moment();
      params["end_time"] = now.toISOString();
      params["start_time"] = now
        .subtract(uiSearchParams.timeframe, "days")
        .toISOString();
    }

    if (uiSearchParams.field === "raw_query") {
      try {
        let esQuery = buildEsQuery(
          undefined,
          [{ query: uiSearchParams.term, language: "kuery" }],
          []
        );
        params = { ...params, term: JSON.stringify(esQuery) };
      } catch (err: any) {
        console.log("failed KQL translation: ", err);
        toast.error("Failed to parse KQL: " + err.shortMessage, {
          autoClose: false,
        });
        return false;
      }
    }

    setIsLoading(true);
    //console.log("calling api", params);
    api
      .callAPI(getAccessTokenSilently, api.searchv2, {
        ...params,
        source: "user",
        score_min: uiSearchParams.score_min / 100,
        score_max: uiSearchParams.score_max / 100,
      })
      .then((res: SearchV2Results) => {
        setIsLoading(false);
        if (
          document.activeElement !== null &&
          document.activeElement.className.includes("pagination") &&
          document.activeElement instanceof HTMLElement &&
          (document.activeElement as HTMLElement).blur
        ) {
          (document.activeElement as HTMLElement).blur();
        }
        window.scrollTo(0, 0);
        handleSearchResults(uiSearchParams, res);
      });

    return false; // Stop browser driven submission as we're doing this via JS
  };

  // TODO Loading button in/put
  // API call
  // Results handling to switch loading back to normal
  // Maybe instead of onsubmit, we have 'onSearchApiCallComplete'
  // This will encapsulate the search form better
  // This should call setState()
  const handleSearchResults = (
    uiSearchParams: UISearchParams,
    results: InternalSearchV2Results
  ) => {
    //console.log("handleresults", searchParams, results);
    if (results.HasError) {
      // TODO Improve
      toast.error("Problem executing search", {
        autoClose: false,
      });
      return;
    }

    // Calculate next and prev link for results
    if (results.HasNext || uiSearchParams.page > 1) {
      if (results.HasNext) {
        results.NextLink = () => {
          submitFormHandler({
            ...uiSearchParams,
            page: uiSearchParams.page + 1,
          });
        };
      }
      if (uiSearchParams.page > 1) {
        results.PrevLink = () => {
          submitFormHandler({
            ...uiSearchParams,
            page: uiSearchParams.page - 1,
          });
        };
      }
    }

    setResults(new ExecutedSearchResults(results, uiSearchParams));
  };

  // Ensure re-render only happens when results or isloading changes
  // not when other props in this component change
  const resultsView = useMemo(() => {
    if (results && results.results) {
      return <SearchResultsView results={results} isLoading={isLoading} />;
    } else {
      return <></>;
    }
  }, [results, isLoading]);

  return (
    <PageContainer>
      <SearchForm
        auth0={auth0}
        isLoading={isLoading}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        submitForm={submitFormHandler}
      />
      {resultsView}
    </PageContainer>
  );
};

export const SearchPage = withAuth0(SearchPageInternal);

// TODO Do we really need to pass the rops in?  Can result in unnecessary re-rendering.
type ApiKeyEntry = {
  ID: string;
  Label: string;
};

// Different match types based on field type
// Key represents field type, value is the set of options.
const matchModesForSearchField = new Map<string, JSX.Element[]>([
  [
    "default",
    [
      <option value="tokenized" key="field-tokenized">
        includes keyword
      </option>,
      <option value="exact" key="field-equals">
        equals
      </option>,
      <option value="substring" key="field-substring">
        contains substring
      </option>,
      <option value="startswith" key="field-startswith">
        starts with
      </option>,
      <option value="endswith" key="field-endswith">
        ends with
      </option>,
    ],
  ],
  [
    "ip",
    [
      <option value="term" key="field-term">
        matches
      </option>,
    ],
  ],
]);

function getMatchTypeOptions(type: string): JSX.Element[] {
  return (
    matchModesForSearchField.get(type) ??
    matchModesForSearchField.get("default") ??
    []
  );
}

const SearchForm = ({
  auth0,
  isLoading,
  searchParams,
  setSearchParams,
  submitForm,
}: {
  auth0: Auth0ContextInterface;
  isLoading: boolean;
  searchParams: SearchV2QueryParams;
  setSearchParams: React.Dispatch<React.SetStateAction<UISearchParams>>;
  submitForm: SubmitFormFunction;
}) => {
  const { api } = useContext(APIContext); // context injected via the withAuth0 wrapper
  const { getAccessTokenSilently } = auth0;

  const [showFieldType, setShowFieldType] = useState(false);
  const [matchTypeOptions, setMatchTypeOptions] = useState(
    getMatchTypeOptions(searchParams.type)
  );

  const [searchStartTime, setSearchStartTime] = useState<string>(
    DEFAULT_SEARCH_PARAMS.start_time
  );
  const [searchEndTime, setSearchEndTime] = useState<string>(
    DEFAULT_SEARCH_PARAMS.end_time
  );

  // Track the latest setting of the timeframe field
  // Default is "0" => all time
  const [timeFrame, setTimeFrame] = useState<string>("0");

  // If "Resources or forensics" is selected, we need to make sure we don't go beyond 90 days
  const [forensicsSearchStartTime, setForensicsSearchStartTime] =
    useState<string>("");

  // Load API key set tied to the user (just once!)
  const [apiKeys, setApiKeys] = useState<ApiKeyEntry[]>([]);
  useEffect(() => {
    api
      .callAPI(getAccessTokenSilently, api.getAPIKeys)
      .then((keys: ApiKeyEntry[]) => {
        setApiKeys(keys);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // No deps prevents it from being re-triggered (so equivalent of componentDidMount)

  // Check for URL search params and execute search on load
  const location = useLocation();

  useEffect(() => {
    if (location.search !== undefined && location.search.length > 1) {
      const urlQueryParams = searchParamsFromUrl(location);
      if (urlQueryParams !== undefined) {
        // check if timeframe is set
        const params = new URLSearchParams(location.search);
        // Because URLs include absolute times, for direct URL searches
        // we now force time frange to 'custom'
        let timeframeValue = params.get("timeframe") ?? "";
        setTimeFrame(timeframeValue);
        setSearchStartTime(urlQueryParams.start_time);
        setSearchEndTime(urlQueryParams.end_time);

        if (timeframeValue !== "") {
          // If there's a custom time frame, we need to decide if we should
          // show it in the timeframe selector, or if we should use 'custom' dates
          // This mostly comes from when you load a search page from a URL, and
          // now you have (timeframe, start, end).  The dropdown can be set
          // to the timeframe or custom.  When loading a page by URL, timeframe=custom
          // makes sense.  When just searching normally, the timeframe should be observed
        }

        const updatedParams = syncFieldType(
          urlQueryParams.field,
          urlQueryParams.field,
          urlQueryParams.type
        );

        setSearchParams({ ...urlQueryParams, ...updatedParams });

        if (!isLoading) {
          // Must pass explicitly as no guarantee setSearchParams applies first
          // We replace existing history entry as search parameters in URL may not include the defaults
          // which then cause 2 entries to be added: 1 for initial nav/click, 1 for the normalized params
          submitForm({ ...urlQueryParams, timeframe: timeframeValue }, true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]); // Used to trigger search when navigating to URL and handle click on labels which link to /search

  const [tenants, setTenants] = useState<
    undefined | { display: string; value: string }[]
  >(undefined);

  // This will be triggered in the admin gated item
  const loadTenants = (): Promise<{ display: string; value: string }[]> => {
    if (tenants !== undefined) {
      return Promise.resolve(tenants);
    }

    const result = api
      .callAPI(getAccessTokenSilently, api.getTenants)
      .then((tenants: any[]) =>
        tenants.map(({ FullName, Name }) => ({
          display: FullName,
          value: Name,
        }))
      );

    setTenants(result);

    return result;
  };

  const onTenantSelectChange = (tenants: string[]) => {
    if (!sameContents(tenants, searchParams.tenants)) {
      setSearchParams({
        ...searchParams,
        tenants: tenants,
      });
    }
  };

  const [placeholder, setQueryPlaceholder] = useState("Filename"); // Set to initial searchparam mode

  // Mapping of query field name to placeholder to override default label derived from option content.
  const queryFieldPlaceholderMap = new Map<string, string>([
    ["ip", "IP or CIDR block or Range (a-b)"],
    ["url", "URL (https://www.example.com)"],
    ["hostname", "Hostname (www.example.com)"],
    ["domain", "Domain (example.com)"],
  ]);

  const handleFieldTypeChange = (field: string, defaultPlaceholder: string) => {
    const label = queryFieldPlaceholderMap.get(field) ?? defaultPlaceholder;
    setQueryPlaceholder(label);
    const updatedParams = syncFieldType(
      field,
      searchParams.field,
      searchParams.type
    );
    setSearchParams({ ...searchParams, ...updatedParams });
  };

  const forensicsOnlyFields = new Set<string>([
    "filetype",
    "detection_name",
    "detection_desc",
  ]);

  /**
   * Sync various form states based on field type
   * Params that should be updated are returned to the caller so the caller can decide
   * when to update the params via setSearchParams.
   * @param newField new search field value
   * @param currentField old search field value (if any)
   * @param currentType search type
   * @return Fields to update in params
   */
  const syncFieldType = (
    newField: string,
    currentField: string,
    currentType: string
  ): Partial<SearchV2QueryParams> => {
    if (newField === "raw_query") {
      setShowFieldType(true);
    } else {
      setShowFieldType(false);
    }

    // if changing from X --> IP, or IP --> X reset match type
    let matchType = currentType;
    if (newField === "ip" || currentField === "ip") {
      const matchOpts = getMatchTypeOptions(newField);
      setMatchTypeOptions(matchOpts);
      if (matchOpts.length > 0) {
        matchType = matchOpts[0].props.value;
      }
    }

    return {
      field: newField,
      type: matchType,
    };
  };

  const onSubmitHandler = (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    // Reset things that should be upon search button click
    const newParams = {
      ...searchParams,
      term: searchParams.term.trim(),
      page: 1,
      timeframe: timeFrame,
    };
    setSearchParams(newParams);
    return submitForm(newParams);
  };

  // Build form
  return (
    <div className="box">
      <h1 className="title is-4">Search</h1>
      <form onSubmit={onSubmitHandler}>
        <div className="columns is-multiline">
          <div className="column is-full">
            <div className="field has-addons pb-2">
              <p className="control">
                <span className="select">
                  <select
                    id="mode"
                    name="mode"
                    value={searchParams.mode}
                    onChange={(evt) => {
                      evt.preventDefault();
                      // When changing to 'forensics' mode, Reset timeframe to forensics specific default timeframe if we're on the 'all time' timeframe (which is not available for forensics)
                      const timeframe =
                        timeFrame === "0" && evt.target.value === "forensics"
                          ? DEFAULT_FORENSICS_SEARCH_TIMEFRAME
                          : timeFrame;
                      // When changing to 'resources' mode, make sure forensics only fields arent picked
                      let currentField = searchParams.field;
                      if (
                        evt.target.value === "resources" &&
                        forensicsOnlyFields.has(searchParams.field)
                      ) {
                        currentField = "filename";
                      }
                      setTimeFrame(timeframe);
                      setSearchParams({
                        ...searchParams,
                        mode: evt.target.value,
                        field: currentField,
                      });
                      if (evt.target.value === "forensics") {
                        setForensicsSearchStartTime(
                          moment()
                            .startOf("day")
                            .subtract(90, "days")
                            .toISOString()
                        );
                      } else {
                        setForensicsSearchStartTime("");
                      }
                    }}
                  >
                    <option value="resources">Resource</option>
                    <option value="forensics">Resource or Forensics</option>
                  </select>
                </span>
              </p>
              <p className="control">
                <span className="select">
                  <select
                    id="field"
                    name="field"
                    value={searchParams.field}
                    onChange={(evt) => {
                      evt.preventDefault();
                      handleFieldTypeChange(
                        evt.target.value,
                        evt.target.options[evt.target.selectedIndex].text
                      );
                    }}
                  >
                    <optgroup label="Files">
                      <option value="filename">Filename</option>
                      <option value="sha256">SHA256</option>
                      <option value="md5">MD5</option>
                      <option value="mimetype">MIME Type</option>
                      {searchParams.mode === "forensics" && (
                        <option value="filetype">File Type</option>
                      )}
                    </optgroup>
                    <optgroup label="URLs">
                      <option value="url">URL</option>
                      <option value="hostname">Hostname</option>
                      <option value="domain">Domain</option>
                      <option value="ip">IP</option>
                    </optgroup>
                    <optgroup label="General">
                      <option value="tag">Tag</option>
                      {searchParams.mode === "forensics" && (
                        <>
                          <option value="detection_name">Detection Name</option>
                          <option value="detection_desc">
                            Detection Description
                          </option>
                        </>
                      )}
                    </optgroup>
                    <UserHasPermission permission="app:admin">
                      <option value="raw_query">KQL Query</option>
                    </UserHasPermission>
                  </select>
                </span>
              </p>
              {!showFieldType && (
                <p className="control">
                  <span className="select">
                    <select
                      id="type"
                      name="type"
                      value={searchParams.type}
                      onChange={(evt) => {
                        evt.preventDefault();
                        setSearchParams({
                          ...searchParams,
                          type: evt.target.value,
                        });
                      }}
                    >
                      {matchTypeOptions}
                    </select>
                  </span>
                </p>
              )}
              <p className="control is-expanded">
                <input
                  id="term"
                  name="term"
                  type="text"
                  className="input"
                  value={searchParams.term}
                  onChange={(evt) => {
                    evt.preventDefault();
                    setSearchParams({
                      ...searchParams,
                      term: evt.target.value,
                    });
                  }}
                  placeholder={placeholder}
                />
              </p>
            </div>

            <div className="columns">
              <div className="column is-narrow">
                <CompactScoreRangeInput
                  initialMin={searchParams.score_min}
                  initialMax={searchParams.score_max}
                  onChange={(min, max) => {
                    setSearchParams({
                      ...searchParams,
                      score_min: min,
                      score_max: max,
                    });
                  }}
                />
              </div>
              <div className="column is-narrow">
                <div className="field">
                  <label htmlFor="verdict" className="label">
                    Verdict
                  </label>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <div className="select is-fullwidth">
                          <select
                            id="verdict"
                            name="verdict"
                            value={searchParams.verdict}
                            onChange={(evt) => {
                              evt.preventDefault();
                              setSearchParams({
                                ...searchParams,
                                verdict: evt.target.value,
                              });
                            }}
                          >
                            <option value="">Any</option>
                            <option value="malware">Malware</option>
                            <option value="phish">Phish</option>
                            <option value="spam">Spam</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-3">
                <div className="field">
                  <label htmlFor="api_key_id" className="label">
                    API Key
                  </label>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <div className="select is-fullwidth">
                          <select
                            id="api_key_id"
                            name="api_key_id"
                            value={searchParams.api_key_id}
                            onChange={(evt) => {
                              evt.preventDefault();
                              setSearchParams({
                                ...searchParams,
                                api_key_id: evt.target.value,
                              });
                            }}
                          >
                            <option value="">-</option>
                            {searchParams.api_key_id &&
                            apiKeys.filter(
                              (x) => x.ID === searchParams.api_key_id
                            ).length === 0 ? (
                              <option value={searchParams.api_key_id}>
                                API Key
                              </option>
                            ) : null}
                            {apiKeys.map((x) => (
                              <option key={x.ID} value={x.ID}>
                                {x.Label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column is-3">
                <div className="field">
                  <label htmlFor="submitted_by" className="label">
                    Submitted By
                  </label>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          id="submitted_by"
                          name="submitted_by"
                          placeholder="alice or alice@example.com"
                          value={searchParams.submitted_by}
                          className="input"
                          type="text"
                          onChange={(evt) => {
                            evt.preventDefault();
                            setSearchParams({
                              ...searchParams,
                              submitted_by: evt.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="columns is-multiline">
          <UserHasPermission permission="app:admin">
            <div className="column is-3">
              <div className="field">
                <label className="label">Tenants</label>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <MultiSelect
                        onChange={onTenantSelectChange}
                        selection={searchParams.tenants || []}
                        options={loadTenants}
                        buttonText="Tenants"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </UserHasPermission>
          <div className="column is-narrow">
            <div className="field">
              <label htmlFor="timeframe" className="label">
                Timeframe
              </label>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        value={timeFrame}
                        id="timeframe"
                        name="timeframe"
                        onChange={(evt) => {
                          evt.preventDefault();
                          setTimeFrame(evt.target.value);
                          setSearchParams({
                            ...searchParams,
                            timeframe: evt.target.value,
                          });
                        }}
                      >
                        <option value="1">Last 24 hours</option>
                        <option value="7">Last 7 days</option>
                        <option value="30">Last 30 days</option>
                        <option value="90">Last 90 days</option>
                        <option value="">Custom</option>
                        {searchParams.mode === "resources" && (
                          <option value="0">All Time</option>
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            // Conditional rendering of start date if custom is selected for timeframe
            timeFrame === "" && (
              <>
                <div className="column is-narrow">
                  <div className="field">
                    <label htmlFor="start_date" className="label">
                      Start Date
                    </label>
                    <Flatpickr
                      id="start_time"
                      name="start_time"
                      defaultValue={searchStartTime}
                      className="input"
                      options={{
                        enableTime: true,
                        enableSeconds: true,
                        defaultHour: 0,
                        time_24hr: true,
                        dateFormat: "Y-m-d H:i:S",
                        maxDate: searchEndTime,
                        minDate: forensicsSearchStartTime,
                      }}
                      onChange={(selectedDates, dateStr, instance) => {
                        const startTime = moment(
                          selectedDates[0]
                        ).toISOString();
                        setSearchParams({
                          ...searchParams,
                          start_time: startTime,
                        });
                        // Update state variable as well
                        setSearchStartTime(startTime);
                      }}
                    />
                  </div>
                </div>
                <div className="column is-narrow">
                  <div className="field">
                    <label htmlFor="end_time" className="label">
                      End Date
                    </label>
                    <Flatpickr
                      id="end_time"
                      name="end_time"
                      defaultValue={searchEndTime}
                      className="input"
                      options={{
                        enableTime: true,
                        enableSeconds: true,
                        defaultHour: 0,
                        time_24hr: true,
                        dateFormat: "Y-m-d H:i:S",
                        minDate: searchStartTime,
                      }}
                      onChange={(selectedDates, dateStr, instance) => {
                        const endTime = moment(selectedDates[0]).toISOString();
                        setSearchParams({
                          ...searchParams,
                          end_time: endTime,
                        });
                        // Update state variable as well
                        setSearchEndTime(endTime);
                      }}
                    />
                  </div>
                </div>
              </>
            )
          }
        </div>

        <div className="columns is-centered">
          <div className="column is-narrow">
            <button
              type="submit"
              className={`button is-primary ${isLoading ? "is-loading" : ""}`}
            >
              Search
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

const SearchResultsView = ({
  results,
  isLoading,
}: {
  results: ExecutedSearchResults | undefined;
  isLoading: boolean;
}) => {
  if (
    results === undefined ||
    results.results === undefined ||
    results.query === undefined
  ) {
    return <></>;
  }

  const numRecords =
    results.query.mode === "resources"
      ? results.results.Jobs.length
      : results.results.Forensics.length;

  if (numRecords === 0) {
    if (isLoading) {
      return <></>;
    }
    return (
      <div>
        <p className="has-text-centered">No results matched the search.</p>
        {results.query.type === "tokenized" && (
          <p className="has-text-centered mx-6 px-6 mt-4">
            Your query used the <b>keyword</b> search type, which matches on
            complete word boundaries. <br />
            You can also try the <b>substring</b> search type, which will match
            on partial words or character sequences.
            <br />
            (for example: <em>"oogle"</em> would not match
            <em>"google.com"</em> in a keyword search, but it would match in a
            substring search)
            <br />
            <b>startswith</b> or <b>endswith</b> search types will also match on
            partial words or character sequences at the beginning or end.
          </p>
        )}
      </div>
    );
  }

  return (
    <div>
      <div>
        <p className="title is-4 has-text-centered">
          Search Results{" "}
          {results.query.page > 1 && (
            <span className="subtitle is-6 has-text-centered">
              (Page {results.query.page})
            </span>
          )}
        </p>
      </div>
      <div>
        {results.query.mode === "resources" ? (
          <JobResultsTable
            isLoading={isLoading}
            results={results.results.Jobs}
            searchParams={results.query}
          />
        ) : (
          <ForensicsResultsTable
            isLoading={isLoading}
            results={results.results.Forensics}
            searchParams={results.query}
          />
        )}
      </div>
      <div className="section">
        <div className="container">
          <nav className="pagination" role="navigation">
            {results.results.PrevLink ? (
              <button
                className="button pagination-previous"
                onClick={(evt) => {
                  evt.preventDefault();
                  results.results.PrevLink && results.results.PrevLink();
                }}
              >
                Previous
              </button>
            ) : (
              <span />
            )}
            {results.results.NextLink && (
              <button
                className="button pagination-next"
                onClick={(evt) => {
                  evt.preventDefault();
                  results.results.NextLink && results.results.NextLink();
                }}
              >
                Next
              </button>
            )}
          </nav>
        </div>
      </div>
    </div>
  );
};

const PageContainer = ({ children }: { children: React.ReactNode }) => (
  <div>
    <section className="section">
      <div className="container">{children}</div>
    </section>
  </div>
);

/**
 * This dedupes and formats search result highlights
 * @param highlights
 * @param searchParams
 * @param limit Max number of items to return
 * @returns
 */
function summarizeHighlights(
  highlights: SearchV2Highlights[],
  searchParams: SearchV2QueryParams,
  limit: number
): JSX.Element[] {
  const seenHighlights = new Set<string>();
  let displayedHighlights = new Array<JSX.Element>();
  for (let i = 0; i < highlights.length; i++) {
    if (displayedHighlights.length >= limit) {
      break;
    }
    const key = highlights[i].HTMLString + searchParams.term;
    if (seenHighlights.has(key)) {
      continue;
    }
    seenHighlights.add(key);
    // We use a non-content based key as sometimes the string cam be super long
    const reactKey = `$hl-${i}`;
    displayedHighlights.push(
      <li key={reactKey}>
        {formatHighlight(highlights[i].HTMLString, searchParams)}
      </li>
    );
  }

  return displayedHighlights;
}

/**
 * This dedupes and formats list of File search results
 * @param files
 * @param searchParams
 * @param limit Max number of items to return.
 * @returns
 */
function summarizeFiles(
  files: SearchV2File[] | undefined | null,
  searchParams: SearchV2QueryParams,
  limit: number
): JSX.Element[] {
  if (files === undefined || files === null || limit === 0) {
    return [];
  }

  if (!/^(?:sha256|md5)$/i.test(searchParams.field)) {
    return [];
  }

  const seen = new Set<string>();
  const displayed = new Array<JSX.Element>();
  for (let i = 0; i < files.length; i++) {
    if (displayed.length >= limit) {
      break;
    }

    const file = files[i];
    let text: string;
    let keyType: string = searchParams.field;

    if (
      searchParams.field === "sha256" &&
      file.SHA256.indexOf(searchParams.term.toLowerCase()) >= 0
    ) {
      text = file.SHA256;
    } else if (
      searchParams.field === "md5" &&
      file.MD5.indexOf(searchParams.term.toLowerCase()) >= 0
    ) {
      text = file.MD5;
    } else {
      // not showing it
      continue;
    }

    const key = text + keyType;
    if (seen.has(key)) {
      continue;
    }
    seen.add(key);

    displayed.push(
      <li key={key}>
        {file.Name.length === 0 ? (
          <div className="is-italic has-text-grey">(empty filename)</div>
        ) : (
          <Copyable data={file.Name}>{truncateMidStr(file.Name, 200)}</Copyable>
        )}
        <span className="is-size-7 nowrap">
          ({keyType.toUpperCase()}:{" "}
          <HighlightSubstring
            searchParams={searchParams}
            text={text}
            maxLength={200}
            charsBefore={20}
            charsAfter={20}
          />
          )
        </span>
      </li>
    );
  }

  return displayed;
}

/**
 * This dedupes and formats list of IP search results
 * @param ips
 * @param searchParams
 * @param limit Max number of items to return.
 * @returns
 */
function summarizeIps(
  ips: SearchV2IP[] | undefined | null,
  searchParams: SearchV2QueryParams,
  limit: number
): JSX.Element[] {
  if (ips === undefined || ips === null || limit === 0) {
    return [];
  }

  if (searchParams.field.toLowerCase() !== "ip") {
    return [];
  }

  let matcher;

  try {
    matcher = ipMatcher(searchParams.term);
  } catch (e) {
    return [];
  }

  const seen = new Set<string>();
  const displayed = new Array<JSX.Element>();
  for (let i = 0; i < ips.length; i++) {
    if (displayed.length >= limit) {
      break;
    }

    const ip = ips[i].IP;

    // Highlighting is tricky since something like a CIDR may have been given.
    // instead we just present the whole thing.
    if (seen.has(ip)) {
      continue;
    }
    seen.add(ip);

    try {
      if (matcher.matches(ip)) {
        displayed.push(
          <li key={ip}>
            <Copyable data={ip}>
              <span className="threat-name">{ip}</span>
            </Copyable>
          </li>
        );
      }
    } catch (e) {}
  }

  return displayed;
}

function summarizeTaskForensics(
  taskForensics: SearchV2IndexedTaskForensics[],
  searchParams: SearchV2QueryParams,
  displayedTasksLimit: number
): JSX.Element[] {
  if (
    taskForensics === undefined ||
    taskForensics === null ||
    taskForensics.length === 0
  ) {
    return [];
  }

  let summaries = summarizeFiles(
    taskForensics.flatMap((t) => t.Files ?? []),
    searchParams,
    displayedTasksLimit
  );

  if (summaries.length < displayedTasksLimit) {
    const ipSummaries = summarizeIps(
      taskForensics.flatMap((t) => t.IPs ?? []),
      searchParams,
      displayedTasksLimit - summaries.length
    );

    if (ipSummaries.length > 0) {
      summaries.push(...ipSummaries);
    }
  }

  return summaries;
}

const JobResultsTable = ({
  isLoading,
  results,
  searchParams,
}: {
  isLoading: boolean;
  results: SearchV2JobResult[];
  searchParams: SearchV2QueryParams;
}) => {
  const displayedHighlightsLimit = 5;
  const displayedTasksLimit = 5;

  // Prevent re-rendering of body except when results changes
  // else body re-renders while isLoading goes through states
  const body = useMemo(() => {
    return results.map((job, index) => {
      var {
        Job: {
          ID,
          Username,
          "@timestamp": CreatedAt,
          SubmittedURL,
          SubmittedFile,
          Files,
          Labels,
          APIKeyLabel,
          Tasks,
          Verdict,
          IPs,
          ResourceCount,
        },
        Highlights,
      } = job;

      if (!Highlights) {
        Highlights = [];
      }
      if (!Files) {
        Files = [];
      }
      if (!IPs) {
        IPs = [];
      }
      if (ResourceCount === undefined) {
        ResourceCount = 0;
      }

      const submissionName =
        SubmittedURL?.URL || SubmittedFile?.Name || "Unknown";

      // Get first N unique files
      const displayedTaskSummaries = summarizeFiles(
        Files,
        searchParams,
        displayedTasksLimit
      );

      const displayedIPSummaries = summarizeIps(
        IPs,
        searchParams,
        displayedTasksLimit
      );
      if (displayedIPSummaries.length > 0) {
        displayedTaskSummaries.push(...displayedIPSummaries);
      }

      // Get first N unique highlights
      const displayedHighlights = summarizeHighlights(
        Highlights,
        searchParams,
        displayedHighlightsLimit
      );

      const hasDisplayedDetails =
        (displayedTaskSummaries && displayedTaskSummaries.length > 0) ||
        (displayedHighlights && displayedHighlights.length > 0);

      return (
        <tr key={`${index}-${ID}`}>
          <td className="nowrap">
            {new Date(CreatedAt).toLocaleString()}
            <br />
            {Username || (APIKeyLabel && `API - ${APIKeyLabel}`) || "API"}
          </td>
          <td className="threat-name">
            <div className="treelist">
              <ul style={{ paddingLeft: 0 }}>
                <li className="root">
                  <Link
                    to={`/job/${ID}`}
                    title={submissionName}
                    className="joblink"
                  >
                    <ThreatName name={submissionName} />
                  </Link>
                  {searchParams.field === "md5" &&
                    SubmittedFile?.MD5 &&
                    SubmittedFile?.MD5.indexOf(searchParams.term) >= 0 && (
                      <span className="is-size-7 nowrap">
                        {" "}
                        (MD5:{" "}
                        <HighlightSubstring
                          searchParams={searchParams}
                          text={SubmittedFile.MD5}
                        />
                        )
                      </span>
                    )}
                  {searchParams.field === "sha256" &&
                    SubmittedFile?.SHA256 &&
                    SubmittedFile?.SHA256.indexOf(searchParams.term) >= 0 && (
                      <span className="is-size-7 nowrap">
                        {" "}
                        (SHA256:{" "}
                        <HighlightSubstring
                          searchParams={searchParams}
                          text={SubmittedFile?.SHA256}
                        />
                        )
                      </span>
                    )}
                  <LabelElement
                    inlineMode
                    labels={[
                      ...(Labels || []),
                      { Type: LabelType.Verdict, Value: Verdict },
                      ...enginesToLabels(Tasks.map((t) => t.EngineName)),
                    ]}
                  />
                </li>
                {hasDisplayedDetails && (
                  <ul>
                    {displayedHighlights}
                    {displayedHighlights.length > 0 &&
                      Highlights.length > displayedHighlightsLimit && (
                        <li>...</li>
                      )}
                    {displayedTaskSummaries}
                    {displayedTaskSummaries.length > 0 &&
                      Files.length > displayedTasksLimit && <li>...</li>}
                  </ul>
                )}
              </ul>
            </div>
          </td>
          <td className="has-text-centered">
            <span className="tag">{ResourceCount}</span>
          </td>
          <td className="has-text-centered">
            <Score score={job.Job.Score} />
          </td>
        </tr>
      );
    });
  }, [results, searchParams]);

  return (
    <table className={`table is-fullwidth ${isLoading ? "is-loading" : ""}`}>
      <thead>
        <tr>
          <th>Submitted</th>
          <th>Filename / URL</th>
          <th className="has-text-centered">Resources</th>
          <th className="has-text-centered">Score</th>
        </tr>
      </thead>
      <tbody>{body}</tbody>
    </table>
  );
};

const ForensicsResultsTable = ({
  isLoading,
  results,
  searchParams,
}: {
  isLoading: boolean;
  results: SearchV2ForensicsResult[];
  searchParams: SearchV2QueryParams;
}) => {
  const displayedHighlightsLimit = 5;
  const displayedTasksLimit = 5;

  // Prevent re-rendering of body except when results changes
  // else body re-renders while isLoading goes through states
  const body = useMemo(() => {
    return results.map((job, index) => {
      var {
        Forensics: {
          ID,
          Username,
          "@timestamp": CreatedAt,
          SubmittedURL,
          SubmittedFile,
          Labels,
          APIKeyLabel,
          TaskForensics,
          Verdict,
          ResourceCount,
        },
        Highlights,
      } = job;

      if (!Highlights) {
        Highlights = [];
      }
      if (ResourceCount === undefined) {
        ResourceCount = 0;
      }

      const submissionName =
        SubmittedURL?.URL || SubmittedFile?.Name || "Unknown";

      // Get first N unique highlights
      const displayedHighlights = summarizeHighlights(
        Highlights,
        searchParams,
        displayedHighlightsLimit
      );

      const displayedTaskSummaries = summarizeTaskForensics(
        TaskForensics,
        searchParams,
        displayedTasksLimit
      );

      const hasDisplayedDetails =
        (displayedTaskSummaries && displayedTaskSummaries.length > 0) ||
        (displayedHighlights && displayedHighlights.length > 0);

      return (
        <tr key={`${index}-${ID}`}>
          <td className="nowrap">
            {new Date(CreatedAt).toLocaleString()}
            <br />
            {Username || (APIKeyLabel && `API - ${APIKeyLabel}`) || "API"}
          </td>
          <td className="threat-name">
            <div className="treelist">
              <ul style={{ paddingLeft: 0 }}>
                <li className="root">
                  <Link
                    to={`/job/${ID}`}
                    title={submissionName}
                    className="joblink"
                  >
                    <ThreatName name={submissionName} />
                  </Link>
                  {searchParams.field === "md5" &&
                    SubmittedFile?.MD5 &&
                    SubmittedFile?.MD5.indexOf(
                      searchParams.term.toLowerCase()
                    ) >= 0 && (
                      <span className="is-size-7 nowrap">
                        {" "}
                        (MD5:{" "}
                        <HighlightSubstring
                          searchParams={searchParams}
                          text={SubmittedFile.MD5}
                        />
                        )
                      </span>
                    )}
                  {searchParams.field === "sha256" &&
                    SubmittedFile?.SHA256 &&
                    SubmittedFile?.SHA256.indexOf(
                      searchParams.term.toLowerCase()
                    ) >= 0 && (
                      <span className="is-size-7 nowrap">
                        {" "}
                        (SHA256:{" "}
                        <HighlightSubstring
                          searchParams={searchParams}
                          text={SubmittedFile?.SHA256}
                        />
                        )
                      </span>
                    )}
                  <LabelElement
                    inlineMode
                    labels={[
                      ...(Labels ?? []),
                      { Type: LabelType.Verdict, Value: Verdict },
                      ...enginesToLabels(
                        TaskForensics.map((t) => {
                          return t.Engine;
                        })
                      ),
                    ]}
                  />
                </li>
                {hasDisplayedDetails && (
                  <ul>
                    {displayedHighlights}
                    {displayedHighlights.length > 0 &&
                      Highlights.length > displayedHighlightsLimit && (
                        <li>...</li>
                      )}
                    {displayedTaskSummaries}
                    {displayedTaskSummaries.length > 0 &&
                      TaskForensics.length > displayedTasksLimit && (
                        <li>...</li>
                      )}
                  </ul>
                )}
              </ul>
            </div>
          </td>
          <td className="has-text-centered">
            <span className="tag">{ResourceCount}</span>
          </td>
          <td className="has-text-centered">
            <Score score={job.Forensics.Score} />
          </td>
        </tr>
      );
    });
  }, [results, searchParams]);

  return (
    <table className={`table is-fullwidth ${isLoading ? "is-loading" : ""}`}>
      <thead>
        <tr>
          <th>Submitted</th>
          <th>Filename / URL</th>
          <th className="has-text-centered">Resources</th>
          <th className="has-text-centered">Score</th>
        </tr>
      </thead>
      <tbody>{body}</tbody>
    </table>
  );
};

/**
 * Highlight a substring within the given text
 *
 * @param searchParams containing all the search context including substring to highlight and search type
 * @param text String in which to highlight `searchText`
 * @param maxLength Show up to X characters (if supplied and greater than zero)
 * @param charsBefore Number of characters to show before highlighted text if text is longer than maxLength
 * @param charsAfter Number of characters to show after highlighted text if text is longer than maxLength
 * @param copyable If true, add a copy component to the text (default: true)
 * @returns
 */
const HighlightSubstring = ({
  searchParams,
  text,
  maxLength,
  charsBefore,
  charsAfter,
  copyable,
}: {
  searchParams: SearchV2QueryParams;
  text: string;
  maxLength?: number;
  charsBefore?: number;
  charsAfter?: number;
  copyable?: boolean;
}) => {
  const searchText = searchParams.term;
  text = text.replaceAll(/[\u2026\u202e]/g, ""); // strip out unicode RTL characters
  const t = text.toLowerCase();
  const s = searchText.toLowerCase();
  // Special case: In case of ends with mode, the last occurrence of the substring which is the end of the string
  // should be highlighted
  const i = searchParams.type === "endswith" ? t.lastIndexOf(s) : t.indexOf(s);
  if (i < 0) {
    return <span>{text}</span>;
  }
  copyable = copyable ?? false;

  const doTrim =
    maxLength !== undefined && maxLength > 0 && text.length > maxLength;

  if (doTrim) {
    // We want to show the string in the form like:
    //  "{prefix}...{beforeChars}{substring}{afterChars}...{suffix}"
    //  "...{beforeChars}{substring}{afterChars}...""

    // Break into: {start}{marked}{end}
    let startSegment = text.slice(0, i);
    let markedSegment = text.slice(i, i + s.length);
    let endSegment = text.slice(i + s.length, t.length);

    // Now we want to apply ellipsis to start/end as needed.
    const middleEllipseThresh = 25; // How much longer a segment must be to apply the "{pre}...{post}" truncation
    const middleEllipseLength = middleEllipseThresh - 6; // How long to make {pre} or {post}; must be GT the thresh!

    if (
      charsBefore !== undefined &&
      charsBefore > 0 &&
      startSegment.length > charsBefore
    ) {
      // If length > (thresh+charsBefore), use form: "{prefix}...{beforeChars}"
      // Else, use form: ...{beforeChars}
      if (startSegment.length + middleEllipseThresh > charsBefore) {
        const before = startSegment.slice(0, middleEllipseLength);
        const after = startSegment.slice(startSegment.length - charsBefore);
        startSegment = `${before}...${after}`;
      } else {
        startSegment = `...${startSegment.slice(
          startSegment.length - charsBefore
        )}`;
      }
    }
    if (
      charsAfter !== undefined &&
      charsAfter > 0 &&
      endSegment.length > charsAfter
    ) {
      // If length > (thresh+charsAfter), use form: "{afterChars}...{suffix}""
      // Else, use form: {afterChars}...
      if (endSegment.length + middleEllipseThresh > charsAfter) {
        const before = endSegment.slice(0, charsAfter);
        const after = endSegment.slice(endSegment.length - middleEllipseLength);
        endSegment = `${before}...${after}`;
      } else {
        endSegment = `${endSegment.slice(0, charsAfter)}...`;
      }
    }

    const disp = (
      <span className="threat-name">
        {startSegment}
        <mark>{markedSegment}</mark>
        {endSegment}
      </span>
    );

    if (copyable) {
      return <Copyable data={text}>{disp}</Copyable>;
    } else {
      return disp;
    }
  }

  const disp = (
    <span className="threat-name">
      {text.slice(0, i)}
      <mark>{text.slice(i, i + s.length)}</mark>
      {text.slice(i + s.length, t.length)}
    </span>
  );

  if (copyable) {
    return <Copyable data={text}>{disp}</Copyable>;
  } else {
    return disp;
  }
};

function formatHighlight(
  htmlString: string,
  searchParams: SearchV2QueryParams
): JSX.Element {
  const searchTerm = searchParams.term;
  const parts = htmlString.split(/<\/?em>/);
  let strippedString = parts.join("");

  if (strippedString.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0) {
    return (
      <HighlightSubstring
        searchParams={searchParams}
        text={strippedString}
        maxLength={250}
        charsBefore={30}
        charsAfter={30}
        copyable={true}
      />
    );
  }

  let tags = [];
  for (let i = 0; i < parts.length; i++) {
    const dispValue = (parts[i] = truncateMidStr(parts[i], 200));
    if (i % 2 === 0) {
      tags.push(<>{dispValue}</>);
    } else {
      tags.push(<mark>{dispValue}</mark>);
    }
  }

  return <>{tags}</>;
}
