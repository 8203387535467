import React from "react";
import { taskDisplayName } from "../utils/DisplayNames";
import { DetectionBox } from "../forensics/Detections";
import { TaskProperties } from "./TaskTypes";
import { ResourceSummary } from "../ResourceSummary";
import { LoadingMessage } from "./LoadingMessage";

export const WildFireResults = ({
  job,
  task,
  resource,
  forensics,
}: TaskProperties) => {
  const details = task.Results.Details || {};

  // URL case
  if (!forensics && details.Verdict === "not_known_to_be_malicious") {
    return (
      <section>
        <ResourceSummary job={job} task={task} resource={resource} />
        URL is not known to be malicious by {taskDisplayName("wildfire")}
      </section>
    );
  }

  if (forensics === "loading") {
    return (
      <section>
        <ResourceSummary job={job} task={task} resource={resource} />
        <LoadingMessage />
      </section>
    );
  }

  return (
    <section>
      <ResourceSummary job={job} task={task} resource={resource} />
      <b>Analysis Platform:</b> {details.Software} - {details.Package}
      {(forensics?.Detections?.length ?? 0) > 0 && (
        <DetectionBox detections={forensics?.Detections} />
      )}
    </section>
  );
};
