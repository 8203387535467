import { Job } from "src/lib/APITypes";
import { toSplunkLogLink } from "src/lib/Otel";
import * as Menu from "./Menu";
import * as Modal from "./Modal";

export const DebugInfo = ({ job }: { job: Job }) => {
  const cacheStats = {
    total: 0,
    fuzzy: 0,
  };

  let hasTruncatedTasks: boolean = false;

  for (const t of job.Tasks || []) {
    if (t?.Results?.Details?.Cache) {
      cacheStats.total += 1;
      cacheStats.fuzzy += t.Results.Details.Cache.IsExact === "false" ? 1 : 0;
    }

    if (t?.StateText === "truncated") {
      hasTruncatedTasks = true;
    }
  }

  return (
    <Menu.Item text="Debug Info" icon={["far", "bug"]} permission="app:admin">
      <Modal.Card title="Debug Info">
        <Modal.CardBody>
          <table>
            <tbody>
              <tr>
                <td>ID</td>
                <td>{job.ID}</td>
              </tr>
              <tr>
                <td>Logs</td>
                <td>
                  <p>
                    <a href={toSplunkLogLink(job)}>
                      Splunk Logs (VPN Required)
                    </a>
                  </p>
                </td>
              </tr>
              <tr>
                <td>Tenant</td>
                <td>{job.TenantID}</td>
              </tr>
              <tr>
                <td>Submitted By</td>
                <td>
                  {job.Username ||
                    `API - ${job.APIKey?.Label} (${job.APIKey?.ID})`}
                </td>
              </tr>
              <tr>
                <td>Profile</td>
                <td>{job.Profile || "-"}</td>
              </tr>
              <tr>
                <td>Engines</td>
                <td>
                  <ul style={{ padding: 0, margin: 0, listStyle: "none" }}>
                    {(job.RequestedEngines || ["default"]).map((e) => (
                      <li key={e}>{e}</li>
                    ))}
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Contains Cached Items</td>
                <td>
                  {cacheStats.total ? (
                    <strong>
                      YES -{" "}
                      {cacheStats.fuzzy
                        ? "includes FUZZY hits"
                        : "no fuzzy hits"}{" "}
                    </strong>
                  ) : (
                    "no"
                  )}
                </td>
              </tr>
              <tr>
                <td>Contains Truncated Tasks</td>
                <td>{hasTruncatedTasks ? <strong>YES</strong> : "no"}</td>
              </tr>
              <tr>
                <td>Parameters</td>
                <td>
                  <table>
                    <thead>
                      <tr>
                        <th style={{ padding: 0 }}>Name</th>
                        <th style={{ padding: 0 }}>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(job.Parameters || []).map(({ Name, Value }) => (
                        <tr key={`${Name}:${Value}`} style={{ padding: 0 }}>
                          <td style={{ padding: 0 }}>{Name}</td>
                          <td style={{ padding: 0 }}>{Value}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td>WA Network Egresses</td>
                <td>
                  <table>
                    <thead>
                      <tr>
                        <th style={{ padding: 0 }}>Public IP</th>
                        <th style={{ padding: 0 }}>Info</th>
                      </tr>
                    </thead>
                    <tbody>
                      {stableConsolidateEgresses(job).map(
                        ({
                          PublicIP,
                          Region,
                          Config,
                          Endpoint,
                          UserAgent,
                          UserAgentAlias,
                        }) => (
                          <tr
                            key={`${PublicIP}:${Region}:${Endpoint}`}
                            style={{ padding: 0 }}
                          >
                            <td style={{ padding: 0 }}>{PublicIP}</td>
                            <td style={{ padding: 0 }}>
                              <ul
                                style={{
                                  padding: 0,
                                  margin: 0,
                                  listStyle: "none",
                                }}
                              >
                                <li
                                  style={{
                                    padding: 0,
                                    maxWidth: 250,
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {Region}
                                </li>
                                <li
                                  style={{
                                    padding: 0,
                                    maxWidth: 250,
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {Config}
                                </li>
                                <li
                                  style={{
                                    padding: 0,
                                    maxWidth: 250,
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {Endpoint}
                                </li>
                                <li
                                  style={{
                                    padding: 0,
                                    maxWidth: 250,
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {UserAgentAlias}
                                </li>
                                <li
                                  style={{
                                    padding: 0,
                                    maxWidth: 250,
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {UserAgent}
                                </li>
                              </ul>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.CardBody>
        <Modal.CardFooter>
          <Modal.CardCloseButton text="Close" />
        </Modal.CardFooter>
      </Modal.Card>
    </Menu.Item>
  );
};

const stableConsolidateEgresses = (job: Job) => {
  const hashToEntry = (job.Tasks || [])
    .filter((t) => t?.Results?.Details?.NetworkEgress)
    .map<{
      PublicIP: string;
      Region: string;
      Endpoint: string;
      UserAgent: string;
      UserAgentAlias: string;
    }>((t) => t.Results.Details.NetworkEgress)
    .reduce<
      Record<
        string,
        {
          PublicIP: string;
          Region: string;
          Endpoint: string;
          UserAgent: string;
          UserAgentAlias: string;
        }
      >
    >((acc, c) => {
      const key =
        encodeURIComponent(c.Endpoint) +
        "|" +
        encodeURIComponent(c.Region) +
        "|" +
        encodeURIComponent(c.PublicIP) +
        "|" +
        encodeURIComponent(c.UserAgent) +
        "|" +
        encodeURIComponent(c.UserAgentAlias);

      acc[key] = c;
      return acc;
    }, {});

  const out: {
    PublicIP: string;
    Region: string;
    Endpoint: string;
    Config: string;
    UserAgent: string;
    UserAgentAlias: string;
  }[] = [];
  for (const hash of Object.keys(hashToEntry).sort()) {
    const [, , Config] = hashToEntry[hash].Endpoint.split(".");
    out.push({ ...hashToEntry[hash], Config });
  }

  return out;
};
