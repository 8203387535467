export const ErrorWrap = (props: {
  children: React.ReactNode;
  hasError?: boolean;
  content?: JSX.Element;
}) => {
  if (props.hasError) {
    let content = (
      <>
        <p>An error has occurred.</p>
        <p>
          <b>Reloading the application window may resolve this issue.</b>
        </p>
      </>
    );

    if (props.content) {
      content = props.content;
    }

    return (
      <div className="section columns">
        <div className="column is-half is-offset-one-quarter">
          <div className="message is-danger">
            <div className="message-header">Error</div>
            <div className="message-body">{content}</div>
          </div>
        </div>
      </div>
    );
  }

  return <>{props.children}</>;
};
