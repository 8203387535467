import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mimeWordsDecode } from "emailjs-mime-codec";
import { useEffect, useState } from "react";
import { Task } from "../../lib/APITypes";
import { CaveatCard } from "../Caveats";
import { FileSize } from "../FileSize";
import { DetectionBox } from "../forensics/Detections";
import { ForensicsScreenshots } from "../forensics/ForensicsScreenshots";
import { ResourceSummary } from "../ResourceSummary";
import { EmailHeader, parseHeaders } from "../utils/EmailUtils";
import { ExpandableBox } from "../utils/ExpandableBox";
import { MultilineString } from "../utils/MultilineString";
import { Tab, Tabs } from "../utils/Tabs";
import { ThreatName } from "../utils/ThreatName";
import { TaskProperties } from "./TaskTypes";

export const EmlAnalyzerResults = ({
  job,
  task,
  resource,
  forensics,
}: TaskProperties) => {
  const hdrs = task.Results.Details.Headers || {};
  const attachments: any[] = task.Results.Details.Attachments || [];

  if (forensics === "loading" || !forensics) {
    return <div className="has-text-info">Loading...</div>;
  }

  let textParts = forensics.Strings || [];
  textParts = textParts.filter((s) => s.Details?.Source === "email_text_part");

  let urls = forensics.URLs || [];
  urls.sort((a, b) => (a.URL > b.URL ? 1 : -1));

  let rawHeaders =
    forensics.Strings?.filter((s) => s.Details?.Source === "email_headers").map(
      (s) => s.String
    ) || [];

  // The headers we get from eml-analyzer are wrapped with non-consistent whitespace
  // due to how python unfolds headers for you.
  let parsedHeaders = rawHeaders.length > 0 ? parseHeaders(rawHeaders[0]) : [];

  const hasImages =
    forensics.Screenshots?.length > 0 || (forensics.Images?.length ?? 0) > 0;

  return (
    <section>
      <ResourceSummary job={job} task={task} resource={resource} />
      {task.Results.Details.Caveats && (
        <CaveatCard
          caveats={task.Results.Details.Caveats}
          engine="eml_analyzer"
        />
      )}

      {(forensics.Detections?.length ?? 0) > 0 && (
        <DetectionBox detections={forensics.Detections} maxHeight="30rem" />
      )}

      <ExpandableBox title="Common Message Headers">
        <table
          className="table"
          style={{ tableLayout: "fixed", width: "100%" }}
        >
          <tbody>
            <EmlHeader
              name="Message-ID"
              value={hdrs["Message-ID"] || hdrs["Message-Id"]}
            />
            <EmlHeader name="Created At" value={hdrs["Date"]} />
            <EmlHeader name="From" value={hdrs["From"]} />
            <EmlHeader name="To" value={hdrs["To"]} />
            <EmlHeader name="Subject" value={hdrs["Subject"]} />
          </tbody>
        </table>
      </ExpandableBox>

      <section className="has-margin-top-50">
        <Tabs>
          {hasImages ? (
            <Tab label="Images">
              <ForensicsScreenshots forensics={forensics} />
            </Tab>
          ) : null}

          <Tab label="Message Headers">
            <AllEmailHeaders parsedHeaders={parsedHeaders} />
          </Tab>

          <Tab label={`Message Text Parts (${textParts.length})`}>
            {textParts.map((s, idx) => (
              <TextPart key={idx} idx={idx + 1}>
                {s.String}
              </TextPart>
            ))}
          </Tab>
          <Tab label={`Attachments (${attachments.length})`}>
            {attachments.map((a) => (
              <Attachment file={a} key={a.SHA256} jobid={task.ID} task={task} />
            ))}
          </Tab>
          <Tab label={`URLs (${urls.length})`}>
            <table className="table">
              <tbody>
                {urls.map((u, idx) => (
                  <tr key={idx}>
                    <td>
                      <ThreatName name={u.URL} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Tab>
        </Tabs>
      </section>
    </section>
  );
};

const EmlHeader = ({ name, value }: { name: string; value: string }) => {
  return (
    <tr>
      <th className="nowrap" style={{ width: "8rem" }}>
        {name}:
      </th>
      <td>
        {value ? (
          <MultilineString
            content={mimeWordsDecode(value)}
            classNames={["email-header"]}
          />
        ) : (
          "-"
        )}
      </td>
    </tr>
  );
};

const Attachment = ({
  file,
  jobid,
  task,
}: {
  file: any;
  jobid: string;
  task: Task;
}) => {
  return (
    <div className="card has-margin-bottom-20">
      <header className="card-header">
        <p className="card-header-title wrap-anywhere">{file.Filename}</p>
      </header>
      <div className="card-content">
        <div className="content">
          <div>
            <b>Claimed File Type:</b> {file.ContentType || "unknown"}
          </div>
          <div>
            <b>Detected File Type:</b> {file.MimeType || "unknown"}
          </div>
          <div>
            <b>SHA256 Hash:</b> {file.SHA256 || "NA"}
          </div>
          <div>
            <b>MD5 Hash:</b> {file.MD5 || "NA"}
          </div>
          <div>
            <b>File Size:</b> <FileSize size={file.Size} />
            {file.size > 50 * 1024 * 1024 && (
              <span className="has-text-danger">
                {" "}
                <FontAwesomeIcon icon="exclamation-triangle" /> File is too
                large for additional analysis
              </span>
            )}
          </div>
          {file.MimeType.startsWith("image/") && (
            <div className="has-text-info">
              <FontAwesomeIcon icon={["far", "info-circle"]} /> Image attachment
              not sent for further analysis
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// Filter options to present
// v - form internal value
// label - visible label
// pattern - filter pattern
const headerOptions = [
  { v: "all", label: "All", pattern: /.*/ },
  {
    v: "common",
    label: "Common",
    pattern: /^(?:from|to|cc|reply-to|subject|date|message-id|delivered-to)$/i,
  },
  {
    v: "senderrecip",
    label: "Sender / Recipient",
    pattern:
      /^(?:from|to|cc|reply-to|return-path|delivered-to|x-originating-ip|sender)$/i,
  },
  { v: "route", label: "Route", pattern: /^(?:received|x-received)$/i },
  {
    v: "auth",
    label: "Sender Auth",
    pattern:
      /^(?:received-spf|authentication-results(?:-original)?|dkim-signature|arc-seal|arc-message-signature|arc-authentication-results|x-ms-exchange-authentication-results)$/i,
  },
  { v: "vendor", label: "Vendor Specific", pattern: /^x-/i },
];
const headerOptionToFilterMap = new Map<string, RegExp>(
  headerOptions.map((entry) => [entry.v, entry.pattern])
);
const headerOptionToFilter = (selected: string): RegExp => {
  return headerOptionToFilterMap.get(selected) ?? headerOptions[0].pattern;
};

type HeaderSortOrder = "original" | "name";

// Sort array in place (returns the sorted array for ease).
const sortHeadersBy = (
  headers: EmailHeader[],
  sortbyfield: HeaderSortOrder
): EmailHeader[] => {
  switch (sortbyfield) {
    case "original":
      return headers.sort((a, b) => {
        return a.Index - b.Index;
      });
    case "name":
      return headers.sort((a, b) => {
        const cmp = a.Name.localeCompare(b.Name);
        if (cmp === 0) {
          // Fallback on ordinal order
          return a.Index - b.Index;
        }
        return cmp;
      });
    default:
      // oops
      return headers;
  }
};

const AllEmailHeaders = ({
  parsedHeaders,
  defaultSelected = "all",
  defaultSortBy = "original",
}: {
  parsedHeaders: EmailHeader[];
  defaultSelected?: string;
  defaultSortBy?: HeaderSortOrder;
}) => {
  const [selectedGroup, setSelectedGroup] = useState(defaultSelected);
  const [sortBy, setSortBy] = useState(defaultSortBy);

  const [selectedHeaders, setSelectedHeaders] = useState(parsedHeaders);

  useEffect(() => {
    const newFilter = headerOptionToFilter(selectedGroup);

    if (selectedGroup === "all") {
      // We make a copy of the array first as sorting is in place
      const sorted = sortHeadersBy([...parsedHeaders], sortBy);
      setSelectedHeaders(sorted);
    } else {
      // No copy needed as filter does it for us.
      const filtered = parsedHeaders.filter((x) => newFilter.test(x.Name));
      setSelectedHeaders(sortHeadersBy(filtered, sortBy));
    }
  }, [selectedGroup, sortBy, parsedHeaders]);

  return (
    <div>
      {parsedHeaders.length > 0 && (
        <nav className="level">
          <div className="level-left">
            <div className="level-item">
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label htmlFor="header-group" className="label">
                    Show:
                  </label>
                </div>
                <div className="field-body select">
                  <select
                    id="header-group"
                    onChange={(ev) => {
                      ev.preventDefault();
                      setSelectedGroup(ev.target.value);
                    }}
                  >
                    {headerOptions.map((o, idx) => {
                      return (
                        <option key={idx} value={o.v}>
                          {o.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            <div className="level-item">
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label htmlFor="header-sortby" className="label">
                    Sort&nbsp;By:
                  </label>
                </div>
                <div className="field-body select">
                  <select
                    id="header-sortby"
                    onChange={(ev) => {
                      ev.preventDefault();
                      setSortBy(ev.target.value as HeaderSortOrder);
                    }}
                  >
                    <option value="original">Original Order</option>
                    <option value="name">Header Name</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </nav>
      )}
      <table
        className="table is-fullwidth is-hoverable"
        style={{ tableLayout: "fixed", width: "100%" }}
      >
        <thead>
          <tr>
            <th style={{ width: "16rem" }}>Header</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {selectedHeaders.map((s, idx) => (
            <tr key={idx} className="email-header">
              <th>{s.Name}</th>
              <td>{s.Value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const TextPart = ({ children, idx }: { children: any; idx: any }) => (
  <div className="card has-margin-bottom-20">
    <header className="card-header">
      <p className="card-header-title">Message Text Part {idx}</p>
    </header>
    <div className="card-content">
      <div className="email-body">{children}</div>
    </div>
  </div>
);
