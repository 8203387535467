import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDoubleRight,
  faAngleDoubleDown,
  faAngleDown,
  faAngleUp,
  faBook,
  faBrowser,
  faInfoCircle,
  faLock as faLockRegular,
  faRoute,
  faSearch,
  faStopwatch,
  faUserCrown,
  faCopy,
  faFile,
  faWindowClose,
  faProjectDiagram,
  faFish,
  faGlobe,
  faFilePdf,
  faFileArchive,
  faFileCode,
  faFileImage,
  faFileAlt,
  faBars,
  faEnvelope,
  faFlag,
  faRedo,
  faTrashAlt,
  faShareSquare,
  faScroll,
  faBug,
  faExternalLink,
  faPlus,
  faStar,
  faCubes,
  faListTree,
  faSparkles,
  faVideo,
  faDiagramSankey,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faAlarmExclamation,
  faBell,
  faCamera,
  faCheckCircle,
  faCheckSquare,
  faClipboardList,
  faCloudDownload,
  faDownload,
  faEdit,
  faEnvelopeOpen,
  faEnvelopeOpenText,
  faExclamationTriangle,
  faFingerprint,
  faHourglassHalf,
  faLock,
  faObjectUngroup,
  faCode,
  faTimes,
  faMinusSquare,
  faPaperclip,
  faPlusSquare,
  faQuestionCircle,
  faRunning,
  faShare,
  faSpinner,
  faSteak,
  faStream,
  faUpload,
  faChevronRight,
  faVirus,
  faFishCooked,
  faDumpster,
  faTrash,
  faCameraRetro,
  faExclamationCircle,
  faStar as faStarFilled,
} from "@fortawesome/pro-solid-svg-icons";

import { faDna } from "@fortawesome/pro-duotone-svg-icons";

export const loadIconLibrary = () => {
  library.add(
    faAlarmExclamation,
    faAngleDoubleRight,
    faAngleDoubleDown,
    faAngleDown,
    faAngleUp,
    faBell,
    faBook,
    faBrowser,
    faCamera,
    faCheckCircle,
    faCheckSquare,
    faClipboardList,
    faCloudDownload,
    faDownload,
    faEdit,
    faEnvelopeOpen,
    faEnvelopeOpenText,
    faExclamationTriangle,
    faFingerprint,
    faCode,
    faTimes,
    faHourglassHalf,
    faInfoCircle,
    faLock,
    faLockRegular,
    faMinusSquare,
    faObjectUngroup,
    faPaperclip,
    faPlusSquare,
    faQuestionCircle,
    faRedo,
    faRoute,
    faRunning,
    faSearch,
    faShare,
    faSpinner,
    faSteak,
    faStopwatch,
    faStream,
    faUpload,
    faUserCrown,
    faCopy,
    faFile,
    faWindowClose,
    faProjectDiagram,
    faFish,
    faDna,
    faGlobe,
    faFilePdf,
    faFileArchive,
    faFileCode,
    faFileImage,
    faFileAlt,
    faBars,
    faEnvelope,
    faChevronRight,
    faExclamationCircle,
    faFlag,
    faTrashAlt,
    faShareSquare,
    faScroll,
    faBug,
    faVirus,
    faFishCooked,
    faDumpster,
    faExternalLink,
    faPlus,
    faTrash,
    faCameraRetro,
    faStar,
    faStarFilled,
    faCubes,
    faListTree,
    faSparkles,
    faVideo,
    faDiagramSankey
  );
};
