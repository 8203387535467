import SplunkRum from "@splunk/otel-web";
import React from "react";

export default class ErrorHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errorOccurred: false };
  }

  componentDidCatch(error, info) {
    this.setState({ errorOccurred: true });
    SplunkRum.error(error, { info: info });
  }

  render() {
    return this.state.errorOccurred ? (
      <section className="hero is-large is-info">
        <div className="hero-body">
          <div className="container">
            <h1 className="title">We’re sorry, something went wrong</h1>
            <h2 className="subtitle">
              The Attack Analyzer team has been notified of the error and will
              investigate. In the mean time, please try reloading the page or
              using your back button.
            </h2>
          </div>
        </div>
      </section>
    ) : (
      this.props.children
    );
  }
}

export class ErrorHider extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    return this.state.hasError ? <></> : this.props.children;
  }
}
