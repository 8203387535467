import { useParams } from "react-router";
import { useArtifactHook } from "src/lib/API";
import { Job, Task } from "src/lib/APITypes";

export const DebugLogLink = (job: Job, task: Task) => {
  if (job && task)
    return `/logs/${new Date(job.CreatedAt).toISOString().split("T")[0]}/${
      job.TenantID
    }/${job.ID}/${task.Results?.Details?.RootTaskID || task.ID}/debug.log.gz`;

  return "";
};

export const LogsPage = () => {
  const params = useParams();
  const path = params["*"];

  const { contents, isLoading } = useArtifactHook(path);

  if (isLoading) return <p>loading…</p>;
  if (!isLoading && !contents)
    return <p>no logs available. check the console for errors</p>;

  return <pre>{contents}</pre>;
};
