//@ts-ignore
import Tooltip from "@cypress/react-tooltip";
import React, { useContext, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  ArtifactCommon,
  File,
  Form,
  Host,
  Job,
  MitreAttack,
  Mutex,
  Network,
  NormalizedForensics,
  RegistryKey,
  Resource,
  SavedArtifact,
  Task,
  URL,
} from "src/lib/APITypes";
import { APIContext } from "src/lib/MAPApi";
import { DetectionTable } from "./forensics/Detections";
import { ForensicsScreenshots } from "./forensics/ForensicsScreenshots";
import { ForensicsString } from "./forensics/ForensicsString";
import { HTTPForensics } from "./forensics/HTTP";
import { MalwareConfigDisplay } from "./forensics/MalwareConfigDisplay";
import { ProcessList } from "./forensics/ProcessList";
import { ProcessTree } from "./forensics/ProcessTree";
import { WhoisResult } from "./forensics/WhoisResult";
import { ActionConfig, ResourceSummary } from "./ResourceSummary";
import { LoadingMessage } from "./tasks/LoadingMessage";
import { taskDisplayName } from "./utils/DisplayNames";
import { Expandable } from "./utils/Expandable";
import { ExpandableBox } from "./utils/ExpandableBox";
import { Tab, Tabs } from "./utils/Tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileViewer } from "./HexViewer";
import { saveAsJson } from "src/lib/Utils";
import { SourceCodeString } from "./forensics/SourceCodeString";
import { toast } from "react-toastify";

const getEngines = (v: ArtifactCommon) => {
  try {
    return v.Engines.map((e) => <p key={e}>{taskDisplayName(e)}</p>);
  } catch (err) {
    return null;
  }
};

const engineCount = (v: ArtifactCommon) => {
  try {
    return v.Engines.length;
  } catch (err) {
    return 0;
  }
};

export const MitreLink = ({ id }: { id: string }) => {
  const match = id.match(/^(?<technique>T\d+)(\.(?<subtechnique>\d+))?$/);
  if (match) {
    let url = `https://attack.mitre.org/techniques/${match.groups?.technique}/`;
    if (match.groups?.subtechnique) {
      url += `${match.groups.subtechnique}/`;
    }
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {id}
      </a>
    );
  }

  return <>{id}</>;
};

export const NormalizedForensicsComponent = ({
  job,
  resource,
  forensics,
  consolidated,
  task,
}: {
  job: Job;
  resource: Resource;
  forensics: NormalizedForensics | "loading" | null;
  consolidated: boolean;
  task?: Task;
}) => {
  let [allExpanded, setAllExpanded] = useState(false);

  if (forensics == null) {
    return (
      <section>
        <ResourceSummary
          job={job}
          resource={resource}
          task={task}
          forensics={forensics}
        />
        <div>No forensics available for {taskDisplayName(task?.Engine)}</div>
      </section>
    );
  }

  if (forensics === "loading") {
    return <LoadingMessage />;
  }

  const detections = forensics.Detections || [];

  const hosts = forensics.Hosts || [];
  hosts.sort((a, b) => {
    let ediff = engineCount(b) - engineCount(a);
    if (ediff !== 0) {
      return ediff;
    }

    if (a.Hostname !== b.Hostname) {
      return (a.Hostname || "\xff") > (b.Hostname || "\xff") ? 1 : -1;
    }

    return (a.IP || "\xff") > (b.IP || "\xff") ? 1 : -1;
  });

  const connections = forensics.Network || [];
  connections.sort((a, b) => {
    let ediff = engineCount(b) - engineCount(a);
    if (ediff !== 0) {
      return ediff;
    }

    if (a.Destination.IP !== b.Destination.IP) {
      return a.Destination.IP > b.Destination.IP ? 1 : -1;
    }
    if (a.Destination.Port !== b.Destination.Port) {
      return a.Destination.Port > b.Destination.Port ? 1 : -1;
    }
    if (a.Source.IP !== b.Source.IP) {
      return a.Source.IP > b.Source.IP ? 1 : -1;
    }
    return a.Source.Port > b.Source.Port ? 1 : -1;
  });

  const http = forensics.HTTP || [];

  const urls = forensics.URLs || [];
  urls.sort(
    (a, b) => engineCount(b) - engineCount(a) || (a.URL > b.URL ? 1 : -1)
  );

  const forms = forensics.Forms || [];
  forms.sort((a, b) => ((a.Action || "") > (b.Action || "") ? 1 : -1));

  const whois = forensics.WhoisResults || [];
  whois.sort((a, b) => (a.DomainName > b.DomainName ? 1 : -1));

  const files = forensics.Files || [];
  files.sort(
    (a, b) =>
      engineCount(b) - engineCount(a) ||
      (a.Path.toLowerCase() > b.Path.toLowerCase() ? 1 : -1)
  );

  const processes = forensics.Processes || [];
  processes.sort(
    (a, b) =>
      engineCount(b) - engineCount(a) ||
      (a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1)
  );

  const regkeys = forensics.RegistryKeys || [];
  regkeys.sort(
    (a, b) =>
      engineCount(b) - engineCount(a) ||
      (a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1)
  );

  const mutexes = forensics.Mutexes || [];
  mutexes.sort(
    (a, b) =>
      engineCount(b) - engineCount(a) ||
      (a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1)
  );

  const mitreattacks = forensics.MitreAttacks || [];
  mitreattacks.sort((a, b) => {
    let ediff = engineCount(b) - engineCount(a);
    if (ediff !== 0) {
      return ediff;
    }
    if (a.ID !== b.ID) {
      return a.ID > b.ID ? 1 : -1;
    }

    if (a.Technique !== b.Technique) {
      return a.Technique > b.Technique ? 1 : -1;
    }

    return a.Tactic > b.Tactic ? 1 : -1;
  });

  const configs = forensics.MalwareConfigs || [];

  const strings = forensics.Strings || [];
  const macros = strings.filter(
    (s) => s.Details && s.Details.Source === "olevba_vbasrc"
  );
  const ocrStrings = strings.filter(
    (s) =>
      s.Details &&
      s.Details.Source &&
      (s.Details.Source.match(/tika_embedded/) || s.Details.Type === "ocr")
  );

  const images = forensics.Images ?? forensics.Screenshots ?? [];

  let savedArtifacts = new Map();
  for (let sa of forensics.SavedArtifacts || []) {
    savedArtifacts.set(sa.Name, sa);
  }

  const detectionCount = detections.length + mitreattacks.length;
  const networkCount =
    hosts.length +
    connections.length +
    http.length +
    urls.length +
    whois.length +
    forms.length;
  const systemCount =
    files.length + processes.length + regkeys.length + mutexes.length;

  let stringKey = 0;

  const expandAllButton = (
    <div>
      {!allExpanded && (
        <button
          className="button is-text is-size-7 has-text-link"
          onClick={() => setAllExpanded(true)}
        >
          Expand All
        </button>
      )}
      {allExpanded && (
        <button
          className="button is-text is-size-7 has-text-link"
          onClick={() => setAllExpanded(false)}
        >
          Collapse All
        </button>
      )}
    </div>
  );

  const detectionBlock = (
    <section>
      <ExpandableBox
        title={`Detections (${detections.length})`}
        maxHeight={allExpanded ? "none" : "25rem"}
      >
        {detections.length > 0 ? (
          <DetectionTable
            detections={detections}
            isConsolidated={consolidated}
            expanded={allExpanded}
          />
        ) : null}
      </ExpandableBox>

      <ExpandableBox
        title={`Mitre ATT&CK (${mitreattacks.length})`}
        maxHeight={allExpanded ? "none" : "25rem"}
      >
        {mitreattacks.length > 0 ? (
          <table className="table">
            <thead>
              <tr>
                {consolidated ? <th>Engines</th> : null}
                <th>ID</th>
                <th>Technique</th>
                <th>Sub Technique</th>
                <th>Tactic</th>
              </tr>
            </thead>
            <tbody>
              {mitreattacks.map((m) => (
                <MitreAttackComponent
                  value={m}
                  key={m.ID + m.Technique + m.Tactic + m.SubTechnique}
                />
              ))}
            </tbody>
          </table>
        ) : null}
      </ExpandableBox>
    </section>
  );

  const networkBlock = (
    <section>
      <ExpandableBox
        title={`Hosts (${hosts.length})`}
        maxHeight={allExpanded ? "none" : "25rem"}
      >
        {hosts.length > 0 && (
          <table className="table">
            <thead>
              <tr>
                {consolidated ? <th>Engines</th> : null}
                <th>Hostname</th>
                <th>IP Address</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {hosts.map((h) => (
                <HostComponent host={h} key={h.Hostname + h.IP} />
              ))}
            </tbody>
          </table>
        )}
      </ExpandableBox>

      <ExpandableBox
        title={`Connections (${connections.length})`}
        maxHeight={allExpanded ? "none" : "25rem"}
      >
        {connections.length > 0 && (
          <table className="table">
            <thead>
              <tr>
                {consolidated ? <th>Engines</th> : null}
                <th>Protocol</th>
                <th>Service</th>
                <th>Source IP</th>
                <th>Source Port</th>
                <th>Dest IP</th>
                <th>Dest Port</th>
                <th>Bytes</th>
              </tr>
            </thead>
            <tbody>
              {connections.map((c) => (
                <ConnectionComponent
                  value={c}
                  key={`${c.Source.IP}:${c.Source.Port}>${c.Destination.IP}:${c.Destination.Port}`}
                />
              ))}
            </tbody>
          </table>
        )}
      </ExpandableBox>

      <ExpandableBox
        title={`HTTP (${http.length})`}
        maxHeight={allExpanded ? "none" : "25rem"}
      >
        {http.length > 0 && (
          <HTTPForensics
            data={http}
            consolidated={consolidated}
            expanded={allExpanded}
          />
        )}
      </ExpandableBox>

      <ExpandableBox
        title={`URLs (${urls.length})`}
        maxHeight={allExpanded ? "none" : "25rem"}
      >
        {urls.length > 0 && (
          <table className="table">
            <thead>
              <tr>
                {consolidated ? <th>Engines</th> : null}
                <th>URL</th>
              </tr>
            </thead>
            <tbody>
              {urls.map((u) => (
                <URLComponent value={u} key={u.URL} />
              ))}
            </tbody>
          </table>
        )}
      </ExpandableBox>

      <ExpandableBox
        title={`Whois Results (${whois.length})`}
        maxHeight={allExpanded ? "none" : "25rem"}
      >
        {whois.length > 0 && (
          <table className="table">
            <tbody>
              {whois.map((w, idx) => (
                <WhoisResult value={w} key={idx} />
              ))}
            </tbody>
          </table>
        )}
      </ExpandableBox>

      <ExpandableBox
        title={`Forms (${forms.length})`}
        maxHeight={allExpanded ? "none" : "25rem"}
      >
        {forms.length > 0 && (
          <table className="table">
            <tbody>
              {forms.map((f, idx) => (
                <FormComponent value={f} key={f.Action || idx} />
              ))}
            </tbody>
          </table>
        )}
      </ExpandableBox>
    </section>
  );

  const systemBlock = (
    <section>
      <ExpandableBox
        title={`Files (${files.length})`}
        maxHeight={allExpanded ? "none" : "25rem"}
      >
        {files.length > 0 && (
          <table className="table">
            <thead>
              <tr>
                {consolidated ? <th>Engines</th> : null}
                <th>File</th>
              </tr>
            </thead>
            <tbody>
              {files.map((f) => (
                <FileComponent
                  value={f}
                  savedArtifact={savedArtifacts.get(f.SHA256)}
                  key={`${f.SHA256}:${f.Path}:${f.FileName}`}
                  fileType={f.FileType}
                />
              ))}
            </tbody>
          </table>
        )}
      </ExpandableBox>

      <ExpandableBox
        title={`Processes (${processes.length})`}
        maxHeight={allExpanded ? "none" : "25rem"}
      >
        {processes.length > 0 &&
          (consolidated ? (
            <ProcessList processes={processes} />
          ) : (
            <ProcessTree processes={processes} />
          ))}
      </ExpandableBox>

      <ExpandableBox
        title={`Registry Keys (${regkeys.length})`}
        maxHeight={allExpanded ? "none" : "25rem"}
      >
        {regkeys.length > 0 && (
          <table className="table">
            <thead>
              <tr>
                {consolidated ? <th>Engines</th> : null}
                <th>Action</th>
                <th>Registry Key</th>
              </tr>
            </thead>
            <tbody>
              {regkeys.map((r) => (
                <RegKeyComponent value={r} key={r.Action + r.Name} />
              ))}
            </tbody>
          </table>
        )}
      </ExpandableBox>

      <ExpandableBox
        title={`Mutexes (${mutexes.length})`}
        maxHeight={allExpanded ? "none" : "25rem"}
      >
        {mutexes.length > 0 && (
          <table className="table">
            <thead>
              <tr>
                {consolidated ? <th>Engines</th> : null}
                <th>Mutex Name</th>
              </tr>
            </thead>
            <tbody>
              {mutexes.map((m) => (
                <MutexComponent value={m} key={m.Name} />
              ))}
            </tbody>
          </table>
        )}
      </ExpandableBox>
    </section>
  );

  const stringsBlock = (
    <section>
      <ExpandableBox
        title={`Extracted Configs (${configs.length})`}
        maxHeight={allExpanded ? "none" : "25rem"}
      >
        {configs.length > 0 && (
          <div>
            {configs.map((c, i) => (
              <MalwareConfigDisplay value={c} key={i} />
            ))}
          </div>
        )}
      </ExpandableBox>

      <ExpandableBox
        title={`All Strings (${strings.length})`}
        maxHeight={allExpanded ? "none" : "25rem"}
      >
        {strings.map((s) => (
          <ForensicsString value={s} key={stringKey++} />
        ))}
      </ExpandableBox>

      <ExpandableBox
        title={`VBA Macros (${macros.length})`}
        maxHeight={allExpanded ? "none" : "25rem"}
      >
        {macros.map((s) => (
          <SourceCodeString
            value={s.String}
            key={stringKey++}
            language="visual-basic"
            toolbarEnabled={true}
          />
        ))}
      </ExpandableBox>
      <ExpandableBox
        title={`OCR Extracted Strings (${ocrStrings.length})`}
        maxHeight={allExpanded ? "none" : "25rem"}
      >
        {ocrStrings.map((s) => (
          <ForensicsString value={s} key={stringKey++} />
        ))}
      </ExpandableBox>
    </section>
  );

  const imagesBlock = (
    <section>
      <ForensicsScreenshots
        forensics={forensics}
        isConsolidated={consolidated}
      />
    </section>
  );

  let menuActions: ActionConfig[] = [];
  if (forensics) {
    let forensicsFilename: string;
    if (consolidated) {
      forensicsFilename = `normalized-forensics-job-${job.ID}.json`;
    } else {
      forensicsFilename = `normalized-forensics-job-${job.ID}-task-${task?.ID}.json`;
    }
    menuActions.push({
      title: "Download Forensics",
      onClick: () => {
        saveAsJson(forensics, forensicsFilename);
      },
      icon: "download",
    });
  }

  return (
    <section>
      <ResourceSummary
        job={job}
        resource={resource}
        task={task}
        forensics={forensics}
        menuActions={menuActions}
      />
      <Tabs>
        <Tab label={`Detections (${detectionCount})`}>
          <>
            {expandAllButton}
            {detectionBlock}
          </>
        </Tab>
        <Tab label={`Network (${networkCount})`}>
          <>
            {expandAllButton}
            {networkBlock}
          </>
        </Tab>
        <Tab label={`System (${systemCount})`}>
          <>
            {expandAllButton}
            {systemBlock}
          </>
        </Tab>
        <Tab label={`Strings & Configs (${strings.length + configs.length})`}>
          <>
            {expandAllButton}
            {stringsBlock}
          </>
        </Tab>
        <Tab label={`Images (${images.length})`}>
          <>
            {expandAllButton}
            {imagesBlock}
          </>
        </Tab>
        <Tab label="All">
          {expandAllButton}
          {detectionBlock}
          {networkBlock}
          {systemBlock}
          {stringsBlock}
          {imagesBlock}
        </Tab>
      </Tabs>
    </section>
  );
};

export const EngineCountCell = ({ value }: { value: ArtifactCommon }) => {
  const engines = getEngines(value);

  return (
    <>
      {engines != null ? (
        <td className="has-text-centered">
          <Tooltip title={engines} placement="left">
            <span className="tag is-light is-rounded">
              {engineCount(value)}
            </span>
          </Tooltip>
        </td>
      ) : null}
    </>
  );
};

export const EngineCountTag = ({ value }: { value: ArtifactCommon }) => {
  const engines = getEngines(value);

  return (
    <>
      {engines != null ? (
        <Tooltip title={engines} placement="left">
          <span className="tag is-light is-rounded">{engineCount(value)}</span>
        </Tooltip>
      ) : null}
    </>
  );
};

export const HostComponent = ({ host }: { host: Host }) => {
  return (
    <tr>
      <EngineCountCell value={host} />
      <td className="forensic-value">{host.Hostname || "-"}</td>
      <td>{host.IP || "-"}</td>
      <td>
        {host.City || host.Country ? (
          <div>
            <span className="has-text-grey">Location:</span>{" "}
            {host.City ? host.City + ", " : null}
            {host.Country || "-"}
          </div>
        ) : null}
        {host.ASN ? (
          <div>
            <span className="has-text-grey">ASN:</span> {host.ASN}
          </div>
        ) : null}
        {host.Organization ? (
          <div>
            <span className="has-text-grey">Org:</span> {host.Organization}
          </div>
        ) : null}
      </td>
    </tr>
  );
};

export const ConnectionComponent = ({ value }: { value: Network }) => {
  return (
    <tr>
      <EngineCountCell value={value} />
      <td>{value.Protocol || "-"}</td>
      <td>{value.Service || "-"}</td>
      <td>{value.Source.IP || "-"}</td>
      <td>{value.Source.Port || "-"}</td>
      <td>{value.Destination.IP || "-"}</td>
      <td>{value.Destination.Port || "-"}</td>
      <td>{value.Length || "-"}</td>
    </tr>
  );
};

export const URLComponent = ({ value }: { value: URL }) => {
  return (
    <tr>
      <EngineCountCell value={value} />
      <td className="forensic-value">
        {value.URL}
        {value.Context && (
          <span className="has-text-grey is-size-7"> ({value.Context})</span>
        )}
        {value.LinkText && (
          <>
            <br />
            <span className="has-text-grey">Link text: </span>
            <span className="has-text-info">"{value.LinkText}"</span>
          </>
        )}
      </td>
    </tr>
  );
};

export const FileComponent = ({
  value,
  savedArtifact,
  fileType,
}: {
  value: File;
  savedArtifact: SavedArtifact | undefined;
  fileType: string | undefined;
}) => {
  const { api } = useContext(APIContext);
  const { getAccessTokenSilently } = useAuth0();
  const [fileViewerVisible, setFileViewerVisible] = useState(false);

  const downloadArtifact = () => {
    toast.success("Download initiated...");
    api
      .callAPI(
        getAccessTokenSilently,
        api.getArtifactByPath,
        savedArtifact!.ArtifactPath
      )
      .catch(() => toast.error("download failed", { autoClose: false }));
  };

  let isText = false;
  if (fileType && /(ASCII|Unicode) text/.test(fileType)) {
    isText = true;
  }

  return (
    <tr>
      <EngineCountCell value={value} />
      <td className="forensic-value">
        <div>
          {value.FileName}{" "}
          {savedArtifact && (
            <span>
              <button
                className="button is-small is-text has-text-link"
                onClick={() => downloadArtifact()}
                title="download artifact"
              >
                <span className="icon is-small is-link">
                  <FontAwesomeIcon icon="download" />
                </span>
              </button>
              <button
                className="button is-small is-text has-text-link"
                onClick={() => setFileViewerVisible(true)}
                title="view artifact"
              >
                <span className="icon is-small is-link">
                  <FontAwesomeIcon icon="code" />
                </span>
              </button>
              {fileViewerVisible && (
                <FileViewer
                  resource={savedArtifact.ArtifactPath}
                  filename={value.FileName}
                  isVisible={fileViewerVisible}
                  onClose={() => {
                    setFileViewerVisible(false);
                  }}
                  mimetype={isText ? "text/plain" : "unknown"}
                  magicString={value.FileType}
                />
              )}
            </span>
          )}
        </div>
        <div className="is-size-7 has-text-grey">Path: {value.Path || "-"}</div>
        <div className="is-size-7 has-text-grey">
          SHA256: {value.SHA256 || "-"}
        </div>
        <div className="is-size-7 has-text-grey">MD5: {value.MD5 || "-"}</div>
        <div className="is-size-7 has-text-grey">Size: {value.Size}</div>
        {value.FileType && (
          <div className="is-size-7 has-text-grey">
            File Type: {value.FileType}
          </div>
        )}
        {value.Ssdeep && (
          <div className="is-size-7 has-text-grey">Ssdeep: {value.Ssdeep}</div>
        )}
        {value.NetworkSources && (
          <div className="is-size-7 has-text-grey">
            Network Sources: {value.NetworkSources.join(", ")}
          </div>
        )}
      </td>
    </tr>
  );
};

export const RegKeyComponent = ({ value }: { value: RegistryKey }) => {
  return (
    <tr>
      <EngineCountCell value={value} />
      <td>{value.Action || "-"}</td>
      <td className="forensic-value">{value.Name}</td>
    </tr>
  );
};

export const MutexComponent = ({ value }: { value: Mutex }) => {
  return (
    <tr>
      <EngineCountCell value={value} />
      <td className="forensic-value">{value.Name}</td>
    </tr>
  );
};

export const MitreAttackComponent = ({ value }: { value: MitreAttack }) => {
  return (
    <tr>
      <EngineCountCell value={value} />
      <td>
        <MitreLink id={value.ID} />
      </td>
      <td>{value.Technique}</td>
      <td>{value.SubTechnique || "-"}</td>
      <td>{value.Tactic}</td>
    </tr>
  );
};

export const FormComponent = ({ value }: { value: Form }) => {
  return (
    <tr>
      <td className="forensic-value">
        <div>
          <b>Page URL</b>: {value.PageURL}
        </div>
        <div>
          <div>
            <b>Method</b>: {(value.Method || "GET").toUpperCase()}
          </div>
          <div>
            <b>Action</b>: {value.Action || <em>Inputs outside of Forms</em>}
          </div>
          {(value.Inputs?.length ?? 0) > 0 && (
            <>
              <div className="has-margin-top-5">
                <Expandable title="Form Inputs" expanded={true}>
                  <table className="table is-narrow is-striped is-bordered">
                    <thead>
                      <tr>
                        <th className="nowrap">Type</th>
                        <th className="nowrap">ID</th>
                        <th className="nowrap">Name</th>
                        <th className="nowrap">Placeholder</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(value.Inputs ?? []).map((i, idx) => (
                        <tr key={(i.ID || i.Name) + idx}>
                          <td className="forensic-value nowrap">{i.Type}</td>
                          <td className="forensic-value">{i.ID}</td>
                          <td className="forensic-value">{i.Name}</td>
                          <td className="forensic-value">{i.Placeholder}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Expandable>
              </div>
            </>
          )}
          {value.SourceCode && (
            <div className="has-margin-top-5">
              <Expandable title="Form Source">
                <SourceCodeString
                  value={value.SourceCode}
                  language="html"
                  toolbarEnabled={true}
                />
              </Expandable>
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};
