import { withAuth0 } from "@auth0/auth0-react";
import React from "react";
import ReactJson from "react-json-view";
import { APIContext } from "../lib/MAPApi";
import { saveAsJson } from "src/lib/Utils";
import { taskDisplayName } from "./utils/DisplayNames";

import * as Menu from "../components/Menu";

export class RawForensicsInternal extends React.Component {
  state = { forensics: "loading", expansion: "initial" };

  static contextType = APIContext;

  constructor(props) {
    super();
    // if forensics were passed in via props, just use those
    if (props.forensics != null) {
      this.state.forensics = props.forensics;
    }
  }

  componentDidMount() {
    // only load forensics if they weren't passed to us
    if (this.state.forensics === "loading") {
      this.context.api
        .callAPI(
          this.props.auth0.getAccessTokenSilently,
          this.context.api.getRawForensics,
          this.props.jobid,
          this.props.taskid
        )
        .then((forensics) => {
          forensics &&
            this.setState({
              forensics: forensics,
            });
        })
        .catch((err) => {
          this.setState({ forensics: null });
        });
    }
  }

  render() {
    // const theme = "tomorrow";
    const theme = {
      base00: "#1a1c20",
      base01: "#fafafa",
      base02: "rgba(255, 255, 255, 0.15)",
      base03: "#fafafa",
      base04: "#fafafa",
      base05: "#fafafa",
      base06: "#fafafa",
      base07: "#fafafa",
      base08: "#fafafa",
      base09: "#f7933f",
      base0A: "#fafafa",
      base0B: "#f7933f",
      base0C: "#fafafa",
      base0D: "#b5b5b5",
      base0E: "#b5b5b5",
      base0F: "#f7933f",
    };

    if (this.state.forensics === "loading") {
      return <div className="has-text-info">Loading...</div>;
    }

    if (this.state.forensics === null) {
      return <div>Error loading raw forensics</div>;
    }

    let forensicsFilename = null;
    if (this.props.engine.toLowerCase() === "consolidated") {
      forensicsFilename =
        `raw-forensics-job-${this.props.jobid}-consolidated.json`.toLowerCase();
    } else {
      forensicsFilename =
        `raw-forensics-job-${this.props.jobid}-task-${this.props.taskid}.json`.toLowerCase();
    }

    let expandCollapseButton = null;
    if (
      this.state.expansion === "initial" ||
      this.state.expansion === "collapsed"
    ) {
      expandCollapseButton = (
        <button
          className="button is-text is-size-7 has-text-link"
          onClick={(e) => this.setState({ expansion: "expanded" })}
        >
          Expand All
        </button>
      );
    } else {
      expandCollapseButton = (
        <button
          className="button is-text is-size-7 has-text-link"
          onClick={(e) => this.setState({ expansion: "collapsed" })}
        >
          Collapse All
        </button>
      );
    }

    return (
      <div>
        {/* Levels used so The H1 and menu coexist nicely with each other and the JSON viewer */}
        <div className="level">
          <div className="level-left">
            <h1 className="title is-5 level-item">
              Forensics for {this.props.engine}
              {expandCollapseButton}
            </h1>
          </div>
          <div
            className="level-right"
            title="menu"
            style={{ minWidth: "3rem" }}
          >
            <Menu.More>
              <Menu.Item
                text="Download"
                key="Download"
                icon="download"
                title={`Download ${taskDisplayName(
                  this.props.engine
                )} Forensics`}
                onClick={(e) => {
                  saveAsJson(this.state.forensics, forensicsFilename);
                }}
              />
            </Menu.More>
          </div>
        </div>

        <ReactJson
          src={this.state.forensics}
          name={null}
          enableClipboard={false}
          displayDataTypes={false}
          theme={theme}
          collapsed={this.state.expansion === "collapsed"}
          groupArraysAfterLength={
            this.state.expansion !== "initial" ? 99999999 : undefined
          }
        />
      </div>
    );
  }
}

export const RawForensics = withAuth0(RawForensicsInternal);
