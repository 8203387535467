import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useState } from "react";
import { UserHasPermission } from "../lib/Auth";
import * as Modal from "./Modal";

export const More = ({
  children,
  text,
}: {
  children: React.ReactNode;
  text?: string;
}) => {
  return (
    <div className="dropdown is-hoverable is-right">
      <div className="dropdown-trigger">
        <Button icon={["far", "bars"]} text={text} />
      </div>
      <div className="dropdown-menu" id="dropdown-menu4" role="menu">
        <div className="dropdown-content">{children}</div>
      </div>
    </div>
  );
};

export const Item = ({
  text,
  permission,
  children,
  isDisabled,
  onClick,
  standalone,
  icon,
  title,
}: {
  text: string;
  permission?: string;
  children?: React.ReactNode;
  isDisabled?: boolean | string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  standalone?: boolean;
  icon: IconProp;
  title?: string;
}) => {
  const [isActive, setIsActive] = useState(false);
  const close = useCallback(() => setIsActive(false), []);
  const open = useCallback(() => setIsActive(true), []);

  if (isDisabled) {
    onClick = open;
    children = (
      <Modal.Card title={text}>
        <Modal.CardBody>
          {typeof isDisabled === "string"
            ? isDisabled
            : "This action is currently disabled."}
        </Modal.CardBody>
        <Modal.CardFooter>
          <Modal.CardCloseButton text="Close" />
        </Modal.CardFooter>
      </Modal.Card>
    );
  } else {
    onClick = onClick || open;
  }

  let launcher: JSX.Element;
  if (standalone) {
    launcher = <Button text={text} onClick={onClick} icon={icon} />;
  } else {
    launcher = (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        className="dropdown-item"
        onClick={onClick}
        title={title ? title : undefined}
      >
        <Icon icon={icon} />
        {text}
      </a>
    );
  }

  return (
    <UserHasPermission permission={permission}>
      {launcher}
      {children && (
        <Modal.Modal isActive={isActive} close={close}>
          {children}
        </Modal.Modal>
      )}
    </UserHasPermission>
  );
};

export const ItemExternalLink = ({
  permission,
  text,
  href,
  standalone,
  icon,
}: {
  permission?: string;
  text: string;
  href: string;
  standalone?: boolean;
  icon: IconProp;
}) => {
  let launcher: JSX.Element;
  if (standalone) {
    launcher = <Link href={href} icon={icon} text={text} />;
  } else {
    launcher = (
      <a
        href={href}
        className="dropdown-item"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="icon-text">
          <Icon icon={icon} />
          {text}
        </span>
      </a>
    );
  }

  return (
    <UserHasPermission permission={permission}>{launcher}</UserHasPermission>
  );
};

const Button = ({
  onClick,
  text,
  icon,
  title,
}: {
  onClick?: React.MouseEventHandler;
  text?: string;
  icon: IconProp;
  title?: string;
}) => {
  return (
    <button
      className="button is-text"
      style={text ? { padding: "26px 5px 20px" } : undefined}
      onClick={onClick}
      title={title ? title : undefined}
    >
      <div>
        <div>
          <FontAwesomeIcon icon={icon} />
        </div>
        {text && (
          <div>
            <span className="is-size-7">{text}</span>
          </div>
        )}
      </div>
    </button>
  );
};

const Link = ({
  text,
  icon,
  href,
}: {
  text?: string;
  icon: IconProp;
  href: string;
}) => {
  return (
    <a
      className="button is-text"
      style={text ? { padding: "26px 5px 20px" } : undefined}
      target="_blank"
      rel="noopener noreferrer"
      href={href}
    >
      <div>
        <div>
          <FontAwesomeIcon icon={icon} />
        </div>
        {text && (
          <div>
            <span className="is-size-7">{text}</span>
          </div>
        )}
      </div>
    </a>
  );
};

const Icon = ({ icon }: { icon: IconProp }) => (
  <span style={{ marginRight: ".5em" }}>
    <FontAwesomeIcon icon={icon} />
  </span>
);
