import React from "react";

export class BrowserWarning extends React.Component {
  state = {
    warningDismissed: false,
    shouldWarn: false,
  };

  dismiss = (evt) => {
    this.setState({ warningDismissed: true });
  };

  componentDidMount() {
    var weirdBrowser = false;
    try {
      new AbortController();
    } catch (err) {
      weirdBrowser = true;
    }

    if (weirdBrowser) {
      this.setState({ shouldWarn: true });
    }
  }

  render() {
    if (!this.state.shouldWarn || this.state.warningDismissed) {
      return null;
    }

    return (
      <div className="section">
        <div className="notification is-warning">
          <button className="delete" onClick={this.dismiss} />
          You appear to be running an older or unsupported browser. Application
          behavior may be unpredictable.
        </div>
      </div>
    );
  }
}
