import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Modal } from "src/components/Modal";
import {
  useSurferDiagnosticHook,
  useWatchForIWASessionURLHook,
} from "src/lib/API";

export const PATH = "/interactivewebanalyzer";
export const MENU_NAME = "Interactive Web Analyzer";

export const MenuItem = () => <></>;
export const _MenuItem = () => (
  <NavLink
    className={(isActive) => "navbar-item" + (isActive ? " is-active" : "")}
    to={PATH}
  >
    <span className="icon has-text-link">
      <FontAwesomeIcon icon={["far", "browser"]} />
    </span>
    <span className="is-size-5">{MENU_NAME}</span>
  </NavLink>
);

export const NewSession = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { JobID } = location?.state || {};
  const { sessionInfo, isDone } = useWatchForIWASessionURLHook(JobID);
  const diagnostics = useSurferDiagnosticHook(JobID);

  useEffect(() => {
    if (!JobID) navigate("/");
  }, [JobID, navigate]);

  useEffect(() => {
    if (isDone && diagnostics.status !== "failed")
      navigate(`/job/${JobID}`, { state: { JobID } });
  }, [isDone, navigate, JobID, diagnostics.status]);

  if (!JobID)
    return (
      <div className="section">
        <div className="container">
          <h1 className="title is-4 has-text-centered">{MENU_NAME}</h1>
          {/* <SubmitForm submitHandler={setJobID} isIWAMode /> */}
        </div>
      </div>
    );

  if (diagnostics.status !== "passed" || !sessionInfo)
    return <Loader diagnostics={diagnostics} />;

  if (!sessionInfo.startsWith("http")) return <></>;

  return (
    <Modal isActive={true} close={() => {}} fullscreen>
      <iframe
        sandbox="allow-scripts allow-same-origin"
        title="Interactive Web Analyzer Session"
        src={sessionInfo}
        style={{ width: "100%", height: "100%" }}
      />
    </Modal>
  );
};

const Loader = ({
  diagnostics,
}: {
  diagnostics: ReturnType<typeof useSurferDiagnosticHook>;
}) => {
  const diagnostic =
    diagnostics.checks.filter((v) => v.status !== "passed")[0] || {};

  if (diagnostic.status === "failed")
    return (
      <div className="section columns">
        <div className="column is-half is-offset-one-quarter">
          <div className="message is-danger">
            <div className="message-header">
              Connectivity check to Interactive Web Analyzer (hosted on
              surfer.twinwave.io) failed.
            </div>
            <div className="message-body">
              <p className="has-padding-bottom-10">
                This is often due to either web browser security settings or a
                network device, like a web proxy, blocking connections to
                surfer.twinwave.io that are required for IWA to work. If you are
                using a web proxy, VPN, firewall or browser extension, please
                ensure that surfer.twinwave.io is safe listed (including
                allowing WebSocket connections if available).
              </p>
              <p className="has-padding-bottom-10">
                If you are unable to resolve this issue, or if you believe it is
                an error on the Attack Analyzer platform, please send a message
                to{" "}
                <a href="mailto:attackanalyzer-support@splunk.com">
                  attackanalyzer-support@splunk.com
                </a>{" "}
                and include the details from the logs below.
              </p>
              <div className="has-padding-bottom-10">
                <details>
                  <summary>Logs…</summary>
                  <pre>{JSON.stringify(diagnostics, null, "  ")}</pre>
                </details>
              </div>
              <p>Thanks!</p>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className="surfer-loader">
      <div className="surfer-spinner">
        <div className="surfer-dot1"></div>
        <div className="surfer-dot2"></div>
      </div>
      <div>
        {diagnostic
          ? "Running Connectivity Checks"
          : "Connecting You to a Live Interactive Browser"}
      </div>
      <div className="surfer-meter">
        <div></div>
      </div>
    </div>
  );
};
