import SplunkRum from "@splunk/otel-web";
import React from "react";
import { AUDIENCE } from "./Auth";
import { saveWithMimeType } from "./Utils";

const yaml = require("js-yaml");
const pako = require("pako");
const apiURL = process.env.REACT_APP_API_URL;

const DefaultPriority = 5;

export const APIContext = React.createContext();

export const MODE = "standard";

export var weirdBrowser = false;
var controller;
try {
  controller = new AbortController();
} catch (err) {
  SplunkRum.error(err);
  controller = {};
  console.log(
    "This browser doesn't support the AbortController feature, which may cause strange UI behavior"
  );
  weirdBrowser = true;
}

export const abortDownload = () => {
  try {
    controller.abort();
    controller = new AbortController();
  } catch (err) {
    logError(err, false);
  }
};

function handleErrors(resp) {
  if (resp.ok) {
    return resp;
  }

  return resp.json().then((jsonResponse) => {
    if (jsonResponse.status) {
      throw Error(jsonResponse.status);
    } else {
      throw resp.statusText;
    }
  });
}

function swallowErrors(resp) {
  if (resp.ok) {
    return resp;
  }

  return "Error";
}

export function logError(err, throwIfErr = true) {
  if (err) {
    SplunkRum.error(err);
    if (throwIfErr) {
      throw err;
    }
  }
}

export const getJobs = (
  authToken,
  {
    count = 10,
    offset,
    username = "",
    source = "",
    apiKeyID = "",
    scoreMin = 0,
    scoreMax = 100,
  }
) => {
  const params = { count };
  let URL = `${apiURL}/jobs/recentsummary`;
  if (offset !== undefined) {
    params["start"] = offset;
  }

  if (username !== undefined) {
    params["username"] = username;
  }

  if (source) {
    params["source"] = source;
  }

  if (apiKeyID) {
    params["api_key_id"] = apiKeyID;
  }

  if (scoreMin !== 0) {
    params["score_min"] = scoreMin / 100;
  }

  if (scoreMax !== 100) {
    params["score_max"] = scoreMax / 100;
  }

  return fetch(`${URL}?${new URLSearchParams(params).toString()}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  })
    .then(handleErrors)
    .then((resp) => resp.json())
    .catch((err) => logError(err, true))
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const getAllJobsForAdmin = (
  authToken,
  {
    count = 10,
    offset,
    username = "",
    source = "",
    scoreMin = 0,
    scoreMax = 100,
  }
) => {
  const URL = `${apiURL}/jobs/globalbystate/any`;
  const params = { count };
  if (offset !== undefined) {
    params["start"] = offset;
  }

  if (username) {
    params["username"] = username;
  }

  if (source) {
    params["source"] = source;
  }

  if (scoreMin !== 0) {
    params["score_min"] = scoreMin / 100;
  }

  if (scoreMax !== 100) {
    params["score_max"] = scoreMax / 100;
  }

  return fetch(`${URL}?${new URLSearchParams(params).toString()}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  })
    .then(handleErrors)
    .then((resp) => resp.json())
    .catch((err) => logError(err, true))
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const submitURL = (authToken, url, engines, parameters) => {
  if (!engines) {
    engines = [];
  }

  const reqObj = {
    priority: DefaultPriority,
    url: url,
    source: "UI",
    engines: engines,
    parameters: parameters,
  };
  const reqBody = JSON.stringify(reqObj);

  return fetch(`${apiURL}/jobs/urls`, {
    method: "POST",
    body: reqBody,
    headers: { Authorization: `Bearer ${authToken}` },
  })
    .then(handleErrors)
    .then((resp) => resp.json())
    .catch((err) => logError(err, true));
};

export const submitFile = (authToken, file, engines, parameters) => {
  if (!engines) {
    engines = [];
  }

  const formData = new FormData();
  formData.append("filedata", file);
  formData.append("filename", file.name);
  formData.append("priority", DefaultPriority);
  formData.append("source", "UI");
  formData.append("engines", JSON.stringify(engines));
  formData.append("parameters", JSON.stringify(parameters));

  return fetch(`${apiURL}/jobs/files`, {
    method: "POST",
    body: formData,
    headers: { Authorization: `Bearer ${authToken}` },
  })
    .then(handleErrors)
    .then((resp) => resp.json())
    .catch((err) => logError(err, true));
};

export const resubmitJob = (authToken, jobid) => {
  return fetch(`${apiURL}/jobs/${jobid}/reanalyze`, {
    method: "POST",
    headers: { Authorization: `Bearer ${authToken}` },
  })
    .then(handleErrors)
    .then((resp) => resp.json())
    .catch((err) => logError(err, true));
};

export const deleteJob = (authToken, jobid) => {
  return fetch(`${apiURL}/jobs/${jobid}`, {
    method: "DELETE",
    headers: { Authorization: `Bearer ${authToken}` },
  })
    .then(handleErrors)
    .then((resp) => resp.json())
    .catch((err) => logError(err, true));
};

export const getJob = (authToken, id) => {
  return fetch(`${apiURL}/jobs/${id}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  })
    .then(handleErrors)
    .then((resp) => resp.json())
    .catch((err) => logError(err, true));
};

export const reportJob = (authToken, jobid, disposition, comments, flare) => {
  return fetch(`${apiURL}/jobs/${jobid}/reports`, {
    method: "POST",
    headers: { Authorization: `Bearer ${authToken}` },
    body: JSON.stringify({ disposition, comments, flare }),
  })
    .then(handleErrors)
    .then((resp) => resp.json())
    .catch((err) => logError(err, true));
};

export const getForensics = (authToken, jobid, taskid) => {
  return fetch(`${apiURL}/jobs/${jobid}/tasks/${taskid}/forensics`, {
    headers: { Authorization: `Bearer ${authToken}` },
    signal: controller.signal,
  })
    .then(handleErrors)
    .then((resp) => resp.json())
    .catch((err) => logError(err, true))
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const getOpenAPISpec = (authToken) => {
  return fetch(`${apiURL}/openapi.yaml`, {
    headers: { Authorization: `Bearer ${authToken}` },
    signal: controller.signal,
  })
    .then(handleErrors)
    .then((resp) => resp.text())
    .then((txt) =>
      yaml.safeLoad(txt, {
        json: true,
      })
    )
    .catch((err) => logError(err, true))
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const getRawForensics = (authToken, jobid, taskid) => {
  return fetch(`${apiURL}/jobs/${jobid}/tasks/${taskid}/rawforensics`, {
    headers: { Authorization: `Bearer ${authToken}` },
    signal: controller.signal,
  })
    .then(handleErrors)
    .then((resp) => resp.json())
    .catch((err) => logError(err, true))
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const getConsolidatedForensics = (authToken, jobid) => {
  return fetch(`${apiURL}/jobs/${jobid}/forensics`, {
    headers: { Authorization: `Bearer ${authToken}` },
    signal: controller.signal,
  })
    .then(handleErrors)
    .then((resp) => resp.json())
    .catch((err) => logError(err, true))
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const getArtifactURL = (authToken, artifactPath) => {
  let url = new URL(`${apiURL}/jobs/artifact/url`);
  url.searchParams.append("path", artifactPath);

  return fetch(url, {
    headers: { Authorization: `Bearer ${authToken}` },
  })
    .then(handleErrors)
    .then((resp) => resp.json())
    .then((resp) => {
      return resp.URL;
    })
    .catch((err) => logError(err, true))
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const getArtifactByPath = (authToken, artifactPath) => {
  let url = new URL(`${apiURL}/jobs/artifact/url`);
  url.searchParams.append("path", artifactPath);

  return fetch(url, {
    headers: { Authorization: `Bearer ${authToken}` },
  })
    .then(handleErrors)
    .then((resp) => resp.json())
    .then((resp) => {
      // weird hack from https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
      const link = document.createElement("a");
      link.href = resp.URL;
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => logError(err, true))
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const getArtifactByPathToMemory = (authToken, artifactPath) => {
  let url = new URL(`${apiURL}/jobs/artifact/url`);
  url.searchParams.append("path", artifactPath);

  return fetch(url, {
    headers: { Authorization: `Bearer ${authToken}` },
  })
    .then(handleErrors)
    .then((resp) => resp.json())
    .then((resp) => {
      return fetch(resp.URL)
        .then(handleErrors)
        .then((resp) => resp.arrayBuffer())
        .then((buf) => {
          let a = new Uint8Array(buf);
          if (a[0] === 0x1f && a[1] === 0x8b) {
            // gzip header detected
            a = pako.inflate(a);
          }
          let s = "";
          for (let i = 0; i < a.length; i++) {
            s += String.fromCharCode(a[i]);
          }
          return s;
        });
    })
    .catch((err) => logError(err, true))
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const getArtifact = (authToken, jobid, taskid, sha256) => {
  return fetch(`${apiURL}/jobs/${jobid}/tasks/${taskid}/artifacts/${sha256}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  })
    .then(handleErrors)
    .then((resp) => resp.blob())
    .then((blob) => {
      saveWithMimeType(blob, `${sha256}.zip`, "application/zip");
    })
    .catch((err) => logError(err, true))
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const getHarAsPCAP = (authToken, artifactPath) => {
  let url = new URL(`${apiURL}/jobs/artifact/har2pcap`);
  url.searchParams.append("path", artifactPath);

  return fetch(url, {
    headers: { Authorization: `Bearer ${authToken}` },
  })
    .then(handleErrors)
    .then((resp) => resp.blob())
    .then((blob) => {
      saveWithMimeType(blob, "recording.pcap.gz", "application/octet-stream");
    })
    .catch((err) => logError(err, true))
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const getSampleToMemory = (authToken, jobid, sha256) => {
  return fetch(`${apiURL}/jobs/${jobid}/resources/${sha256}?hex=true`, {
    headers: { Authorization: `Bearer ${authToken}` },
  })
    .then(handleErrors)
    .then((resp) => resp.text())
    .then((t) => {
      const a = [];
      for (let i = 0; i < t.length; i += 2) {
        a.push(t.substring(i, i + 2).toUpperCase());
      }
      return a;
    })
    .catch((err) => logError(err, true))
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const getSample = (authToken, jobid, sha256) => {
  return fetch(`${apiURL}/jobs/${jobid}/resources/${sha256}`, {
    headers: { Authorization: `Bearer ${authToken}` },
  })
    .then(handleErrors)
    .then((resp) => resp.blob())
    .then((blob) => {
      saveWithMimeType(blob, `${sha256}.zip`, "application/zip");
    })
    .catch((err) => logError(err, true))
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const getPDFReport = (authToken, jobid) => {
  return fetch(`${apiURL}/jobs/${jobid}/pdfreport`, {
    headers: { Authorization: `Bearer ${authToken}` },
  })
    .then(handleErrors)
    .then((resp) => resp.blob())
    .then((blob) => {
      saveWithMimeType(blob, `${jobid}.pdf`, "application/pdf");
    })
    .catch((err) => logError(err, true))
    .catch((err) => {
      console.log(err);
      return null;
    });
};

export const searchv2 = (
  authToken,
  {
    mode,
    term,
    field,
    type = "substring",
    submitted_by = "",
    start_time = "",
    end_time = "",
    count = 50,
    page = 1,
    source = "unknown",
    api_key_id = "",
    tenants = [],
    verdict = "",
    score_min = 0.0,
    score_max = 1.0,
  }
) => {
  let url = new URL(`${apiURL}/jobs/searchv2/summary`);
  url.searchParams.append("mode", mode);
  url.searchParams.append("field", field);
  url.searchParams.append("term", term);
  url.searchParams.append("count", count);
  url.searchParams.append("page", page);
  url.searchParams.append("submitted_by", submitted_by);
  url.searchParams.append("type", type);
  url.searchParams.append("start_time", start_time);
  url.searchParams.append("end_time", end_time);
  url.searchParams.append("source", source);
  url.searchParams.append("api_key_id", api_key_id);
  url.searchParams.append("verdict", verdict);
  url.searchParams.append("score_min", score_min);
  url.searchParams.append("score_max", score_max);

  if (tenants?.length) url.searchParams.append("tenants", tenants.join(","));

  return fetch(url, { headers: { Authorization: `Bearer ${authToken}` } })
    .then(handleErrors)
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => logError(err, true))
    .catch((err) => {
      console.log(err);
      console.trace();
      return { Jobs: [], Forensics: [], HasNext: false, HasError: true };
    });
};

export const searchRelated = (authToken, mode, value) => {
  let url = new URL(`${apiURL}/jobs/search/related/${mode}`);
  url.searchParams.append("value", value);

  return fetch(url, { headers: { Authorization: `Bearer ${authToken}` } })
    .then(handleErrors)
    .then((resp) => {
      return resp.json();
    })
    .catch((err) => logError(err, true))
    .catch((err) => {
      console.log(err);
      console.trace();
      return { Jobs: [], HasNext: false };
    });
};

export const getEngines = (authToken) => {
  let url = new URL(`${apiURL}/engines`);

  return fetch(url, { headers: { Authorization: `Bearer ${authToken}` } })
    .then(handleErrors)
    .then((resp) => resp.json())
    .then((resp) => {
      return resp.sort((a, b) => (a.Name > b.Name ? 1 : -1));
    })
    .catch((err) => logError(err, true))
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const getAPIKeys = (authToken) => {
  let url = new URL(`${apiURL}/apikeys`);

  return fetch(url, { headers: { Authorization: `Bearer ${authToken}` } })
    .then(handleErrors)
    .then((resp) => resp.json())
    .then((resp) => {
      return resp.sort((a, b) => (a.Label > b.Label ? 1 : -1));
    })
    .catch((err) => logError(err, true))
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const deleteUser = async (authToken, id) => {
  let url = new URL(`${apiURL}/manage/users/${id}`);
  const resp = await fetch(url, {
    method: "DELETE",
    headers: { Authorization: `Bearer ${authToken}` },
  });

  let status = resp.statusText;
  try {
    status = (await resp.json()).status;
  } catch (_) {}

  return {
    code: resp.status,
    statusText: status,
  };
};

export const createUser = async (authToken, payload) => {
  let url = new URL(`${apiURL}/manage/users`);

  const resp = await fetch(url, {
    method: "POST",
    headers: { Authorization: `Bearer ${authToken}` },
    body: JSON.stringify(payload),
  });

  let status = resp.statusText;
  let json = undefined;
  try {
    json = await resp.json();
    status = json.status;
  } catch (_) {}

  return {
    code: resp.status,
    statusText: status,
    result: resp.ok ? json : undefined,
  };
};

export const inviteUser = async (authToken, id) => {
  let url = new URL(`${apiURL}/manage/users/${id}/invite`);

  const resp = await fetch(url, {
    method: "POST",
    headers: { Authorization: `Bearer ${authToken}` },
    body: "",
  });

  let status = resp.statusText;
  let json = undefined;
  try {
    json = await resp.json();
    status = json.status;
  } catch (_) {}

  return {
    code: resp.status,
    statusText: status,
    result: resp.ok ? json : undefined,
  };
};

export const getUserRole = async (authToken, id) => {
  let url = new URL(`${apiURL}/manage/users/${id}/role`);

  // Users with no public role (such as TW admins) will generate errors when calling this API.
  return fetch(url, { headers: { Authorization: `Bearer ${authToken}` } })
    .then(swallowErrors)
    .then((resp) => resp.json());
};

export const setUserRole = async (authToken, id, payload) => {
  let url = new URL(`${apiURL}/manage/users/${id}/role`);

  const resp = await fetch(url, {
    method: "PATCH",
    headers: { Authorization: `Bearer ${authToken}` },
    body: JSON.stringify(payload),
  });

  let status = resp.statusText;
  let json = undefined;
  try {
    json = await resp.json();
    status = json.status;
  } catch (_) {}

  return {
    code: resp.status,
    statusText: status,
    key: resp.ok ? json : undefined,
  };
};

export const getSurferURL = (authToken) => {
  let url = new URL(`${apiURL}/surfer-diagnostics`);
  return fetch(url, { headers: { Authorization: `Bearer ${authToken}` } });
};

export const repProxy = (authToken, path, data) => {
  let url = new URL(`${apiURL}/internal/repproxy${path}`);
  let i = (d) => d;
  let o = (d) => d;
  switch (path) {
    case "/v1/cachenormalizeurls":
      i = (d) => {
        return { URLs: d };
      };
      o = (d) => d;
      break;
    case "/v1/rateurls":
      i = (d) => {
        return { URLs: d };
      };
      o = (d) => d["SelectedURLs"];
      break;
    case "/v1/ratelinktexts":
      i = (d) => {
        return { LinkTexts: d };
      };
      o = (d) => d["ScoredLinkTexts"];
      break;
    default:
      break;
  }

  console.log("data", i(data));

  return fetch(url, {
    method: "POST",
    body: JSON.stringify(i(data)),
    headers: { Authorization: `Bearer ${authToken}` },
  })
    .then(handleErrors)
    .then((resp) => resp.json())
    .then((data) => o(data))
    .catch((err) => logError(err, true))
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const listUsers = (authToken, tenant, page) => {
  let url = new URL(`${apiURL}/manage/users`);

  if (page && page !== 0) {
    url.searchParams.append("page", page.toString());
  }

  if (tenant) {
    url.searchParams.append("tenant", tenant);
  }

  return fetch(url, { headers: { Authorization: `Bearer ${authToken}` } })
    .then(handleErrors)
    .then((resp) => resp.json());
};

export const listAPIKeys = (authToken) => {
  let url = new URL(`${apiURL}/admin/apikeys`);

  return fetch(url, { headers: { Authorization: `Bearer ${authToken}` } })
    .then(handleErrors)
    .then((resp) => resp.json());
};

export const getTenants = (authToken) => {
  let url = new URL(`${apiURL}/admin/tenants`);

  return fetch(url, { headers: { Authorization: `Bearer ${authToken}` } })
    .then(handleErrors)
    .then((resp) =>
      resp.json().then((tenants) => {
        tenants.sort((a, b) =>
          a.FullName.toUpperCase() < b.FullName.toUpperCase() ? -1 : 1
        );
        return tenants;
      })
    );
};

export const createAPIKey = async (authToken, payload) => {
  let url = new URL(`${apiURL}/admin/apikeys`);

  const resp = await fetch(url, {
    method: "post",
    headers: { Authorization: `Bearer ${authToken}` },
    body: JSON.stringify(payload),
  });

  let status = resp.statusText;
  let json = undefined;
  try {
    json = await resp.json();
    status = json.status;
  } catch (_) {}

  return {
    code: resp.status,
    statusText: status,
    key: resp.ok ? json : undefined,
  };
};

export const editAPIKey = async (authToken, payload) => {
  let url = new URL(`${apiURL}/admin/apikeys/${payload.ID}`);

  if (!payload.Permissions.length) {
    payload.Permissions = ["none"];
  }

  const resp = await fetch(url, {
    method: "PATCH",
    headers: { Authorization: `Bearer ${authToken}` },
    body: JSON.stringify(payload),
  });

  let status = resp.statusText;
  try {
    status = (await resp.json()).status;
  } catch (_) {}

  return {
    code: resp.status,
    statusText: status,
  };
};

export const deleteAPIKey = async (authToken, id) => {
  let url = new URL(`${apiURL}/admin/apikeys/${id}`);
  const resp = await fetch(url, {
    method: "DELETE",
    headers: { Authorization: `Bearer ${authToken}` },
  });

  let status = resp.statusText;
  try {
    status = (await resp.json()).status;
  } catch (_) {}

  return {
    code: resp.status,
    statusText: status,
  };
};

export const getServices = (authToken) => {
  let url = new URL(`${apiURL}/admin/devops/services`);

  return fetch(url, { headers: { Authorization: `Bearer ${authToken}` } })
    .then(handleErrors)
    .then((resp) => resp.json());
};

export const getServiceDiff = (authToken, service) => {
  let url = new URL(
    `${apiURL}/admin/devops/services/${encodeURIComponent(service)}/diff`
  );

  return fetch(url, { headers: { Authorization: `Bearer ${authToken}` } })
    .then(handleErrors)
    .then((resp) => resp.json());
};

export const setSharedState = (authToken, jobid, state) => {
  const reqObj = {
    shareState: state,
  };
  const reqBody = JSON.stringify(reqObj);

  return fetch(`${apiURL}/jobs/${jobid}/sharedstate`, {
    method: "POST",
    body: reqBody,
    headers: { Authorization: `Bearer ${authToken}` },
  })
    .then(handleErrors)
    .then((resp) => resp.json())
    .catch((err) => logError(err, true));
};

export const searchForensics = (
  authToken,
  iocType,
  iocSubtype,
  query,
  searchType = "exact",
  timeframe = 0,
  count = 50,
  start = 0
) => {
  query = query.replace(/^\s+|\s+$/g, ""); // strip leading and trailing whitespace

  let url = new URL(`${apiURL}/forensics/search`);
  url.searchParams.append("q", query);
  url.searchParams.append("timeframe", timeframe);

  if (iocType) {
    url.searchParams.append("type", iocType);
  }
  if (iocSubtype) {
    url.searchParams.append("subtype", iocSubtype);
  }
  if (searchType) {
    url.searchParams.append("searchtype", searchType);
  }
  if (count > 0) {
    url.searchParams.append("count", count);
  }
  if (start > 0) {
    url.searchParams.append("offset", start);
  }

  return fetch(url, { headers: { Authorization: `Bearer ${authToken}` } })
    .then(handleErrors)
    .then((resp) => resp.json())
    .then((resp) => {
      return { results: resp };
    })
    .catch((err) => logError(err, true))
    .catch((err) => {
      console.log(err);
      return { results: [] };
    });
};

export const searchScreenshots = (
  authToken,
  aHash,
  dHash,
  pHash,
  searchType,
  timeframe = 0,
  count = 50,
  start = 0
) => {
  let url = new URL(`${apiURL}/forensics/screenshotsearch`);
  url.searchParams.append("ahash", aHash);
  url.searchParams.append("dhash", dHash);
  url.searchParams.append("phash", pHash);
  url.searchParams.append("searchtype", searchType);
  url.searchParams.append("timeframe", timeframe);

  if (count > 0) {
    url.searchParams.append("count", count);
  }
  if (start > 0) {
    url.searchParams.append("offset", start);
  }

  return fetch(url, { headers: { Authorization: `Bearer ${authToken}` } })
    .then(handleErrors)
    .then((resp) => resp.json())
    .then((resp) => {
      return { results: resp };
    })
    .catch((err) => logError(err, true))
    .catch((err) => {
      console.log(err);
      return { results: [] };
    });
};

export const callAPI = (tokenGetter, apifunc, ...args) => {
  return tokenGetter({ audience: AUDIENCE })
    .catch((err) => {
      if (err.error === "login_required") {
        console.log("tried to call API but login is required!");
        window.location.replace("/"); // this should force the login dialog to show if the user truly is logged out
      }
    })
    .then((token) => {
      return apifunc(token, ...args);
    });
};

export const listEmailConfigs = (authToken) => {
  let url = new URL(`${apiURL}/admin/emlgwy`);

  return fetch(url, { headers: { Authorization: `Bearer ${authToken}` } })
    .then(handleErrors)
    .then((resp) => resp.json());
};

export const createEmailConfig = async (authToken, payload) => {
  let url = new URL(`${apiURL}/admin/emlgwy`);

  const resp = await fetch(url, {
    method: "POST",
    headers: { Authorization: `Bearer ${authToken}` },
    body: JSON.stringify(payload),
  });

  let status = resp.statusText;
  let json = undefined;
  try {
    json = await resp.json();
    status = json.status;
  } catch (_) {}

  return {
    code: resp.status,
    statusText: status,
    config: resp.ok ? json : undefined,
  };
};

export const editEmailConfig = async (authToken, payload) => {
  let url = new URL(`${apiURL}/admin/emlgwy/${payload.ID}`);

  const resp = await fetch(url, {
    method: "PATCH",
    headers: { Authorization: `Bearer ${authToken}` },
    body: JSON.stringify(payload),
  });

  let status = resp.statusText;
  try {
    status = (await resp.json()).status;
  } catch (_) {}

  return {
    code: resp.status,
    statusText: status,
  };
};

export const deleteEmailConfig = async (authToken, id) => {
  let url = new URL(`${apiURL}/admin/emlgwy/${id}`);

  const resp = await fetch(url, {
    method: "DELETE",
    headers: { Authorization: `Bearer ${authToken}` },
  });

  let status = resp.statusText;
  try {
    status = (await resp.json()).status;
  } catch (_) {}

  return {
    code: resp.status,
    statusText: status,
  };
};
