import React from "react";

export const ThreatName = ({
  name,
  ellipsed = false,
  showTitle = false,
  classNames = [],
}: {
  name: string;
  ellipsed?: boolean;
  showTitle?: boolean;
  classNames?: string[];
}) => {
  try {
    name = name.replaceAll(/[\u2026\u202e]/g, ""); // remove two different unicode RTL override characters
  } catch {}

  let classes = ["threat-name"];
  if (ellipsed) {
    classes.push("has-ellipsed-text");
  }
  if (classNames && classNames.length > 0) {
    classes.push(...classNames);
  }

  return (
    <span className={classes.join(" ")} title={showTitle ? name : undefined}>
      {name}
    </span>
  );
};
