import React from "react";
import { String as nfString } from "../../lib/APITypes";

export const ForensicsString = ({
  value,
  expanded,
}: {
  value: nfString;
  expanded?: boolean;
}) => {
  let string = value.String.replace(/(^\s+|\s+$)/g, ""); // remove any leading or trailing whitespace
  string = string.replace(/(\s{5})\s+/g, (x, m1) => m1); // convert any length runs of whitespace into the first five

  let context = value.Details?.SourceDisplay || value.Details?.Source;

  return (
    <div>
      {context && (
        <span className="has-text-info-light is-size-7 threat-name">
          {context}
        </span>
      )}
      {string.length < 1000 ? (
        <div className="forensics-string">{string}</div>
      ) : (
        <ExpandableString string={string} expanded={expanded} />
      )}
    </div>
  );
};

interface ExpandableStringProps {
  string: String;
  expanded?: boolean;
  nChars?: number;
}
export class ExpandableString extends React.Component<ExpandableStringProps> {
  state = {
    expanded: false,
  };

  constructor(props: ExpandableStringProps) {
    super(props);

    if (props.expanded) {
      this.state.expanded = true;
    }
  }

  toggleExpansion = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const nChars = this.props.nChars || 500;
    if (this.state.expanded) {
      return (
        <div className="forensics-string">
          {this.props.string}
          <br />
          <button
            className="button is-small is-text has-text-info"
            onClick={this.toggleExpansion}
          >
            Show Less
          </button>
        </div>
      );
    }

    return (
      <div className="forensics-string">
        {this.props.string.substring(0, nChars)}
        ...
        <br />
        <button
          className="button is-small is-text has-text-link"
          onClick={this.toggleExpansion}
        >
          Show More
        </button>
      </div>
    );
  }
}
