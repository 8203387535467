import React from "react";
import { DetectionBox } from "../forensics/Detections";
import { TaskProperties } from "./TaskTypes";
import { ResourceSummary } from "../ResourceSummary";
import { LoadingMessage } from "./LoadingMessage";
import { ForensicsScreenshots } from "../forensics/ForensicsScreenshots";
import { taskDisplayName } from "../utils/DisplayNames";

export const HybridAnalysisResults = ({
  job,
  task,
  resource,
  forensics,
  engineName,
}: TaskProperties & { engineName: string }) => {
  return (
    <section>
      <ResourceSummary job={job} task={task} resource={resource} />
      <ul>
        <li>
          <b>Environment:</b> {task.Results.Details.SandboxEnvironment}
        </li>
        {task.Results.Details.MalFamily && (
          <li>
            <b>Malware Family:</b>{" "}
            <span className="tag">{task.Results.Details.MalFamily}</span>
          </li>
        )}
        <li>
          <b>Permalink:</b>{" "}
          <a
            href={task.Results.Details.UIURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            {taskDisplayName(engineName)}
          </a>
        </li>
      </ul>

      {forensics === "loading" ? (
        <LoadingMessage />
      ) : (
        <>
          {(forensics?.Detections?.length ?? 0) > 0 && (
            <DetectionBox detections={forensics?.Detections} />
          )}
          <ForensicsScreenshots forensics={forensics} />
        </>
      )}
    </section>
  );
};
