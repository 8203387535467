import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SplunkRum from "@splunk/otel-web";
import isUtf8 from "isutf8";
import { Base64 } from "js-base64";
import { useContext, useEffect, useState } from "react";
import { SavedArtifact } from "src/lib/APITypes";
import { APIContext } from "src/lib/MAPApi";
import { commafy, saveBlob, truncateMidStr } from "src/lib/Utils";
import { SourceCodeString } from "../forensics/SourceCodeString";
import { DataViewer } from "../HexViewer";
import { Copyable, InlineCopyable } from "../utils/Copyable";
import { Expandable } from "../utils/Expandable";
import { GenericDataDisplay } from "../utils/GenericDataDisplay";

export interface JsHookTrace {
  script: string;
  line: number;
  column: number;
  functionName?: string;
  fromEval: boolean;
  evalOrigin?: string;
}

export interface JsHookDomEventListener {
  tagName: string;
  id?: string;
  rel?: string;
  name?: string;
}

export type JsHookArgs = any;

export interface JsHookData {
  action: string;
  args: JsHookArgs[];
  trace?: JsHookTrace;
  // Verbose mode event type specific fields
  // Events: js/eval (currently disabled)
  retval?: string | Uint8Array;
  // Events: document.addEventListener, js/EventTarget.addEventListener
  funcSource?: string;
  // Events: js/EventTarget.addEventListener
  target?: JsHookDomEventListener;
  // dom/element/[src|href|rel|preconnect]
  element?: string;
  prop?: string;

  // Custom fields used via JS Hooks Processing
  httpMethod?: string;
  httpUrl?: string;
}

export interface JsHookEvent {
  data: JsHookData;
  count: number;
}
export interface JsHookCollection {
  events: JsHookEvent[];
  isTruncated?: boolean;
  pageUrl: string;
}

const FileOptionsViewer = ({
  data,
  filename,
  mimeType,
}: {
  data: Uint8Array;
  filename?: string;
  mimeType?: string;
}): JSX.Element => {
  const [fileViewerVisible, setFileViewerVisible] = useState(false);

  return (
    <>
      <button
        className="button is-small is-text has-text-link"
        onClick={(e) => {
          const b = new Blob([data.buffer], {
            type: mimeType ?? "application/octet-stream",
          });
          saveBlob(b, filename ?? "sample.bin");
        }}
        title="download"
      >
        <span className="icon is-small is-link">
          <FontAwesomeIcon icon="download" />
        </span>
      </button>
      <button
        className="button is-small is-text has-text-link"
        onClick={(e) => setFileViewerVisible(true)}
        title="view"
      >
        <span className="icon is-small is-link">
          <FontAwesomeIcon icon="code" />
        </span>
      </button>
      {fileViewerVisible && (
        <DataViewer
          data={data}
          isVisible={fileViewerVisible}
          filename={filename}
          mimeType={mimeType}
          onClose={() => {
            setFileViewerVisible(false);
          }}
        />
      )}
    </>
  );
};

const JsHookDataViewer = ({ event }: { event: JsHookData }): JSX.Element => {
  let items: Array<JSX.Element> = [];
  switch (event.action) {
    case "js/network/fetch": {
      if (event.httpUrl) {
        items.push(
          <Copyable data={event.httpUrl}>
            {truncateMidStr(event.httpUrl, 200)}
          </Copyable>
        );
      }
      if (
        event.args.length >= 2 &&
        event.args[1] &&
        typeof event.args[1] === "object"
      ) {
        // We iterate fields explicitly so we can control order and formatting better.
        let genericItemCount = 0;
        const genericItems = Object.fromEntries(
          Object.entries(event.args[1]).filter((x) => {
            const res = x[0] !== "method" && x[0] !== "body";
            if (res) {
              genericItemCount++;
            }
            return res;
          })
        );

        // Probe body content type
        let bodyLanguage = "text";
        if (
          "body" in event.args[1] &&
          event.args[1]["body"] &&
          "headers" in event.args[1] &&
          event.args[1]["headers"]
        ) {
          // Create headers object so we don't worry about case
          const headers = new Headers(event.args[1]["headers"]);
          if (headers.has("content-type")) {
            try {
              const type = headers.get("content-type");
              if (type !== null && type.length > 0) {
                if (type.includes("text/html")) {
                  bodyLanguage = "html";
                } else if (type.includes("text/xml")) {
                  bodyLanguage = "markup";
                } else if (type.includes("javascript")) {
                  bodyLanguage = "text/javascript";
                } else if (type.includes("application/json")) {
                  bodyLanguage = "json";
                }
              }
            } catch (e) {}
          }
        }

        items.push(
          <Expandable title="Request Details">
            {"method" in event.args[1] && event.args[1] && (
              <GenericDataDisplay data={{ method: event.args[1]["method"] }} />
            )}
            {"body" in event.args[1] && event.args[1] && (
              <div className="detection-detail">
                <ul>
                  <li>
                    <span className={"has-text-weight-medium"}>body</span>:
                    <span style={{ paddingLeft: 8 }}>
                      <SourceCodeString
                        value={event.args[1]["body"]}
                        language={bodyLanguage}
                        wrapLongLines={true}
                        toolbarEnabled={true}
                      />
                    </span>
                  </li>
                </ul>
              </div>
            )}
            {genericItemCount > 0 && <GenericDataDisplay data={genericItems} />}
          </Expandable>
        );
      }
      break;
    }
    case "js/network/XMLHttpRequest": {
      if (event.httpUrl) {
        items.push(
          <Copyable data={event.httpUrl}>
            {truncateMidStr(event.httpUrl, 200)}
          </Copyable>
        );
      }
      break;
    }
    case "js/document.write": {
      if (event.args && event.args[0] && typeof event.args[0] === "string") {
        items.push(
          <Expandable
            title={`Write content (${commafy(event.args[0].length)} bytes)`}
          >
            <SourceCodeString
              value={event.args[0]}
              language="html"
              toolbarEnabled={true}
              wrapLongLines={true}
            />
          </Expandable>
        );
      }
      break;
    }
    case "js.eval": {
      if (event.args) {
        items.push(
          <SourceCodeString
            value={event.args.join("\n")}
            language="javascript"
            wrapLongLines={true}
          />
        );
      }
      break;
    }
    case "js/atob": {
      if (event.retval) {
        let displayed: JSX.Element = <>N/A</>;
        if (typeof event.retval === "string") {
          const guessedLang = guessLanguageFromContent(event.retval) ?? "text";
          displayed = (
            <Expandable
              title={`Decoded as ${guessedLang} string (${commafy(
                event.retval.length
              )} characters)`}
            >
              <SourceCodeString
                value={event.retval}
                language={guessedLang ?? "text"}
                toolbarEnabled={true}
                wrapLongLines={true}
              />
            </Expandable>
          );
        } else if (event.retval instanceof Uint8Array) {
          displayed = (
            <>
              <span>{`Decoded value appears to be binary or non UTF-8 (${commafy(
                event.retval.length
              )} bytes)`}</span>
              <FileOptionsViewer data={event.retval} />
            </>
          );
        } else {
          displayed = (
            <Expandable title="Decoded Value (unknown type)">
              <GenericDataDisplay data={event.retval} />
            </Expandable>
          );
        }

        items.push(displayed);
      }
      break;
    }
    case "js/btoa": {
      if (
        event.args &&
        event.args.length > 0 &&
        typeof event.args[0] === "string"
      ) {
        let displayed = <>N/A</>;
        const enc = new TextEncoder();
        const bindata = enc.encode(event.args[0]);

        displayed = (
          <Expandable
            title={`Encoded (${commafy(event.args[0].length)} bytes)`}
            subtitle={<FileOptionsViewer data={bindata} />}
          >
            <SourceCodeString
              value={event.args[0]}
              language="text"
              toolbarEnabled={true}
              wrapLongLines={true}
            />
          </Expandable>
        );

        items.push(displayed);
      }
      break;
    }
    default: {
      items.push(<GenericDataDisplay data={event} />);
    }
  }

  // Common items
  if (event.trace) {
    items.push(
      <Expandable title="Execution Trace">
        <ul>
          <li>
            <span className="has-text-weight-medium pr-2">Script:</span>
            <span className="forensics-string pr-4">
              <InlineCopyable data={event.trace.script}>
                {truncateMidStr(event.trace.script, 200)}
              </InlineCopyable>
            </span>
          </li>
          {event.trace.functionName && (
            <li>
              <span className="has-text-weight-medium pr-2">Function:</span>
              <span className="forensics-string">
                {event.trace.functionName}
              </span>
            </li>
          )}
          <li>
            <span className="has-text-weight-medium pr-2">Line:</span>
            <span className="forensics-string">{event.trace.line}</span>
          </li>
          <li>
            <span className="has-text-weight-medium pr-2">Column:</span>
            <span className="forensics-string">{event.trace.column}</span>
          </li>
          {event.trace.fromEval && (
            <li>
              <span className="has-text-weight-medium pr-2">From Eval:</span>
              <span>{event.trace.fromEval ? "Yes" : "No"}</span>
            </li>
          )}
        </ul>
      </Expandable>
    );
  }

  // Build it
  return (
    <>
      <ul>
        {items.map((x, idx) => {
          return (
            <li key={idx}>
              <div>{x}</div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export const JsHooksViewer = ({
  savedArtifacts,
}: {
  savedArtifacts: SavedArtifact[];
}) => {
  const { api } = useContext(APIContext);
  const { getAccessTokenSilently } = useAuth0();

  const artifactOffsetMap: Map<string, number> = new Map(); // Map artifact path -> offset in array
  for (let i = 0; i < savedArtifacts.length; i++) {
    artifactOffsetMap.set(savedArtifacts[i].ArtifactPath, i);
  }

  const getArtifactByPath = (name: string): SavedArtifact | undefined => {
    const offset = artifactOffsetMap.get(name);
    if (offset === undefined || offset > savedArtifacts.length) {
      return undefined;
    }
    return savedArtifacts[offset];
  };

  const [selectedArtifact, setSelectedArtifact] = useState<
    SavedArtifact | undefined
  >();

  const [artifactData, setArtifactData] = useState<
    JsHookCollection | undefined
  >();

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (
      selectedArtifact === undefined ||
      selectedArtifact.ArtifactPath === undefined
    ) {
      return;
    }

    setIsLoading(true);
    api
      .callAPI(
        getAccessTokenSilently,
        api.getArtifactByPathToMemory,
        selectedArtifact!.ArtifactPath
      )
      .then((data?: string) => {
        if (data === undefined) {
          setErrorMessage("Error: Empty data returned");
          setArtifactData(undefined);
        } else {
          let dec: JsHookCollection = JSON.parse(data);
          enrichEventCollection(dec);
          setArtifactData(dec);
          setErrorMessage(undefined);
        }
      })
      .catch(() => console.log("download failed"))
      .finally(() => {
        setIsLoading(false);
      });
  }, [api, getAccessTokenSilently, selectedArtifact]);

  // Trigger initial artifact loading.
  if (savedArtifacts === undefined || savedArtifacts.length === 0) {
    return <div>No data</div>;
  }

  if (selectedArtifact === undefined) {
    setSelectedArtifact(savedArtifacts[0]);
  }

  const HookData = (): JSX.Element => {
    if (errorMessage !== undefined) {
      return <p>Error: {errorMessage}</p>;
    }
    if (selectedArtifact === undefined) {
      return <p>Problem loading data</p>;
    }

    if (isLoading) {
      return <p>Loading...</p>;
    }

    const eventSummary = summarizeEvents(artifactData);
    const displayedSummary =
      eventSummary.length === 0 ? (
        <></>
      ) : (
        <div className="block">
          <p className="has-text-weight-bold">Summary:</p>
          <ul>
            {eventSummary.map((s, idx) => {
              return (
                <li key={idx} className="pl-4">
                  {s[1] === undefined ? (
                    <span className="has-text-weight-medium">{s[0]}</span>
                  ) : (
                    <>
                      <span className="has-text-weight-medium">
                        {s[0]}:&nbsp;
                      </span>
                      <span>{s[1]}</span>
                    </>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      );

    return (
      <div className="mt-4">
        {displayedSummary}
        <div className="block">
          <ol className="ml-4 lined-list">
            {artifactData?.events.map((e, idx) => {
              return (
                <li key={idx}>
                  <span>{actionToDescription(e.data)}</span>
                  <div>
                    <JsHookDataViewer event={e.data} />
                  </div>
                </li>
              );
            })}
          </ol>
        </div>
      </div>
    );
  };

  return (
    <div>
      <article className="message">
        <div className="message-body">
          <p>
            Javascript execution hooks monitor a subset of in-browser Javascript
            actions performed by a web page. These actions are used to assist in
            understanding a pages' behavior, as well as authoring detection
            rules. Some pages have multiple event logs, when this occurs you
            will be able to select the event log below. This can occur when a
            single page contains multiple Javascript execution sequences from
            elements such as iframes.
          </p>
        </div>
      </article>

      {savedArtifacts.length === 1 ? (
        <div className="blocK">
          <span className="mr-3 has-text-weight-bold">Hook Event Log:</span>
          <span>{savedArtifacts[0].ArtifactPath.replace(/.*\//, "")}</span>
        </div>
      ) : (
        <nav className="level">
          <div className="level-left">
            <div className="level-item">
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label htmlFor="jshookitem" className="label">
                    Hook&nbsp;Event&nbsp;Log:
                  </label>
                </div>

                <div className="field-body select">
                  <select
                    id="jshookitem"
                    className="is-small"
                    onChange={(ev) => {
                      ev.preventDefault();
                      setSelectedArtifact(getArtifactByPath(ev.target.value));
                    }}
                  >
                    {savedArtifacts.map((a, idx) => {
                      return (
                        <option key={idx} value={a.ArtifactPath}>
                          {`${idx + 1} - ${a.ArtifactPath.replace(/.*\//, "")}`}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </nav>
      )}

      <div>
        <HookData />
      </div>
    </div>
  );
};

/**
 * Some hook events can use pre-processing so its done just once
 * rather than each time we access it.
 *
 * This method modifies the passed value!
 *
 * @param event Event to pre-process/munge as needed
 */
function enrichEventCollection(collection: JsHookCollection) {
  for (const e of collection.events) {
    let event = e.data;
    switch (event.action) {
      case "js/network/fetch": {
        // Pull out method and url
        let method = "GET";
        let url = "/";
        if (event.args) {
          if (event.args.length > 0 && event.args[0]) {
            url = event.args[0];
          }
          if (
            event.args.length >= 2 &&
            event.args[1] &&
            typeof event.args[1] === "object" &&
            "method" in event.args[1]
          ) {
            method = event.args[1]["method"];
          }
        }

        event.httpMethod = method;
        event.httpUrl = url;

        break;
      }
      case "js/network/XMLHttpRequest": {
        // Pull out method and url
        const method = event.args && event.args[0] ? event.args[0] : "GET";
        event.httpMethod = method;

        const url = event.args && event.args[1] ? event.args[1] : "/";
        event.httpUrl = url;

        break;
      }
      case "js/atob": {
        if (event.args && event.args.length > 0) {
          // Grab value returned by atob (not passed in hooks for brevity)
          try {
            let decoded = Base64.toUint8Array(event.args[0]);
            // Try to see if its a string, if yes, return that.
            if (isUtf8(decoded)) {
              try {
                const utf8Decoder = new TextDecoder("utf-8");
                event.retval = utf8Decoder.decode(decoded);
              } catch (e) {
                event.retval = decoded; // Couldn't decode just leave as bytes
              }
            } else {
              // Unsure as to charset or its binary --> leave as bytes
              event.retval = decoded;
            }
          } catch (e) {
            // Couldn't decode.  Garbage?
            SplunkRum.error(e);
            event.retval = undefined;
          }
        }
        break;
      }
    }
  }
}

function actionToDescription(event: JsHookData): string {
  switch (event.action) {
    case "js/network/fetch": {
      let method = event.httpMethod ?? "GET";
      return `HTTP ${method} via fetch()`;
    }
    case "js/network/XMLHttpRequest": {
      const method = event.httpMethod ?? "GET";
      return `HTTP ${method} via XMLHttpRequest()`;
    }
    case "js/document.write":
      return "Document Write";
    case "js.eval":
      return "Code Eval";
    case "js/atob":
      return "Base64 Decode";
    case "js/btoa":
      return "Base64 Encode";
    // Items below are only enabled in verbode event collection mode
    case "js/window.addEventListener":
      return "Add Event Listener (Scope: window)";
    case "document.addEventListener":
      return "Add Event Listener (Scope: document)";
    case "js/EventTarget.addEventListener":
      return "Add Event Listener (Scope: DOM Element)";
    case "js/setTimeout":
      return "Javascript Timer";
    case "js/Image.src":
      return "Assignment to image src.";
    case "js/Image.onload":
      return "Image onLoad trigger";
    case "js/Audio":
      return "Audio";
    case "js/FontFace":
      return "Font face loaded";
    case "js/WebSocket":
      return "WebSocket created";
    case "dom/innerHTML":
      return "Assignment to DOM innerHTML";
    case "dom/CssBackgroundImage":
      return "Set background image via CSS";
  }

  // Generic fallbacks
  if (event.action.startsWith("js/network/")) {
    return "Network Retrieval";
  } else if (event.action.startsWith("dom/element/")) {
    const attr = event.action.replace(/^\/dom\/element\//, "");
    return `Set DOM Attribute ${attr} on tag ${event.element ?? ""}`;
  }

  return `${event.action} (no description)`;
}

/**
 * Guess syntax highlighting language based on content
 * @param value input
 * @returns Guessed language for source code viewer or undefined if no guess.
 */
function guessLanguageFromContent(value: string): string | undefined {
  if (value.length === 0) {
    return undefined;
  }

  let language: string | undefined = undefined;

  if (
    (value.startsWith("{") && value.endsWith("}")) ||
    (value.startsWith("[") && value.endsWith("]"))
  ) {
    language = "json";
  } else {
    const partialValue = value.substring(0, 512);
    if (
      partialValue.startsWith("!function(") ||
      partialValue.startsWith("~function(")
    ) {
      language = "javascript";
    } else if (
      partialValue.match(/<xml|svg|html|script|!doctype/i) ||
      partialValue.match(/<\/a|img|script>/i)
    ) {
      language = "markup";
    } else if (
      partialValue.match(/function\(|document\.write|\.location|new URL\(/i)
    ) {
      language = "javascript";
    }
  }

  return language;
}

type EventSummary = [string, number?];

/**
 * Summarize events in a specific order for presentation
 *
 * Not all event types are included in the summary by design, some provide
 * higher information value than others.
 * @param eventLog
 * @returns
 */
function summarizeEvents(eventLog?: JsHookCollection): Array<EventSummary> {
  if (eventLog === undefined) {
    return [];
  }

  let summary: Array<EventSummary> = [];

  let numEvents = 0;
  let numNetworkCalls = 0;
  let numDocWrite = 0;
  let numEvalCode = 0;
  let numB64Enc = 0;
  let numB64Dec = 0;
  let numEventListener = 0;
  let numWebsocket = 0;
  let numOther = 0;

  const events = eventLog.events;

  for (let i = 0; i < events.length; i++) {
    numEvents += events[i].count;

    if (events[i].data.action.startsWith("js/network")) {
      numNetworkCalls += events[i].count ?? 1;
    } else {
      let ct = events[i].count ?? 1;
      switch (events[i].data.action) {
        case "js/document.write":
          numDocWrite += ct;
          break;
        case "js.eval":
          numEvalCode += ct;
          break;
        case "js/atob":
          numB64Enc += ct;
          break;
        case "js/btoa":
          numB64Dec += ct;
          break;
        // Items below are only enabled in verbode event collection mode
        case "js/window.addEventListener":
        case "document.addEventListener":
        case "js/EventTarget.addEventListener":
          numEventListener += ct;
          break;
        case "js/WebSocket":
          numWebsocket += ct;
          break;
        default:
          numOther += ct;
      }
    }
  }

  summary.push(["Events", numEvents]);

  if (eventLog.isTruncated) {
    summary.push(["Trace truncated due to excessive size"]);
  }
  if (numNetworkCalls > 0) {
    summary.push(["Network Calls", numNetworkCalls]);
  }
  if (numDocWrite > 0) {
    summary.push(["Document Writes", numDocWrite]);
  }
  if (numEvalCode > 0) {
    summary.push(["Code Evals", numEvalCode]);
  }
  if (numB64Enc > 0) {
    summary.push(["Base64 Encodes", numB64Enc]);
  }
  if (numB64Dec > 0) {
    summary.push(["Base64 Decodes", numB64Dec]);
  }
  if (numEventListener > 0) {
    summary.push(["Event Listener Installs", numEventListener]);
  }
  if (numWebsocket > 0) {
    summary.push(["Websocket creation", numWebsocket]);
  }
  if (numOther > 0) {
    summary.push(["Other Actions", numOther]);
  }

  return summary;
}
