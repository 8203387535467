export interface ArtifactCommon {
  Timestamp?: string;
  Verdict: string;
  Details?: { [key: string]: any };
  Engines: Array<string>;
}
export interface Body {
  url: string;
  priority: number;
  engines?: Array<string>;
  parameters?: Array<Parameter>;
  profile: string;
}
export interface Body1 {
  priority: number;
  engines: string;
  parameters?: Array<Parameter>;
  filename: string;
  filedata?: Blob;
  profile: string;
}
export interface Credential {
  Username: string;
  Password: string;
}
export interface DNSRequest extends ArtifactCommon {
  Server: string;
  QueryType: string;
  Query: string;
  Responses?: Array<DNSRequestResponses>;
}
export interface DNSRequestResponses {
  Type: string;
  Value: string;
}
export interface DNSServer extends ArtifactCommon {
  IP: string;
}
export interface Detection extends ArtifactCommon {
  Name: string;
  Description: string;
  Severity: number;
  MalwareFamilies?: Array<string>;
  PhishKitFamilies?: Array<string>;
  PhishedBrands?: Array<string>;
}
export interface Engine {
  Name: string;
  DefaultEnabled?: boolean;
  SupportedTypes?: Array<string>;
}
export interface EngineResults {
  Score: number;
  DisplayScore: number;
  Forensics: EngineResultsForensics;
  Details: { [key: string]: any };
}
export interface EngineResultsForensics {
  Raw: string;
  Normalized: string;
}
export interface FileMetadata {
  MD5: string;
  SHA256: string;
  Size: number;
  MimeType: string;
  FileType: string;
}
export interface Form extends ArtifactCommon {
  Method: string;
  Action: string;
  SourceCode: string;
  PageURL: string;
  Inputs?: Array<FormInput>;
}
export interface FormInput {
  Name: string;
  ID: string;
  Type: string;
  Placeholder: string;
  SourceCode: string;
}
export interface HTTP extends ArtifactCommon {
  Hostname: string;
  Path: string;
  URL: string;
  Source?: IPAndPort;
  Destination?: IPAndPort;
  RequestSize: number;
  ResponseSize: number;
  TotalSize: number;
  Method: string;
  StatusCode: number;
  UserAgent: string;
  RequestHeaders?: { [key: string]: string };
  ResponseHeaders?: { [key: string]: string };
}
export interface Host extends ArtifactCommon {
  Hostname: string;
  IP: string;
  Country: string;
  City: string;
  ASN: number;
  Organization: string;
}
export interface IPAndPort {
  IP: string;
  Port: number;
}
export interface InlineResponse200 {
  URL: string;
}
export interface Job {
  ID: string;
  TenantID: string;
  Username: string;
  Submission: {
    Name: string;
    SHA256: string;
    MD5: string;
  };
  Profile?: string;
  Parameters?: Array<{ Name: string; Value: string }>;
  APIKey?: { ID: string; Label: string };
  Resources: Array<Resource>;
  ResourceCount: number;
  Priority: number;
  Labels: Array<Label>;
  State: StateType;
  Score: number;
  DisplayScore: number;
  Tasks: Array<Task>;
  CreatedAt: string;
  UpdatedAt: string;
  StartedAt: string;
  CompletedAt?: string;
  ForensicsPath: string;
  SubmissionSource: SourceType;
  RequestedEngines: Array<string>;
  Sharing: {
    ShareToken: string;
    SharedBy: string;
    SharedAt: string;
  };
  Verdict?: string;
}
export interface Label {
  Type?: LabelType;
  Value: string;
}
export enum LabelType {
  MalwareFamily = "malware_family",
  PhishedBrand = "phished_brand",
  PhishKitFamily = "phishkit_family",
  Verdict = "verdict",
  Generic = "generic",
}

export interface Log extends ArtifactCommon {
  LogLine: string;
}
export interface MalwareConfig extends ArtifactCommon {
  MalwareName: string;
  Addresses?: Array<TypedString>;
  Credentials?: Array<Credential>;
  Keys?: Array<TypedString>;
  Filenames?: Array<string>;
  Other?: { [key: string]: any };
}
export interface MitreAttack extends ArtifactCommon {
  Tactic: string;
  Technique: string;
  SubTechnique: string;
  ID: string;
}
export interface File extends ArtifactCommon {
  FileName: string;
  Path: string;
  FileType: string;
  MimeType?: string;
  Size: number;
  Context: string;
  SHA256: string;
  MD5: string;
  Ssdeep: string;
  NetworkSources?: Array<string>;
}
export interface String extends ArtifactCommon {
  String: string;
}
export interface Mutex extends ArtifactCommon {
  Name: string;
}
export interface Network extends ArtifactCommon {
  Protocol: string;
  Source: IPAndPort;
  Destination: IPAndPort;
  Length: number;
  Service: string;
}
export interface NewJobResponse {
  JobID: string;
  QuotaRemaining: number;
  QueueDepth: number;
}
export interface NormalizedForensics {
  Version: string;
  Engine: string;
  Score: number;
  DisplayScore: number;
  StartTime?: string;
  EndTime?: string;
  Verdict: string;
  MalwareFamilies?: Array<string>;
  PhishKitFamilies?: Array<string>;
  PhishedBrands?: Array<string>;
  Details: { [key: string]: any };
  Detections?: Array<Detection>;
  Files: Array<any>;
  TLS: Array<TLS>;
  HTTP: Array<HTTP>;
  URLs: Array<URL>;
  Network: Array<Network>;
  DNSServers: Array<DNSServer>;
  DNSRequests: Array<DNSRequest>;
  Hosts: Array<Host>;
  Processes: Array<Process>;
  Logs: Array<Log>;
  Strings: Array<String>;
  Screenshots: Array<Screenshot>;
  Images?: Image[];
  MitreAttacks: Array<MitreAttack>;
  RegistryKeys: Array<RegistryKey>;
  Mutexes: Array<Mutex>;
  MalwareConfigs: Array<MalwareConfig>;
  Forms: Array<Form>;
  WhoisResults: Whois[];
  SavedArtifacts?: SavedArtifact[];
}
export interface Parameter {
  Name: string;
  Value: string;
}
export interface Process extends ArtifactCommon {
  Name: string;
  Path: string;
  PID: number;
  PPID: number;
  Arguments: string;
}
export interface RegistryKey extends ArtifactCommon {
  Name: string;
  Action: string;
}
export interface Resource {
  ID: string;
  JobID: string;
  Type: "URL" | "file";
  Name: string;
  Location: string;
  FileMetadata: FileMetadata;
  ParentID: string;
  Tasks?: Array<Task>;
  CreatedAt: string;
  Score: number;
  DisplayScore: number;
}
export interface Screenshot extends ArtifactCommon {
  String: string;
  URI: string;
  ArtifactPath: string;
  ImageHashes?: any;
  Resource: string;
}
export interface SearchResult {
  HasNext?: boolean;
  Jobs?: Array<Job>;
}
export enum SourceType {
  UI = "UI",
  API = "API",
}
export enum StateType {
  Pending = "pending",
  Inprogress = "inprogress",
  Done = "done",
  Error = "error",
  Dispatched = "dispatched",
  Timedout = "timedout",
  Any = "any",
}
export interface TLS extends ArtifactCommon {
  Source?: IPAndPort;
  Destination?: IPAndPort;
  Fingerprint: string;
  Subject: string;
  Issuer: string;
}
export interface Task {
  ID: string;
  CreatedAt: string;
  UpdatedAt: string;
  ResourceID: string;
  Engine: string;
  State: StateType;
  StateText: string;
  Priority: number;
  Results: EngineResults;
}
export interface TypedString {
  Type: string;
  Value: string;
}
export interface URL extends ArtifactCommon {
  Context: string;
  URL: string;
  LinkText: string;
}
export interface Image extends ArtifactCommon {
  Type: string;
  ArtifactPath: string;
  ImageHashes: { [key: string]: string };
  Resource: string;
  DetectedObjects: DetectedObject[];
}
export interface DetectedObject {
  Label: string;
  Brand: string;
  Confidence: number;
  BoundingBox: BoundingBox;
}
export interface BoundingBox {
  UpperLeft: Point;
  LowerRight: Point;
}
export interface Point {
  X: number;
  Y: number;
}
export interface Whois extends ArtifactCommon {
  DomainName: string;
  Registrar: string;
  WhoisServer: string;
  UpdatedAt: string;
  CreatedAt: string;
  ExpiresAt: string;
  NameServers: string[];
  Emails: string[];
  DNSSec: string;
  Name: string;
  Org: string;
  Address: string;
  City: string;
  State: string;
  Country: string;
  ZipCode: string;
}
export interface SavedArtifact extends ArtifactCommon {
  Type: "pcap" | "har" | "web_artifact" | "sandbox_artifact";
  ArtifactPath: string;
  Name: string;
}

export interface Caveat {
  code: number;
  code_human: string;
  resource_name: string;
}

export interface UserRecord {
  user_id: string;
  email: string;
  last_login: string;
  last_ip: string;
  logins_count: number;
  email_verified: boolean;
  role: string;
}

export interface UserList {
  start: number;
  limit: number;
  length: number;
  total: number;
  next: string;
  users: UserRecord[];
}

export interface NewUserRecord {
  userid: string;
}

export interface APIKey {
  TenantID: string;
  Label: string;
  ID: string;
  Permissions: string[];
  CreatedAt: string;
  UpdatedAt: string;
  DeletedAt?: string;
}

export interface APIKeyWithSecrets {
  Secret: string;
  TenantID: string;
  Label: string;
  ID: string;
  Permissions: { ID: string }[];
  CreatedAt: string;
  UpdatedAt: string;
}

export interface RepProxyReturnTypes {
  "/v1/cachenormalizeurls": RepProxyCacheNormalizeURLReturnType[];
  "/v1/rateurls": RepProxyRateURLReturnType[];
  "/v1/ratelinktexts": RepProxyRateLinkTextType[];
}

export interface RepProxyCacheNormalizeURLReturnType {
  URL: string;
  NormalizedTo: string;
  TTLOverride: any;
  NormalizedByID: string;
}

export interface RepProxyRateURLReturnType {
  URL: string;
  NormalizedTo: string;
  Score: number;
  Features?: string[];
}

export interface RepProxyRateLinkTextType {
  String: string;
  Score: number;
  Features?: string[];
}

// /v1/jobs/searchv2 API query params
export interface SearchV2QueryParams {
  /** Search mode 'forensics' or 'resources' */
  mode: string;
  /** Search term (the query string) */
  term: string;
  /** Search field */
  field: string;
  /** Search match type */
  type: string;
  /** Job submitter */
  submitted_by: string;
  /** Job search start time */
  start_time: string;
  /** Job search end time */
  end_time: string;
  /** Results per page */
  count: number;
  /** Result page number */
  page: number;
  /** Submitted with API key id */
  api_key_id: string;
  /** Job tenants (admin only) */
  tenants: string[];
  /** Job verdict */
  verdict: string;
  /** Job min score */
  score_min: number;
  /** Job max score */
  score_max: number;
}

export interface SearchV2URL {
  URL: string;
  MD5: string;
  Hostname: string;
}
export interface SearchV2File {
  Name: string;
  MD5: string;
  SHA256: string;
  MimeType: string;
  FileType: string;
  Size: number;
}
export interface SearchV2Domain {
  Domain: string;
}
export interface SearchV2Host {
  Hostname: string;
}
export interface SearchV2IP {
  IP: string;
}
export interface SearchV2Task {
  ID: string;
  EngineName: string;
  Score: number;
}
export interface SearchV2IndexedJob {
  ID: string;
  TenantID: string;
  "@timestamp": string;
  Username: string;
  APIKeyID: string;
  APIKeyLabel: string;
  SubmittedFile?: SearchV2File;
  SubmittedURL?: SearchV2URL;
  Files: SearchV2File[];
  URLs: SearchV2URL[];
  Tasks: SearchV2Task[];
  Score: number;
  Verdict: string;
  Labels: Label[];
  Shared: boolean;
  IPs: SearchV2IP[];
  Hosts: SearchV2Host[];
  Domains: SearchV2Domain[];
  ResourceCount?: number;
}

export interface SearchV2IndexedTaskForensicsResourceSummary {
  ID: string;
  Name: string;
  Type: string;
}

export interface SearchV2IndexedTaskForensics {
  ID: string;
  Engine: string;
  CreatedAt: string;
  Files?: SearchV2File[];
  URLs: SearchV2URL[];
  IPs: SearchV2IP[];
  Hosts: SearchV2Host[];
  Domains: SearchV2Domain[];
  Resource: SearchV2IndexedTaskForensicsResourceSummary;
}
export interface SearchV2IndexedForensics {
  ID: string;
  TenantID: string;
  "@timestamp": string;
  Username: string;
  APIKeyID: string;
  APIKeyLabel: string;
  SubmittedFile?: SearchV2File;
  SubmittedURL?: SearchV2URL;
  Score: number;
  Verdict: string;
  Labels: Label[];
  TaskForensics: SearchV2IndexedTaskForensics[];
  ResourceCount?: number;
}
export interface SearchV2Highlights {
  ResourceType: string;
  HTMLString: string;
  FieldContext: string;
}
export interface SearchV2JobResult {
  Job: SearchV2IndexedJob;
  Highlights: SearchV2Highlights[];
}
export interface SearchV2ForensicsResult {
  Forensics: SearchV2IndexedForensics;
  Highlights: SearchV2Highlights[];
}
export interface SearchV2Results {
  HasNext: boolean;
  HasError: boolean;
  Jobs: SearchV2JobResult[];
  Forensics: SearchV2ForensicsResult[];
}

export interface EmailSubmissionOptions {
  // When customer facing tenantid will be in top level Config interface as it will be automatically determined via auth
  // rather than via params. For internal admin UI we allow staff to select the tenantid.
  Priority: number;
  Profile: string;

  Dkim: {
    Domain: string;
  };

  Spf: {
    Enabled: boolean;
  };

  AllowedSenders: {
    Addresses: string[];
  };

  LegacyAddressAlias: string;
}

// Admin requests to create new submission configs can specify the tenantid
export interface AdminCreateEmailSubmissionRequest
  extends EmailSubmissionOptions {
  TenantID: string;
}

export interface SelfServiceCreateEmailSubmissionRequest
  extends EmailSubmissionOptions {}

export interface EmailSubmissionConfig extends EmailSubmissionOptions {
  SubmitID: string;
  TenantID: string;
  CreatedAt: string;
  UpdatedAt: string;
}

export interface BaseApiResponse {
  code: number;
  statusText: string;
}

export interface CreateEmailSubmissionResponse extends BaseApiResponse {
  config?: EmailSubmissionConfig;
}
