import React, { useState } from "react";

export const MultilineString = ({
  isExpanded = false,
  content,
  classNames = [],
}: {
  isExpanded?: boolean;
  content: string;
  classNames?: string[];
}) => {
  const [expanded, setExpanded] = useState(isExpanded);

  let classes = expanded
    ? ["multiline-string"]
    : ["multiline-string", "collapsed"];

  if (classNames && classNames.length > 0) {
    classes.push(...classNames);
  }

  return (
    <div
      className={classes.join(" ")}
      onClick={(evt) => setExpanded(!expanded)}
    >
      {content}
    </div>
  );
};
