import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ToggleSwitch } from "src/components/utils/ToggleSwitch";
import {
  useCreateAPIKeyHook,
  useDeleteAPIKeyHook,
  useEditAPIKeyHook,
  useListAPIKeysHook,
} from "src/lib/API";
import { APIKey, APIKeyWithSecrets } from "src/lib/APITypes";

export const PATH = "/apikeys";
export const PATH_CREATE = "/apikeys/create";
export const PATH_EDIT = "/apikeys/:id";

export const ListKeys = () => {
  const { keys, refresh } = useListAPIKeysHook();
  const deletion = useDeleteAPIKeyHook();

  useEffect(() => {
    if (!deletion.response?.code) return;
    if (deletion.response.code === 200) {
      toast.success("Successfully deleted!");
      return;
    }

    toast.error(
      `Error [${deletion.response?.code}]: ${deletion.response?.statusText}`,
      {
        autoClose: false,
      }
    );
  }, [deletion.response?.code, deletion.response?.statusText]);

  useEffect(() => {
    if (deletion.updatedAt) refresh();
  }, [deletion.updatedAt, refresh]);

  const sortbyTenantIDandLabel = (a: APIKey, b: APIKey) =>
    a.TenantID === b.TenantID
      ? a.Label > b.Label
        ? 1
        : -1
      : a.TenantID > b.TenantID
      ? 1
      : -1;

  const notDeleted = keys
    .filter((k) => !k.DeletedAt)
    .sort(sortbyTenantIDandLabel);
  const deleted = keys.filter((k) => k.DeletedAt).sort(sortbyTenantIDandLabel);

  return (
    <div className="section">
      <div className="container">
        <h1 className="title is-4 has-text-centered">API Keys</h1>
      </div>
      <div className="container">
        <NavLink to={PATH_CREATE} className="button is-primary is-pulled-right">
          <span className="icon is-small">
            <FontAwesomeIcon icon={["far", "plus"]} />
          </span>
          <span>New</span>
        </NavLink>
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>Tenant</th>
              <th>Label</th>
              <th>Permissions</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>ID</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {notDeleted.map((k) => (
              <tr key={k.ID}>
                <td>{k.TenantID}</td>
                <td>{k.Label}</td>
                <td>{k.Permissions.sort().join(", ") || "-"}</td>
                <td>{new Date(Date.parse(k.CreatedAt)).toLocaleString()}</td>
                <td>{new Date(Date.parse(k.UpdatedAt)).toLocaleString()}</td>
                <td>{k.ID}</td>
                <td>
                  <div className="buttons">
                    <div className="button is-small">
                      <NavLink to={`/apikeys/${k.ID}`}>
                        <span className="icon is-small">
                          <FontAwesomeIcon icon={["fas", "edit"]} />
                        </span>
                      </NavLink>
                    </div>
                    <button
                      className="button is-small is-danger"
                      disabled={deletion.isLoading}
                      onClick={(evt) => {
                        evt.preventDefault();
                        const confirmation = window.prompt(
                          `Are you sure you want to delete ${k.TenantID}'s API Key called ${k.Label}? Type DELETE to execute!`
                        );
                        if (confirmation === "DELETE") {
                          deletion.del(k.ID);
                        } else {
                          window.alert("Operation cancelled!");
                        }
                      }}
                    >
                      <span className="icon is-small">
                        <FontAwesomeIcon icon={["fas", "trash"]} />
                      </span>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <hr />
        <div className="container">
          <h1 className="title is-4 has-text-centered">Deleted API Keys</h1>
        </div>
        <table className="table is-fullwidth">
          <thead>
            <tr>
              <th>Tenant</th>
              <th>Label</th>
              <th>Permissions</th>
              <th>Created At</th>
              <th>Deleted At</th>
              <th>ID</th>
            </tr>
          </thead>
          <tbody>
            {deleted.map((k) => (
              <tr key={k.ID}>
                <td>{k.TenantID}</td>
                <td>{k.Label}</td>
                <td>{k.Permissions.sort().join(", ") || "-"}</td>
                <td>{new Date(Date.parse(k.CreatedAt)).toLocaleString()}</td>
                <td>{new Date(Date.parse(k.DeletedAt!)).toLocaleString()}</td>
                <td>{k.ID}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const AVAILABLE_PERMISSIONS = [
  "app:admin",
  "auditrule:view",
  "ioc:view",
  "job:share",
  "job:submit",
  "job:view",
  "job:delete",
  "job:report",
].sort();

export const APIKeyPreview = (args: APIKeyWithSecrets) => {
  return (
    <div className="section">
      <div className="container is-max-desktop">
        <div className="container">
          <h1 className="title is-4 has-text-centered">Your New API Key</h1>
        </div>
        <article className="message is-danger">
          <div className="message-header">
            <p>Save Your Secret API Key</p>
          </div>
          <div className="message-body has-text-centered">
            <p>Here's your API secret to use:</p>
            <p>
              <strong>{args.Secret}</strong>
            </p>
            <p>
              Save it to some place secure <strong>BEFORE</strong> you close
              this window.
            </p>
            <p>
              This key is configured for <strong>{args.TenantID}</strong> and is
              called <strong>{args.Label}</strong>.
            </p>
            {args.Permissions && args.Permissions.length > 0 && (
              <>
                <p>It has the following permissions:</p>
                <ul>
                  {args.Permissions.map(({ ID }) => ID)
                    .sort()
                    .map((p) => (
                      <li key={p}>{p}</li>
                    ))}
                </ul>
              </>
            )}
          </div>
        </article>
      </div>
    </div>
  );
};

export const EditForm = () => {
  const params = useParams();
  const { edit, response, isLoading, existingKey } = useEditAPIKeyHook(
    params.id ?? ""
  );
  const [label, setLabel] = useState<string>("");
  const [permissions, setPermissions] = useState<string[]>([]);

  useEffect(() => {
    if (existingKey?.Label) setLabel(existingKey.Label);
    if (existingKey?.Permissions) setPermissions(existingKey.Permissions);
  }, [existingKey?.Label, existingKey?.Permissions]);

  useEffect(() => {
    if (!response?.code) return;
    if (response.code === 200) {
      toast.success("Saved!");
      return;
    }

    toast.error(`Error [${response?.code}]: ${response?.statusText}`, {
      autoClose: false,
    });
  }, [response?.code, response?.statusText]);

  if (isLoading) return <p>loading…</p>;

  return (
    <div className="section">
      <div className="container is-max-desktop">
        <div className="container">
          <h1 className="title is-4 has-text-centered">Edit an API Key</h1>
        </div>
        <form
          onSubmit={(evt) => {
            evt.preventDefault();
            edit({
              Label: label!,
              Permissions: permissions,
            });
          }}
        >
          <fieldset disabled={isLoading}>
            <div className="field">
              <label className="label">Tenant ID</label>
              <div className="control">
                <input
                  disabled
                  value={existingKey?.TenantID || ""}
                  className="input"
                  type="text"
                  placeholder="acme"
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Label</label>
              <div className="control">
                <input
                  required
                  value={label}
                  onChange={(evt) => {
                    evt.preventDefault();
                    setLabel(evt.target.value);
                  }}
                  className="input"
                  type="text"
                  placeholder="General"
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Permissions</label>
              {AVAILABLE_PERMISSIONS.map((p) => (
                <div className="control" key={p}>
                  <ToggleSwitch
                    name={p}
                    checked={permissions.includes(p)}
                    onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
                      const cp = [...permissions];
                      const i = cp.indexOf(p);
                      if (i >= 0) cp.splice(i, 1);
                      else cp.push(p);
                      setPermissions(cp);
                    }}
                    label={p}
                  />
                </div>
              ))}
            </div>
            <div className="control">
              <input
                type="submit"
                className="button is-primary"
                disabled={isLoading || !label}
              />
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export const CreateForm = () => {
  const [tenantID, setTenantID] = useState<string>("");
  const [label, setLabel] = useState<string>("");
  const [permissions, setPermissions] = useState<string[]>([]);
  const { submit, response, isLoading } = useCreateAPIKeyHook();

  useEffect(() => {
    if (!response?.code || response.code === 200) return;
    toast.error(`Error [${response?.code}]: ${response?.statusText}`, {
      autoClose: false,
    });
  }, [response?.code, response?.statusText]);

  if (response?.key) return <APIKeyPreview {...response.key} />;

  return (
    <div className="section">
      <div className="container is-max-desktop">
        <div className="container">
          <h1 className="title is-4 has-text-centered">Create a New API Key</h1>
        </div>
        <form
          onSubmit={(evt) => {
            evt.preventDefault();
            submit({
              TenantID: tenantID!,
              Label: label!,
              Permissions: permissions,
            });
          }}
        >
          <fieldset disabled={isLoading}>
            <div className="field">
              <label className="label">Tenant ID</label>
              <div className="control">
                <input
                  required
                  value={tenantID}
                  onChange={(evt) => {
                    evt.preventDefault();
                    setTenantID(evt.target.value);
                  }}
                  className="input"
                  type="text"
                  placeholder="acme"
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Label</label>
              <div className="control">
                <input
                  required
                  value={label}
                  onChange={(evt) => {
                    evt.preventDefault();
                    setLabel(evt.target.value);
                  }}
                  className="input"
                  type="text"
                  placeholder="General"
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Permissions</label>
              {AVAILABLE_PERMISSIONS.map((p) => (
                <div className="control" key={p}>
                  <ToggleSwitch
                    name={p}
                    checked={permissions.includes(p)}
                    onChange={(evt: React.ChangeEvent<HTMLSelectElement>) => {
                      const cp = [...permissions];
                      const i = cp.indexOf(p);
                      if (i >= 0) cp.splice(i, 1);
                      else cp.push(p);
                      setPermissions(cp);
                    }}
                    label={p}
                  />
                </div>
              ))}
            </div>
            <div className="control">
              <input
                type="submit"
                className="button is-primary"
                disabled={isLoading || !tenantID || !label}
              />
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  );
};
