import React from "react";
import { Score } from "../Score";
import { DetectionBox } from "../forensics/Detections";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { S3ArtifactDownload } from "../utils/S3ArtifactDownload";
import { FileSize } from "../FileSize";
import { ResourceSummary } from "../ResourceSummary";
import { LoadingMessage } from "./LoadingMessage";
import { ForensicsScreenshots } from "../forensics/ForensicsScreenshots";
import { ThreatName } from "../utils/ThreatName";

export const VMRayResults = (props) => {
  const task = props.task;

  const details = task.Results.Details || {};

  const samples = details.Samples || [];

  let pcap = null;
  if (props.forensics && typeof props.forensics === "object") {
    const pcaps = (props.forensics.SavedArtifacts || []).filter(
      (s) => s.Type === "pcap"
    );
    if (pcaps.length > 0) {
      pcap = pcaps[0].ArtifactPath;
    }
  }

  if (details.PseudoTask && details.VMName === "resource_extraction") {
    const resources = details.ExtractedResources || [];
    return (
      <section>
        <ResourceSummary
          job={props.job}
          task={props.task}
          resource={props.resource}
        />
        <p className="has-margin-bottom-20">
          VMRay extracted the following resources from{" "}
          <span className="has-text-weight-bold">
            <ThreatName name={props.resource.Name} />
          </span>
          :
        </p>
        {resources.map((r) => (
          <div key={r.Name} className="card has-margin-bottom-20">
            <div className="card-header">
              <p className="card-header-title">{r.Name}</p>
            </div>
            <div className="card-content">
              <ul>
                <li>
                  <b>Name:</b> {r.Name}
                </li>
                {r.FileMetadata && (
                  <>
                    <li>
                      <b>SHA256 Hash:</b> {r.FileMetadata.SHA256}
                    </li>
                    <li>
                      <b>MD5 Hash:</b> {r.FileMetadata.MD5}
                    </li>
                    <li>
                      <b>File Size:</b> <FileSize size={r.FileMetadata.Size} />
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        ))}
      </section>
    );
  }

  return (
    <section>
      <ResourceSummary
        job={props.job}
        task={props.task}
        resource={props.resource}
      />
      <ul>
        {details.UIURL && (
          <li>
            <b>Permalink:</b>{" "}
            <a href={details.UIURL} target="_blank" rel="noopener noreferrer">
              VMRay Analysis{" "}
              {details.AnalysisID ? `#${details.AnalysisID}` : ""}
            </a>
          </li>
        )}
      </ul>

      {samples.map((s, idx) => {
        const sd = s.Sample.Data;
        const analyses = s.Analyses || [];
        return (
          <div key={sd.sample_id}>
            {analyses.length > 0 && (
              <table className="table is-narrow is-striped is-bordered has-margin-top-10">
                <tbody>
                  <tr>
                    <th>Analysis Type</th>
                    <th>VM Name</th>
                    <th>Score</th>
                    <th>PCAP</th>
                  </tr>
                  {analyses.map((a) => (
                    <tr key={a.analysis_id}>
                      <td>
                        {a.analysis_jobrule_sampletype ||
                          a.analysis_analyzer_name}
                      </td>
                      <td>{a.analysis_vm_name || "NA"}</td>
                      <td className="has-text-centered">
                        <Score score={a.analysis_vti_score / 100} />
                      </td>
                      <td className="has-text-centered">
                        {a.PCAPPath || pcap ? (
                          <S3ArtifactDownload path={a.PCAPPath || pcap}>
                            <span className="icon is-small">
                              <FontAwesomeIcon icon="download" />
                            </span>
                            <span>Download</span>
                          </S3ArtifactDownload>
                        ) : (
                          <span className="has-text-grey-light">
                            Not Available
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        );
      })}

      {props.forensics === "loading" ? (
        <LoadingMessage />
      ) : (
        <div>
          {(props.forensics?.Detections?.length ?? 0) > 0 && (
            <DetectionBox
              detections={props.forensics ? props.forensics.Detections : []}
              maxHeight="30rem"
            />
          )}
          <ForensicsScreenshots forensics={props.forensics} />
        </div>
      )}
    </section>
  );
};
