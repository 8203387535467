import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import deviceDescriptorsJSON from "./deviceDescriptors.json";
// @ts-ignore
import Tooltip from "@cypress/react-tooltip";
import { toast } from "react-toastify";

export const UADeviceSelector = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (v: string) => void;
}) => {
  const resolvedUA = value.startsWith("alias:")
    ? (deviceDescriptorsJSON as any)[value.slice(6)].userAgent
    : value;
  return (
    <>
      <label className="label">
        User Agent
        <Tooltip
          title={
            <>
              <p>
                Choose from one of many common device User Agents, or provide a
                custom User Agent string for Web Analyzer to use throughout its
                analysis.
              </p>
            </>
          }
        >
          <span className="icon">
            <FontAwesomeIcon icon={"question-circle"} />
          </span>
        </Tooltip>
      </label>
      <div
        className="field has-addons has-addons-fullwidth"
        style={{ marginBottom: "0px" }}
      >
        <div className="control">
          <div className="select">
            <select
              value={value.startsWith("alias:") ? value : "custom"}
              onChange={(evt) => {
                onChange(evt.target.value);
              }}
            >
              <option value="alias:Default">Default</option>
              <option value="custom">Custom</option>
              <optgroup label={"Mobile"}>
                <option value="alias:iPhone 8">iPhone 8</option>
                <option value="alias:iPhone 14">iPhone 14</option>
                <option value="alias:Pixel 5">Pixel 5</option>
                <option value="alias:Galaxy S8">Galaxy S8</option>
              </optgroup>
              <optgroup label={"Desktop"}>
                <option value="alias:Desktop Chrome">Chrome</option>
                <option value="alias:Desktop Edge">Edge</option>
                <option value="alias:Desktop Firefox">Firefox</option>
                <option value="alias:Desktop Safari">Safari</option>
              </optgroup>
            </select>
          </div>
        </div>
        <div
          className="control"
          style={{ width: "100%", flexGrow: "unset", flexShrink: "unset" }}
        >
          <input
            className="input"
            type="text"
            onChange={(evt) => {
              switch (evt.target.value) {
                case deviceDescriptorsJSON["Default"].userAgent:
                  onChange("alias:Default");
                  break;
                case deviceDescriptorsJSON["iPhone 8"].userAgent:
                  onChange("alias:iPhone 8");
                  break;
                case deviceDescriptorsJSON["iPhone 14"].userAgent:
                  onChange("alias:iPhone 14");
                  break;
                case deviceDescriptorsJSON["Pixel 5"].userAgent:
                  onChange("alias:Pixel 5");
                  break;
                case deviceDescriptorsJSON["Galaxy S8"].userAgent:
                  onChange("alias:Galaxy S8");
                  break;
                case deviceDescriptorsJSON["Desktop Chrome"].userAgent:
                  onChange("alias:Desktop Chrome");
                  break;
                case deviceDescriptorsJSON["Desktop Edge"].userAgent:
                  onChange("alias:Desktop Edge");
                  break;
                case deviceDescriptorsJSON["Desktop Firefox"].userAgent:
                  onChange("alias:Desktop Firefox");
                  break;
                case deviceDescriptorsJSON["Desktop Safari"].userAgent:
                  onChange("alias:Desktop Safari");
                  break;
                default:
                  onChange(evt.target.value);
              }
            }}
            value={value === "custom" ? "" : resolvedUA}
            placeholder={`enter a custom User Agent…`}
          />
        </div>
      </div>
      <button
        type="button"
        className="button is-small is-ghost is-pulled-right"
        onClick={(evt) => {
          evt.preventDefault();
          try {
            navigator.clipboard
              .writeText(resolvedUA)
              .then(() => toast.success("Copied!"))
              .catch(() => toast.error("Failed to copy to clipboard!"));
          } catch (_) {
            toast.error("Failed to copy to clipboard!");
          }
        }}
      >
        copy to clipboard
      </button>
    </>
  );
};
