import moment from "moment";
import React from "react";
import { DetectionTable } from "../forensics/Detections";
import { ResourceSummary } from "../ResourceSummary";
import { Score } from "../Score";
import { ExpandableBox } from "../utils/ExpandableBox";

export const URLReputationResults = (props) => {
  const task = props.task;
  const details = task.Results.Details;

  // Old style GSB results
  if (details.hasOwnProperty("Detections")) {
    var results;
    if (!details.Detections || Object.keys(details.Detections).length === 0) {
      results = [];
    } else {
      let matches = details.Detections.matches || [];
      results = matches.map((m) => {
        return { Score: task.Results.Score };
      });
      console.log(results);
    }

    return (
      <div>
        <ResourceSummary
          job={props.job}
          resource={props.resource}
          task={props.task}
        />
        <table className="table">
          <thead>
            <tr>
              <th>Service</th>
              <th>Score</th>
              <th>Scan Links</th>
            </tr>
          </thead>
          <tbody>
            <EngineResult name="Google Safebrowse" results={results} />
          </tbody>
        </table>
      </div>
    );
  }

  // New style url reputation engine results

  let engines = [];

  if (details.Engines.gsb) {
    engines.push({
      score: maxScore(details.Engines.gsb),
      component: (
        <EngineResult
          key="googlesafebrowse"
          name="Google Safebrowse"
          results={details.Engines.gsb}
        />
      ),
    });
  }

  if (details.Engines.virustotal) {
    engines.push({
      score: maxScore(details.Engines.virustotal),
      component: (
        <EngineResult
          key="virustotal"
          name="VirusTotal"
          results={details.Engines.virustotal}
          links={
            details.Engines.virustotal.length === 1 && [
              <ScanLink
                timestamp={moment(details.Engines.virustotal[0].ScanTime)
                  .toDate()
                  .toLocaleString()}
                link={details.Engines.virustotal[0].Permalink}
              />,
            ]
          }
        />
      ),
    });
  }

  if (details.Engines.apivoid) {
    engines.push({
      score: maxScore(details.Engines.apivoid),
      component: (
        <EngineResult
          key="urlvoid"
          name="URLVoid"
          results={details.Engines.apivoid}
        />
      ),
    });
  }

  if (details.Engines.urlscanio) {
    engines.push({
      score: maxScore(details.Engines.urlscanio),
      component: (
        <EngineResult
          key="urlscanio"
          name="Urlscan.io"
          results={details.Engines.urlscanio}
          links={
            details.Engines.urlscanio.length > 0 &&
            details.Engines.urlscanio[0].Scans &&
            details.Engines.urlscanio[0].Scans.map((s, idx) => (
              <ScanLink
                timestamp={new Date(s.ScanTime).toLocaleString()}
                label={
                  idx === details.Engines.urlscanio[0].Scans.length - 1
                    ? "latest scan: "
                    : idx === 0
                    ? "earliest scan: "
                    : null
                }
                link={`https://urlscan.io/result/${s.UUID}`}
              />
            ))
          }
        />
      ),
    });
  }

  if (details.Engines.twinwave) {
    let score = undefined;

    if ((details.Engines.twinwave[0]?.Features || []).includes("blocklisted")) {
      score = 1;
    } else if (
      (details.Engines.twinwave[0]?.Features || []).includes("safelisted")
    ) {
      score = 0;
    }

    if (score !== undefined) {
      engines.push({
        score: score,
        component: (
          <tr>
            <td>Twinwave</td>
            <td>
              <Score score={score} />
            </td>
            <td />
          </tr>
        ),
      });
    }
  }

  engines.sort((a, b) => {
    // sort by score descending, or if a tie, by name ascending
    if (b.score === a.score) {
      return a.component.key > b.component.key ? 1 : -1;
    } else if (b.score === undefined) {
      return -1;
    } else if (a.score === undefined) {
      return 1;
    } else {
      return b.score - a.score;
    }
  });

  if (engines.length > 0) {
    return (
      <div>
        <ResourceSummary
          job={props.job}
          resource={props.resource}
          task={props.task}
        />

        {props.forensics &&
          props.forensics !== "loading" &&
          props.forensics.Detections?.length > 0 && (
            <ExpandableBox
              title={`Detections (${props.forensics.Detections?.length || 0})`}
            >
              {props.forensics.Detections &&
              props.forensics.Detections.length > 0 ? (
                <DetectionTable detections={props.forensics.Detections} />
              ) : (
                "URL was not detected as malicious"
              )}
            </ExpandableBox>
          )}

        <ExpandableBox title="URL Reputation Results">
          <table className="table">
            <thead>
              <tr>
                <th>Service</th>
                <th>Score</th>
                <th>Scan Links</th>
              </tr>
            </thead>
            <tbody>
              {engines.map((e) => {
                return e.component;
              })}
            </tbody>
          </table>
        </ExpandableBox>
      </div>
    );
  }

  // emergency fallback
  return <div>No results for URL</div>;
};

const EngineResult = (props) => {
  const results = props.results;

  if (results.length === 0) {
    return (
      <tr>
        <td>{props.name}</td>
        <td>
          <Score score="-" />
        </td>
        <td></td>
      </tr>
    );
  }

  return results.map((r, i) => (
    <tr key={i}>
      <td>{props.name}</td>
      <td>
        <Score score={r.Score} />
      </td>
      <td>
        {props.links && props.links.map((l, i) => <div key={i}>{l}</div>)}
      </td>
    </tr>
  ));
};

const ScanLink = (props) => {
  return (
    <span>
      {props.label}
      <a href={props.link} target="_blank" rel="noopener noreferrer">
        {props.timestamp}
      </a>
    </span>
  );
};

const maxScore = (results) => {
  let score = undefined;

  results.forEach((r) => {
    if (score === undefined || r.Score > score) {
      score = r.Score;
    }
  });

  return score;
};
