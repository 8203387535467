import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocalStorage } from "src/components/utils/LocalStorageHook";
import { JobList } from "src/components/JobList";
import { useAuth0 } from "@auth0/auth0-react";
import { APIContext } from "../lib/MAPApi";
import { ErrorWrap } from "src/components/ErrorWrap";
import { ScoreRangeInput } from "src/components/ScoreRangeInput";

enum GeneralFilter {
  Mine = "mine",
  User = "user",
  API = "api",
  All = "all",
}

const isAPIKeyID = (s: string) => {
  return !!(
    s &&
    ![
      GeneralFilter.Mine,
      GeneralFilter.User,
      GeneralFilter.API,
      GeneralFilter.All,
    ].includes(s as GeneralFilter)
  );
};

type Filter = GeneralFilter | string;

interface APIKey {
  ID: string;
  Label: string;
}

const generalFilterFromString = (f: string): GeneralFilter => {
  switch (f) {
    case GeneralFilter.Mine:
      return GeneralFilter.Mine;
    case GeneralFilter.User:
      return GeneralFilter.User;
    case GeneralFilter.API:
      return GeneralFilter.API;
    case GeneralFilter.All:
      return GeneralFilter.All;
    default:
      return GeneralFilter.All;
  }
};

const addInDefaultKey = (id: string, keys: APIKey[]) => {
  if (!isAPIKeyID(id)) {
    return keys;
  }

  const missing = keys.filter(({ ID }) => ID === id).length === 0;
  if (missing) {
    return [...keys, { ID: id, Label: "API Key" }];
  }

  return keys;
};

const humanizeFilter = (f: Filter, keys: APIKey[] = []) => {
  if (isAPIKeyID(f)) {
    let key = keys.filter((k) => k.ID === f);
    return key.length > 0 ? `API - ${key[0].Label}` : "API Key";
  }

  switch (f) {
    case GeneralFilter.Mine:
      return "My Submissions";
    case GeneralFilter.User:
      return "User Submissions";
    case GeneralFilter.API:
      return "API Submissions";
    case GeneralFilter.All:
      return "All Submissions";
    default:
      return f;
  }
};

const noRecentSubmissions = (f: Filter) => {
  if (isAPIKeyID(f)) {
    return "No recent submissions for the selected API key. If you're looking for user submissions, or other API submissions, please use a different filter.";
  }

  switch (f) {
    case GeneralFilter.Mine:
      return "No recent submissions found for your user. If you're looking for API or other user submissions, please use a different filter.";
    case GeneralFilter.User:
      return "No recent user submissions. If you're looking for API submissions, please use a different filter.";
    case GeneralFilter.API:
      return "No recent API submissions. If you're looking for user submissions, please use a different filter.";
    default:
      return "No recent submissions.";
  }
};

const RECENTS_VIEW_KEY = "recents_view_preferred_mode";

const FilterPicker = (props: {
  filter: Filter;
  keys: APIKey[];
  onChange: ChangeEventHandler<HTMLSelectElement>;
}) => {
  const keys = addInDefaultKey(props.filter, props.keys);
  const keyOpts = keys.map(({ ID, Label }) => (
    <option key={ID} value={ID}>
      API - {Label}
    </option>
  ));

  return (
    <div className="field is-grouped is-grouped-right">
      <div className="field-label is-normal">
        <label className="label">Show:</label>
      </div>
      <div className="control">
        <div className="select">
          <select value={props.filter} onChange={props.onChange}>
            <option value={GeneralFilter.Mine}>
              {humanizeFilter(GeneralFilter.Mine)}
            </option>
            <option value={GeneralFilter.User}>
              {humanizeFilter(GeneralFilter.User)}
            </option>
            <option value={GeneralFilter.API}>
              {humanizeFilter(GeneralFilter.API)}
            </option>
            {keyOpts}
            <option value={GeneralFilter.All}>
              {humanizeFilter(GeneralFilter.All)}
            </option>
          </select>
        </div>
      </div>
    </div>
  );
};

/**
 * Admin only all recent jobs
 *
 * Separated out from RecentJobs to avoid React state caching issues in
 * the interaction between React Router and RecentJobs.
 *
 * @returns
 */
export const AdminRecentJobs = () => {
  return <RecentJobs isAdminMode={true} />;
};

export const RecentJobs = (props: {
  isAdminMode?: boolean;
  refreshInterval?: number;
  maxResults?: number;
  isPaginationEnabled?: boolean;
  forceRefreshAt?: Date;
  isHeadless?: boolean;
}) => {
  const isAdminMode = !!props.isAdminMode;
  const refreshInterval = props.refreshInterval || 60_000;
  const maxResults = props.maxResults || 25;
  const isPaginationEnabled = props.isPaginationEnabled ?? true;
  const forceRefreshAt = props.forceRefreshAt;
  const isHeadless = props.isHeadless ?? false;

  const { storedValue: storedPreference, setValue: setStoredPreference } =
    useLocalStorage<Filter>(RECENTS_VIEW_KEY, GeneralFilter.All);

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  var pageFromSearch = parseInt(params.get("page") || "1") || 1;
  const filterFromSearch = params.get("filter");
  const scoreMinFromSearch = parseInt(params.get("scoreMin") || "0") || 0;
  const scoreMaxFromSearch = parseInt(params.get("scoreMax") || "100") || 100;

  const [scoreMin, setScoreMin] = useState(scoreMinFromSearch);
  const [scoreMax, setScoreMax] = useState(scoreMaxFromSearch);
  const [_scores, _setScores] = useState({
    scoreMin: scoreMin,
    scoreMax: scoreMax,
  });
  const [scoreHasChanged, setScoreHasChanged] = useState(false);

  if (pageFromSearch < 1) {
    pageFromSearch = 1;
  }

  const [page, setPage] = useState(pageFromSearch);
  let _filter =
    (filterFromSearch &&
      (isAPIKeyID(filterFromSearch)
        ? filterFromSearch
        : generalFilterFromString(filterFromSearch))) ||
    storedPreference;

  // sticky key id doesn't apply in admin mode
  if (isAdminMode && isAPIKeyID(_filter)) {
    _filter = GeneralFilter.API;
  }

  const [filter, setFilter] = useState(_filter);

  const [hasNext, setHasNext] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [jobs, setJobs] = useState<any[]>([]);
  const [keys, setKeys] = useState<APIKey[]>([]);

  const { user, getAccessTokenSilently } = useAuth0();
  const { api } = useContext(APIContext);
  const callInProgress = useRef(false);

  if (page !== pageFromSearch) setPage(pageFromSearch);
  if (filter !== _filter) setFilter(_filter);
  if (scoreMin !== scoreMinFromSearch || scoreMax !== scoreMaxFromSearch) {
    setScoreMin(scoreMinFromSearch);
    setScoreMax(scoreMaxFromSearch);
    setScoreHasChanged(false);
    _setScores({ scoreMin: scoreMinFromSearch, scoreMax: scoreMaxFromSearch });
  }

  useEffect(() => {
    let ignore = false;
    if (isAdminMode) {
      setKeys([]);
      return;
    }

    api
      .callAPI(getAccessTokenSilently, api.getAPIKeys)
      .then((keys: APIKey[]) => {
        if (ignore) return;
        setKeys(keys);
      });
  }, [api, getAccessTokenSilently, isAdminMode]);

  useEffect(() => {
    // Used to prevent mutating state after unmount.
    let ignore = false;
    callInProgress.current = false;

    const refreshJobs = () => {
      if (callInProgress.current) {
        // Do this so we don't stack multiple API calls on top of each other, which can cause cascading
        // performance issues

        return;
      }
      const jobFunc = isAdminMode ? api.getAllJobsForAdmin : api.getJobs;
      const offset = (page - 1) * maxResults;
      let source = "";
      let apiKeyID = undefined;
      if (filter === GeneralFilter.User) {
        source = "ui";
      } else if (filter === GeneralFilter.API) {
        source = "api";
      } else if (isAPIKeyID(filter)) {
        source = "api";
        apiKeyID = filter;
      }

      let username = filter === GeneralFilter.Mine ? user?.name : undefined;

      callInProgress.current = true;
      api
        .callAPI(getAccessTokenSilently, jobFunc, {
          count: maxResults + 1,
          offset: offset,
          username: username,
          source: source,
          apiKeyID: apiKeyID,
          scoreMin: scoreMin,
          scoreMax: scoreMax,
        })
        .then((js: any) => {
          if (ignore) return;
          setHasNext(js.length > maxResults);
          if (js.length > maxResults) js.pop();
          setJobs(js);
          setHasError(false);
          setIsLoading(false);
        })
        .catch((_: any) => {
          if (ignore) return;
          setIsLoading(false);
          setHasError(true);
        })
        .finally(() => {
          callInProgress.current = false;
        });
    };

    refreshJobs();

    let refresher: number | null = null;
    // Only schedule refresh on first page
    if (page === 1) {
      refresher = window.setInterval(refreshJobs, refreshInterval);
    }

    return () => {
      ignore = true;
      if (refresher !== null) {
        clearInterval(refresher);
      }
    };
  }, [
    page,
    filter,
    scoreMin,
    scoreMax,
    refreshInterval,
    isAdminMode,
    maxResults,
    forceRefreshAt,
    getAccessTokenSilently,
    user,
    api,
  ]);

  const changeScoreRange = (min: number, max: number) => {
    _setScores({ scoreMin: min, scoreMax: max });
    setScoreHasChanged(true);
  };

  const updateScores = () => {
    if (scoreMin === _scores.scoreMin && scoreMax === _scores.scoreMax) {
      return;
    }
    updateSearch({
      scoreMin: _scores.scoreMin,
      scoreMax: _scores.scoreMax,
      page: 1,
    });
  };

  const updateSearch = useCallback(
    (updates: {
      page?: number;
      filter?: string;
      scoreMin?: number;
      scoreMax?: number;
    }) => {
      setIsLoading(true);
      setJobs([]);
      setHasError(false);

      const params = {
        page,
        filter,
        scoreMin,
        scoreMax,
        ...updates,
      };

      if (isHeadless) {
        navigate({ pathname: undefined, search: undefined });
      } else {
        navigate({
          pathname: location.pathname,
          search: new URLSearchParams({
            page: `${params.page}`,
            filter: `${params.filter}`,
            scoreMin: `${params.scoreMin}`,
            scoreMax: `${params.scoreMax}`,
          }).toString(),
        });
      }
    },
    [filter, navigate, location, isHeadless, page, scoreMax, scoreMin]
  );

  const changePreference = useCallback<ChangeEventHandler<HTMLSelectElement>>(
    (e) => {
      setStoredPreference(e.target.value as GeneralFilter);

      updateSearch({ filter: e.target.value, page: 1 });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter, setStoredPreference, updateSearch]
  );

  return (
    <ErrorWrap
      hasError={hasError}
      content={
        <>
          <p>Unable to load recent jobs from the server.</p>
          <p>
            <b>Reloading the application window may resolve this issue</b>
          </p>
        </>
      }
    >
      <div className="section">
        <div className="container">
          <h1 className="title is-4 has-text-centered">Recent Submissions</h1>
          <h2 className="subtitle is-6 has-text-grey-light has-text-weight-semibold has-text-centered">
            Showing: {humanizeFilter(filter, keys)}
            {isPaginationEnabled && ` | Page: ${page}`}
          </h2>
          <div className="columns">
            <div className="column is-narrow">
              <FilterPicker
                filter={filter}
                keys={keys}
                onChange={changePreference}
              />
            </div>
            <div className="column"></div>
            {!isHeadless && (
              <>
                <div className="column is-narrow">
                  <ScoreRangeInput
                    onChange={changeScoreRange}
                    initialMin={scoreMin}
                    initialMax={scoreMax}
                  />
                </div>
                <div className="column is-narrow">
                  <button
                    className={
                      "button" + (scoreHasChanged ? " is-primary" : "")
                    }
                    onClick={updateScores}
                    disabled={!scoreHasChanged}
                  >
                    Update
                  </button>
                </div>
              </>
            )}
          </div>
          <JobList
            emptyMessageOverride={noRecentSubmissions(filter)}
            jobs={jobs}
            showTenant={isAdminMode}
            summaryMode={!isAdminMode}
            loading={isLoading}
          />
          {isPaginationEnabled && (
            <div className="section">
              <div className="container">
                <nav className="pagination" role="navigation">
                  {
                    page > 1 ? (
                      <button
                        className="button pagination-next"
                        onClick={() => {
                          updateSearch({ page: page - 1 });
                        }}
                      >
                        Previous
                      </button>
                    ) : (
                      <span />
                    ) /* just to bump the next button to the right*/
                  }
                  {hasNext && (
                    <button
                      className="button pagination-next"
                      onClick={() => {
                        updateSearch({ page: page + 1 });
                      }}
                    >
                      Next
                    </button>
                  )}
                </nav>
              </div>
            </div>
          )}
        </div>
      </div>
    </ErrorWrap>
  );
};
