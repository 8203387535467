import React from "react";
import { useParams } from "react-router-dom";

/**
 * Adapter for `useParams` in class compents as you cannot use hooks in non functional
 * components.
 *
 * This lets you access parameters under `this.props.params`
 */
export const withParams = (Component: React.ComponentType, props: any) => {
  return (props: any) => <Component {...props} params={useParams()} />;
};
