import SplunkRum from "@splunk/otel-web";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { NavBar } from "src/components/NavBar";

export enum Version {
  Unknown = "unknown",
  Maintenance = "maintenance",
}

interface MaintenanceMessage {
  title: string;
  paragraphs: string[];
}

export const MetaVersionName = "twinwave-app-version";
export const RefreshInterval = 5 * 60 * 1000; // 5 minutes
export const CurrentJSVersion = process.env.REACT_APP_TW_VERSION || "";

export const getIndexHTMLVersion = () => {
  for (let elem of Array.from(document.getElementsByTagName("meta"))) {
    if (elem.getAttribute("name") === MetaVersionName) {
      const content = elem.getAttribute("content");
      if (content) {
        return content;
      }
    }
  }

  return "";
};

export const newVersionAvailable = (
  htmlVersion: string,
  jsVersion: string,
  networkVersion: string
) => !(htmlVersion === jsVersion && jsVersion === networkVersion);

export const getNetworkVersion = async () => {
  let message: MaintenanceMessage | null = null;
  let version: string = Version.Unknown;
  try {
    const res = await fetch(
      process.env.PUBLIC_URL + `/deployed_version.json?v=${Date.now()}`
    );
    if (res.ok) {
      const { message: m, version: v } = await res.json();
      if (m) {
        message = m;
      }

      if (v) {
        version = v;
      }
    }
    return { message, version };
  } catch (err) {
    SplunkRum.error(err);
    return { message, version };
  }
};

export const useVersionInfo = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isMaintenanceMode, setMaintenanceMode] = useState(false);
  const [isNewversionAvailable, setIsNewVersionAvailable] = useState(false);
  const [maintenanceMessage, setMaintenanceMessage] =
    useState<MaintenanceMessage | null>(null);

  useEffect(() => {
    let ignore = false;
    const refresh = async () => {
      try {
        const { message, version } = await getNetworkVersion();
        if (ignore) return;
        if (version === Version.Unknown) {
          // Fall through
        } else if (version === Version.Maintenance) {
          setMaintenanceMessage(message);
          setMaintenanceMode(true);
        } else {
          setMaintenanceMode(false);
          setMaintenanceMessage(null);
          setIsNewVersionAvailable(
            newVersionAvailable(
              getIndexHTMLVersion(),
              CurrentJSVersion,
              version
            )
          );
        }
      } catch (e) {
        console.warn("Error while fetching version info", e);
        // Restore initial state
        setIsLoading(true);
        setMaintenanceMode(false);
        setIsNewVersionAvailable(false);
        setMaintenanceMessage(null);
      }

      setIsLoading(false);
    };

    refresh();
    const refresher = window.setInterval(refresh, RefreshInterval);

    return () => {
      ignore = true;
      window.clearInterval(refresher);
    };
  }, []);

  return {
    isLoading,
    isMaintenanceMode,
    isNewversionAvailable,
    maintenanceMessage,
  };
};

export const UpdateAndMaintenanceChecker = () => {
  const location = useLocation();
  const { isMaintenanceMode, isNewversionAvailable } = useVersionInfo();
  const [isNotifiedOfNewVersion, setIsNotifiedOfNewVersion] = useState(false);

  useEffect(() => {
    if (isMaintenanceMode && location.pathname !== "/maintenance") {
      // NB: We circumvent React here since our Auth dialog is an iframe and overlays
      // on the idiomatic redirection, so do a hard redirection.
      window.location.replace("/maintenance");
    }

    if (isNewversionAvailable && !isNotifiedOfNewVersion) {
      toast.info(
        `New Version Available: It looks like you're running an older version of the app. Please refresh your browser to get the latest and greatest!`,
        {
          autoClose: false,
        }
      );
      setIsNotifiedOfNewVersion(true);
    }
  }, [
    location,
    isMaintenanceMode,
    isNewversionAvailable,
    isNotifiedOfNewVersion,
  ]);

  return <></>;
};

export const MaintenancePage = () => {
  const { isMaintenanceMode, isLoading, maintenanceMessage } = useVersionInfo();

  useEffect(() => {
    if (!isLoading && !isMaintenanceMode) {
      // Again, force a refresh outside the scope of react router
      window.location.replace("/");
    }
  }, [isLoading, isMaintenanceMode]);

  if (!isLoading && isMaintenanceMode) {
    return (
      <div>
        <NavBar />
        <section className="hero is-primary is-fullheight-with-navbar">
          <div className="hero-body">
            <div className="container has-text-centered">
              <p className="title">{maintenanceMessage?.title}</p>
              {(maintenanceMessage?.paragraphs || []).map((p, i) => (
                <p className="subtitle mx-6 px-6" key={i}>
                  {p}
                </p>
              ))}
            </div>
          </div>
        </section>
      </div>
    );
  }

  return <></>;
};
