import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { APIContext } from "../../lib/MAPApi";
import { FileSize } from "../FileSize";
import { DetectionTable } from "../forensics/Detections";
import { ScreenshotBlock } from "../forensics/Screenshots";
import { FormComponent } from "../NormalizedForensics";
import { Copyable } from "../utils/Copyable";
import { Tab, Tabs } from "../utils/Tabs";
import { ThreatName } from "../utils/ThreatName";

const DownloadedFile = (props) => {
  const { getAccessTokenSilently } = useAuth0();
  const { api } = useContext(APIContext);

  return (
    <div className="card has-margin-bottom-20">
      <header className="card-header">
        <p className="card-header-title">{props.file.filename}</p>
        <a
          href="/"
          onClick={(evt) => {
            evt.preventDefault();
            api.callAPI(
              getAccessTokenSilently,
              api.getArtifact,
              props.job.ID,
              props.task.ID,
              props.file.sha256
            );
          }}
          className="card-header-icon"
        >
          <FontAwesomeIcon icon="download" className="has-text-info" />
        </a>
      </header>
      <div className="card-content">
        <div className="content">
          <div>
            <b>File Type:</b> {props.file.magic || "unknown"}
          </div>
          <div>
            <b>SHA256 Hash:</b> {props.file.sha256 || "NA"}
          </div>
          <div>
            <b>MD5 Hash:</b> {props.file.md5 || "NA"}
          </div>
          <div>
            <b>Ssdeep Hash:</b> {props.file.ssdeep || "NA"}
          </div>
          <div>
            <b>File Size:</b> <FileSize size={props.file.size} />
            {props.file.size > 50 * 1024 * 1024 && (
              <span className="has-text-danger">
                {" "}
                <FontAwesomeIcon icon="exclamation-triangle" /> File is too
                large for additional analysis
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const StaticURLResults = (props) => {
  const task = props.task;

  const details = task.Results.Details || {};
  const downloads = details.Downloads || [];

  const { getAccessTokenSilently } = useAuth0();
  const { api } = useContext(APIContext);
  const forensicsLoaded = props.forensics && props.forensics !== "loading";

  let detections = [];
  let forms = [];
  let tabs = [];

  if (forensicsLoaded) {
    detections = props.forensics.Detections;
    forms = props.forensics.Forms;
    let screenshots = props.forensics.Screenshots;

    if (screenshots.length > 0) {
      tabs.push(
        <Tab label="Screenshots" key="screenshots" icon="camera">
          <ScreenshotBlock screenshots={screenshots} noExpansion={true} />
        </Tab>
      );
    }
  }

  const stats = task.Results.Details.Stats || {};

  if (downloads.length > 0) {
    tabs.push(
      <Tab
        label={`Downloaded Files (${downloads.length})`}
        key="downloads"
        icon="cloud-download"
      >
        {downloads.map((d) => (
          <DownloadedFile
            file={d}
            key={d.sha256}
            jobid={props.job.ID}
            task={props.task}
          />
        ))}
      </Tab>
    );
  }

  if (forms.length > 0) {
    tabs.push(
      <Tab label={`Forms (${forms.length})`} key="forms" icon="edit">
        <table className="table">
          <tbody>
            {forms.map((f, idx) => (
              <FormComponent value={f} key={f.Action || idx} />
            ))}
          </tbody>
        </table>
      </Tab>
    );
  }

  return (
    <div>
      <ul>
        <li>
          <Copyable data={props.resource.Location}>
            <b>Analyzed Resource</b>: <ThreatName name={props.resource.Name} />
          </Copyable>
        </li>
        <li>
          <b>Page Title</b>:{" "}
          <ThreatName
            name={
              task.Results.Details.Title
                ? `"${task.Results.Details.Title}"`
                : "<no title>"
            }
          />
        </li>
        <li>
          <b>Domains Visited</b>: {stats.visited_domain_cnt || 1}
        </li>
        <li>
          <b>Unique URLs Requested</b>: {stats.visited_urls_uniq_cnt || 1}
        </li>
        <li>
          <b>Forms Detected</b>: {forms.length}
        </li>
      </ul>

      {task.Results.Details.HAR && (
        <div>
          <button
            className="button is-small is-info"
            onClick={(e) =>
              api.callAPI(
                getAccessTokenSilently,
                api.getArtifactByPath,
                task.Results.Details.HAR
              )
            }
            download="archive.har.gz"
          >
            <span className="icon is-small">
              <FontAwesomeIcon icon="download" />
            </span>
            <span>Download HAR Archive</span>
          </button>
        </div>
      )}

      {!forensicsLoaded ? (
        <>
          <hr />
          <div className="has-text-info">Loading...</div>
        </>
      ) : (
        <>
          <hr />
          {detections && detections.length > 0 && (
            <div>
              <DetectionTable detections={detections} />
              <hr />
            </div>
          )}

          {tabs.length > 0 && <Tabs key={task.ID}>{tabs.map((t) => t)}</Tabs>}
        </>
      )}
    </div>
  );
};
