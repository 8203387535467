import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const EncryptedResourceIcon = ({ resource, style }) => {
  if (resource && resource.FileMetadata && resource.FileMetadata.IsEncrypted) {
    return (
      <span style={style} className="is-size-7 has-text-grey">
        <FontAwesomeIcon icon={["far", "lock"]} />
      </span>
    );
  }

  return null;
};
