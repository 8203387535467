import { useState } from "react";
import { Job } from "src/lib/APITypes";
import * as Menu from "./Menu";
import * as Modal from "./Modal";
import { usePDFReportHook } from "src/lib/API";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const DownloadPDFReport = ({ job }: { job: Job }) => {
  const { download } = usePDFReportHook();

  const [active, setIsActive] = useState(false);

  const startDownload = (jobid: string) => {
    setIsActive(true);

    download(jobid).then(() => {
      setIsActive(false);
    });
  };

  return (
    <>
      <Menu.Item
        text="Download PDF Report"
        isDisabled={
          job.State === "done"
            ? ""
            : "In progress jobs cannot be downloaded. Please wait for the job to finish before downloading the report."
        }
        icon={["far", "file-pdf"]}
        onClick={() => {
          startDownload(job.ID);
        }}
      ></Menu.Item>
      <Modal.Modal isActive={active} close={() => {}}>
        <Modal.Card title="PDF Forensics Report Download">
          <Modal.CardBody>
            <p>
              <FontAwesomeIcon icon="spinner" spin /> Report PDF is being
              generated...
            </p>
            <p>Once the PDF is ready, the download will automatically begin</p>
          </Modal.CardBody>
        </Modal.Card>
      </Modal.Modal>
    </>
  );
};
