import {
  useLocation,
  useNavigate,
  useParams,
  Location,
  NavigateFunction,
  Params,
} from "react-router-dom";
import React from "react";

export interface WithRouterProps {
  router: {
    location: Location;
    navigate: NavigateFunction;
    params: Readonly<Params<string>>;
  };
}

/**
 * Replacementfor react-router-v5 withRouter
 * @param Component
 * @returns wrapped component with location, navigate, params properties.
 */
export const withRouter = <T extends WithRouterProps>(
  Component: React.ComponentType<T>
): React.ComponentType<Omit<T, keyof WithRouterProps>> => {
  return (props: any): JSX.Element => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return React.createElement(Component, {
      ...props,
      router: {
        location,
        navigate,
        params,
      },
    });
  };
};
