import React from "react";
import { Expandable } from "./utils/Expandable";
import { taskDisplayName } from "./utils/DisplayNames";
import { Caveat } from "../lib/APITypes";
import { ThreatName } from "./utils/ThreatName";

export const TRUNCATION_CAVEAT_CODE = 15;
export const TRUNCATION_MESSAGE =
  "Task details have been truncated. Forensics are still available for the task";
export const truncationCaveat = (resourceName?: string) => {
  return {
    code: TRUNCATION_CAVEAT_CODE,
    code_human: TRUNCATION_MESSAGE,
    resource_name: resourceName || "",
  };
};

export const CaveatCard = ({
  caveats,
  engine,
  children,
}: {
  caveats: Caveat[];
  engine: string;
  children?: JSX.Element[];
}) => {
  let groups: any = [];
  for (const c of caveats) {
    let g = groups[c.code] || [];
    g.push(c);
    groups[c.code] = g;
  }

  const isSimplyTruncation =
    caveats.length === 1 && caveats[0].code === TRUNCATION_CAVEAT_CODE;

  let message = `${taskDisplayName(
    engine
  )} skipped injection of some resources for the following reasons:`;
  if (isSimplyTruncation)
    message = `${taskDisplayName(engine)} truncated Task details:`;

  return (
    <div className="message is-warning">
      <div className="message-body">
        <div>{message}</div>
        {children}
        {groups.map((cg: any, idx: number) => (
          <Expandable title={`${cg[0].code_human} (${cg.length})`} key={idx}>
            <ul>
              {cg.map((c: any, idx: number) => (
                <li key={idx}>
                  <b>Resource:</b> <ThreatName name={c.resource_name} />
                  {c.metadata && (
                    <ul>
                      {Object.keys(c.metadata).map((k, idx) => (
                        <li key={idx}>
                          <b>{k}</b> - <MetadataValue value={c.metadata[k]} />
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </Expandable>
        ))}
      </div>
    </div>
  );
};

const MetadataValue = ({ value }: { value: any }) => {
  if (Array.isArray(value)) {
    return <span>{value.join(", ")}</span>;
  } else {
    return <span>{value}</span>;
  }
};
