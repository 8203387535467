/*
  This is a progressive renderer for react-syntax handler adapted from:
    https://github.com/conorhastings/react-syntax-highlighter-virtualized-renderer/
  The above one uses react-virtualized which doesn't work with React 17 and
  overlaps with our existing use of react-window.

  Its meant to be used where we want to render large source segments which
  can tank browser performance as a huge number of DOM elements get created/inserted.
*/
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import { createElement } from "react-syntax-highlighter";

export default function virtualizedRenderer({
  overscanRowCount = 50,
  rowHeight = 15,
  containerHeight = "200rem",
} = {}) {
  return ({ rows, stylesheet, useInlineStyles }) => (
    <div style={{ height: containerHeight }}>
      <AutoSizer>
        {({ height, width }) => (
          <List
            height={height}
            itemHeight={rowHeight}
            overscanCount={overscanRowCount}
            itemCount={rows.length}
            itemSize={rowHeight}
            width={width}
          >
            {({ index, style }) =>
              createElement({
                node: rows[index],
                stylesheet,
                style,
                useInlineStyles,
                index,
              })
            }
          </List>
        )}
      </AutoSizer>
    </div>
  );
}
