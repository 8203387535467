import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { ErrorWrap } from "src/components/ErrorWrap";
import { LoadingWrap } from "src/components/tasks/LoadingMessage";
import { ExpandableBox } from "src/components/utils/ExpandableBox";
import { APIContext } from "src/lib/MAPApi";

interface ServiceStatus {
  ECR: string;
  GitLab: string;
  Staging: RepoInfo;
  Production: RepoInfo;
}

interface RepoInfo {
  Commit: string;
  PushedAt: string;
  CommitRunCount: number;
  OtherRunCount: number;
}

export const DevOpsPage = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { api } = useContext(APIContext);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [services, setServices] = useState<ServiceStatus[]>([]);

  useEffect(() => {
    let ignore = false;
    api
      .callAPI(getAccessTokenSilently, api.getServices)
      .then((ss: ServiceStatus[]) => {
        if (ignore) return;
        setServices(ss);
        setIsLoading(false);
      })
      .catch((e: any) => {
        console.log(e);
        if (ignore) return;
        setHasError(true);
        setIsLoading(false);
      });

    return () => {
      ignore = true;
    };
  }, [getAccessTokenSilently, api]);

  services.sort((a, b) =>
    serviceName(a.GitLab).localeCompare(serviceName(b.GitLab))
  );

  const upToDate = services.filter(isUpToDate);
  const outOfDate = services.filter((s) => !isUpToDate(s));

  return (
    <div className="section">
      <div className="container">
        <h1 className="title is-4 has-text-centered">DevOps Dashboard</h1>
        <ErrorWrap hasError={hasError}>
          <LoadingWrap isLoading={isLoading}>
            <h2 className="title is-5">
              Out of Date Services{" "}
              <span className="has-text-grey">
                ({outOfDate.length}/{services.length})
              </span>
            </h2>
            {outOfDate.map((s, idx) => (
              <ServiceInfo {...s} key={`outdated-${idx}`} />
            ))}
            <hr />
            <h2 className="title is-5">
              Up to Date Services{" "}
              <span className="has-text-grey">
                ({upToDate.length}/{services.length})
              </span>
            </h2>
            {upToDate.map((s, idx) => (
              <ServiceInfo {...s} key={`current-${idx}`} />
            ))}
          </LoadingWrap>
        </ErrorWrap>
      </div>
    </div>
  );
};

const serviceName = (s: string) => {
  const parts = s.split("/");
  return parts[parts.length - 1];
};

const isUpToDate = (s: ServiceStatus) => {
  return s.Production.Commit === s.Staging.Commit;
};

const formatRunCount = (env: string, r: RepoInfo) => {
  const total = r.CommitRunCount + r.OtherRunCount;
  return (
    <span
      style={{ display: "inline-block", margin: "0 5px" }}
      className="tags has-addons"
    >
      <span style={{ margin: 0 }} className="tag is-light">
        {env}
      </span>
      <span
        style={{ margin: 0 }}
        className={`tag ${
          total === r.CommitRunCount ? "is-success" : "is-danger"
        }`}
      >
        {r.CommitRunCount}/{total}
      </span>
    </span>
  );
};

const ServiceInfo = (s: ServiceStatus) => {
  let content = <p>The service is up to date!</p>;
  if (!isUpToDate(s)) {
    content = <DiffLog {...s} />;
  }

  let title = (
    <>
      {serviceName(s.GitLab)} {formatRunCount("staging", s.Staging)}{" "}
      {formatRunCount("production", s.Production)}
    </>
  );

  return (
    <ExpandableBox title={title} contentVisible={!isUpToDate(s)}>
      {content}
    </ExpandableBox>
  );
};

interface Commit {
  id: string;
  short_id: string;
  title: string;
  author_name: string;
  author_email: string;
  created_at: string;
}

const DiffLog = (s: ServiceStatus) => {
  const { getAccessTokenSilently } = useAuth0();
  const { api } = useContext(APIContext);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [commits, setCommits] = useState<Commit[]>([]);

  useEffect(() => {
    let ignore = false;
    api
      .callAPI(getAccessTokenSilently, api.getServiceDiff, s.GitLab)
      .then((cc: Commit[]) => {
        if (ignore) return;
        setCommits(cc.reverse());
        setIsLoading(false);
      })
      .catch((e: any) => {
        console.log(e);
        if (ignore) return;
        setHasError(true);
        setIsLoading(false);
      });

    return () => {
      ignore = true;
    };
  }, [getAccessTokenSilently, api, s.GitLab]);

  const baseCommitUrl = `https://gitlab.com/${s.GitLab}/-/commit`;

  return (
    <ErrorWrap hasError={hasError}>
      <LoadingWrap isLoading={isLoading}>
        <ul>
          {commits.map((c) => (
            <li key={c.short_id} style={{ fontFamily: "monospace" }}>
              <span className="has-text-grey">
                <a
                  href={baseCommitUrl + `/${c.id}`}
                  title="Open diff in new window"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {c.short_id}
                </a>
              </span>{" "}
              <span style={{ fontWeight: "bold" }}>{c.title}</span>{" "}
              <span>
                ({c.author_name}, {moment(c.created_at).fromNow()})
              </span>
            </li>
          ))}
        </ul>
      </LoadingWrap>
    </ErrorWrap>
  );
};
