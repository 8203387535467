// [RAW] NB: This is a legacy component. DO NOT EDIT THIS FILE!
import React from "react";
import { taskDisplayName } from "../utils/DisplayNames";
import { FileSize } from "../FileSize";
import { Expandable } from "../utils/Expandable";

export const ArchiveExtractionResultsV0 = (props) => {
  if (!props || !props.task || !props.task.Results) {
    return (
      <div className="has-text-centered has-text-weight-bold has-text-info">
        Loading…
      </div>
    );
  }

  const {
    Children,
    Filename,
    MagicHuman,
    Password,
    RelativePath,
    SHA256,
    Size,
    Caveats,
  } = props.task.Results.Details.MetaData;

  return (
    <>
      <ul>
        {Caveats ? <CaveatCard caveats={Caveats} /> : null}
        <li>
          <b>Target:</b> <span className="threat-name">{Filename}</span>
        </li>
        <li>
          <b>File Type:</b> {MagicHuman}
        </li>
        {RelativePath ? (
          <li>
            <b>Relative Path:</b>{" "}
            <span className="threat-name">{RelativePath}</span>
          </li>
        ) : null}
        <li>
          <b>SHA256 Hash:</b> {SHA256}
        </li>
        <li>
          <b>File Size:</b> <FileSize size={Size} />
        </li>
        {Password ? (
          <li>
            <b>Password:</b> {Password}
          </li>
        ) : null}
        <hr />
        {Children && Children.length > 0 ? (
          <>
            <p className="has-margin-bottom-20">
              {taskDisplayName("archive_extraction")} extracted the following
              files:
            </p>
            <li>
              <ul>
                {Children.map((c) => (
                  <li key={c.SHA256}>
                    <ArchiveExtractionCard {...c} />
                  </li>
                ))}
              </ul>
            </li>
          </>
        ) : (
          <p>No files extracted</p>
        )}
      </ul>
    </>
  );
};

const CaveatCard = ({ caveats }) => {
  let child = null;
  if (caveats && caveats.length > 0 && typeof caveats[0] === "string") {
    // old style
    child = (
      <Expandable
        title={`${taskDisplayName(
          "archive_extraction"
        )} skipped analysis of some files`}
      >
        <ul>
          {caveats.map((c, i) => (
            <li key={i}>
              <span className="threat-name">{c}</span>
            </li>
          ))}
        </ul>
      </Expandable>
    );
  } else {
    child = <GroupedCaveats caveats={caveats} />;
  }

  return (
    <article className="message is-warning">
      <div className="message-body">{child}</div>
    </article>
  );
};
// {
//   "415": [
//     {
//       "code": 415,
//       "code_human": "File could not be decrypted",
//       "filename": "fail_to_decrypt.zip",
//       "metadata": {
//         "AttemptedPasswords": [
//           "user_supplied_password",
//           "infected",
//           "malware",
//           "password"
//         ]
//       }
//     }
//   ]
// }
const GroupedCaveats = ({ caveats }) => {
  console.log(caveats);
  const cg = {};
  for (const c of caveats) {
    const l = cg[c["code"]] || [];
    l.push(c);
    cg[c["code"]] = l;
  }

  if (cg["415"]) {
    return (
      <Expandable
        title={`${taskDisplayName("archive_extraction")} failed for ${
          cg["415"][0]["filename"]
        } - target file failed to decrypt`}
      >
        <p>
          <b>Attempted Passwords:</b>
        </p>
        <ul>
          {cg["415"][0]["metadata"]["AttemptedPasswords"].map((p, i) => (
            <li key={i}>{p}</li>
          ))}
        </ul>
      </Expandable>
    );
  }

  return (
    <li>
      {[
        { code: "414", message: "files exceeded file count limit" },
        { code: "413", message: "files exceeded 50 MB size limit" },
        {
          code: "416",
          message: "file skipped due to potential directory traversal attack",
        },
        { code: "500", message: "files were unpackable or unsupported" },
      ].map(({ code, message }, i) => {
        return (
          cg[code] && (
            <Expandable
              title={`${taskDisplayName(
                "archive_extraction"
              )} skipped analysis of some files - ${message}`}
            >
              <p>
                <b>Files:</b>
              </p>
              <ul>
                {cg[code].map(({ filename }, i) => (
                  <li key={i}>{filename}</li>
                ))}
              </ul>
            </Expandable>
          )
        );
      })}
    </li>
  );
};

const ArchiveExtractionCard = ({
  Filename,
  MagicHuman,
  Password,
  RelativePath,
  SHA256,
  Size,
}) => {
  return (
    <div className="card has-margin-bottom-20">
      <div className="card-header">
        <p className="card-header-title">
          <span className="threat-name">{Filename}</span>
        </p>
      </div>
      <div className="card-content">
        <div className="card-content">
          <ul>
            <li>
              <b>File Type:</b> {MagicHuman}
            </li>
            {RelativePath ? (
              <li>
                <b>Relative Path:</b>{" "}
                <span className="threat-name">{RelativePath}</span>
              </li>
            ) : null}
            <li>
              <b>SHA256 Hash:</b> {SHA256}
            </li>
            <li>
              <b>File Size:</b> <FileSize size={Size} />
            </li>
            {Password ? (
              <li>
                <b>Password:</b> {Password}
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    </div>
  );
};
