import { Task } from "./APITypes";

export const TaskOrder = [
  "eml_analyzer",
  "static_doc",
  "unarchiver",
  "archive_extraction",
  "static_file",
  "statictools",
  "virustotal",
  "gsb",
  "clamav",
  "yara",
  "tika",
  "staticurl",
  "static_web",
  "web_analyzer",
  "url_reputation",
  "twcuckoo",
  "twinwave_cuckoo",
  "sandbox_win7",
  "twinwave_cuckoo_win10",
  "sandbox_win10",
  "interactive_win7",
  "interactive_win10",
  "hybridanalysis",
  "falcon",
  "vmray",
  "wildfire",
  "falconx",
  "fireeye",
  "intezer",
];

export const TaskSortOrder = (task: Task) => TaskOrder.indexOf(task.Engine);

export const EngineSortOrder = (engineName: string) =>
  TaskOrder.indexOf(engineName);
