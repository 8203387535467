import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext, useState } from "react";
import { Job, NormalizedForensics, Caveat } from "src/lib/APITypes";
import { APIContext } from "src/lib/MAPApi";
import { truncationCaveat } from "../Caveats";
import { DetectionTable } from "../forensics/Detections";
import { ForensicsScreenshots } from "../forensics/ForensicsScreenshots";
import { FormComponent } from "../NormalizedForensics";
import { ActionConfig, ResourceSummary } from "../ResourceSummary";
import { taskDisplayName } from "../utils/DisplayNames";
import { ExpandableBox } from "../utils/ExpandableBox";
import { ThreatName } from "../utils/ThreatName";
import { LoadingMessage } from "./LoadingMessage";

export const ConsolidatedResults = ({
  job,
  forensics,
  children,
}: {
  job: Job;
  forensics: NormalizedForensics | "loading" | null;
  children: JSX.Element | JSX.Element[];
}) => {
  const { api } = useContext(APIContext);
  const { getAccessTokenSilently } = useAuth0();
  var [modalMessage, setModalMessage] = useState<JSX.Element | null>(null);

  if (forensics === "loading") {
    return <LoadingMessage />;
  }

  if (!forensics) {
    return <div>No forensics available</div>;
  }

  const detections = forensics.Detections || [];
  const forms = forensics.Forms || [];

  const checkResubmit = () => {
    setModalMessage(
      <>
        <div className="modal-card-body">
          <div className="content">
            <p>
              Resubmitting this sample will cause a new job to be created and
              analysis performed again.
            </p>
            <p>
              Resource:{" "}
              <b>
                <ThreatName name={job.Submission.Name} />
              </b>
            </p>
          </div>
        </div>
        <footer className="modal-card-foot">
          <button className="button is-success" onClick={doResubmit}>
            Resubmit
          </button>
          <button className="button" onClick={closeModal}>
            Cancel
          </button>
        </footer>
      </>
    );
  };

  const doResubmit = (evt: any) => {
    evt.target.classList.add("is-loading");
    evt.target.disabled = true;
    api
      .callAPI(getAccessTokenSilently, api.resubmitJob, job.ID)
      .then((resp: any) => {
        setModalMessage(
          <>
            <div className="modal-card-body">
              <div className="content">
                <p>Sample resubmitted for analysis</p>
                <p>
                  click <a href={"/job/" + resp.JobID}>here</a> to view
                  submission
                </p>
              </div>
            </div>
            <footer className="modal-card-foot"></footer>
          </>
        );
      })
      .catch((err: Error) => {
        console.log("Error resubmitting job: " + err);
      });
  };

  const closeModal = () => {
    setModalMessage(null);
  };

  var actions: ActionConfig[] = [];

  if (
    api.MODE === "standard" &&
    !job.RequestedEngines?.includes("interactive_web_analyzer")
  ) {
    actions.push({
      title: "Resubmit for Analysis",
      onClick: checkResubmit,
      icon: ["far", "redo"],
    });
  }

  return (
    <section>
      <ResourceSummary
        job={job}
        resource={job.Resources[0]}
        forensics={forensics}
        menuActions={actions}
      />
      {children}
      <ConsolidatedCaveats job={job} />
      {detections.length > 0 && (
        <ExpandableBox
          maxHeight={"30rem"}
          title={`Detections (${detections.length})`}
        >
          <DetectionTable detections={detections} isConsolidated={true} />
        </ExpandableBox>
      )}
      {forms.length > 0 && (
        <ExpandableBox title={`Forms (${forms.length})`} maxHeight="15rem">
          {forms.length > 0 ? (
            <table className="table">
              <tbody>
                {forms.map((f, idx) => (
                  <FormComponent value={f} key={idx} />
                ))}
              </tbody>
            </table>
          ) : null}
        </ExpandableBox>
      )}
      <ForensicsScreenshots
        forensics={forensics}
        isConsolidated={true}
        resources={job.Resources}
      />
      {modalMessage && (
        <div className="modal is-active">
          <div className="modal-background" onClick={closeModal}></div>
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Resubmit Sample</p>
              <button className="delete" onClick={closeModal}></button>
            </header>

            {modalMessage}
          </div>
        </div>
      )}
    </section>
  );
};

const ConsolidatedCaveats = ({ job }: { job: Job }) => {
  const codes_to_display = [1, 2, 3, 7, 13, 15, 16, 47, 500];
  let caveats = [];

  for (const t of job.Tasks) {
    let fc = (
      t.Results?.Details?.Caveats ||
      (t.StateText === "truncated" ? [truncationCaveat()] : [])
    ).filter((c: Caveat) => codes_to_display.includes(c.code));
    if (fc.length > 0) {
      const r = job.Resources.filter((r) => r.ID === t.ResourceID)[0];

      for (const c of fc) {
        caveats.push({
          parent_resource: r.Name,
          child_resource: c.resource_name,
          engine: t.Engine,
          caveat: c.code_human,
        });
      }
    }
  }

  if (caveats.length === 0) {
    return <></>;
  }

  return (
    <ExpandableBox
      maxHeight={"30rem"}
      title={`Processing Exceptions (${caveats.length})`}
      className="is-warning"
    >
      <table className="table" width="100%">
        <thead>
          <tr>
            <th>Engine</th>
            <th>Resource</th>
            <th>Exception</th>
          </tr>
        </thead>
        <tbody>
          {caveats.map((c, i) => (
            <tr key={i}>
              <td className="nowrap">{taskDisplayName(c.engine)}</td>
              {c.child_resource && c.child_resource !== c.parent_resource ? (
                <td>
                  <div className="treelist">
                    <ul>
                      <li className="root">
                        <ThreatName name={c.parent_resource} />
                      </li>
                      <ul>
                        <li>
                          <ThreatName name={c.child_resource} />
                        </li>
                      </ul>
                    </ul>
                  </div>
                </td>
              ) : (
                <td>
                  <ThreatName name={c.parent_resource} />
                </td>
              )}
              <td className="nowrap">{c.caveat}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </ExpandableBox>
  );
};
