import React from "react";
import { Score } from "./Score";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Tooltip from "@cypress/react-tooltip";
import { Labels } from "./utils/Labels";
import { LabelType } from "src/lib/APITypes";
import { ThreatName } from "./utils/ThreatName";
import { enginesToLabels } from "./utils/DisplayNames";

const taskStatus = (job) => {
  let nTasks = 0;
  let nComplete = 0;

  for (let k in job.Tasks) {
    nTasks++;

    if (["done", "error", "timedout"].includes(job.Tasks[k].State)) {
      nComplete++;
    }
  }

  return { nTasks, nComplete };
};

export const getStatusString = (job) => {
  if (job.State === "done") {
    return (
      <>
        <FontAwesomeIcon icon="check-circle" />{" "}
        {job.Sharing.SharedBy && (
          <>
            {" "}
            <Tooltip
              title={`This analysis was shared by ${job.Sharing.SharedBy}`}
            >
              <span>
                <FontAwesomeIcon icon="share" className="has-text-grey" />
              </span>
            </Tooltip>
          </>
        )}
      </>
    );
  }
  if (job.State === "error") {
    return (
      <FontAwesomeIcon
        icon="exclamation-triangle"
        className="has-text-warning"
      />
    );
  }

  const ts = taskStatus(job);
  if (ts.nTasks > 0) {
    return (
      <span className="tag is-info">
        <FontAwesomeIcon icon="spinner" pull="left" spin />
        {`${ts.nComplete}/${ts.nTasks}`}
      </span>
    );
  } else {
    if (job.State === "pending") {
      return (
        <FontAwesomeIcon icon="hourglass-half" className="has-text-grey" />
      );
    } else {
      return <FontAwesomeIcon icon="spinner" spin className="has-text-grey" />;
    }
  }
};

export const getMaxScore = (job) => {
  let maxScore = 0.0;

  for (let k in job.Tasks) {
    let score = 0.0;
    try {
      let results = job.Tasks[k].Results;
      score = results.score || results.Score;
    } catch (err) {}

    if (score > maxScore) {
      maxScore = score;
    }
  }

  return maxScore;
};

export const getSortedResources = (job) => {
  let resources = job.Resources;
  resources.sort((a, b) => new Date(a.CreatedAt) - new Date(b.CreatedAt)); // put into timestamp order for deterministic ordering
  let sorted = [];

  // build up a tree out of the resources
  let tree = resources.map((r) => {
    return { resource: r, children: [] };
  });
  for (let i = 0; i < tree.length; i++) {
    const r = tree[i];
    if (r.resource.ParentID) {
      const parent = tree.filter(
        (t) => t.resource.ID === r.resource.ParentID
      )[0];
      parent.children.push(r);
    }
  }

  const roots = tree.filter((r) => !r.resource.ParentID);

  function appendResource(r) {
    sorted.push(r.resource);
    r.children.forEach(appendResource);
  }

  roots.forEach(appendResource);

  return sorted;
};

export const getFileMeta = (job, name) => {
  try {
    return job.Resources[0].FileMetadata[name];
  } catch (err) {
    return null;
  }
};

export class JobRow extends React.Component {
  render() {
    const job = this.props.jobData;
    var name;
    try {
      name = job.Submission.Name;
    } catch (err) {}

    if (!name) {
      name = "ERROR";
    }

    var labels = [
      ...(job.Labels || []),
      { Type: LabelType.Verdict, Value: job?.Verdict },
    ];

    labels = labels.concat(enginesToLabels(job.RequestedEngines || []));

    return (
      <tr>
        <td className="nowrap fit-to-content">
          {new Date(Date.parse(job.CreatedAt)).toLocaleString()}
        </td>
        <td className="nowrap has-text-centered fit-to-content">
          {job.Username || (job.APIKey && `API - ${job.APIKey.Label}`) || "API"}
          {this.props.showTenant && !job.Username && ` (${job.TenantID})`}
        </td>
        <td>
          <Link to={`/job/${job.ID}`} title={name} className="joblink">
            <ThreatName name={name} />
          </Link>
          <Labels labels={labels} />
        </td>
        <td className="has-text-centered fit-to-content">
          <span className="tag">{job.Resources.length}</span>
        </td>
        <td className="has-text-centered fit-to-content">
          {getStatusString(job)}
        </td>
        <td className="has-text-centered fit-to-content">
          {["pending", "error"].includes(job.State) ? (
            <Score score="-" />
          ) : (
            <Score score={job.Score || getMaxScore(job)}></Score>
          )}
        </td>
      </tr>
    );
  }
}
