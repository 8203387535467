/**
 * Misc helpers
 */

import * as ipaddr from "ipaddr.js";

export const truncateStr = (s: string, n: number) => {
  if (s.length <= n) {
    return s;
  } else {
    return s.slice(0, n) + "...";
  }
};

/**
 * Truncate a string in the middle by replacing the center segment with ellipsis when it is too long.
 * @param s String to truncate
 * @param maxLength Length at which to enable truncation
 * @returns Truncated string (if necessary)
 */
export const truncateMidStr = (s: string, maxLength?: number): string => {
  if (maxLength === undefined || s.length < maxLength) {
    return s;
  }

  const charsBefore = Math.floor(maxLength / 2);
  const charsAfter = maxLength - charsBefore;

  const midOffset = Math.floor(s.length / 2);
  const beforeEndOffset = Math.min(charsBefore, midOffset - 3);
  const charsAfterOffset = Math.max(s.length - charsAfter, midOffset + 3);

  return `${s.slice(0, beforeEndOffset)}...${s.slice(charsAfterOffset)}`;
};

/**
 * Format a number using the users' locale
 * @param x
 * @returns Commafied number
 */
export const commafy = (x: number): string => {
  return x.toLocaleString();
};

export const humanize = (s: string) =>
  s
    .split("_")
    .map((m) => (m.length > 0 ? m[0].toUpperCase() + m.slice(1, m.length) : ""))
    .join(" ");

export const sameContents = (a: any, b: any): boolean => {
  if (!Array.isArray(a) || !Array.isArray(b)) return false;
  if (a.length !== b.length) return false;

  for (const e of a) if (!b.includes(e)) return false;

  return true;
};

/**
 * Convert supplied object to JSON and trigger browser download
 *
 * @param object Item to convert via `JSON.stringify`
 * @param filename Filename to present to end user (if browser supports)
 */
export const saveAsJson = (object: any, filename: string) => {
  saveWithMimeType(
    JSON.stringify(object),
    filename,
    "application/json;charset=utf-8"
  );
};

/**
 * Take supplied bytes and trigger browser download
 * @param bytes Bytes containing content to download (and suggested mime type)
 * @param filename Filename to present to end user (if browser supports)
 * @param mimeType Mimetype / content type header value
 */
export const saveWithMimeType = (
  bytes: any,
  filename: string,
  mimeType: string
) => {
  let content = new Blob([bytes], {
    type: mimeType,
  });

  saveBlob(content, filename);
};

/**
 * Take supplied blob and trigger browser download
 * @param blob Blob containing content to download (and suggested mime type)
 * @param filename Filename to present to end user (if browser supports)
 */
export const saveBlob = (blob: Blob, filename: string) => {
  // weird hack from https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
};

export interface IpAddressMatcher {
  /**
   * Returns true when the supplied IP matches this matcher
   *
   * Throws an error if the input cannot be parsed.
   * @param ip IP address (v4 or v6)
   */
  matches(ip: string): boolean;
}

/**
 * IPv4/IPv6 address matcher factory
 *
 * Provides a matcher given the IP pattern
 * @param pattern A standard format IPv4 or V6 ip, cidr, or range (A-B) pattern to match against.
 * @returns An IpAddressMatcher that tells you if the supplied IP is
 * contained in the match pattern.
 *
 * Throws an error if the input cannot be parsed.
 */
export const ipMatcher = (pattern: string): IpAddressMatcher => {
  // Identify match mode and return a matcher
  if (pattern.includes("/")) {
    // cidr mode
    const cidr = ipaddr.parseCIDR(pattern.replaceAll(" ", ""));

    return {
      matches: (ip: string): boolean => {
        const parsedIp = ipaddr.parse(ip);
        return parsedIp.match(cidr);
      },
    };
  } else if (pattern.includes("-")) {
    // range
    const parts = pattern.replaceAll(" ", "").split("-", 2);
    if (parts.length !== 2) {
      throw new TypeError("IP range not valid");
    }
    const start = ipaddr.parse(parts[0]);
    const end = ipaddr.parse(parts[1]);

    if (start.kind !== end.kind) {
      throw new TypeError("Mismatched start and end IP type");
    }

    const startBytes = start.toByteArray();
    const endBytes = end.toByteArray();
    if (!byteRangeLte(startBytes, endBytes)) {
      // backwards range
      throw new TypeError("IP Range start is after end");
    }

    return {
      matches: (ip: string): boolean => {
        const parsedIp = ipaddr.parse(ip).toByteArray();
        // start <= ip <= end
        return (
          byteRangeLte(startBytes, parsedIp) && byteRangeGte(endBytes, parsedIp)
        );
      },
    };
  } else {
    // exact
    return { matches: (ip: string): boolean => pattern.toLowerCase() === ip };
  }
};

/**
 * Check if the given IP matches the supplied IP pattern
 *
 * For repeat matches, one should use ipMatcher.
 * @param ip IP address (v4 or v6)
 * @param pattern A standard format IPv4 or V6 ip, cidr, or range (A-B) pattern to match against.
 * @returns
 */
export const ipMatches = (ip: string, pattern: string): boolean => {
  return ipMatcher(pattern).matches(ip);
};

function byteRangeLte(a: number[], b: number[]): boolean {
  const val = byteRangeCmp(a, b);
  return val === -1 || val === 0;
}

function byteRangeGte(a: number[], b: number[]): boolean {
  return byteRangeCmp(a, b) >= 0;
}

/**
 * Compare big endian byte ranges
 * @param a
 * @param b
 * @returns -1 if a<b, 0 if eq, 1 if a>b, -2 on err
 */
function byteRangeCmp(a: number[], b: number[]): number {
  if (a === undefined || b === undefined || a.length !== b.length) {
    return -2;
  }

  for (let i = 0; i < a.length; i++) {
    if (a[i] < b[i]) {
      return -1;
    }
    if (a[i] > b[i]) {
      return 1;
    }
  }
  return 0;
}
