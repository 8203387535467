import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useReportJobHook } from "src/lib/API";
import { Job } from "src/lib/APITypes";
import * as Menu from "./Menu";
import * as Modal from "./Modal";

export const ReportJob = ({ job }: { job: Job }) => {
  const { send, isSent, hasError } = useReportJobHook(job.ID);
  let view;
  if (!isSent) {
    view = <Editor send={send} />;
  } else if (hasError) {
    view = <Failure />;
  } else {
    view = <Success />;
  }

  return (
    <Menu.Item
      text="Send Feedback"
      permission="job:report"
      icon={["far", "flag"]}
      standalone={true}
    >
      <Modal.Card title="Submit Feedback">{view}</Modal.Card>
    </Menu.Item>
  );
};

// eslint-disable-next-line
const StarRating = ({
  n,
  onChange,
}: {
  n: number;
  onChange: (i: number) => void;
}) => {
  const [rating, setRating] = useState(0);

  useEffect(() => {
    onChange(rating);
  }, [rating, onChange]);

  const elems = [];
  for (let i = 0; i < n; i++) {
    const isSelected = i < rating;
    elems.push(
      <span
        className={`star-rating ${isSelected ? "is-selected" : ""}`}
        onMouseEnter={() => setRating(i + 1)}
      >
        <FontAwesomeIcon
          size="lg"
          key={i}
          icon={[isSelected ? "fas" : "far", "star"]}
        ></FontAwesomeIcon>
      </span>
    );
  }

  return <div>{elems}</div>;
};

const Editor = ({
  send,
}: {
  send: (p: { comments: string; disposition: string }) => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [disposition, setDisposition] = useState("");
  const [comments, setComments] = useState("");
  const [validationMsg, setValidationMessage] = useState<JSX.Element | null>(
    null
  );
  const [submittable, setSubmittable] = useState(false);

  const validate = () => {
    const msgs = [];
    if (!disposition) {
      msgs.push("A Reason must be set to submit feedback.");
    }

    if (comments.length > 10000) {
      msgs.push("Comments must be less than 10K characters.");
    }

    setSubmittable(msgs.length === 0);
    if (msgs.length === 0) {
      setValidationMessage(null);
    } else {
      setValidationMessage(
        <ul style={{ margin: 0, listStyle: "none" }}>
          {msgs.map((m) => (
            <li key={m}>{m}</li>
          ))}
        </ul>
      );
    }
  };

  return (
    <>
      <Modal.CardBody>
        {validationMsg && (
          <div className="notification is-warning">{validationMsg}</div>
        )}
        <div className="field">
          <label className="label">Reason (Required)</label>
          <div className="control">
            <div className="select is-fullwidth">
              <select
                onChange={(evt) => {
                  setDisposition(evt.target.value);
                }}
                value={disposition}
                onBlur={validate}
              >
                <option value="">-</option>
                <option value="false_positive">
                  False Positive: It was scored high (malicious), but it's
                  benign.
                </option>
                <option value="false_negative">
                  False Negative: It was scored low (benign), but it's
                  malicious.
                </option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>
        </div>
        <div className="field">
          <label className="label">Comments (Optional)</label>
          <div className="control">
            <textarea
              className="textarea"
              placeholder="Comments…"
              value={comments}
              onChange={(evt) => {
                setComments(evt.target.value);
              }}
              onBlur={validate}
            ></textarea>
          </div>
        </div>
      </Modal.CardBody>
      <Modal.CardFooter>
        <button
          className={`button is-primary ${isLoading ? "is-loading" : ""}`}
          disabled={!submittable}
          onClick={() => {
            setIsLoading(true);
            send({ comments, disposition });
          }}
        >
          Send Feedback
        </button>
        <Modal.CardCloseButton />
      </Modal.CardFooter>
    </>
  );
};

const Success = () => {
  return (
    <>
      <Modal.CardBody>
        <p>
          Thank you for reporting this submission. The Attack Analyzer team will
          automatically be notified of your feedback. If we need any additional
          information we will contact you directly via email.
        </p>
        <p>
          You can also reach us at <SupportEmail />.
        </p>
        <p>Attack Analyzer Team</p>
      </Modal.CardBody>
      <Modal.CardFooter>
        <Modal.CardCloseButton text="Close" />
      </Modal.CardFooter>
    </>
  );
};

const Failure = () => {
  return (
    <>
      <Modal.CardBody>
        <p>Whoops, there was an error reporting this submission.</p>
        <p>
          Please try again in a few minutes. If the issue persists please submit
          your report to us at <SupportEmail />.
        </p>
        <p>Attack Analyzer Team</p>
      </Modal.CardBody>
      <Modal.CardFooter>
        <Modal.CardCloseButton text="Close" />
      </Modal.CardFooter>
    </>
  );
};

const SupportEmail = () => (
  <a href="mailto:attackanalyzer-support@splunk.com">
    attackanalyzer-support@splunk.com
  </a>
);
