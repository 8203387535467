import React, { useContext } from "react";
import ReactDOM from "react-dom";

const nopCloser = () => {};

const ModalContext = React.createContext<{ close: VoidFunction | undefined }>({
  close: nopCloser,
});

const MODAL_ID = "modal-root";

export const Container = () => <div id={MODAL_ID}></div>;

export const Modal = ({
  isActive,
  close,
  children,
  fullscreen,
  wider,
}: {
  isActive: boolean;
  close?: VoidFunction;
  children: React.ReactNode;
  fullscreen?: boolean;
  wider?: boolean;
}) => {
  const root = document.getElementById(MODAL_ID)!;
  fullscreen = fullscreen ?? false;

  if (isActive) {
    const extraStyles = fullscreen
      ? {
          width: "100vw",
          height: "100vh",
          position: "absolute" as "absolute",
          top: 0,
          left: 0,
        }
      : {};
    if (wider) {
      extraStyles.width = "auto";
    }

    return ReactDOM.createPortal(
      <ModalContext.Provider value={{ close }}>
        <div className="modal is-active">
          <div className="modal-background" onClick={close}></div>
          <div
            className="modal-content"
            style={{ overflowY: "hidden", ...extraStyles }}
          >
            {children}
          </div>
        </div>
      </ModalContext.Provider>,
      root
    );
  }

  return null;
};

export const Card = ({
  title,
  children,
  close,
}: {
  title: string;
  children: React.ReactNode;
  close?: VoidFunction;
}) => {
  const { close: inheritedCloser } = useContext(ModalContext);
  close = close || inheritedCloser;

  return (
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">{title}</p>
        {
          // if no close function is passed in, then we don't need the close button
          close !== undefined && (
            <button
              className="delete"
              aria-label="close"
              onClick={close}
            ></button>
          )
        }
      </header>
      {children}
    </div>
  );
};

export const CardBody = ({ children }: { children: React.ReactNode }) => {
  return (
    <section className="modal-card-body">
      <div className="content">{children}</div>
    </section>
  );
};

export const CardFooter = ({ children }: { children?: React.ReactNode }) => {
  return <footer className="modal-card-foot">{children}</footer>;
};

export const CardCloseButton = ({ text }: { text?: string }) => {
  const { close } = useContext(ModalContext);
  return (
    <button className="button" onClick={close}>
      {text ?? "Cancel"}
    </button>
  );
};
