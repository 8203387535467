import { useResubmitHook } from "src/lib/API";
import { Job } from "src/lib/APITypes";
import * as Menu from "./Menu";
import * as Modal from "./Modal";
import { ThreatName } from "./utils/ThreatName";

export const ResubmitSample = ({ job }: { job: Job }) => {
  const { isLoading, newJobID, resubmit } = useResubmitHook(job.ID);

  if (job.RequestedEngines?.includes("interactive_web_analyzer")) return <></>;

  let content;
  if (!newJobID) {
    content = (
      <>
        <Modal.CardBody>
          <p>
            Resubmitting this sample will cause a new job to be created and
            analysis performed again.
          </p>
          <p>
            Resource:{" "}
            <b>
              <ThreatName name={job.Submission.Name} />
            </b>
          </p>
        </Modal.CardBody>
        <Modal.CardFooter>
          <button
            className={`button is-success ${isLoading ? "is-loading" : ""}`}
            disabled={isLoading}
            onClick={resubmit}
          >
            Resubmit
          </button>
          <Modal.CardCloseButton />
        </Modal.CardFooter>
      </>
    );
  } else {
    content = (
      <>
        <Modal.CardBody>
          <p>Sample resubmitted for analysis</p>
          <p>
            click <a href={"/job/" + newJobID}>here</a> to view submission
          </p>
        </Modal.CardBody>
        <Modal.CardFooter />
      </>
    );
  }

  return (
    <Menu.Item
      text="Resubmit"
      icon={["far", "redo"]}
      standalone={true}
      permission="job:submit"
    >
      <Modal.Card title="Resubmit Sample">{content}</Modal.Card>
    </Menu.Item>
  );
};
