import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDeleteHook } from "src/lib/API";
import { Job } from "src/lib/APITypes";
import * as Menu from "./Menu";
import * as Modal from "./Modal";

export const DeleteJob = ({ job }: { job: Job }) => {
  const navigate = useNavigate();
  const { del, isLoading, isDeleted, hasError } = useDeleteHook(job.ID);

  useEffect(() => {
    if (isDeleted) {
      toast.success("Job deleted sucessfully", {
        autoClose: false,
      });
      navigate("/");
    }
  }, [isDeleted, navigate]);

  useEffect(() => {
    if (hasError) {
      toast.error("Failed to delete job", {
        autoClose: false,
      });
    }
  }, [hasError]);

  return (
    <Menu.Item
      text="Delete Job"
      permission="job:delete"
      isDisabled={
        job.State === "done"
          ? ""
          : "In progress jobs cannot be deleted. Please wait for the job to finish before deleting."
      }
      icon={["far", "trash-alt"]}
    >
      <Modal.Card title="Delete Job">
        <Modal.CardBody>
          <p>
            This will permanently delete the job and all associated data from
            the system.
          </p>
          <p>Are you sure you want to continue?</p>
        </Modal.CardBody>
        <Modal.CardFooter>
          <button
            className={`button is-danger ${isLoading ? "is-loading" : ""}`}
            disabled={isLoading}
            onClick={del}
          >
            Delete
          </button>
          <Modal.CardCloseButton />
        </Modal.CardFooter>
      </Modal.Card>
    </Menu.Item>
  );
};
