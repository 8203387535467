export function URLIsEncoded(url) {
  let re =
    /^https?:\/\/(urldefense\.(?:proofpoint\.com|com|us)\/v[123]|isolate\.menlosecurity\.com|\w+\.safelinks\.protection\.outlook\.com|[.\w-]*(?:ctp.wtp|ctp|-urlprotect)\.trendmicro\.com(?::443)?\/wis\/clicktime\/v[12]|linkprotect\.cudasvc\.com)\//;

  return url.trim().match(re) != null;
}

export function decodeURL(url) {
  while (URLIsEncoded(url)) {
    url = url.trim();

    let decodedURL = url;
    if (url.match(/^https?:\/\/urldefense\.(?:proofpoint\.com|com|us)/)) {
      decodedURL = decodeProofpoint(url);
    } else if (url.match(/^https?:\/\/isolate\.menlosecurity\.com/)) {
      decodedURL = decodeMenlo(url);
    } else if (
      url.match(/^https?:\/\/\w+\.safelinks\.protection\.outlook\.com\/\?/)
    ) {
      decodedURL = decodeSafelinks(url);
    } else if (
      url.match(
        /^https:\/\/(?:[\w-]+-urlprotect|[\w-]+-ctp|ctp.wtp|ctp)\.trendmicro\.com(?::443)?\/wis\/clicktime(?:\/v1\/query|\/v2\/clickthrough)/
      )
    ) {
      decodedURL = decodeTrendMicro(url);
    } else if (url.match(/^https?:\/\/linkprotect\.cudasvc\.com\/url\?/)) {
      decodedURL = decodeBarracuda(url);
    }

    // just to be paranoid, if we tried and failed to un-rewrite, just give up so we don't go into an endless loop
    if (decodedURL === url) {
      return decodedURL;
    }
    url = decodedURL;
  }

  return url;
}

export function decodeSafelinks(url) {
  let u = new URL(url);

  let eu = u.searchParams.get("url");
  if (eu) {
    return decodeURI(eu);
  } else {
    return url;
  }
}

export function decodeTrendMicro(url) {
  let u = new URL(url);

  let eu = u.searchParams.get("url");
  if (eu) {
    return decodeURI(eu);
  } else {
    return url;
  }
}

export function decodeBarracuda(url) {
  let u = new URL(url);

  let eu = u.searchParams.get("a");
  if (eu) {
    return decodeURI(eu);
  } else {
    return url;
  }
}

export function decodeMenlo(url) {
  let re = /^https?:\/\/isolate.menlosecurity.com(?:\/1\/\d+)?\/(.*)/;

  let groups = re.exec(url);

  if (!groups || groups.length !== 2) {
    return url;
  }

  return fixScheme(groups[1]);
}

// fixScheme converts URLs with only a single slash in their scheme to a proper URL (e.g., http:/foo.com -> http://foo.com)
function fixScheme(url) {
  return url.replace(/^((?:http|ftp)s?):\/([^/])/, "$1://$2");
}

/**
 * Convert string containing base64 to bytes
 *
 * Wonky workaround due to atob() assuming a charset.
 * @param {*} base64 string containing base64 data
 * @returns Uint8Array of bytes
 */
function base64ToBytes(base64) {
  const binString = atob(base64);
  return Uint8Array.from(binString, (m) => m.codePointAt(0));
}

const UTF8_DECODER = new TextDecoder();

export function decodeProofpoint(url) {
  let match = url.match(
    /https?:\/\/urldefense(?:\.proofpoint)?\.com\/(v[0-9])\//
  );
  if (match && match.length === 2) {
    let version = match[1];
    if (version === "v1" || version === "v2") {
      match = url.match(/\?u=([^&]+)&/);
      if (match && match.length === 2) {
        let encoded = match[1];

        if (version === "v2") {
          encoded = encoded.replace(/-/g, "%").replace(/_/g, "/");
        }

        return unescape(encoded);
      }
    }
    if (version === "v3") {
      match = url.match(/__(.+?)__(?:;|%3[bB])(.*?)(?:!|%21)/);
      if (match && match.length === 3) {
        let encoded = match[1];
        let replaceChars = match[2];
        let decoded = "";

        if (replaceChars) {
          let subs = base64ToBytes(
            replaceChars.replace(/_/g, "/").replace(/-/g, "+")
          );
          let subidx = 0;

          for (let i = 0; i < encoded.length; i++) {
            if (encoded[i] === "*") {
              if (encoded[i + 1] === "*") {
                // case with double ** characters
                // The substitute may be single byte or wide chars!
                let runlen = v3_sub_length[encoded[i + 2]] || 0; // run in number of bytes!

                let chunk = new Uint8Array(runlen);
                for (let j = 0; j < runlen; j++) {
                  chunk[j] = subs[subidx++];
                }
                decoded += UTF8_DECODER.decode(chunk);
                i += 2; // advance i past what we just parsed
              } else {
                // case with single * character
                decoded += String.fromCharCode(subs[subidx++]);
              }
            } else {
              decoded += encoded[i];
            }
          }
        } else {
          decoded = encoded;
        }

        return fixScheme(decoded);
      }
    }
  }

  return url;
}

const v3_sub_length = {
  A: 2,
  B: 3,
  C: 4,
  D: 5,
  E: 6,
  F: 7,
  G: 8,
  H: 9,
  I: 10,
  J: 11,
  K: 12,
  L: 13,
  M: 14,
  N: 15,
  O: 16,
  P: 17,
  Q: 18,
  R: 19,
  S: 20,
  T: 21,
  U: 22,
  V: 23,
  W: 24,
  X: 25,
  Y: 26,
  Z: 27,
  a: 28,
  b: 29,
  c: 30,
  d: 31,
  e: 32,
  f: 33,
  g: 34,
  h: 35,
  i: 36,
  j: 37,
  k: 38,
  l: 39,
  m: 40,
  n: 41,
  o: 42,
  p: 43,
  q: 44,
  r: 45,
  s: 46,
  t: 47,
  u: 48,
  v: 49,
  w: 50,
  x: 51,
  y: 52,
  z: 53,
  0: 54,
  1: 55,
  2: 56,
  3: 57,
  4: 58,
  5: 59,
  6: 60,
  7: 61,
  8: 62,
  9: 63,
  "-": 64,
  _: 65,
};
