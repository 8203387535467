import React from "react";
import { CaveatCard } from "../Caveats";
import { FileSize } from "../FileSize";
import { DetectionBox } from "../forensics/Detections";
import { taskDisplayName } from "../utils/DisplayNames";
import { Expandable } from "../utils/Expandable";
import { TaskProperties } from "./TaskTypes";
import { ResourceSummary } from "../ResourceSummary";
import { ExpandableBox } from "../utils/ExpandableBox";
import { ForensicsScreenshots } from "../forensics/ForensicsScreenshots";
import { LoadingMessage } from "./LoadingMessage";
import { ThreatName } from "../utils/ThreatName";

export const StaticDocResults = ({
  job,
  task,
  resource,
  forensics,
}: TaskProperties) => {
  if (forensics === "loading" || !forensics) {
    return (
      <section>
        <ResourceSummary job={job} resource={resource} task={task} />
        <LoadingMessage />
      </section>
    );
  }

  const details = task.Results.Details || {};

  const add = (key: string, name: string, collapsed = false) => {
    const value = details[key];
    return value ? (
      <Item name={name} value={value} collapsed={collapsed} />
    ) : null;
  };

  let password = details.FileDecryption?.password;

  return (
    <section>
      <ResourceSummary job={job} resource={resource} task={task} />

      {details.Caveats && details.Caveats.length >= 1 && (
        <CaveatCard caveats={details.Caveats} engine="static_doc" />
      )}

      {(forensics.Detections?.length || 0) > 0 && (
        <DetectionBox detections={forensics.Detections} maxHeight="30rem" />
      )}

      <ExpandableBox title="Document Properties" maxHeight="15rem">
        <table className="table is-narrow is-full-width">
          <tbody>
            {add("dc:title", "Title")}
            {add("dc:subject", "Subject")}
            {add("meta:keyword", "Keyword")}
            {add("dc:format", "Format")}
            {add("dcterms:created", "Created At")}
            {add("dcterms:modified", "Modified At")}
            {add("cp:revision", "Revision")}
            {add("dc:creator", "Creator")}
            {add("meta:author", "Author")}
            {add("meta:last-author", "Last Author")}
            {add("dc:publisher", "Publisher")}
            {add("extended-properties:Company", "Company")}
            {add("extended-properties:Application", "Creating Application")}
            {add(
              "extended-properties:AppVersion",
              "Creating Application Version"
            )}
            {add("pdf:docinfo:creator_tool", "Creator Tool")}
            {add("pdf:docinfo:producer", "Producer")}
            {add("pdf:encrypted", "Encrypted")}
            {add("xmpTPg:NPages", "Number of Pages")}
            {add("meta:page-count", "Page Count")}
            {add("extended-properties:Template", "Document Template")}
            {add("pdf:charsPerPage", "Characters Per Page", true)}
            {add("meta:paragraph-count", "Paragraph Count", true)}
            {add("meta:line-count", "Line Count", true)}
            {add("meta:word-count", "Word Count", true)}
            {add("meta:character-count", "Character Count", true)}
            {add("resourceName", "Resource Names", true)}
            {add("Content-Type", "Content Types", true)}
            {password && <Item name={"Password"} value={password} />}
            <VBAAnalysis vba={details["olevba_analysis"]} />
          </tbody>
        </table>
      </ExpandableBox>
      {details.EXIFMetadata && (
        <ExpandableBox title="EXIF Metadata" maxHeight="15rem">
          <table className="table is-narrow is-full-width">
            <tbody>
              {Object.keys(details.EXIFMetadata).map((k) => (
                <Item
                  key={k}
                  name={k}
                  value={details.EXIFMetadata[k]}
                  collapsed={false}
                />
              ))}
            </tbody>
          </table>
        </ExpandableBox>
      )}

      {password && (
        <>
          <hr />
          <p className="has-margin-bottom-20">
            {taskDisplayName("static_doc")} extracted the following file after
            decrypting the original file:
          </p>
          <div className="card has-margin-bottom-20">
            <div className="card-header">
              <p className="card-header-title">
                <ThreatName name={details.resourceName} />
              </p>
            </div>
            <div className="card-content">
              <div className="card-content">
                <ul>
                  <li>
                    <b>SHA256 Hash:</b> {details.FileDecryption.sha256}
                  </li>
                  <li>
                    <b>MD5 Hash:</b> {details.FileDecryption.md5}
                  </li>
                  <li>
                    <b>File Size:</b>{" "}
                    <FileSize size={details.FileDecryption.size} />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
      <ForensicsScreenshots forensics={forensics} />
    </section>
  );
};

const Item = ({
  name,
  value,
  collapsed = false,
}: {
  name: string;
  value: string[];
  collapsed?: boolean;
}) => {
  let child;
  if (Array.isArray(value)) {
    let idx = 0;
    child = (
      <tr>
        <th>{name}</th>
        <td>
          {collapsed ? (
            <Expandable expanded={false} title={`${value.length} items`}>
              <ul>
                {value.map((v) => (
                  <li key={idx++}>{v}</li>
                ))}
              </ul>
            </Expandable>
          ) : (
            <ul>
              {value.map((v) => (
                <li key={idx++}>{v}</li>
              ))}
            </ul>
          )}
        </td>
      </tr>
    );
  } else {
    child = (
      <tr>
        <th>{name}</th>
        <td>{value}</td>
      </tr>
    );
  }
  return child;
};

const VBAAnalysis = ({
  vba,
}: {
  vba: { [key: string]: { [key: string]: string }[] };
}) => {
  let detections: { [key: string]: number } = {};
  if (!vba) {
    return null;
  }
  Object.values(vba).forEach((a) => {
    a.forEach((o) => {
      Object.values(o).forEach((v) => {
        detections[v] = detections[v] + 1 || 1;
      });
    });
  });
  return (
    <tr>
      <th>VBA Analysis</th>
      <td>
        <ul>
          {Object.keys(detections).map((d) => (
            <li key={d}>
              {d} {detections[d] > 1 && <>[{detections[d]} times]</>}
            </li>
          ))}
        </ul>
      </td>
    </tr>
  );
};
