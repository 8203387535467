import React from "react";
import { Score } from "./Score";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { taskDisplayName, packageDisplayName } from "./utils/DisplayNames";

export class TaskRow extends React.Component {
  render() {
    let task = this.props.task;
    var score;

    try {
      let results = task.Results;
      score = results.Score;
    } catch (err) {}

    if (score === undefined) {
      score = 0;
    }

    let taskIcon;
    let stateTip = task.State;
    switch (task.State) {
      case "done":
        taskIcon = "check-circle";
        stateTip = "Completed";
        break;
      case "error":
        taskIcon = "exclamation-triangle";
        stateTip = "Error";
        score = "-";
        break;
      case "inprogress":
        taskIcon = "spinner";
        stateTip = "In Progress";
        score = "TBD";
        break;
      case "timedout":
        taskIcon = "alarm-exclamation";
        stateTip = "Timed Out";
        score = "-";
        break;
      case "dispatched":
        taskIcon = "hourglass-half";
        stateTip = "In Queue";
        score = "TBD";
        break;
      default:
        taskIcon = "question-circle";
    }

    const shouldSpin = task.State === "inprogress";

    return (
      <div className="is-full-width">
        <a
          href="/loadforensics"
          className={"panel-block" + (this.props.isActive ? " is-active" : "")}
          onClick={(evt) => {
            evt.preventDefault();
            this.props.onSelect(this.props.resource, task);
          }}
        >
          <span className="panel-icon">
            <FontAwesomeIcon
              icon={taskIcon}
              spin={shouldSpin}
              title={stateTip}
            />
          </span>
          <span className="is-full-width has-ellipsed-text">
            {taskDisplayName(task.Engine)}{" "}
            {[
              "twinwave_cuckoo",
              "capev2",
              "capev2win10",
              "twinwave_cuckoo_win10",
              "sandbox_win7",
              "sandbox_win10",
            ].includes(task.Engine) &&
              task.Results &&
              task.Results.Details &&
              task.Results.Details.Package && (
                <div className="is-size-7 has-text-grey has-ellipsed-text">
                  {packageDisplayName(task.Results.Details.Package)}
                </div>
              )}
            {task.Engine === "wildfire" &&
              task.Results &&
              task.Results.Details &&
              task.Results.Details.Package && (
                <div className="is-size-7 has-text-grey has-ellipsed-text">
                  {packageDisplayName(task.Results.Details.Package)}
                </div>
              )}
            {task.Engine === "fireeye" &&
              task.Results &&
              task.Results.Details &&
              task.Results.Details.Package && (
                <div className="is-size-7 has-text-grey has-ellipsed-text">
                  {packageDisplayName(task.Results.Details.Package)}
                </div>
              )}
            {task.Engine === "vmray" &&
              task.Results &&
              task.Results.Details &&
              task.Results.Details.VMName && (
                <div className="is-size-7 has-text-grey has-ellipsed-text">
                  {packageDisplayName(task.Results.Details.VMName)}
                </div>
              )}
            {task.Engine === "hatching_triage" &&
              task.Results &&
              task.Results.Details &&
              task.Results.Details.Package && (
                <div className="is-size-7 has-text-grey has-ellipsed-text">
                  {packageDisplayName(task.Results.Details.Package)}
                </div>
              )}
            {task.Engine.startsWith("interactive") && (
              <div className="is-size-7 has-text-grey has-ellipsed-text">
                Interactive
              </div>
            )}
          </span>
          <span className="has-text-right">
            <Score score={score}></Score>
          </span>
        </a>
      </div>
    );
  }
}
