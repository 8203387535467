import React from "react";
import { Score } from "./Score";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
// @ts-ignore
import Tooltip from "@cypress/react-tooltip";
import { Labels } from "./utils/Labels";
import { Label, LabelType } from "src/lib/APITypes";
import { ThreatName } from "./utils/ThreatName";
import { enginesToLabels } from "./utils/DisplayNames";

interface JobSummary {
  JobID: string;
  CreatedAt: string;
  TenantID: string;
  Username: string;
  APIKeyLabel: string;
  ResourceName: string;
  ResourceCount: number;
  State: string;
  Score: number;
  TasksCompleted: number;
  TasksTotal: number;
  MaxTaskScore: number;
  Labels: Label[];
  SharedBy: string;
  Verdict: string;
  RequestedEngines?: string[];
}

export const getStatusString = (job: JobSummary) => {
  if (job.State === "done") {
    return (
      <>
        <FontAwesomeIcon icon="check-circle" />{" "}
        {job.SharedBy && (
          <>
            {" "}
            <Tooltip title={`This analysis was shared by ${job.SharedBy}`}>
              <span>
                <FontAwesomeIcon icon="share" className="has-text-grey" />
              </span>
            </Tooltip>
          </>
        )}
      </>
    );
  }
  if (job.State === "error") {
    return (
      <FontAwesomeIcon
        icon="exclamation-triangle"
        className="has-text-warning"
      />
    );
  }

  if (job.TasksTotal > 0) {
    return (
      <span className="tag is-info">
        <FontAwesomeIcon icon="spinner" pull="left" spin />
        {`${job.TasksCompleted}/${job.TasksTotal}`}
      </span>
    );
  } else {
    if (job.State === "pending") {
      return (
        <FontAwesomeIcon icon="hourglass-half" className="has-text-grey" />
      );
    } else {
      return <FontAwesomeIcon icon="spinner" spin className="has-text-grey" />;
    }
  }
};

export class JobSummaryRow extends React.Component<{ jobData: JobSummary }> {
  render() {
    const job: JobSummary = this.props.jobData;
    var name;
    try {
      name = job.ResourceName;
    } catch (err) {}

    if (!name) {
      name = "ERROR";
    }

    var labels = [
      ...(job.Labels || []),
      { Type: LabelType.Verdict, Value: job?.Verdict },
    ];

    labels = labels.concat(enginesToLabels(job.RequestedEngines || []));

    return (
      <tr>
        <td className="nowrap fit-to-content">
          {new Date(Date.parse(job.CreatedAt)).toLocaleString()}
        </td>
        <td className="nowrap has-text-centered fit-to-content">
          {job.Username ||
            (job.APIKeyLabel && `API - ${job.APIKeyLabel}`) ||
            "API"}
        </td>
        <td>
          <Link to={`/job/${job.JobID}`} title={name} className="joblink">
            <ThreatName name={name} />
          </Link>
          <Labels labels={labels} />
        </td>
        <td className="has-text-centered fit-to-content">
          <span className="tag">{job.ResourceCount}</span>
        </td>
        <td className="has-text-centered fit-to-content">
          {getStatusString(job)}
        </td>
        <td className="has-text-centered fit-to-content">
          {["pending", "error"].includes(job.State) ? (
            <Score score="-" />
          ) : (
            <Score score={job.Score || job.MaxTaskScore}></Score>
          )}
        </td>
      </tr>
    );
  }
}
