import { useAuth0 } from "@auth0/auth0-react";
import SplunkRum from "@splunk/otel-web";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import { useImmer } from "use-immer";
import {
  UserList,
  APIKey,
  APIKeyWithSecrets,
  NewUserRecord,
  RepProxyReturnTypes,
  SearchV2QueryParams,
  SearchV2Results,
  EmailSubmissionConfig,
  BaseApiResponse,
  CreateEmailSubmissionResponse,
  AdminCreateEmailSubmissionRequest,
} from "./APITypes";
import { AUDIENCE } from "./Auth";
import { APIContext } from "./MAPApi";

export const useAPI = () => {
  const { api } = useContext(APIContext);
  const { getAccessTokenSilently: tokenGetter } = useAuth0();

  const proxy = useMemo(() => {
    return new Proxy(api, {
      get: (target, prop, _receiver) => {
        const apiCallInNeedOfToken = target[prop];
        return (...args: any[]) =>
          tokenGetter({ audience: AUDIENCE })
            .catch((err) => {
              if (err.error === "login_required") {
                console.log("tried to call API but login is required!");
                window.location.replace("/"); // this should force the login dialog to show if the user truly is logged out
              }
            })
            .then((token) => {
              return apiCallInNeedOfToken(token, ...args);
            });
      },
    });
  }, [api, tokenGetter]);

  return proxy;
};

export const useShareHook = (id: string, currentlyShared: boolean) => {
  const api = useAPI();
  const [isShared, setIsShared] = useState(currentlyShared);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [updatedAt, setUpdatedAt] = useState<null | number>(null);

  const toggleShare = useCallback(() => {
    if (isLoading) return;
    setIsLoading(true);
    setIsShared(!isShared);
    api
      .setSharedState(id, !isShared)
      .catch((err: Error) => {
        setHasError(true);
        setIsShared(isShared); // Revert the opportunistic update
        console.error("Error resubmitting job: " + err);
      })
      .finally(() => {
        setUpdatedAt(Date.now());
        setIsLoading(false);
      });
  }, [api, id, isLoading, isShared]);

  return { isShared, isLoading, hasError, toggleShare, updatedAt };
};

export const useArtifactHook = (path: string | undefined) => {
  const api = useAPI();
  const [isLoading, setIsLoading] = useState(true);
  const [contents, setContents] = useState("");

  useEffect(() => {
    let cancelled = false;

    if (path !== undefined) {
      (async () => {
        try {
          const s = await api.getArtifactByPathToMemory(path);
          if (!cancelled) setContents(s || "");
        } catch (_) {
        } finally {
          if (!cancelled) setIsLoading(false);
        }
      })();
    }

    return () => {
      cancelled = true;
    };
  }, [path, api]);

  return { isLoading, contents };
};

export function useRepProxyHook<T extends keyof RepProxyReturnTypes>(path: T) {
  const api = useAPI();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<RepProxyReturnTypes[T]>([]);

  const submit = useCallback(
    (p: string[]) => {
      setIsLoading(true);
      setData([]);
      api
        .repProxy(path, p)
        .then((d: any) => setData(d))
        .finally(() => {
          setIsLoading(false);
        });
    },
    [api, path]
  );

  return { isLoading, data, submit };
}

export const useReportJobHook = (id: string) => {
  const api = useAPI();
  const [isSent, setIsSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const send = useCallback(
    ({ comments, disposition }: { comments: string; disposition: string }) => {
      const flare = Math.floor(Date.now()).toString();

      setIsLoading(true);
      api
        .reportJob(id, disposition, comments, flare)
        .catch((err: Error) => {
          setHasError(true);
          console.error("Error submitting feedback for job: " + err);
        })
        .finally(() => {
          setIsLoading(false);
          setIsSent(true);
        });
    },
    [api, id]
  );

  return { isSent, isLoading, hasError, send };
};

export const useResubmitHook = (id: string) => {
  const api = useAPI();
  const [newJobID, setNewJobID] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const resubmit = useCallback(() => {
    setIsLoading(true);
    api
      .resubmitJob(id)
      .then(({ JobID }: any) => {
        setNewJobID(JobID);
      })
      .catch((err: Error) => {
        setHasError(true);
        console.error("Error resubmitting job: " + err);
        toast.error("Error resubmitting job: " + err.message, {
          autoClose: false,
        });
      })
      .finally(() => setIsLoading(false));
  }, [api, id]);

  return { newJobID, isLoading, hasError, resubmit };
};

export const useListUsersHook = () => {
  const api = useAPI();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [users, setUsers] = useState<UserList | undefined>();
  const [refreshedAt, setRefreshedAt] = useState<number | null>();

  const refresh = useCallback(
    (tenant?: string, page?: number) => {
      let cancelled = false;
      (async () => {
        try {
          const ks = await api.listUsers(tenant, page);
          if (cancelled) return;
          setIsLoading(false);
          setUsers(ks);
        } catch (_) {
          if (cancelled) return;
          setHasError(true);
        } finally {
          if (cancelled) return;
          setIsLoading(false);
          setRefreshedAt(Date.now());
        }
      })();

      return () => {
        cancelled = true;
      };
    },
    [api]
  );

  return { users, isLoading, hasError, refresh, refreshedAt };
};

export const useListTenantsHook = () => {
  const api = useAPI();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [tenants, setTenants] =
    useState<{ FullName: string; Name: string }[]>();

  const refresh = useCallback(() => {
    let cancelled = false;
    (async () => {
      try {
        const ks = await api.getTenants();
        if (cancelled) return;
        setIsLoading(false);
        setTenants(ks);
      } catch (_) {
        if (cancelled) return;
        setHasError(true);
      } finally {
        if (cancelled) return;
        setIsLoading(false);
      }
    })();

    return () => {
      cancelled = true;
    };
  }, [api]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return { tenants, isLoading, hasError };
};

export const useListAPIKeysHook = () => {
  const api = useAPI();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [keys, setKeys] = useState<APIKey[]>([]);

  const refresh = useCallback(() => {
    let cancelled = false;
    (async () => {
      try {
        const ks = await api.listAPIKeys();
        if (cancelled) return;
        setIsLoading(false);
        setKeys(ks);
      } catch (_) {
        if (cancelled) return;
        setHasError(true);
      } finally {
        if (cancelled) return;
        setIsLoading(false);
      }
    })();

    return () => {
      cancelled = true;
    };
  }, [api]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return { keys, isLoading, hasError, refresh };
};

export const useGetUserRolesListHook = () => {
  const api = useAPI();
  const [isLoading, setIsLoading] = useState<boolean[]>([]);
  const updateIsLoading = (idx: number, val: boolean) =>
    setIsLoading((isLoading) =>
      isLoading.map((element, i) => (i === idx ? val : element))
    );

  const [hasError, setHasError] = useState<boolean[]>([]);
  const updateHasError = (idx: number, val: boolean) =>
    setHasError((hasError) =>
      hasError.map((element, i) => (i === idx ? val : element))
    );

  const [roles, setRoles] = useState<string[]>([]);
  const updateRoles = (idx: number, val: string) =>
    setRoles((roles) => roles.map((element, i) => (i === idx ? val : element)));

  const getRoles = useCallback(
    (ids: string[]) => {
      setIsLoading(Array.from({ length: ids.length }, () => true));
      setHasError(Array.from({ length: ids.length }, () => false));
      setRoles(Array.from({ length: ids.length }, () => "Loading..."));

      for (let i = 0; i < ids.length; i++) {
        (async () => {
          try {
            const role = await api.getUserRole(ids[i]);
            updateIsLoading(i, false);
            updateRoles(i, role);
          } catch (_) {
            updateHasError(i, true);
            updateRoles(i, "Error");
          } finally {
            updateIsLoading(i, false);
          }
        })();
      }

      return () => {
        return roles;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [api]
  );

  return { getRoles, isLoading, hasError, roles };
};

export const useGetUserRoleHook = () => {
  const api = useAPI();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [role, setRole] = useState<string>("");

  const getRole = useCallback(
    (id: string) => {
      let cancelled = false;
      (async () => {
        try {
          if (cancelled) return;
          const role = await api.getUserRole(id);
          if (cancelled) return;
          setIsLoading(false);
          setRole(role);
        } catch (_) {
          if (cancelled) return;
          setHasError(true);
        } finally {
          if (cancelled) return;
          setIsLoading(false);
        }
      })();

      return () => {
        cancelled = true;
        return role;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [api]
  );

  return { getRole, isLoading, hasError, role };
};

export const useEditUserHook = (id: string) => {
  const api = useAPI();
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<
    | {
        code: number;
        statusText: string;
        role?: string;
      }
    | undefined
  >();

  const edit = useCallback(
    (payload: { Role: string }) => {
      let cancelled = false;
      (async () => {
        try {
          if (cancelled) return;
          setIsLoading(true);
          setResponse(undefined);
          const resp = await api.setUserRole(id, payload);
          if (cancelled) return;
          setResponse(resp);
        } catch (e: any) {
          if (cancelled) return;
          setResponse({ code: 500, statusText: e.toString() });
        } finally {
          setIsLoading(false);
          if (cancelled) return;
        }
      })();

      return () => {
        cancelled = true;
      };
    },
    [api, id]
  );

  return {
    response,
    isLoading: isLoading,
    edit,
  };
};

export const useDeleteUserHook = () => {
  const api = useAPI();
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<
    | {
        code: number;
        statusText: string;
      }
    | undefined
  >();
  const [updatedAt, setUpdatedAt] = useState<number | null>();

  const del = useCallback(
    (id: string) => {
      let cancelled = false;
      (async () => {
        try {
          if (cancelled) return;
          setIsLoading(true);
          setResponse(undefined);
          const resp = await api.deleteUser(id);
          if (cancelled) return;
          setResponse(resp);
        } catch (e: any) {
          if (cancelled) return;
          setResponse({ code: 500, statusText: e.toString() });
        } finally {
          if (cancelled) return;
          setIsLoading(false);
          setUpdatedAt(Date.now());
        }
      })();

      return () => {
        cancelled = true;
      };
    },
    [api]
  );

  return {
    response,
    isLoading,
    del,
    updatedAt,
  };
};

export const useCreateUserHook = () => {
  const api = useAPI();
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<
    | {
        code: number;
        statusText: string;
        result?: NewUserRecord;
      }
    | undefined
  >();

  const submit = useCallback(
    (payload: { TenantID: string; Email: string; Role: string }) => {
      let cancelled = false;
      (async () => {
        try {
          if (cancelled) return;
          setIsLoading(true);
          setResponse(undefined);
          const resp = await api.createUser(payload);
          if (cancelled) return;
          setResponse(resp);
        } catch (e: any) {
          if (cancelled) return;
          setResponse({ code: 500, statusText: e.toString() });
        } finally {
          if (cancelled) return;
          setIsLoading(false);
        }
      })();

      return () => {
        cancelled = true;
      };
    },
    [api]
  );

  return { response, isLoading, submit };
};

export const useInviteUserHook = () => {
  const api = useAPI();
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<
    | {
        code: number;
        statusText: string;
        result?: NewUserRecord;
      }
    | undefined
  >();

  const submit = useCallback(
    (userid: string) => {
      let cancelled = false;
      (async () => {
        try {
          if (cancelled) return;
          setIsLoading(true);
          setResponse(undefined);
          const resp = await api.inviteUser(userid);
          if (cancelled) return;
          setResponse(resp);
        } catch (e: any) {
          if (cancelled) return;
          setResponse({ code: 500, statusText: e.toString() });
        } finally {
          if (cancelled) return;
          setIsLoading(false);
        }
      })();

      return () => {
        cancelled = true;
      };
    },
    [api]
  );

  return { response, isLoading, submit };
};

export const useCreateAPIKeyHook = () => {
  const api = useAPI();
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<
    | {
        code: number;
        statusText: string;
        key?: APIKeyWithSecrets;
      }
    | undefined
  >();

  const submit = useCallback(
    (payload: { TenantID: string; Label: string; Permissions: string[] }) => {
      let cancelled = false;
      (async () => {
        try {
          if (cancelled) return;
          setIsLoading(false);
          setResponse(undefined);
          const resp = await api.createAPIKey(payload);
          if (cancelled) return;
          setResponse(resp);
        } catch (e: any) {
          if (cancelled) return;
          setResponse({ code: 500, statusText: e.toString() });
        } finally {
          if (cancelled) return;
          setIsLoading(false);
        }
      })();

      return () => {
        cancelled = true;
      };
    },
    [api]
  );

  return { response, isLoading, submit };
};

export const useDeleteAPIKeyHook = () => {
  const api = useAPI();
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<
    | {
        code: number;
        statusText: string;
      }
    | undefined
  >();
  const [updatedAt, setUpdatedAt] = useState<number | null>();

  const del = useCallback(
    (id: string) => {
      let cancelled = false;
      (async () => {
        try {
          if (cancelled) return;
          setIsLoading(false);
          setResponse(undefined);
          const resp = await api.deleteAPIKey(id);
          if (cancelled) return;
          setResponse(resp);
        } catch (e: any) {
          if (cancelled) return;
          setResponse({ code: 500, statusText: e.toString() });
        } finally {
          if (cancelled) return;
          setIsLoading(false);
          setUpdatedAt(Date.now());
        }
      })();

      return () => {
        cancelled = true;
      };
    },
    [api]
  );

  return {
    response,
    isLoading,
    del,
    updatedAt,
  };
};

export const useEditAPIKeyHook = (id: string) => {
  const api = useAPI();
  const list = useListAPIKeysHook();
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<
    | {
        code: number;
        statusText: string;
        key?: APIKey;
      }
    | undefined
  >();

  const edit = useCallback(
    (payload: { Label: string; Permissions: string[] }) => {
      let cancelled = false;
      (async () => {
        try {
          if (cancelled) return;
          setIsLoading(false);
          setResponse(undefined);
          const resp = await api.editAPIKey({ ...payload, ID: id });
          if (cancelled) return;
          setResponse(resp);
        } catch (e: any) {
          if (cancelled) return;
          setResponse({ code: 500, statusText: e.toString() });
        } finally {
          if (cancelled) return;
          setIsLoading(false);
        }
      })();

      return () => {
        cancelled = true;
      };
    },
    [api, id]
  );

  return {
    response,
    isLoading: isLoading || list.isLoading,
    edit,
    existingKey: list.isLoading
      ? undefined
      : list.keys.filter(({ ID }) => ID === id)[0],
  };
};

export const useDeleteHook = (id: string) => {
  const api = useAPI();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  const del = useCallback(() => {
    setIsLoading(true);
    api
      .deleteJob(id)
      .then(() => {
        setIsDeleted(true);
      })
      .catch((err: Error) => {
        setHasError(true);
        console.error("Error deleting job: " + err);
      })
      .finally(() => setIsLoading(true));
  }, [api, id]);

  return { isDeleted, isLoading, hasError, del };
};

export const usePresignedURLHook = (path: string) => {
  const api = useAPI();
  const [presignedURL, setPresignedURL] = useState("");

  useEffect(() => {
    let ignore = false;
    api.getArtifactURL(path).then((url: string) => {
      if (ignore) return;
      setPresignedURL(url);
    });

    return () => {
      ignore = true;
    };
  }, [path, api]);

  return { presignedURL };
};

export const useSearchV2Hook = (searchParams: SearchV2QueryParams) => {
  const api = useAPI();
  const [isLoading, setIsLoading] = useState(true);
  const [results, setResults] = useState<SearchV2Results | undefined>();

  useEffect(() => {
    let cancelled = false;
    (async () => {
      try {
        const r = await api.searchv2(searchParams);
        if (!cancelled) setResults(r);
      } catch (_) {
      } finally {
        if (!cancelled) setIsLoading(false);
      }
    })();

    return () => {
      cancelled = true;
    };
  }, [api, searchParams]);

  return { isLoading, results };
};

export interface SurferCheck {
  description: string;
  status: Status;
  helpText: string;
  fn: (i: any) => Promise<any>;
  error?: any;
}

type Status =
  | "initializing"
  | "initialized"
  | "waiting"
  | "checking"
  | "failed"
  | "passed"
  | "skipped";
/**
 * useSurferDiagnosticHook is a headless checker for basic connectivity tests the
 * Surfer Backend. While Surfer works for most of our customers most of the time,
 * occasionally, the user while not be able to connect to Surfer successfully.
 *
 * Most of the time, we've found that it's because of corporate proxies, VPNs,
 * and/or firewalls. The goal of this check is to fail gracefully and helpfully
 * so the customer can get things working as painlessly as possible.
 *
 * The overall steps, while somewhat redundant, are the following:
 *
 * 1. Basic Connectivity to TwinWave API Server (api.twinwave.io)
 * 2. Basic Connectivity to Interactive Web Analyzer Browser Backend (surfer.twinwave.io)
 * 3. Load Interactive Web Analyzer iFrame (surfer.twinwave.io)
 * 4. WebSocket Connectivity from Embedded iFrame to Interactive Web Analyzer Backend (surfer.twinwave.io)
 * 5. Actually connect to the full browser backend.
 *
 */
export const useSurferDiagnosticHook = (jobID: string) => {
  const api = useAPI() as { getSurferURL: () => ReturnType<typeof fetch> };
  const iframe = useRef<HTMLIFrameElement>();
  const [state, updateState] = useImmer<{
    status: Status;
    checks: SurferCheck[];
  }>({
    status: "initializing",
    checks: [],
  });

  useEffect(() => {
    if (state.status === "failed" && jobID) {
      SplunkRum.error("IWA session initialization failed", {
        steps: JSON.stringify(state.checks, ["status", "description"], "  "),
      });
    }
  }, [state.status, jobID, state.checks]);

  useEffect(() => {
    if (state.status !== "initializing") return;
    console.log("init hook ran");
    const step = (
      description: string,
      helpText: string,
      fn: (i: any) => Promise<any>
    ) => {
      updateState((draft) => {
        draft.checks.push({ description, status: "waiting", fn, helpText });
      });
    };

    step(
      "Ping to Attack Analyzer API Server (api.twinwave.io)",
      "The Attack Analyzer UI failed to contact the API server (api.twinwave.io). Please check your internet connection and try again from the home page.",
      async () => {
        const { url } = await api.getSurferURL().then((r) => r.json());
        if (!(url as string).startsWith("http")) {
          throw Error(
            `Expected Backend URL to start with http, but got ${url}`
          );
        }
        return url;
      }
    );

    step(
      "Ping to Interactive Web Analyzer Backend (surfer.twinwave.io)",
      "We couldn't reach the Interactive Web Analyzer hosted at surfer.twinwave.io. If you are using a proxy, VPN, firewall, or browser extension, please ensure surfer.twinwave.io is safelisted (including allowing WebSocket connections).",
      async (url: string) => {
        console.log(url);
        const diagnosticSessionURL = `${url}/diagnoses`;
        const { id } = await fetch(diagnosticSessionURL, {
          method: "POST",
        }).then((r) => r.json());
        return { id, diagnosticSessionURL };
      }
    );

    const iframeStep = (description: string, help: string, checkFor: string) =>
      step(
        description,
        help,
        async ({
          id,
          diagnosticSessionURL,
        }: {
          id: string;
          diagnosticSessionURL: string;
        }) => {
          let iframeInitRequired = false;
          if (!iframe.current) {
            iframeInitRequired = true;
            const e = document.createElement("iframe");
            iframe.current = e;
            e.style.display = "none";
            document.body.appendChild(e);
          }

          const waitForProperty = new Promise<void>(async (res, rej) => {
            let cancelled = false;
            setTimeout(() => {
              cancelled = true;
              rej("timeout exceeded");
            }, 15_000);

            for (;;) {
              if (cancelled) return;
              const {
                info: { [checkFor]: desiredProperty },
              } = await fetch(
                `${diagnosticSessionURL}/${encodeURIComponent(id)}`
              ).then((r) => r.json());
              if (desiredProperty) return res();
              await new Promise((s) => setTimeout(s, 500));
            }
          });

          if (iframeInitRequired)
            iframe.current.src = `${diagnosticSessionURL}/iframe?diagnostic_id=${encodeURIComponent(
              id
            )}`;

          await waitForProperty;
          return { id, diagnosticSessionURL };
        }
      );

    iframeStep(
      "Load Web Analyzer iframe",
      "We couldn't load the Interactive Web Analyzer iframe hosted at surfer.twinwave.io. If you are using a proxy, VPN, firewall, or browser extension, please ensure surfer.twinwave.io is safelisted (including allowing WebSocket connections).",
      "iframeLoaded"
    );
    iframeStep(
      "request",
      "The Interactive Web Analyzer iframe hosted at surfer.twinwave.io could not reach the socket server. If you are using a proxy, VPN, firewall, or browser extension, please ensure surfer.twinwave.io is safelisted (including allowing WebSocket connections).",
      "serverSocketRequest"
    );
    iframeStep(
      "connection",
      "The Interactive Web Analyzer iframe hosted at surfer.twinwave.io could not connect to the socket server. If you are using a proxy, VPN, firewall, or browser extension, please ensure surfer.twinwave.io is safelisted (including allowing WebSocket connections).",
      "connection"
    );
    iframeStep(
      "ping",
      "The Interactive Web Analyzer iframe hosted at surfer.twinwave.io could not successfully open a WebSocket connection. If you are using a proxy, VPN, firewall, or browser extension, please ensure surfer.twinwave.io is safelisted (including allowing WebSocket connections).",
      "ping"
    );

    updateState((draft) => {
      draft.status = "initialized";
    });
  }, [updateState, state.status, api]);

  useEffect(() => {
    if (state.status !== "initialized") return;
    console.log("check hook ran");
    updateState((draft) => {
      draft.status = "checking";
    });

    (async () => {
      let lastStatus: Status = "passed";
      let lastResult: any = null;
      for (let i = 0; i < state.checks.length; i++) {
        if (lastStatus !== "passed") {
          updateState((draft) => {
            draft.checks[i].status = "skipped";
          });
          continue;
        }
        updateState((draft) => {
          draft.checks[i].status = "checking";
        });
        try {
          lastResult = await state.checks[i].fn(lastResult);
          updateState((draft) => {
            draft.checks[i].status = "passed";
          });
        } catch (e) {
          console.error(e);
          lastStatus = "failed";
          updateState((draft) => {
            draft.checks[i].status = "failed";
            try {
              draft.checks[i].error = `${(e as any).name}\n${
                (e as any).stack
              }\n${e}`;
            } catch {
              draft.checks[i].error = `${e}`;
            }
          });
        }
      }
      updateState((draft) => {
        draft.status = lastStatus;
      });

      return () => {
        if (iframe.current) iframe.current.remove();
      };
    })();
  }, [state.status, state.checks, updateState]);

  return state;
};

export const useWatchForIWASessionURLHook = (id: string) => {
  const api = useAPI();
  const [sessionInfo, setSessionInfo] = useState("");
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    if (!id) return;
    let cancelled = false;
    (async () => {
      for (;;) {
        if (cancelled || isDone) return;
        const job = await api.getJob(id);
        const [info]: { StateText: string; State: string }[] = (job.Tasks || [])
          .filter(({ Engine }: any) => Engine === "interactive_web_analyzer")
          .map(({ StateText, State }: any) => ({
            StateText,
            State,
          }));

        if (info && !cancelled) {
          if (info.StateText && info.StateText !== sessionInfo)
            setSessionInfo(info.StateText);
          if (
            info.State === "done" ||
            info.State === "error" ||
            info.State === "timedout" ||
            info.StateText === "processing-session"
          ) {
            setIsDone(true);
            cancelled = true;
          }
        }

        await new Promise((s) =>
          setTimeout(s, info?.State === "inprogress" ? 5_000 : 1_000)
        );
      }
    })();

    return () => {
      cancelled = true;
    };
  }, [id, api, isDone, sessionInfo]);

  return { sessionInfo, isDone };
};

export const useWatchForCuckooSessionURLHook = (id: string) => {
  const api = useAPI();
  const [sessionInfo, setSessionInfo] = useState("");
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    if (!id) return;
    let cancelled = false;
    (async () => {
      for (;;) {
        if (cancelled || isDone) return;
        const job = await api.getJob(id);
        const [info]: { StateText: string; State: string }[] = (job.Tasks || [])
          .filter(({ Engine }: any) => Engine.startsWith("interactive_win"))
          .map(({ StateText, State }: any) => ({
            StateText,
            State,
          }));

        if (info && !cancelled) {
          // if (info.StateText && info.StateText !== sessionInfo)
          if (info.StateText) {
            try {
              let parsedStateText = JSON.parse(info.StateText);
              if (parsedStateText.VNCURL !== sessionInfo)
                setSessionInfo(parsedStateText.VNCURL);
            } catch {
              console.log("Error parsing interactive cuckoo session URL");
              setIsDone(true);
              cancelled = true;
            }
          }
          if (
            info.State === "done" ||
            info.State === "error" ||
            info.State === "timedout"
          ) {
            setIsDone(true);
            cancelled = true;
          }
        }

        await new Promise((s) =>
          setTimeout(s, info?.State === "inprogress" ? 5_000 : 1_000)
        );
      }
    })();

    return () => {
      cancelled = true;
    };
  }, [id, api, isDone, sessionInfo]);

  return { sessionInfo, isDone };
};

export const usePDFReportHook = () => {
  const api = useAPI();

  const download = useCallback(
    (jobid: string) => {
      return api.getPDFReport(jobid);
    },
    [api]
  );

  return { download };
};

// Email Submission Config (ADMIN) hooks
export const useAdminListEmailConfigsHook = () => {
  const api = useAPI();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [configs, setConfigs] = useState<EmailSubmissionConfig[]>([]);

  const refresh = useCallback(() => {
    let cancelled = false;
    (async () => {
      try {
        const cfgs = await api.listEmailConfigs(true);
        if (cancelled) return;
        setIsLoading(false);
        setConfigs(cfgs);
      } catch (_) {
        if (cancelled) return;
        setHasError(true);
      } finally {
        if (cancelled) return;
        setIsLoading(false);
      }
    })();

    return () => {
      cancelled = true;
    };
  }, [api]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return { configs, isLoading, hasError, refresh };
};

export const useAdminCreateEmailConfigHook = () => {
  const api = useAPI();
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<
    CreateEmailSubmissionResponse | undefined
  >();

  const submit = useCallback(
    (options: AdminCreateEmailSubmissionRequest) => {
      let cancelled = false;
      (async () => {
        try {
          if (!options.TenantID) {
            setResponse(undefined);
            toast.error("Please select a Tenant", {
              autoClose: 10000,
            });
            return;
          }
          if (cancelled) return;
          setIsLoading(false);
          setResponse(undefined);
          const resp = await api.createEmailConfig(options, true);
          if (cancelled) return;
          setResponse(resp);
        } catch (e: any) {
          if (cancelled) return;
          setResponse({ code: 500, statusText: e.toString() });
        } finally {
          if (cancelled) return;
          setIsLoading(false);
        }
      })();

      return () => {
        cancelled = true;
      };
    },
    [api]
  );

  return { response, isLoading, submit };
};

export const useAdminDeleteEmailConfigHook = () => {
  const api = useAPI();
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<BaseApiResponse | undefined>();
  const [updatedAt, setUpdatedAt] = useState<number | null>();

  const del = useCallback(
    (id: string) => {
      let cancelled = false;
      (async () => {
        try {
          if (cancelled) return;
          setIsLoading(false);
          setResponse(undefined);
          const resp = await api.deleteEmailConfig(id, true);
          if (cancelled) return;
          setResponse(resp);
        } catch (e: any) {
          if (cancelled) return;
          setResponse({ code: 500, statusText: e.toString() });
        } finally {
          if (cancelled) return;
          setIsLoading(false);
          setUpdatedAt(Date.now());
        }
      })();

      return () => {
        cancelled = true;
      };
    },
    [api]
  );

  return {
    response,
    isLoading,
    del,
    updatedAt,
  };
};

export const useAdminEditEmailConfigHook = (id: string) => {
  const api = useAPI();
  const list = useAdminListEmailConfigsHook();
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<BaseApiResponse | undefined>();

  const edit = useCallback(
    //(payload: { profile: string; priority: number }) => {
    (payload: EmailSubmissionConfig) => {
      let cancelled = false;
      (async () => {
        try {
          if (cancelled) return;
          setIsLoading(false);
          setResponse(undefined);
          const resp = await api.editEmailConfig({ ...payload, ID: id }, true);
          if (cancelled) return;
          setResponse(resp);
        } catch (e: any) {
          if (cancelled) return;
          setResponse({ code: 500, statusText: e.toString() });
        } finally {
          if (cancelled) return;
          setIsLoading(false);
        }
      })();

      return () => {
        cancelled = true;
      };
    },
    [api, id]
  );

  return {
    response,
    isLoading: isLoading || list.isLoading,
    edit,
    existingConfig: list.isLoading
      ? undefined
      : list.configs.filter(({ SubmitID }) => SubmitID === id)[0],
  };
};
