import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext } from "react";
import { toast } from "react-toastify";
import { APIContext } from "../../lib/MAPApi";

export const S3ArtifactDownload = ({
  path,
  children,
}: {
  path: string;
  children: any;
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { api } = useContext(APIContext);

  return (
    /* eslint-disable-next-line */
    <a
      className="button is-small is-link"
      onClick={(e) =>
        api
          .callAPI(getAccessTokenSilently, api.getArtifactByPath, path)
          .catch(() => toast.error("Error downloading artifact"))
      }
    >
      {children}
    </a>
  );
};
