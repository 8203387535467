import React, { useEffect, useState } from "react";

import { html_beautify, css_beautify, js_beautify } from "js-beautify";
import { copySource } from "src/components/utils/Copyable";

// react-syntax-highlighter setup
// Done this way to enable async and light mode
// This ONLY compiles in prism and the languages we enable, reducing bundle size
import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";

// Styles
import { default as darkStyle } from "react-syntax-highlighter/dist/esm/styles/prism/vsc-dark-plus";

// Prism Langs
import clike from "react-syntax-highlighter/dist/esm/languages/prism/clike";
import javascript from "react-syntax-highlighter/dist/esm/languages/prism/javascript";
import markup from "react-syntax-highlighter/dist/esm/languages/prism/markup";
import css from "react-syntax-highlighter/dist/esm/languages/prism/css";
import json from "react-syntax-highlighter/dist/esm/languages/prism/json";
import powershell from "react-syntax-highlighter/dist/esm/languages/prism/powershell";
import visualBasic from "react-syntax-highlighter/dist/esm/languages/prism/visual-basic";
import vbnet from "react-syntax-highlighter/dist/esm/languages/prism/vbnet";
import xmlDoc from "react-syntax-highlighter/dist/esm/languages/prism/xml-doc";

// Other rendering stuff
import virtualizedRenderer from "./SourceCodeStringRenderer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

SyntaxHighlighter.registerLanguage("clike", clike);
SyntaxHighlighter.registerLanguage("javascript", javascript);
SyntaxHighlighter.registerLanguage("markup", markup);
SyntaxHighlighter.registerLanguage("html", markup); // Alias HTML
SyntaxHighlighter.registerLanguage("css", css);
SyntaxHighlighter.registerLanguage("json", json);
SyntaxHighlighter.registerLanguage("powershell", powershell);
SyntaxHighlighter.registerLanguage("visual-basic", visualBasic);
SyntaxHighlighter.registerLanguage("vbnet", vbnet);
SyntaxHighlighter.registerLanguage("xml-doc", xmlDoc);

// php, bash, powershell

const languageMappings = {
  html: {
    mimeTypes: ["text/html"],
    exts: ["html"],
    magicPat: [],
  },
  clike: {
    mimeTypes: ["x-c", "x-c++", "x-objective-c"],
    exts: ["c", "cpp", "cxx", "h", "hpp", "hxx"],
    magicPat: [/C(?:[+][+])? (?:program|source) text/i],
  },
  javascript: {
    mimeTypes: ["text/javascript"],
    exts: ["js", "ts"],
    magicPat: [],
  },
  markup: {
    mimeTypes: ["text/html", "application/xml", "text/xml", "image/svg"],
    exts: ["html", "xml", "svg"],
    magicPat: [/^(?:X?HTML|SVG|XML)/i],
  },
  css: {
    mimeTypes: ["text/css"],
    exts: ["css"],
    magicPat: [],
  },
  json: {
    mimeTypes: ["application/json"],
    exts: ["json"],
    magicPat: [],
  },
  powershell: {
    mimeTypes: [],
    exts: ["ps1"],
    magicPat: [],
  },
  "visual-basic": {
    mimeTypes: [],
    exts: ["vb"],
    magicPat: [],
  },
  vbnet: {
    mimeTypes: [],
    exts: [""],
    magicPat: [],
  },
  "xml-doc": {
    // TODO: These can be compressed
    // starts with
    mimeTypes: [], //"application/vnd.openxmlformats",
    exts: [], //"docx", "pptx", "xlsx"]
    magicPat: [],
  },
};

const supportedLanguages = new Set(Object.keys(languageMappings));

// Static mime mappings
function generateMimeMapping() {
  let res = new Map<string, string>();
  for (const [lang, v] of Object.entries(languageMappings)) {
    for (const x of v.mimeTypes) {
      res.set(x, lang);
    }
  }
  return res;
}
function generateExtMapping() {
  let res = new Map<string, string>();
  for (const [lang, v] of Object.entries(languageMappings)) {
    for (const x of v.exts) {
      res.set(x, lang);
    }
  }
  return res;
}
function generateMagicStringMapping() {
  let res = new Map<RegExp, string>();
  for (const [lang, v] of Object.entries(languageMappings)) {
    for (const x of v.magicPat) {
      res.set(x, lang);
    }
  }
  return res;
}

// mimetype -> lang
const mimeTypeToSupportedLanguage = generateMimeMapping();

// ext -> lang
const extensionToLanguage = generateExtMapping();

// re -> lang for magic strings
const magicPatternToLanguage = generateMagicStringMapping();

// js-beautify setup
const jsBeautifyConfig = {
  indent_size: 2,
  break_chained_methods: true,
  // This prevents super long lines from making browsers unhappy
  // Large lines can really bog down viewing
  wrap_line_length: 128, // Default: 0 (none)
};

/**
 * Try to infer highlighting language based on mimetype.
 *
 * Unsupported languages will return undefined.
 * @param mimeType
 * @returns Highlighting language (if supported and inferred) or undefined
 */
export const guessLanguageFromMimeType = (
  mimeType: string
): string | undefined => {
  if (mimeType === undefined || mimeType.length === 0) {
    return undefined;
  }

  mimeType = mimeType.toLowerCase();

  // Check static mappings
  let language: string | undefined;
  if (mimeTypeToSupportedLanguage.has(mimeType)) {
    language = mimeTypeToSupportedLanguage.get(mimeType);
  } else {
    // Check trickier ones
    if (mimeType.startsWith("application/vnd.openxmlformats")) {
      language = mimeTypeToSupportedLanguage.get(
        "application/vnd.openxmlformats"
      );
    } else if (mimeType.endsWith("+xml")) {
      return mimeTypeToSupportedLanguage.get("application/xml");
    }
  }

  return language;
};

export const guessLanguageFromFilename = (
  filename: string
): string | undefined => {
  if (filename === undefined || filename.length === 0) {
    return undefined;
  }
  const dotIndex = filename.lastIndexOf(".");
  if (dotIndex < 0) {
    // Try full filename in case its just 'js' or 'css'
    return extensionToLanguage.get(filename);
  }

  const extension = filename.substring(dotIndex + 1).toLowerCase();
  if (extension.length === 0) {
    return undefined;
  }

  return extensionToLanguage.get(extension);
};

export const guessLanguageFromMagicString = (
  magicString: string
): string | undefined => {
  if (magicString === undefined || magicString.length === 0) {
    return undefined;
  }

  for (const [p, lang] of magicPatternToLanguage.entries()) {
    if (p.test(magicString)) {
      return lang;
    }
  }

  return undefined;
};

/**
 * Attempt to guess highlighting language based on filename or mimetype (whichever is available).
 * @param [filename] filename (if any)
 * @param [mimeType] mime type (if any)
 * @param [magicString] file-magic string (if any)
 * @returns
 */
export const guessLanguage = (
  filename?: string,
  mimeType?: string,
  magicString?: string
): string | undefined => {
  let language: string | undefined;

  if (language === undefined && mimeType !== undefined) {
    language = guessLanguageFromMimeType(mimeType);
  }
  if (filename !== undefined) {
    language = guessLanguageFromFilename(filename);
  }
  if (language === undefined && magicString !== undefined) {
    language = guessLanguageFromMagicString(magicString);
  }

  // Last ditch for URL with query param like filenames such as: blah/foo?css
  if (language === undefined && filename !== undefined) {
    const quotepos = filename.indexOf("?");
    if (quotepos > 0) {
      const prefix = filename.substring(0, quotepos);
      if (prefix && prefix.length > 0) {
        language = guessLanguageFromFilename(prefix);
      }
    }
  }

  return language;
};

enum LoadingStage {
  IDLE,
  WAITING,
  DONE,
}

/**
 * Display syntax highlighted and formatted (if formatter available) code
 * @param value - Source code
 * @param language - Language to syntax highlight code as
 * @param [pretty] - If true, initially display code in 'pretty' mode
 * @param [expanded] - If true, expand source view
 * @param [disableExpansionBox] - If true, disable the show more/less UI
 * @param [toolbarEnabled] - Enable toolbar with pretty/unpretty button and copy button
 * @param [title] - Title to display (when toolbar active)
 * @param [reformat] - If true, reformat source code during pretty mode (default: Yes, when pretty enabled)
 * @returns Rendered source code component
 *
 * This abuses effects to make the component somewhat stateful.  This lets us
 * augment the UX with 'waiting/loading' notification.  When the component loads
 * it will:
 *   1) Decide if a loading notification is needed
 *   2) If no, display source as usual
 *   3) If yes, transition to a 'loading' state.
 *      In this state, it ONLY changes the DOM to show the loading banner.
 *      (This lets us prevent jarring UX where the content disappears in the toolbar mode)
 *      Upon completion of rendering the 'loading' state, we use `useEffect` to
 *      trigger the 'is pretty === true' state and prettify the source.
 */
export const SourceCodeString = ({
  value,
  language = "text",
  pretty = false,
  expanded = false,
  disableExpansionBox = false,
  toolbarEnabled = false,
  title,
  reformat = true,
  wrapLongLines = false,
}: {
  value: string;
  language: string;
  pretty?: boolean;
  expanded?: boolean;
  disableExpansionBox?: boolean;
  toolbarEnabled?: boolean;
  title?: string | JSX.Element;
  reformat?: boolean;
  wrapLongLines?: boolean;
}) => {
  const prettyMayBeSlow = value.length > 50000;
  const [isPretty, setPretty] = useState(pretty);
  const [loadingStage, setLoadingStage] = useState(
    pretty && prettyMayBeSlow ? LoadingStage.WAITING : LoadingStage.IDLE
  );

  useEffect(() => {
    if (loadingStage === LoadingStage.WAITING) {
      setLoadingStage(LoadingStage.DONE);
      setPretty(true);
    }
  }, [loadingStage]);

  let str = value.trim(); // remove leading/trailing space from original input
  str = str.replace(/(\s{5})\s+/g, (x, m1) => m1); // convert > 5 char runs of whitespace into just 5

  if (reformat && isPretty) {
    if (language === "html" || language === "markup") {
      str = html_beautify(str, jsBeautifyConfig);
    } else if (language === "css") {
      str = css_beautify(str, jsBeautifyConfig);
    } else if (language === "javascript" || language === "json") {
      str = js_beautify(str, jsBeautifyConfig);
    }
  }

  let content =
    str.length < 1000 || disableExpansionBox ? (
      <SourceString
        value={loadingStage === LoadingStage.WAITING ? "Formatting..." : str}
        language={language}
        highlight={isPretty}
        wrapLongLines={wrapLongLines}
      />
    ) : (
      <ExpandableSourceString
        value={str}
        initialExpanded={expanded}
        language={language}
        highlight={isPretty}
        wrapLongLines={wrapLongLines}
      />
    );

  if (toolbarEnabled) {
    return (
      <div>
        <div className="has-padding-bottom-10">
          <div>
            <nav className="level">
              <div className="level-left" style={{ flexGrow: 1, width: "50%" }}>
                <div className="level-item" style={{ width: "100%" }}>
                  <div style={{ width: "100%" }}>{title}</div>
                </div>
                <div className="level-item"></div>
              </div>
              <div className="level-right">
                <p className="level-item">
                  <button
                    className={"button toggle-button is-not-selected"}
                    onClick={(e) => {
                      e.preventDefault();
                      if (isPretty) {
                        copySource(str); // copy formatted value
                      } else {
                        copySource(value); // copy raw input value
                      }
                    }}
                    title={
                      isPretty
                        ? "Copy formatted source to clipboard"
                        : "Copy source to clipboard"
                    }
                  >
                    <FontAwesomeIcon icon={["far", "copy"]} />
                  </button>
                </p>
                {language !== "text" && (
                  <p className="level-item">
                    <button
                      className={`button toggle-button ${
                        isPretty ? "is-selected" : "is-not-selected"
                      }`}
                      onClick={(e) => {
                        if (!isPretty) {
                          setLoadingStage(LoadingStage.WAITING);
                        } else {
                          setPretty(!isPretty);
                        }
                        e.currentTarget.blur();
                      }}
                      title={
                        isPretty ? "Show Raw Source" : "Show Formatted Source"
                      }
                    >
                      <FontAwesomeIcon
                        icon={["far", "sparkles"]}
                        beat={loadingStage === LoadingStage.WAITING}
                      />
                    </button>
                  </p>
                )}
              </div>
            </nav>
          </div>
        </div>
        {content}
      </div>
    );
  } else {
    return content;
  }
};

export const PlainSourceString = ({
  value,
  language = "text",
  highlight = false,
  wrapLongLines = false,
  showLineNumbers = true,
}: {
  value: string;
  language?: string;
  highlight?: boolean;
  wrapLongLines?: boolean;
  showLineNumbers?: boolean;
}) => {
  return (
    <div className="textviewer">
      <SourceString
        value={value}
        language={language}
        highlight={highlight}
        wrapLongLines={wrapLongLines}
        showLineNumbers={showLineNumbers}
      />
    </div>
  );
};

const SourceString = ({
  value,
  language = "text",
  highlight = false,
  wrapLongLines = false,
  additionalContainerClasses = ["forensics-string"],
  showLineNumbers = false,
}: {
  value: string;
  language?: string;
  highlight?: boolean;
  wrapLongLines?: boolean;
  additionalContainerClasses?: string[];
  showLineNumbers?: boolean;
}) => {
  let cssClass;

  if (language !== undefined && supportedLanguages.has(language)) {
    cssClass = language && "language-" + language;
  }

  let classNames = ["source-code"];
  if (
    additionalContainerClasses !== undefined &&
    additionalContainerClasses.length > 0
  ) {
    classNames.push(...additionalContainerClasses);
  }

  const classNamesString = classNames.join(" ");

  if (highlight) {
    return (
      <div className={classNamesString}>
        <PrismCode
          source={value}
          language={language}
          wrapLongLines={wrapLongLines}
          showLineNumbers={showLineNumbers}
        />
      </div>
    );
  }

  return (
    <div className={classNamesString}>
      <pre className={classNamesString}>
        <code className={cssClass}>{value}</code>
      </pre>
    </div>
  );
};

const ExpandableSourceString = ({
  value,
  initialExpanded,
  nChars = 500,
  language = "text",
  highlight = false,
  wrapLongLines = false,
}: {
  value: string;
  initialExpanded: boolean;
  nChars?: number;
  language?: string;
  highlight?: boolean;
  wrapLongLines?: boolean;
}) => {
  const [isExpanded, setExpanded] = useState(initialExpanded);

  const sourceString = isExpanded ? value : value.substring(0, nChars);
  const displayComponent = highlight ? (
    <>
      <PrismCode
        source={sourceString}
        language={language}
        wrapLongLines={wrapLongLines}
      />
    </>
  ) : (
    <pre className="source-code forensics-string">
      <code className={language ? `language-${language}` : undefined}>
        {sourceString}
      </code>
    </pre>
  );

  if (isExpanded) {
    return (
      <>
        <div className="source-code forensics-string">
          {displayComponent}
          <button
            className="button is-small is-text has-text-info"
            onClick={(e) => {
              setExpanded(!isExpanded);
              e.currentTarget.blur();
            }}
          >
            Show Less
          </button>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="source-code forensics-string">
          {displayComponent}
          <button
            className="button is-small is-text has-text-info"
            onClick={(e) => {
              setExpanded(!isExpanded);
              e.currentTarget.blur();
            }}
          >
            Show More
          </button>
        </div>
      </>
    );
  }
};

const PrismCode = ({
  source,
  language,
  onCompleteCallback,
  progressiveRenderThreshold = 200000,
  wrapLongLines = false,
  showLineNumbers = false,
}: {
  source: string;
  language: any;
  onCompleteCallback?: (fn: boolean) => any;
  progressiveRenderThreshold?: number;
  wrapLongLines?: boolean;
  showLineNumbers?: boolean;
}) => {
  const style = darkStyle;

  if (progressiveRenderThreshold === undefined) {
    progressiveRenderThreshold = 100000;
  }
  const useAlternateRenderer = source.length > progressiveRenderThreshold;

  useEffect(() => {
    if (onCompleteCallback !== undefined) {
      onCompleteCallback(useAlternateRenderer);
    }
  });

  if (language === "html") {
    // Prism calls html 'markup'
    language = "markup";
  }

  // Custom formatting to match TW CSS
  // Don't override TW style for PRE background and font
  let customStyle: any = {
    backgroundColor: "inherit",
    fontFamily: "inherit",
    fontSize: "inherit",
    // Override TW padding so its not double padded
    padding: 0,
    margin: 0,
  };
  if (useAlternateRenderer) {
    customStyle.height = "100%";
    customStyle.overflow = "inherit"; // prevent double scroll bar sets
  }

  // Merge default code tag props from style with ours.
  // Workaround due to lack of API ability to merge default style on code tag
  const codeTagProps = {
    className: `language-${language}`,
    style: {
      ...style['code[class*="language-"]'],
      ...style[`code[class*="language-${language}"]`],
      // Overrides
      ...{ fontFamily: "inherit", fontSize: "inherit" },
    },
  };

  return (
    <SyntaxHighlighter
      style={style}
      language={language}
      customStyle={customStyle}
      renderer={useAlternateRenderer ? virtualizedRenderer() : undefined}
      codeTagProps={codeTagProps}
      wrapLongLines={wrapLongLines}
      showLineNumbers={showLineNumbers}
    >
      {source}
    </SyntaxHighlighter>
  );
};
