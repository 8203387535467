import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { DetectionBox } from "../forensics/Detections";
import { ForensicsScreenshots } from "../forensics/ForensicsScreenshots";
import { ResourceSummary } from "../ResourceSummary";
import { S3ArtifactDownload } from "../utils/S3ArtifactDownload";
import { LoadingMessage } from "./LoadingMessage";
import { TaskProperties } from "./TaskTypes";

const severityTranslation = (sev: string) => {
  switch (sev.toLowerCase()) {
    case "majr":
      return "Major";
    case "minr":
      return "Minor";
    case "crit":
      return "Critical";
    default:
      return sev;
  }
};

const maliciousTranslation = (mal: string) => {
  switch (mal.toLowerCase()) {
    case "yes":
      return "Yes";
    case "no":
      return "No";
    default:
      return mal;
  }
};

export const FireEyeResults = ({
  job,
  task,
  forensics,
  resource,
}: TaskProperties) => {
  const details = (task.Results && task.Results.Details) || {};

  // Look for PCAP and default to old location on details
  let pcap = details.PCAP;
  if (forensics && typeof forensics === "object") {
    const pcaps = (forensics.SavedArtifacts || []).filter(
      (s) => s.Type === "pcap"
    );
    if (pcaps.length > 0) {
      pcap = pcaps[0].ArtifactPath;
    }
  }

  return (
    <section>
      <ResourceSummary job={job} task={task} resource={resource} />

      <div className="columns">
        <div className="column">
          <table className="table is-narrow is-completely-borderless">
            <tbody>
              {details.Malicious && (
                <tr>
                  <th>Malicious</th>
                  <td>
                    {maliciousTranslation(details.Malicious)}
                    {details.Severity && (
                      <> ({severityTranslation(details.Severity)})</>
                    )}
                  </td>
                </tr>
              )}

              {details.OSInfo && (
                <tr>
                  <th>OS</th>
                  <td>
                    {details.OSInfo.Name} ({details.OSInfo.Arch}, Software
                    Package {details.OSInfo.SoftwarePackage}) [
                    {details.OSInfo.Application}]
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="column is-narrow">
          {pcap && (
            <S3ArtifactDownload path={pcap}>
              <span className="icon is-small">
                <FontAwesomeIcon icon="download" />
              </span>
              <span>Download PCAP</span>
            </S3ArtifactDownload>
          )}
        </div>
      </div>

      {forensics === "loading" ? (
        <LoadingMessage />
      ) : (
        <div>
          {(forensics?.Detections?.length ?? 0) > 0 && (
            <DetectionBox detections={forensics?.Detections} />
          )}
          <ForensicsScreenshots forensics={forensics} />
        </div>
      )}
    </section>
  );
};
