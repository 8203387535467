import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  CSSProperties,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { APIContext } from "src/lib/MAPApi";
import { FixedSizeList as List } from "react-window";
import { Tab, Tabs } from "./utils/Tabs";
import { LoadingWrap } from "./tasks/LoadingMessage";
import { ThreatName } from "./utils/ThreatName";
import { SourceCodeString, guessLanguage } from "./forensics/SourceCodeString";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const bufferToHex = (buffer: ArrayBuffer) => {
  return [...new Uint8Array(buffer)].map((b) =>
    b.toString(0x10).padStart(2, "0").toUpperCase()
  );
};

const NON_PRINTABLE_ASCII_DEFAULT = ".";

const MAX_RAW_ASCII_CHARS = 100_000;

const _hexToASCII = (
  hex: string,
  replaceWith = NON_PRINTABLE_ASCII_DEFAULT,
  allowWhiteSpaceChars: boolean = false
) => {
  let str = NON_PRINTABLE_ASCII_DEFAULT;
  for (let i = 0; i < hex.length && hex.substr(i, 2) !== "00"; i += 2) {
    str = String.fromCharCode(parseInt(hex.substr(i, 2), 0x10));
  }

  if (allowWhiteSpaceChars && /^\s+$/.test(str)) {
    return str;
  }

  return isPrintableASCII(str) ? str : replaceWith;
};

const hexToASCII = (hex: string) => _hexToASCII(hex);
const hexToASCIIWithWhiteSpacePreserved = (hex: string) =>
  _hexToASCII(hex, "?", true);

const isPrintableASCII = (s: string) => /^[\x20-\x7F]*$/.test(s);

const hexToColorClass = (ch: string) => {
  if (ch === undefined || ch.length > 2) {
    return undefined;
  }

  let className = undefined;
  let code = parseInt(ch, 0x10); // These are all hex values >= 00 && <= 0xff
  if (code === 0x00) {
    // NULL
    className = "hex-null";
  } else if (code >= 0x21 && code <= 0x7e) {
    // Printable ASCII
    className = "hex-printable";
  } else if (code === 0x20 || code === 0x09 || (code >= 0x0a && code <= 0x0d)) {
    // ASCII Whitespace
    className = "hex-ws";
  } else if (code <= 0x7f) {
    // other ascii
    className = "hex-ascii";
  } else {
    // Non ascii
    className = "hex-nonascii";
  }

  return className;
};

const Modal = ({
  isActive,
  close,
  children,
}: {
  isActive: boolean;
  close: () => void;
  children: React.ReactNode;
}) => {
  if (isActive) {
    return (
      <div className="modal is-active">
        <div className="modal-background" onClick={close}></div>
        <div className="modal-content" style={{ overflowY: "hidden" }}>
          {children}
        </div>
      </div>
    );
  }

  return null;
};

const Card = ({
  filename,
  children,
  close,
}: {
  filename: string;
  children: React.ReactNode;
  close: () => void;
}) => {
  return (
    <div className="card">
      <header className="card-header">
        <p className="card-header-title is-centered">
          <span className="icon">
            <FontAwesomeIcon icon="code" />
          </span>
          <span className="nowrap">&nbsp;File Viewer&nbsp;</span>
        </p>
        <span onClick={close} className="card-header-icon" aria-label="close">
          <FontAwesomeIcon icon="times" />
        </span>
      </header>
      <div className="card-content">
        <div className="mb-5">
          <span className="has-text-weight-bold">Filename: </span>
          <ThreatName name={filename} />
        </div>
        {children}
      </div>
    </div>
  );
};

export const BYTE_COLUMNS = 0x10;

const reducer = (
  state: {
    startByte: number;
    endByte: number;
    dragging: boolean;
    activeByte: number;
  },
  action: "mouseup" | { byte: number; mouse?: "down" | "over" }
) => {
  const nextState = { ...state };
  if (action === "mouseup") {
    nextState.dragging = false;
  } else {
    nextState.activeByte = action.byte;
    switch (action.mouse) {
      case "down":
        nextState.dragging = true;
        // Reset selection
        nextState.startByte = action.byte;
        nextState.endByte = action.byte;
        break;
      case "over":
        if (state.dragging) {
          if (action.byte <= state.startByte) {
            nextState.startByte = action.byte;
          } else {
            nextState.endByte = action.byte;
          }
        }
        break;
    }
  }

  return nextState;
};

/**
 * HexViewer takes an array of hex string values treating each
 * array entry as a zero-padded hex byte string (e.g. `0x00`)
 * @param data Array of zero padded hex strings
 * @returns JSX Component
 */
export const HexViewer = ({ data }: { data: string[] }) => {
  const [state, dispatch] = useReducer(reducer, {
    startByte: -1,
    endByte: -1,
    activeByte: -1,
    dragging: false,
  });

  useEffect(() => {
    const listener = () => {
      dispatch("mouseup");
    };

    document.addEventListener("mouseup", listener);

    return () => {
      document.removeEventListener("mouseup", listener);
    };
  }, []);

  const rowCount = Math.ceil(data.length / BYTE_COLUMNS);
  const maxLenRowIndex = (rowCount * 0x10).toString(0x10).length;

  const Row = ({ index, style }: { index: number; style: CSSProperties }) => {
    const startByte = index * BYTE_COLUMNS;
    const endByte = startByte + BYTE_COLUMNS;

    const rowData = data.slice(startByte, endByte);
    const isActiveRow =
      startByte <= state.activeByte && state.activeByte < endByte;
    const hex = rowData; //bufferToHex(rowData);
    // Pad rows less than 16 bytes
    for (let i = rowData.length; i < 16; i++) {
      rowData.push("--");
    }

    const ascii = hex.map(hexToASCII);
    const hexRowIndex = (index * BYTE_COLUMNS)
      .toString(0x10)
      .padStart(maxLenRowIndex, "0")
      .toUpperCase();

    const hs = hex.map((h, i) => {
      let m = <>{h}</>;
      if (
        (state.startByte <= startByte + i && startByte + i <= state.endByte) ||
        state.activeByte === startByte + i
      ) {
        m = <mark>{h}</mark>;
      }

      let colorClass = hexToColorClass(h);

      return (
        <span
          onMouseDown={() => dispatch({ mouse: "down", byte: startByte + i })}
          onMouseEnter={() => dispatch({ mouse: "over", byte: startByte + i })}
          key={`c::${index}::${i}`}
          className={colorClass}
        >
          {m}
          {i % 4 === 3 && i !== hex.length - 1 ? "   " : " "}
        </span>
      );
    });

    // Issue: hex2ascii filters out some chars so we can't color them using the raw value in the ASCII array.
    // Not sure this fallback is necessary
    const asciiColorizer =
      hex.length === ascii.length
        ? (i: number) => hexToColorClass(hex[i])
        : (i: number) => undefined; // This means someone broke the mapping function and its no longer 1:1

    const as = ascii.map((a, i) => {
      let m = <>{a}</>;
      if (
        (state.startByte <= startByte + i && startByte + i <= state.endByte) ||
        state.activeByte === startByte + i
      ) {
        m = <mark>{a}</mark>;
      }

      let colorClass = asciiColorizer(i);

      return (
        <span
          onMouseDown={() => dispatch({ mouse: "down", byte: startByte + i })}
          onMouseEnter={() => dispatch({ mouse: "over", byte: startByte + i })}
          key={`d::${index}::${i}`}
          className={colorClass}
        >
          {m}
        </span>
      );
    });

    let rowIndexElem = <>{hexRowIndex}</>;
    if (isActiveRow) {
      rowIndexElem = <mark>{rowIndexElem}</mark>;
    }

    return (
      <div
        style={{ ...style, fontFamily: "monospace" }}
        className="disable-selection"
      >
        <pre style={{ padding: 0 }}>
          <span className="hex-header">{rowIndexElem}</span>: {hs}| {as}
        </pre>
      </div>
    );
  };

  const HeaderRow = () => {
    const hex = Array(16)
      .fill(" ")
      .map((_, i) => i.toString(0x10).padStart(2, "0").toUpperCase());
    const hs = hex.map((h, i) => {
      let m = <>{h}</>;
      if (state.activeByte % BYTE_COLUMNS === i) {
        m = <mark>{h}</mark>;
      }

      return (
        <span key={`a::${i}::${i}`} className="hex-header">
          {m}
          {i % 4 === 3 && i !== hex.length - 1 ? "   " : " "}
        </span>
      );
    });

    const ascii = Array(16)
      .fill(" ")
      .map((_, i) => i.toString(0x10).toUpperCase());
    const as = ascii.map((a, i) => {
      let m = <>{a}</>;
      if (state.activeByte % BYTE_COLUMNS === i) {
        m = <mark>{m}</mark>;
      }

      return (
        <span key={`b::${i}::${i}`} className="hex-header">
          {m}
        </span>
      );
    });

    return (
      <div
        style={{
          fontFamily: "monospace",
          marginBottom: 4,
          width: 600,
          fontWeight: 800,
        }}
        className="disable-selection"
      >
        <pre style={{ padding: 0 }}>
          {Array(maxLenRowIndex).fill(" ").join("")}&nbsp; {hs}| {as}
        </pre>
      </div>
    );
  };

  const copyHex = () => {
    navigator.clipboard.writeText(
      // bufferToHex(data.slice(state.startByte, state.endByte + 1)).join(" ")
      data.slice(state.startByte, state.endByte + 1).join(" ")
    );
  };

  const copyASCII = () => {
    navigator.clipboard.writeText(
      data
        .slice(state.startByte, state.endByte + 1)
        .map(hexToASCII)
        .join("")
    );
  };

  return (
    <>
      <div className="columns">
        <div className="column hexviewer has-padding-0">
          <pre style={{ padding: 0 }}>
            Offset:{" "}
            {state.activeByte === -1
              ? `- [-]`
              : `${state.activeByte} [${state.activeByte.toString(0x10)}]`}
          </pre>
        </div>
        <div className="column has-text-right has-padding-0">
          <div className="has-margin-bottom-5">
            {state.startByte > -1 && (
              <>
                <button
                  className="button is-text has-text-link is-small has-margin-right-10"
                  onClick={copyHex}
                >
                  Copy Selected Hex
                </button>
                <button
                  className="button is-text has-text-link is-small"
                  onClick={copyASCII}
                >
                  Copy Selected ASCII
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="hexviewer content">
        <HeaderRow />
        <List height={750} itemCount={rowCount} itemSize={23} width={600}>
          {Row}
        </List>
      </div>
    </>
  );
};

export interface ResourceReference {
  jobid: string;
  sha256: string;
}

export const FileViewer = (props: {
  resource: ResourceReference | string;
  filename: string;
  isVisible: boolean;
  onClose: () => void;
  mimetype?: string;
  magicString?: string; // file-magic string (vs mimetype)
}) => (
  <Modal isActive={props.isVisible} close={props.onClose}>
    <FV {...props}></FV>
  </Modal>
);

export const DataViewerInternal = ({
  data,
  onClose = () => {},
  filename,
  mimeType,
  magicString,
}: {
  data: Uint8Array;
  onClose?: () => void;
  filename?: string;
  mimeType?: string;
  magicString?: string;
}): JSX.Element => {
  let hexBytes = [];
  for (let i = 0; i < data.length; i++) {
    hexBytes.push(("0" + data[i].toString(16).toUpperCase()).slice(-2));
  }

  useEffect(() => {
    const listener = (evt: KeyboardEvent) => {
      if (evt.key.toUpperCase() === "ESCAPE") {
        evt.preventDefault();
        onClose();
      }
    };

    document.addEventListener("keydown", listener);

    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [onClose]);

  const tabs = [
    <Tab label="Hex" key="1">
      <HexViewer data={hexBytes || []} />
    </Tab>,
  ];

  const insertAt =
    mimeType && mimeType.toLowerCase().startsWith("text") ? 0 : 1;

  const asciiData = hexBytes
    ?.slice(0, MAX_RAW_ASCII_CHARS)
    .map(hexToASCIIWithWhiteSpacePreserved)
    .join("");

  tabs.splice(
    insertAt,
    0,
    <Tab label="ASCII" key="2">
      <div className="columns has-margin-bottom-5">
        <div className="column hexviewer" style={{ maxWidth: "100%" }}>
          <pre
            style={{
              height: "75vh",
              overflow: "auto",
              padding: "0.1rem",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
            }}
          >
            {asciiData}
          </pre>
          {hexBytes?.length && hexBytes.length > MAX_RAW_ASCII_CHARS ? (
            <p className="has-text-centered has-text-grey">
              (truncated to the first {MAX_RAW_ASCII_CHARS} characters of{" "}
              {data.length})
            </p>
          ) : null}
        </div>
      </div>
    </Tab>
  );

  if (asciiData !== undefined) {
    let language = guessLanguage(filename, mimeType, magicString);
    if (language !== undefined) {
      tabs.splice(
        insertAt + 1,
        0,
        <Tab label="Pretty" key="3">
          <div
            className="columns hexviewer has-margin-bottom-5"
            style={{
              maxWidth: "100%",
              height: "75vh",
              overflow: "auto",
              padding: "0.75rem",
            }}
          >
            <SourceCodeString
              value={asciiData}
              language={language}
              pretty={true}
              disableExpansionBox={true}
              wrapLongLines={true}
            />
          </div>
        </Tab>
      );
    }
  }

  return <Tabs>{tabs}</Tabs>;
};

/**
 * Displays a hex/ascii editor for the supplied binary datadata
 *
 * Functions similar to FileViewer but uses the raw data passed to it
 * vs a fetch.
 * @param data Uint8Array where each item is a byte of the underlying data.
 * @returns
 */
export const DataViewer = ({
  data,
  isVisible,
  onClose,
  title = "Data Viewer",
  filename,
  mimeType,
  magicString,
}: {
  data: Uint8Array;
  isVisible: boolean;
  onClose: () => void;
  title?: string;
  filename?: string;
  mimeType?: string;
  magicString?: string; // file-magic string (vs mimetype)
}) => (
  <Modal isActive={isVisible} close={onClose}>
    <div className="card">
      <header className="card-header">
        <p className="card-header-title is-centered">
          <span className="icon">
            <FontAwesomeIcon icon="code" />
          </span>
          <span className="nowrap">&nbsp;{title}&nbsp;</span>
        </p>
        <span onClick={onClose} className="card-header-icon" aria-label="close">
          <FontAwesomeIcon icon="times" />
        </span>
      </header>
      <div className="card-content">
        <DataViewerInternal
          data={data}
          onClose={onClose}
          filename={filename}
          mimeType={mimeType}
          magicString={magicString}
        ></DataViewerInternal>
      </div>
    </div>
    );
  </Modal>
);

const FV = ({
  resource,
  filename,
  onClose,
  mimetype,
  magicString,
}: {
  resource: ResourceReference | string;
  filename: string;
  onClose: () => void;
  mimetype?: string;
  magicString?: string;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<string[] | null>(null);

  const { getAccessTokenSilently } = useAuth0();
  const { api } = useContext(APIContext);

  useEffect(() => {
    let cancelled = false;

    if (typeof resource === "string") {
      api
        .callAPI(
          getAccessTokenSilently,
          api.getArtifactByPathToMemory,
          resource
        )
        .then((s: string) => {
          if (cancelled || !s) return;
          let hexBytes = [];
          for (let i = 0; i < s.length; i++) {
            hexBytes.push(
              ("0" + s.charCodeAt(i).toString(16).toUpperCase()).slice(-2)
            );
          }
          setData(hexBytes);
        })
        .finally(() => {
          if (cancelled) return;
          setIsLoading(false);
        });
    } else {
      const p = api.callAPI(
        getAccessTokenSilently,
        api.getSampleToMemory,
        resource.jobid,
        resource.sha256
      ) as Promise<string[]>;
      p.then((d) => {
        if (cancelled) return;
        setData(d);
      }).finally(() => {
        if (cancelled) return;
        setIsLoading(false);
      });
    }

    return () => {
      cancelled = true;
    };
  }, [api, getAccessTokenSilently, resource, filename]);

  useEffect(() => {
    const listener = (evt: KeyboardEvent) => {
      if (evt.key.toUpperCase() === "ESCAPE") {
        evt.preventDefault();
        onClose();
      }
    };

    document.addEventListener("keydown", listener);

    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [onClose]);

  const tabs = [
    <Tab label="Hex" key="1">
      <HexViewer data={data || []} />
    </Tab>,
  ];

  const insertAt =
    mimetype && mimetype.toLowerCase().startsWith("text") ? 0 : 1;

  const asciiData = data
    ?.slice(0, MAX_RAW_ASCII_CHARS)
    .map(hexToASCIIWithWhiteSpacePreserved)
    .join("");

  tabs.splice(
    insertAt,
    0,
    <Tab label="ASCII" key="2">
      <div className="columns has-margin-bottom-5">
        <div className="column hexviewer" style={{ maxWidth: "100%" }}>
          <pre
            style={{
              height: "75vh",
              overflow: "auto",
              padding: "0.1rem",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
            }}
          >
            {asciiData}
          </pre>
          {data?.length && data.length > MAX_RAW_ASCII_CHARS ? (
            <p className="has-text-centered has-text-grey">
              (truncated to the first {MAX_RAW_ASCII_CHARS} characters of{" "}
              {data.length})
            </p>
          ) : null}
        </div>
      </div>
    </Tab>
  );

  if (asciiData !== undefined) {
    let language = guessLanguage(filename, mimetype, magicString);
    if (language !== undefined) {
      tabs.splice(
        insertAt + 1,
        0,
        <Tab label="Pretty" key="3">
          <div
            className="columns hexviewer has-margin-bottom-5"
            style={{
              maxWidth: "100%",
              height: "75vh",
              overflow: "auto",
              padding: "0.75rem",
            }}
          >
            <SourceCodeString
              value={asciiData}
              language={language}
              pretty={true}
              disableExpansionBox={true}
              wrapLongLines={true}
            />
          </div>
        </Tab>
      );
    }
  }

  return (
    <Card filename={filename} close={onClose}>
      <LoadingWrap isLoading={isLoading}>
        <Tabs>{tabs}</Tabs>
      </LoadingWrap>
    </Card>
  );
};
