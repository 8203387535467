import { Job } from "src/lib/APITypes";
import * as Menu from "./Menu";
import { ResubmitSample } from "./ResubmitSample";
import { DeleteJob } from "./DeleteJob";
import { ShareJob } from "./ShareControl";
import { ReportJob } from "./ReportJob";
import { SplunkLogs } from "src/lib/Otel";
import { DebugInfo } from "./DebugInfo";
import { DownloadPDFReport } from "./DownloadPDF";

export const JobMenu = ({
  job,
  reloadJob,
}: {
  job: Job;
  reloadJob: VoidFunction;
}) => {
  return (
    <>
      <div style={{ display: "contents" }}>
        <ReportJob job={job} />
        <ResubmitSample job={job} />
        <Menu.More text="More">
          <DownloadPDFReport job={job} />
          <DeleteJob job={job} />
          <ShareJob job={job} reloadJob={reloadJob} />
          <SplunkLogs job={job} />
          <DebugInfo job={job} />
        </Menu.More>
      </div>
    </>
  );
};
