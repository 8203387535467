import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { toast } from "react-toastify";
import { APIContext } from "src/lib/MAPApi";
import { UserHasPermission } from "../../lib/Auth";
import { CaveatCard } from "../Caveats";
import { FileSize } from "../FileSize";
import { DetectionTable } from "../forensics/Detections";
import { ForensicsScreenshots } from "../forensics/ForensicsScreenshots";
import { SourceCodeString } from "../forensics/SourceCodeString";
import { FileComponent, FormComponent } from "../NormalizedForensics";
import { ResourceSummary } from "../ResourceSummary";
import { ExpandableBox } from "../utils/ExpandableBox";
import { Tab, Tabs } from "../utils/Tabs";
import { ThreatName } from "../utils/ThreatName";
import { JsHooksViewer } from "./JsHooks";

const DownloadedFile = ({ file }) => {
  return (
    <div className="card has-margin-bottom-20">
      <header className="card-header">
        <p className="card-header-title wrap-anywhere">{file.filename}</p>
      </header>
      <div className="card-content">
        <div className="content">
          <div>
            <b>File Type:</b> {file.magic || "unknown"}
          </div>
          <div>
            <b>SHA256 Hash:</b> {file.sha256 || "NA"}
          </div>
          <div>
            <b>MD5 Hash:</b> {file.md5 || "NA"}
          </div>
          <div>
            <b>Ssdeep Hash:</b> {file.ssdeep || "NA"}
          </div>
          <div>
            <b>File Size:</b> <FileSize size={file.size} />
            {file.size > 50 * 1024 * 1024 && (
              <span className="has-text-danger">
                {" "}
                <FontAwesomeIcon icon="exclamation-triangle" /> File is too
                large for additional analysis
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const WebAnalyzerResults = ({
  task,
  job,
  forensics,
  resource,
  engine,
}) => {
  const { api } = useContext(APIContext);
  const details = task.Results.Details || {};
  const downloads = details.Downloads || [];

  const forensicsLoaded = forensics && forensics !== "loading";

  const auditDetections = details.AuditDetections || [];
  let detections = [];
  let forms = [];
  let sourceCode = [];
  let deobfsJs = [];
  let savedArtifacts = new Map();
  let savedFiles = [];
  let savedJsHooks = [];

  let redirectTarget = null;

  const { getAccessTokenSilently } = useAuth0();

  let har = null;
  let images = [];
  if (forensicsLoaded) {
    detections = forensics.Detections;
    forms = forensics.Forms;

    const hars = (forensics.SavedArtifacts || []).filter(
      (s) => s.Type === "har"
    );
    if (hars.length > 0) {
      har = hars[0].ArtifactPath;
    }

    let http = forensics.HTTP || [];
    if (
      http.length === 1 &&
      http[0].StatusCode > 300 &&
      http[0].StatusCode < 400
    ) {
      redirectTarget =
        http[0].ResponseHeaders.Location || http[0].ResponseHeaders.location;
    }

    sourceCode = forensics.Strings.filter((s) =>
      ["html_dom", "http_response"].includes((s.Details || {}).Type)
    );

    deobfsJs = forensics.Strings.filter((s) =>
      ["html_js_deobfs"].includes((s.Details || {}).Type)
    );

    for (let sa of forensics.SavedArtifacts || []) {
      savedArtifacts.set(sa.Name, sa);
      if (sa.Type === "tw_web_jshooks") {
        savedJsHooks.push(sa);
      }
    }
    for (let f of forensics.Files || []) {
      if (savedArtifacts.get(f.SHA256)) {
        savedFiles.push(f);
      }
    }

    images = forensics.Images ?? forensics.Screenshots ?? [];
  }

  const downloadHAR = () => {
    api
      .callAPI(
        getAccessTokenSilently,
        api.getArtifactByPath,
        details.HAR || har
      )
      .catch(() => toast.error("Error downloading HAR file"));
  };

  const downloadHarAsPCAP = () => {
    api
      .callAPI(getAccessTokenSilently, api.getHarAsPCAP, details.HAR || har)
      .catch((e) => toast.error("Error downloading PCAP file" + e));
  };

  let actions = [];
  if (details.HAR || har) {
    actions = [
      { title: "Download HAR Archive", onClick: downloadHAR, icon: "download" },
      {
        title: "Download PCAP (converted from HAR)",
        onClick: downloadHarAsPCAP,
        icon: "download",
      },
    ];
  }

  let viewTabs = [];

  if (forensicsLoaded) {
    if (images.length > 0) {
      viewTabs.push(
        <Tab label={`Screenshots (${images.length})`} key={viewTabs.length}>
          {images.length > 0 ? (
            <ForensicsScreenshots
              engine={engine}
              forensics={forensics}
              noExpansion={true}
            />
          ) : (
            <div>No screenshot taken</div>
          )}
        </Tab>
      );
    }
    if (forms.length > 0) {
      viewTabs.push(
        <Tab label={`Forms (${forms.length})`} key={viewTabs.length}>
          <table className="table">
            <tbody>
              {forms.map((f, idx) => (
                <FormComponent value={f} key={idx} />
              ))}
            </tbody>
          </table>
        </Tab>
      );
    }
    if (sourceCode.length > 0) {
      viewTabs.push(
        <Tab label={`Source (${sourceCode.length})`} key={viewTabs.length}>
          {sourceCode.map((s, i) => (
            <SourceCode string={s} key={i} />
          ))}
        </Tab>
      );
    }
    if (deobfsJs.length > 0) {
      viewTabs.push(
        <Tab
          label={`Deobfuscated JS (${deobfsJs.length})`}
          key={viewTabs.length}
        >
          {deobfsJs.map((s, i) => (
            <SourceCode string={s} key={i} />
          ))}
        </Tab>
      );
    }
    if (savedJsHooks.length > 0) {
      viewTabs.push(
        <Tab label={`JS Events`} key={viewTabs.length}>
          <JsHooksViewer savedArtifacts={savedJsHooks} />
        </Tab>
      );
    }
    if (downloads.length > 0) {
      viewTabs.push(
        <Tab label={`Downloads (${downloads.length})`} key={viewTabs.length}>
          {downloads.map((d, idx) => (
            <>
              {idx !== 0 && <hr />}
              <DownloadedFile
                file={d}
                key={d.sha256}
                jobid={task.JobID}
                task={task}
              />
            </>
          ))}
        </Tab>
      );
    }
    if (savedFiles.length > 0) {
      viewTabs.push(
        <Tab label={`Artifacts (${savedFiles.length})`} key={viewTabs.length}>
          <table className="table">
            <tbody>
              {savedFiles.map((f, idx) => (
                <FileComponent
                  value={f}
                  key={`${idx}:${f.SHA256}:${f.Path}:${f.FileName}`}
                  savedArtifact={savedArtifacts.get(f.SHA256)}
                  fileType={f.FileType}
                />
              ))}
            </tbody>
          </table>
        </Tab>
      );
    }
  }

  return (
    <section>
      <ResourceSummary
        job={job}
        resource={resource}
        task={task}
        menuActions={actions}
      />
      {task.Results.Details.Caveats && (
        <CaveatCard
          caveats={task.Results.Details.Caveats}
          engine="web_analyzer"
        />
      )}
      {!forensicsLoaded ? (
        <section>
          <div className="has-text-info">Loading...</div>
        </section>
      ) : (
        <section>
          {redirectTarget && (
            <div className="has-padding-bottom-20">
              <b>Page redirects to:</b> <ThreatName name={redirectTarget} />
            </div>
          )}

          {(detections.length > 0 || auditDetections.length > 0) && (
            <ExpandableBox
              title={`Detections (${detections.length})`}
              maxHeight="25em"
            >
              {detections && detections.length > 0 && (
                <DetectionTable detections={detections} />
              )}
              <UserHasPermission permission="auditrule:view">
                {auditDetections && auditDetections.length > 0 && (
                  <div className="box has-background-grey-lighter">
                    <h1 className="title is-4">Audit Mode Detections</h1>
                    <DetectionTable detections={auditDetections} />
                  </div>
                )}
              </UserHasPermission>
            </ExpandableBox>
          )}

          {viewTabs.length > 0 ? (
            <Tabs>{viewTabs}</Tabs>
          ) : (
            <div>No screenshot taken</div>
          )}
        </section>
      )}
    </section>
  );
};

const SourceCode = ({ string }) => {
  const typeMap = {
    http_response: "HTML - Raw Source",
    html_dom: "HTML - Evaluated Browser DOM",
    html_js_deobfs: "Deobfuscated Javascript",
  };

  const language =
    string.Details.Type === "html_js_deobfs" ? "javascript" : "markup";

  const header = (
    <>
      <p className="has-text-info has-text-weight-medium">
        {typeMap[string.Details.Type]}
      </p>
      {string.Details.URL && (
        <div className="has-text-grey has-ellipsed-text">
          <span className="has-text-weight-bold">URL: </span>
          <ThreatName
            name={string.Details.URL}
            ellipsed={true}
            showTitle={true}
          />
        </div>
      )}
    </>
  );

  return (
    <SourceCodeString
      value={string.String}
      title={header}
      language={language}
      toolbarEnabled={true}
    />
  );
};
