import React from "react";
import "../ResourceTree.css";

function addChildProcess(children, process) {
  try {
    if (!children.some((p) => p.PID === process.PID)) {
      children.push(process);
    }
  } catch {}
}

const ProcessNode = (props) => {
  const proc = props.process;
  const children = proc.children;

  children.sort((a, b) => a.PID - b.PID);

  let procDisplay = proc.Arguments.trim();
  if (!proc.Arguments.toLowerCase().includes(proc.Name.toLowerCase())) {
    procDisplay = proc.Path.trim() + " " + proc.Arguments.trim();
  }

  if (procDisplay[0] === '"' && procDisplay[procDisplay.length - 1] === '"') {
    procDisplay = procDisplay.substr(1, procDisplay.length - 2); // if it's quoted, strip the quotes
  }

  if (proc.Path.trim().endsWith(procDisplay)) {
    procDisplay = proc.Path.trim();
  }

  return (
    <li className={props.root ? "root" : undefined}>
      <span className="threat-name">
        {procDisplay}
        {proc.PID !== 0 && (
          <>
            {" "}
            <span className="has-text-grey is-italic">(pid: {proc.PID})</span>
          </>
        )}
      </span>
      {children.length > 0 && (
        <ul>
          {children.map((p, idx) => (
            <ProcessNode process={p} key={idx} />
          ))}
        </ul>
      )}
    </li>
  );
};

export const ProcessTree = (props) => {
  let procmap = {};

  props.processes.forEach((p) => {
    p.children = [];

    if (p.PID !== 0) {
      procmap[p.PID] = p;
    }
    try {
      p.Details.OtherPIDs.forEach((op) => {
        procmap[op] = p;
      });
    } catch {}
  });

  props.processes.forEach((p) => {
    if (p.PPID !== 0) {
      let pp = procmap[p.PPID];
      if (pp !== undefined) {
        addChildProcess(procmap[p.PPID].children, p);
        p.parent = pp;
      }
    }
  });

  const rootNodes = props.processes.filter((p) => p.parent === undefined);
  rootNodes.sort((a, b) => a.PID - b.PID);

  return (
    <div className="treelist">
      <ul className="root">
        {rootNodes.map((p) => (
          <ProcessNode process={p} key={p.PID} root={true} />
        ))}
      </ul>
    </div>
  );
};
